import React from "react";
import Button from "components/CustomButtons/Button";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { useHistory } from "react-router-dom";

const GoBack = (props) => {
  const history = useHistory();

  const goBack = () => {
    if (props.returnLocation) {
      window.location.href = props.returnLocation
    } else {
      history.goBack();
    }
  };

  return (
    <React.Fragment>
      <Button onClick={goBack} round={!props.disableRound} color="white" style={{ padding: '12px 20px', display: 'flex', alignItems: 'center' }}>
        <ArrowBackIcon /> <span style={{ marginBottom: '-2.5px' }}>Back</span>
      </Button>
    </React.Fragment>
  );
};

export default GoBack;
