import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import React, { useState, useRef, useEffect } from "react";
import ReactDOM from "react-dom";
import Button from "components/CustomButtons/Button";
import { ArrowRightAltTwoTone, Close, DeleteForever } from "@material-ui/icons";
import axios from "axios";
import { useRecoilValue } from "recoil";
import { viewSinglePo } from "state/purchaseOrders";
import update from "immutability-helper";
import api from "../../api.config";
import Loading from "components/Loading/Loading";
import { getSinglePO } from "state/purchaseOrders";

const FileListModal = (props) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const currentPo = props.currentPo

  const [uploading, setUploading] = useState(false)

  const [files, setFiles] = useState(JSON.parse(currentPo.attachedFiles));
  //console.log("files", files);

  const fileUploadField = useRef();

  const styles = {
    wrap: {
      background: "#000",
      position: "absolute",
      top: "0",
      left: "0",
      right: "0",
      bottom: "0",
      zIndex: 99998,
      opacity: ".7",
    },
    modal: {
      // position: "absolute",
      // top: "10vh",
      // left: "2vw",
      //marginTop: '10vh',
      width: "96vw",
      maxWidth: '800px',
      margin: "0",
      boxShadow: "0px 0px 20px 5px #333",
      padding: "1rem 2rem",
      //paddingTop: "0",
      background: "#fff",
      zIndex: 99999,
      borderRadius: "10px",
      maxHeight: "80vh",
      overflowY: "auto",
    },
  };

  const fileUploadChangeHandler = (e) => {
    //console.log("e.target.files", e.target.files, [...e.target.files]);

    setSelectedFile(e.target.files);
  };

  const fileUploadHandler = async () => {
    //console.log("file ref", fileUploadField, fileUploadField.current);
    /* if (selectedFile === null) {
      alert("Please choose a file first.");
      return;
    }

    if (
      selectedFile.type !== "image/png" &&
      selectedFile.type !== "image/jpeg" &&
      selectedFile.type !== "application/pdf"
    ) {
      alert("Invalid file type. Please upload a png, jpg, or pdf.");
      return;
    }

    if (selectedFile.size > 25000000) {
      alert("File size too large. Please select a file under 25MB");
      return;
    } */

    if (selectedFile === null) {
      alert("Please choose a file first.");
      return;
    }

    //console.log("selectedFile", selectedFile);

    const formData = new FormData();

    formData.append("poid", currentPo.id);

    let fileErrors = false;
    let appendedFiles = 0;
    let totalSize = 0;
    let totalFiles = files?.length + 1 || 0;
    //console.log("totalFiles start", totalFiles);

    for (let i = 0; i < selectedFile.length; i++) {
      //console.log("total files", totalFiles);
      if (totalFiles > 20) {
        alert("You can only upload 20 files.");
        break;
      }
      //console.log("selectedFile[i]", selectedFile[i], selectedFile[i].name);
      //allFiles.push(selectedFile[i]);

      if (
        selectedFile[i].type !== "image/png" &&
        selectedFile[i].type !== "image/jpeg" &&
        selectedFile[i].type !== "application/pdf"
      ) {
        //alert("Invalid file type. Please upload a png, jpg, or pdf.");
        fileErrors = true;
        continue;
      }

      if (selectedFile[i].size > 25000000) {
        //alert("File size too large. Please select a file under 25MB");
        fileErrors = true;
        continue;
      }

      formData.append("files", selectedFile[i], selectedFile[i].name);
      appendedFiles++;
      totalSize += selectedFile[i].size;
      totalFiles++;
    }

    if (totalSize > 200000000) {
      alert(
        "Your files are too large. Please remove some or compress them and try again."
      );
      return;
    }

    if (fileErrors) {
      alert(
        "Some of your files were either too large (over 25mb) or not an accepted file type (png, jpg, pdf)."
      );
    }

    // If we have no files appended then we should skip calling the api and just exit early
    if (!appendedFiles) {
      return;
    }
    //console.log("allFiles", allFiles);
    //formData.append("file", allFiles);

    //formData.append("file", selectedFile);
    setUploading(true)
    await axios
      .post(`${api.path}/digitalpo_api/v1/attachfile`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        //console.log("res", res);
        // TODO: Add indicator and update state

        setFiles(JSON.parse(res.data));

        setSelectedFile(null);

        if (files < 20 & fileUploadField & fileUploadField.current) {
          fileUploadField.current.value = "";
        }
      });
    setUploading(false)
  };

  const deleteImageHandler = (fileName) => {
    let postData = {
      poid: currentPo.id,
      removefiles: JSON.stringify(files.filter((item) => item === fileName)),
      updatedfilelist: files.filter((item) => item !== fileName).length === 0 ? null : JSON.stringify(
        files.filter((item) => item !== fileName)
      ),
    };

    axios
      .post(`${api.path}/digitalpo_api/v1/deletefile`, {
        data: postData,
      })
      .then((res) => {
        // TODO: Add indicator and update state
        setFiles(postData.updatedfilelist ? JSON.parse(postData.updatedfilelist) : postData.updatedfilelist);
      });
  };

  return (
    <React.Fragment>
      {ReactDOM.createPortal(
        <div style={styles.wrap} onClick={props.closeHandler}></div>,
        document.getElementById("file-list-modal-overlay")
      )}
      {ReactDOM.createPortal(
        <div style={{ position: 'absolute', width: '100vw', top: '10vh' }}>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <GridContainer style={styles.modal}>
              {!currentPo ? <Loading color={'blue'} />
                : <>
                  <GridContainer style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'nowrap' }}>
                    <div style={{ lineBreak: 'anywhere' }}>
                      <h3 style={{ margin: '0px', marginTop: '5px' }}>
                        #{currentPo.id} - {currentPo.vendor}
                      </h3>
                      <span style={{ fontSize: "16px" }}>
                        Purchase Reason: {currentPo.purchaseReason}
                      </span>
                    </div>
                    <div style={{ minWidth: '32px' }}>
                      <Button
                        justIcon
                        round
                        color="danger"
                        size="sm"
                        onClick={props.closeHandler}
                      >
                        <Close />
                      </Button>
                    </div>
                  </GridContainer>
                  <GridContainer
                  >
                    {Array.isArray(files) &&
                      files.map((item, index) => {
                        return (
                          <React.Fragment key={index}>
                            <GridItem xs={12}>
                              <Button
                                color="danger"
                                justIcon
                                round
                                size="sm"
                                style={{ marginRight: "15px" }}
                              >
                                <DeleteForever
                                  onClick={() => deleteImageHandler(item)}
                                />
                              </Button>
                              <span style={{ fontWeight: "bold", fontSize: "1.2rem" }}>
                                {item.replace("static/", "")}
                              </span>
                            </GridItem>
                          </React.Fragment>
                        );
                      })}

                    {!Array.isArray(files) && (
                      <React.Fragment>
                        <GridItem>
                          <span style={{ fontWeight: "bold", fontSize: "1.2rem" }}>
                            No files attached.
                          </span>
                        </GridItem>
                      </React.Fragment>
                    )}
                  </GridContainer>

                  <GridContainer>
                    <GridItem xs={12} style={{ textAlign: "center" }}>
                      {uploading ?
                        <Loading color="blue" />
                        :
                        <>
                          {(files?.length < 20 || files === null) && (
                            <>
                              <input
                                style={{ marginTop: '10px' }}
                                type="file"
                                onChange={fileUploadChangeHandler}
                                ref={fileUploadField}
                                multiple
                              />

                              <Button style={{ marginTop: '10px' }} color="primary" onClick={fileUploadHandler}>
                                Upload
                              </Button>
                            </>
                          )}
                        </>
                      }
                    </GridItem>

                  </GridContainer>
                </>}
            </GridContainer>
          </div>
        </div>,
        document.getElementById("file-list-modal")
      )}
    </React.Fragment>
  );
};

export default FileListModal;
