import React from "react";
import CustomTabs from "components/CustomTabs/CustomTabs";
import { useRecoilState } from "recoil";
import Loading from "components/Loading/Loading";
import ListTickets from "./ListTickets";
import {
  myTicketsAtom,
  getMyTickets
} from "state/ticketSystem";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";

const MyTicketsComp = (props) => {

  const [tickets, setTickets] = useRecoilState(myTicketsAtom)
  const redirect = `${props.location.pathname}${props.location.search}`

  const updateTickets = async () => {
    let t = await getMyTickets()
    setTickets(t)
    return
  }

  const searchOpenTicket = (tickets, search) => {
    return tickets.filter(ticket => {
      return (ticket.subject.toLowerCase().includes(search.toLowerCase()) ||
        (ticket.number.toString()).includes(search) ||
        ticket.department.toLowerCase().includes(search.toLowerCase()) ||
        ticket.created.toLowerCase().includes(search.toLowerCase()) ||
        ticket.userName.toLowerCase().includes(search.toLowerCase()))
    })
  }
  const openCols = [
    {
      Header: "#",
      accessor: "number",
      disableFilters: true,
      disableSortBy: true,
      style: { maxWidth: '50px', fontSize: '11px' },
      headerStyle: { maxWidth: '50px', margin: '0px' }
    },
    {
      Header: "Created",
      accessor: "created",
      disableFilters: true,
      disableSortBy: true,
      style: { fontSize: '11px', maxWidth: '74px' },
      headerStyle: { margin: '0px', maxWidth: '74px' }
    },
    {
      Header: "Department",
      accessor: "department",
      disableFilters: true,
      disableSortBy: true,
      style: { maxWidth: '125px' },
      headerStyle: { margin: '0px', maxWidth: '125px' }
    },
    {
      Header: "Subject",
      accessor: "subjectLink",
      disableFilters: true,
      disableSortBy: true,
      style: { minWidth: '200px' },
      headerStyle: { minWidth: '200px', margin: '0px' }
    },
    {
      Header: "",
      accessor: "notifications",
      disableFilters: true,
      disableSortBy: true,
      style: { maxWidth: '70px', padding: '0', fontSize: '12px' },
      headerStyle: { maxWidth: '70px', padding: '0', margin: '0px' }
    },
    {
      Header: "Created By",
      accessor: "userName",
      disableFilters: true,
      disableSortBy: true,
      style: { maxWidth: '150px' },
      headerStyle: { margin: '0px', maxWidth: '150px' }
    },
    {
      Header: "Status",
      accessor: "status",
      disableFilters: true,
      disableSortBy: true,
      style: { maxWidth: '74px' },
      headerStyle: { margin: '0px', maxWidth: '74px' }
    },
    {
      Header: "",
      accessor: "view",
      disableFilters: true,
      disableSortBy: true,
      style: { maxWidth: '50px' },
      headerStyle: { margin: '0px', maxWidth: '50px' }
    },
  ]


  return (
    <React.Fragment>
      <div style={{ maxWidth: '1200px' }}>
        <Card>
          <CardHeader color='primary'>
            <span>My Tickets</span>
          </CardHeader>
          <CardBody>
            <ListTickets
              list={tickets}
              viewType='user'
              buttons={{
                view: true,
                refresh: true
              }}
              cols={openCols}
              searchTicket={searchOpenTicket}
              redirect={redirect}
              updateList={updateTickets}
            />
          </CardBody>
        </Card>
      </div>
    </React.Fragment>
  )
}

const MyTicketsLoading = (props) => {
  const tabs = [
    {
      tabName: "Open",
      tabContent: <Loading color="blue" />,
    },
    {
      tabName: "Closed",
      tabContent: <Loading color="blue" />,
    },
  ];
  return (
    <React.Fragment>
      <div style={{ maxWidth: '1200px' }}>
        <CustomTabs headerColor="info" tabs={tabs} />
      </div>
    </React.Fragment>
  );
}

const MyTickets = (props) => {
  return (
    <React.Suspense fallback={<MyTicketsLoading {...props} />}>
      <MyTicketsComp {...props} />
    </React.Suspense>
  )
}

export default MyTickets
