// regular weekend numbers per class
const regular = {
  EH_AI: 60,
  EH_SS: 72,
  EH_W: 60,
  FM_AI: 60,
  FM_SS: 72,
  FM_W: 72,
  HL_AI: 60,
  HL_SS: 48,
  HL_W: 48,
  LL_AI: 36,
  LL_SS: 36,
  LL_W: 36,
  NE_AI: 60,
  NE_SS: 72,
  NE_W: 72,
  RS_AI_K: 192,
  RS_AI_1ST: 192,
  RS_AI_2ND: 192,
  RS_SS_DOLPHINS: 84,
  RS_SS_FISH_TANK: 96,
  RS_SS_PENGUINS: 72,
  RS_SS_SEA_LIONS: 72,
  RS_SS_SEA_TURTLES: 60,
  RS_SS_STINGRAYS: 48,
  RS_SS_SWORDFISH: 48,
  RS_W_3RD: 204,
  RS_W_4th_5TH: 204,
  SF_AI: 36,
  SF_SS: 24,
  SF_W: 36,
  UP_AI: 60,
  UP_SS: 48,
  UP_W: 48,
  KL_AI: 0,
  KL_SS: 0,
  KL_W: 0,
}

// big weekend numbers per class
const bigEvent = {
  EH_AI: 80,
  EH_SS: 80,
  EH_W: 80,
  FM_AI: 80,
  FM_SS: 80,
  FM_W: 80,
  HL_AI: 80,
  HL_SS: 60,
  HL_W: 70,
  LL_AI: 40,
  LL_SS: 40,
  LL_W: 50,
  NE_AI: 80,
  NE_SS: 80,
  NE_W: 80,
  RS_AI_K: 245,
  RS_AI_1ST: 245,
  RS_AI_2ND: 245,
  RS_SS_DOLPHINS: 100,
  RS_SS_FISH_TANK: 115,
  RS_SS_PENGUINS: 90,
  RS_SS_SEA_LIONS: 90,
  RS_SS_SEA_TURTLES: 80,
  RS_SS_STINGRAYS: 65,
  RS_SS_SWORDFISH: 65,
  RS_W_3RD: 245,
  RS_W_4th_5TH: 245,
  SF_AI: 30,
  SF_SS: 30,
  SF_W: 35,
  UP_AI: 70,
  UP_SS: 70,
  UP_W: 70,
  KL_AI: 0,
  KL_SS: 0,
  KL_W: 0,
}

// number of services per campus
export const NumberOfServices = [
  {
    label: 'EH',
    value: 2
  },
  {
    label: 'FM',
    value: 3
  },
  {
    label: 'HL',
    value: 2
  },
  {
    label: 'LL',
    value: 1
  },
  {
    label: 'NE',
    value: 2
  },
  {
    label: 'RS',
    value: 5
  },
  {
    label: 'SF',
    value: 2
  },
  {
    label: 'UP',
    value: 2
  },
  {
    label: 'KL',
    value: 0
  },
]

//the breakout of RS and multisite and their room names and numbers for a regular weekend for Seaside Surf
export const SSRegular = {
  RS: {
    label: 'Riverside',
    entries: [
      {
        label: 'Dolphins',
        value: regular.RS_SS_DOLPHINS
      },
      {
        label: 'Fish Tank',
        value: regular.RS_SS_FISH_TANK
      },
      {
        label: 'Penguins',
        value: regular.RS_SS_PENGUINS
      },
      {
        label: 'Sea Lions',
        value: regular.RS_SS_SEA_LIONS
      },
      {
        label: 'Sea Turtles',
        value: regular.RS_SS_SEA_TURTLES
      },
      {
        label: 'Stingrays',
        value: regular.RS_SS_STINGRAYS
      },
      {
        label: 'Swordfish',
        value: regular.RS_SS_SWORDFISH
      }
    ]
  },
  multisite: {
    label: 'Multisites',
    entries: [
      {
        label: 'EH',
        value: regular.EH_SS
      },
      {
        label: 'FM',
        value: regular.FM_SS
      },
      {
        label: 'HL',
        value: regular.HL_SS
      },
      {
        label: 'LL',
        value: regular.LL_SS
      },
      {
        label: 'NE',
        value: regular.NE_SS
      },
      {
        label: 'SF',
        value: regular.SF_SS
      },
      {
        label: 'UP',
        value: regular.UP_SS
      },
      {
        label: 'KL',
        value: regular.KL_SS
      },
    ],
  }
}

//the breakout of RS and multisite and their room names and numbers for a big weekend for Seaside Surf
export const SSBigEvent = {
  RS: {
    label: 'Riverside',
    entries: [
      {
        label: 'Dolphins',
        value: bigEvent.RS_SS_DOLPHINS
      },
      {
        label: 'Fish Tank',
        value: bigEvent.RS_SS_FISH_TANK
      },
      {
        label: 'Penguins',
        value: bigEvent.RS_SS_PENGUINS
      },
      {
        label: 'Sea Lions',
        value: bigEvent.RS_SS_SEA_LIONS
      },
      {
        label: 'Sea Turtles',
        value: bigEvent.RS_SS_SEA_TURTLES
      },
      {
        label: 'Stingrays',
        value: bigEvent.RS_SS_STINGRAYS
      },
      {
        label: 'Swordfish',
        value: bigEvent.RS_SS_SWORDFISH
      }
    ]
  },
  multisite: {
    label: 'Multisites',
    entries: [
      {
        label: 'EH',
        value: bigEvent.EH_SS
      },
      {
        label: 'FM',
        value: bigEvent.FM_SS
      },
      {
        label: 'HL',
        value: bigEvent.HL_SS
      },
      {
        label: 'LL',
        value: bigEvent.LL_SS
      },
      {
        label: 'NE',
        value: bigEvent.NE_SS
      },
      {
        label: 'SF',
        value: bigEvent.SF_SS
      },
      {
        label: 'UP',
        value: bigEvent.UP_SS
      },
      {
        label: 'KL',
        value: bigEvent.KL_SS
      },
    ],
  }
}

//the breakout of RS and multisite and their room names and numbers for a regular weekend for Adventure Island
export const AIRegular = {
  RS: {
    label: 'Riverside',
    entries: [
      {
        label: 'Kinder',
        value: regular.RS_AI_K
      },
      {
        label: '1st',
        value: regular.RS_AI_1ST
      },
      {
        label: '2nd',
        value: regular.RS_AI_2ND
      },
    ]
  },
  multisite: {
    label: 'Multisites',
    entries: [
      {
        label: 'EH',
        value: regular.EH_AI
      },
      {
        label: 'FM',
        value: regular.FM_AI
      },
      {
        label: 'HL',
        value: regular.HL_AI
      },
      {
        label: 'LL',
        value: regular.LL_AI
      },
      {
        label: 'NE',
        value: regular.NE_AI
      },
      {
        label: 'SF',
        value: regular.SF_AI
      },
      {
        label: 'UP',
        value: regular.UP_AI
      },
      {
        label: 'KL',
        value: regular.KL_AI
      },
    ],
  }
}

//the breakout of RS and multisite and their room names and numbers for a big weekend for Adventure Island
export const AIBigEvent = {
  RS: {
    label: 'Riverside',
    entries: [
      {
        label: 'Kinder',
        value: bigEvent.RS_AI_K
      },
      {
        label: '1st',
        value: bigEvent.RS_AI_1ST
      },
      {
        label: '2nd',
        value: bigEvent.RS_AI_2ND
      },
    ]
  },
  multisite: {
    label: 'Multisites',
    entries: [
      {
        label: 'EH',
        value: bigEvent.EH_AI
      },
      {
        label: 'FM',
        value: bigEvent.FM_AI
      },
      {
        label: 'HL',
        value: bigEvent.HL_AI
      },
      {
        label: 'LL',
        value: bigEvent.LL_AI
      },
      {
        label: 'NE',
        value: bigEvent.NE_AI
      },
      {
        label: 'SF',
        value: bigEvent.SF_AI
      },
      {
        label: 'UP',
        value: bigEvent.UP_AI
      },
      {
        label: 'KL',
        value: bigEvent.KL_AI
      },
    ],
  }
}

//the breakout of RS and multisite and their room names and numbers for a regular weekend for Wired
export const WRegular = {
  RS: {
    label: 'Riverside',
    entries: [
      {
        label: '3rd',
        value: regular.RS_W_3RD
      },
      {
        label: '4th & 5th',
        value: regular.RS_AI_1ST
      },
    ]
  },
  multisite: {
    label: 'Multisites',
    entries: [
      {
        label: 'EH',
        value: regular.EH_W
      },
      {
        label: 'FM',
        value: regular.FM_W
      },
      {
        label: 'HL',
        value: regular.HL_W
      },
      {
        label: 'LL',
        value: regular.LL_W
      },
      {
        label: 'NE',
        value: regular.NE_W
      },
      {
        label: 'SF',
        value: regular.SF_W
      },
      {
        label: 'UP',
        value: regular.UP_W
      },
      {
        label: 'KL',
        value: regular.KL_W
      },
    ],
  }
}

//the breakout of RS and multisite and their room names and numbers for a big weekend for Wired
export const WBigEvent = {
  RS: {
    label: 'Riverside',
    entries: [
      {
        label: '3rd',
        value: bigEvent.RS_W_3RD
      },
      {
        label: '4th & 5th',
        value: bigEvent.RS_AI_1ST
      },
    ]
  },
  multisite: {
    label: 'Multisites',
    entries: [
      {
        label: 'EH',
        value: bigEvent.EH_W
      },
      {
        label: 'FM',
        value: bigEvent.FM_W
      },
      {
        label: 'HL',
        value: bigEvent.HL_W
      },
      {
        label: 'LL',
        value: bigEvent.LL_W
      },
      {
        label: 'NE',
        value: bigEvent.NE_W
      },
      {
        label: 'SF',
        value: bigEvent.SF_W
      },
      {
        label: 'UP',
        value: bigEvent.UP_W
      },
      {
        label: 'KL',
        value: bigEvent.KL_W
      },
    ],
  }
}