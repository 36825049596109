import React from "react";
import { useRecoilValue } from "recoil";
import { groupsAtom } from "../../state/auth";

const AccessLevel = (props) => {
  const userGroups = useRecoilValue(groupsAtom);

  if (
    userGroups.find(
      (item) =>
        item.name === props.groupName &&
        item.role.toLowerCase() === props.groupRole.toLowerCase()
    )
  ) {
    return <>{props.children}</>;
  }

  return <></>;
};

export default AccessLevel;
