import { atom, selector } from "recoil";
import axios from "axios";
import api from "api.config";

export const onlineFormsAtom = atom({
  key: 'onlineForms',
  default: selector({
    key: 'onlineFormsSelector',
    get: async () => {
      return await getOnlineForms()
    }
  })
})

const sortByTitle = (a, b) => {
  if (a.title.toLowerCase() > b.title.toLowerCase()) return 1
  if (a.title.toLowerCase() < b.title.toLowerCase()) return -1
  return 0
}

export const getOnlineForms = async () => {
  return await axios
    .get(`${api.path}/staff_portal_api/v1/getOnlineForms`)
    .then((res) => {
      return res.data.sort(sortByTitle)
    });
}

export const createOnlineForm = async (newForm) => {
  return await axios.post(`${api.path}/staff_portal_api/v1/createOnlineForm`, newForm)
    .then((res) => {
      return
    })
}

export const editOnlineForm = async (newForm) => {
  return await axios.post(`${api.path}/staff_portal_api/v1/editOnlineForm`, newForm)
    .then((res) => {
      return
    })
}

export const removeOnlineForm = async (form) => {
  return await axios.post(`${api.path}/staff_portal_api/v1/removeOnlineForm`, form)
    .then((res) => {
      return
    })
}

export const linksAtom = atom({
  key: 'links',
  default: selector({
    key: 'linksSelector',
    get: async () => {
      return await getLinks()
    }
  })
})

export const getLinks = async () => {
  return await axios
    .get(`${api.path}/staff_portal_api/v1/getLinks`)
    .then((res) => {
      return res.data
    });
}

export const createLink = async (link) => {
  return await axios.post(`${api.path}/staff_portal_api/v1/createLink`, link)
    .then((res) => {
      return
    })
}

export const editLink = async (link) => {
  return await axios.post(`${api.path}/staff_portal_api/v1/editLink`, link)
    .then((res) => {
      return
    })
}

export const removeLink = async (link) => {
  return await axios.post(`${api.path}/staff_portal_api/v1/deleteLink`, link)
    .then((res) => {
      return
    })
}