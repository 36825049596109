import React, { useState } from "react";
import Button from "components/CustomButtons/Button.js";
import ReactTable from "components/ReactTable/ReactTable.js";
import Loading from "components/Loading/Loading";
import CustomTabs from "components/CustomTabs/CustomTabs";
import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { useRecoilState } from "recoil";
import {
  ownedDepartmentsAtom,
  departmentAnalyticsStartDateAtom,
  departmentAnalyticsEndDateAtom,
  departmentAnalyticsAtom
} from "state/ticketSystem";
import CardBody from "components/Card/CardBody";
import Card from "components/Card/Card";
import ListTickets from "./ListTickets";

const DepartmentAnalyticsComp = (props) => {
  const curTab = +(props.location.search?.split("=")[1] || 0)
  const redirect = `${props.location.pathname}${props.location.search}`
  const [departments, setDepartments] = useRecoilState(ownedDepartmentsAtom)
  const [selectedDepartment, setSelectedDepartment] = useState(departments.length > 0 ? departments[0] : null)

  const [loading, setLoading] = useState(false)

  const [startDate, setStartDate] = useRecoilState(departmentAnalyticsStartDateAtom);
  const [endDate, setEndDate] = useRecoilState(departmentAnalyticsEndDateAtom)

  const [search, setSearch] = useState(selectedDepartment && startDate && endDate ?
    {
      departmentID: selectedDepartment.id,
      start: startDate,
      end: endDate
    }
    :
    null
  )

  const [departmentAnalytics, setDepartmentAnalytics] = useRecoilState(departmentAnalyticsAtom(search))

  const requestAnalytics = () => {
    setSearch({
      departmentID: selectedDepartment.id,
      start: startDate,
      end: endDate
    })
  }

  const exportAnalytics = () => {
    setLoading(true)
    const dictionaryKeys = Object.keys(departmentAnalytics.tickets[0]);
    const dictValuesAsCsv = departmentAnalytics.tickets.map(dict => (
      dictionaryKeys.map(key => dict[key]).join(',')
    ));
    const result = [dictionaryKeys.join(','), ...dictValuesAsCsv].join('\n');

    const csvData = new Blob([result], { type: 'text/csv' });
    const csvURL = URL.createObjectURL(csvData);
    const link = document.createElement('a');
    link.href = csvURL;
    link.download = `Tickets_Closed_${search.start}_to_${search.end}.csv`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    setLoading(false)
  }

  const cols = [
    {
      Header: "#",
      accessor: "number",
      disableFilters: true,
      disableSortBy: true,
      style: { maxWidth: '50px', fontSize: '11px' },
      headerStyle: { maxWidth: '50px', margin: '0px' }
    },
    {
      Header: "Closed",
      accessor: "closed",
      disableFilters: true,
      disableSortBy: true,
      style: { fontSize: '11px', maxWidth: '74px' },
      headerStyle: { margin: '0px', maxWidth: '74px' }
    },
    {
      Header: "From",
      accessor: "user",
      disableFilters: true,
      disableSortBy: true,
      style: { maxWidth: '175px', fontSize: '12px' },
      headerStyle: { maxWidth: '175px', margin: '0px' }
    },
    {
      Header: "Subject",
      accessor: "subjectLink",
      disableFilters: true,
      disableSortBy: true,
      style: { minWidth: '200px', fontSize: '12px' },
      headerStyle: { minWidth: '200px', margin: '0px' }
    },
    {
      Header: "Open For",
      accessor: "openFor",
      disableFilters: true,
      disableSortBy: true,
      style: { maxWidth: '100px', fontSize: '12px' },
      headerStyle: { maxWidth: '100px', margin: '0px' }
    },
    {
      Header: "ClosedBy",
      accessor: "closedBy",
      disableFilters: true,
      disableSortBy: true,
      style: { maxWidth: '175px', fontSize: '12px' },
      headerStyle: { maxWidth: '175px', margin: '0px' }
    },
  ]

  const searchTickets = (tickets, s) => {
    let searchLower = s.toLowerCase()
    return tickets.filter(ticket => {
      return (
        ticket.subject.toLowerCase().includes(searchLower) ||
        ticket.number.toString().includes(searchLower) ||
        ticket.assignedTo.toLowerCase().includes(search) ||
        ticket.closed.includes(searchLower) ||
        ticket.closedBy.toLowerCase().includes(searchLower) ||
        ticket.created.includes(searchLower) ||
        ticket.firstMessage.toLowerCase().includes(searchLower) ||
        ticket.openFor.includes(searchLower) ||
        ticket.tags.toLowerCase().includes(searchLower) ||
        ticket.user.toLowerCase().includes(searchLower)
      )
    })
  }

  const tabs = [
    {
      tabName: "Closed By",
      tabContent: <ReactTable
        columns={[
          {
            Header: "Name",
            accessor: "user",
            disableFilters: false,
            disableSortBy: false,
          },
          {
            Header: "QTY",
            accessor: "number",
            disableFilters: false,
            disableSortBy: false,
          },
        ]}
        data={departmentAnalytics.closedBy}
      />,
    },
    {
      tabName: "Created By",
      tabContent: <ReactTable
        columns={[
          {
            Header: "Name",
            accessor: "user",
            disableFilters: false,
            disableSortBy: false,
          },
          {
            Header: "QTY",
            accessor: "number",
            disableFilters: false,
            disableSortBy: false,
          },
        ]}
        data={departmentAnalytics.createdBy}
      />,
    },
    {
      tabName: "Tickets",
      tabContent: loading ?
        <Loading color='blue' />
        :
        <ListTickets
          list={departmentAnalytics.tickets}
          buttons={{
            customButton: true,
          }}
          viewType='agent'
          cols={cols}
          searchTicket={searchTickets}
          redirect={redirect}
          CustomButton={(
            <Button
              color='info'
              disabled={departmentAnalytics.tickets.length === 0}
              onClick={(e) => {
                e.preventDefault()
                exportAnalytics()
              }}
            >
              Export
            </Button>
          )}
        />,
    }
  ]

  return (
    <React.Fragment>
      <Card style={{ marginTop: '0px' }}>
        <CardBody>
          <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              <div style={{ width: '250px', margin: '5px 0px' }}>
                <Autocomplete
                  style={{ width: '250px' }}
                  disablePortal
                  options={departments}
                  getOptionLabel={(option) =>
                    `${option.name}` || ""
                  }
                  renderInput={(params) => (
                    <TextField {...params} label="Department" variant="outlined" />
                  )}
                  onChange={(e, v) => {
                    setSelectedDepartment(v)
                  }}
                  autoSelect={true}
                  autoComplete={true}
                  autoHighlight={true}
                  disableClearable={true}
                  value={selectedDepartment ? selectedDepartment.id === undefined ? null : selectedDepartment : null}
                  getOptionSelected={(selected) => {
                    return selectedDepartment.id === selected.id
                  }}
                />
              </div>
              <div style={{ width: '10px' }}></div>
              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                <div style={{ margin: '5px 0px' }}>
                  <TextField
                    style={{ width: '150px' }}
                    type="date"
                    variant="outlined"
                    label='Start'
                    value={startDate}
                    onChange={(e) => {
                      setStartDate(e.target.value)
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>
                <div style={{ width: '10px' }}></div>
                <div style={{ margin: '5px 0px' }}>
                  <TextField
                    style={{ width: '150px' }}
                    type="date"
                    variant="outlined"
                    label='End'
                    value={endDate}
                    onChange={(e) => {
                      setEndDate(e.target.value)
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>
              </div>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Button
                color='primary'
                disabled={!selectedDepartment || !startDate || !endDate || startDate > endDate}
                onClick={(e) => {
                  e.preventDefault()
                  requestAnalytics()
                }}
              >
                Request
              </Button>
            </div>
          </div>
        </CardBody>
      </Card>
      <CustomTabs headerColor="info" tabs={tabs} value={curTab} />
    </React.Fragment>
  )
}

const DepartmentAnalyticsLoading = (props) => {
  const curTab = +(props.location.search?.split("=")[1] || 0)
  const tabs = [
    {
      tabName: "Closed By",
      tabContent: <Loading color='blue' />,
    },
    {
      tabName: "Created By",
      tabContent: <Loading color='blue' />,
    },
    {
      tabName: "Tickets",
      tabContent: <Loading color='blue' />,
    }
  ]
  return (
    <React.Fragment>
      <Card style={{ marginTop: '0px' }}>
        <CardBody>
          <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              <div style={{ width: '250px', margin: '5px 0px' }}>
                <Autocomplete
                  style={{ width: '250px' }}
                  options={[]}
                  disablePortal
                  renderInput={(params) => (
                    <TextField {...params} label="Department" variant="outlined" />
                  )}
                  autoSelect={true}
                  autoComplete={true}
                  autoHighlight={true}
                  disableClearable={true}
                  disabled
                />
              </div>
              <div style={{ width: '10px' }}></div>
              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                <div style={{ margin: '5px 0px' }}>
                  <TextField
                    style={{ width: '150px' }}
                    type="date"
                    variant="outlined"
                    label='Start'
                    InputLabelProps={{
                      shrink: true,
                    }}
                    disabled
                  />
                </div>
                <div style={{ width: '10px' }}></div>
                <div style={{ margin: '5px 0px' }}>
                  <TextField
                    style={{ width: '150px' }}
                    type="date"
                    variant="outlined"
                    label='End'
                    InputLabelProps={{
                      shrink: true,
                    }}
                    disabled
                  />
                </div>
              </div>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Button
                color='primary'
                disabled
                onClick={(e) => {
                  e.preventDefault()
                }}
              >
                Request
              </Button>
            </div>
          </div>
        </CardBody>
      </Card>
      <CustomTabs headerColor="info" tabs={tabs} value={curTab} />
    </React.Fragment>
  )
}

const DepartmentAnalytics = (props) => {
  return (
    <React.Suspense fallback={<DepartmentAnalyticsLoading {...props} />}>
      <DepartmentAnalyticsComp {...props} />
    </React.Suspense>
  )
}

export default DepartmentAnalytics