import React, { useState } from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

import Radio from "@material-ui/core/Radio";
import Button from "components/CustomButtons/Button";

import axios from "axios";
import update from "immutability-helper";
import api from "api.config";


import Datetime from "react-datetime";

import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

// Card
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import CardText from "components/Card/CardText";

// Atom stuff
import { useRecoilValue } from "recoil";
import { userAtom } from "state/auth";
import {
  approversListAtom,
  secondApproversListAtom,
  reviewersListAtom,
  poFundCodesAtom,
  poGlCodesAtom,
  poDeptCodesAtom,
} from "state/purchaseOrders";

import dashboardStyles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import Loading from "components/Loading/Loading";
import LoadingBars from "components/Loading/LoadingBars";
import { Prompt } from "react-router-dom";
import GoBack from "components/GoBack/GoBack";

const useStyles = makeStyles(() => ({
  ...styles,
  ...dashboardStyles,
  formSection: {
    marginBottom: "1rem",
  },
}));

const FormToEmailComp = (props) => {

  const classes = useStyles();

  const [submitting, setSubmitting] = useState(false)

  const [header, setHeader] = useState('')
  const [body, setBody] = useState('')

  const formType = props.match.params.formType
  const user = useRecoilValue(userAtom)

  const emailTo = formType === 'maintenance' ? 'maintenance' : 'techsupport'

  const submitHandler = async () => {
    if (body.trim() !== '' && header.trim() !== '') {
      let title = ''
      if (formType === 'techsupport' || formType === 'maintenance') {
        title = `New Ticket via Staff Portal: ${header}`
      } else if (formType === 'webrequest') {
        title = `New PCO Request via Staff Portal: ${header}`
      }

      setSubmitting(true)
      let formData = new FormData()
      formData.append('formid', formType)
      formData.append('data', JSON.stringify({
        emailaddr: user.email,
        title: title,
        body: body
      }))
      await axios
        .post(`${api.path}/form_api_be/v1/formsubmit`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          location.href = `/portal/onlineforms`
        });
    } else {
      alert('Fields cannot be  blank.')
    }
  }
  return (
    <React.Fragment>
      <GoBack />
      <GridContainer>
        <GridItem xs={12} md={10} lg={7}>
          <Card>
            <CardHeader color="info" text>
              <CardText color="info">
                <h4 className={classes.cardTitleWhite}>
                  {(formType === 'maintenance') && (<>Maintenance Request</>)}
                  {(formType === 'techsupport') && (<>Tech Support</>)}
                  {(formType === 'webrequest') && (<>PCO Request</>)}
                </h4>
              </CardText>
            </CardHeader>
            <CardBody>
              {
                submitting ?
                  <Loading color='blue' />
                  :
                  <GridContainer className={classes.formSection}>
                    <GridItem xs={12} md={12}>
                      <span style={{ fontSize: '16px', fontWeight: 'bold' }}>Request:</span>
                      <TextField
                        label=""
                        variant="outlined"
                        fullWidth
                        value={header}
                        onChange={(e) => {
                          let val = e.target.value
                          if (val.length > 100) {
                            val = val.substring(0, 100)
                          }
                          setHeader(val)
                        }}
                        required
                        // error={errors.purchaseReason.hasError}
                        helperText={`${header.length}/100`}
                        style={{ marginBottom: '10px' }}
                      />
                    </GridItem>
                    <GridItem xs={12}>
                      <span style={{ fontSize: '16px', fontWeight: 'bold' }}>Details:</span>
                      <TextField
                        label=""
                        variant="outlined"
                        fullWidth
                        value={body}
                        multiline
                        rows={8}
                        required
                        onChange={(e) => {
                          setBody(e.target.value);
                        }}
                      />
                      <p>*If you need to attach any files, please email us at {emailTo}@sagebrush.church.</p>
                    </GridItem>
                    <GridItem xs={12} style={{ textAlign: 'right', marginTop: '10px', marginBottom: '-20px' }}>
                      <Button
                        color="primary"
                        onClick={() => { submitHandler() }}
                        disabled={submitting}
                      >
                        {submitting ? <LoadingBars /> : `Submit`}
                      </Button>
                    </GridItem>
                  </GridContainer>
              }
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </React.Fragment>
  )
}

const FormToEmail = (props) => {
  return (
    <React.Suspense fallback={<Loading color='blue' />}>
      <FormToEmailComp {...props} />
    </React.Suspense>
  )
}


export default FormToEmail