import React from "react";

import CalendarSettings from "./CalendarSettings";

const Settings = (props) => {
  return (
    <React.Fragment>
      <CalendarSettings />
    </React.Fragment>
  );
};

export default Settings;
