import React, { useState } from "react";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import ReactTable from "components/ReactTable/ReactTable.js";
import Loading from "components/Loading/Loading";
import Visibility from "@material-ui/icons/Visibility";
import Edit from "@material-ui/icons/Edit";

import {
  AttachFile,
  CenterFocusStrong,
  GetApp,
  Folder,
  FolderSpecial,
  FolderOpen,
  Close,
  Check,
  Archive,
  Unarchive,
  Send,
} from "@material-ui/icons";
import axios from "axios";
import FileListModal from "components/FileListModal/FileListModal";
import { pdf, PDFDownloadLink } from "@react-pdf/renderer";
import CurrentPDF from "views/PurchaseOrders/CurrentPDF";
import { saveAs } from "file-saver";

import { useHistory } from "react-router";
import LoadingBars from "components/Loading/LoadingBars";
import api from "api.config";
import { encryptData } from "assets/jss/EncriptDecrypt/EncryptDecrypt";
import CheckNumberModal from "components/CheckNumberModal/CheckNumberModal";

var JSZip = require("jszip");


const ListPOsComp = (props) => {

  const { poList, buttons, redirect, cols, updatePOList } = props


  const history = useHistory();
  const [showFileListModal, setShowFileListModal] = useState(false);
  const [fileListModalId, setFileListModalId] = useState(null);

  const [loadingLong, setloadingLong] = useState(false)

  const timeoutLength = 2000 // 2 sec

  const setLoadingLongFalse = () => {
    setloadingLong(false)
  }

  const [buttonLoading, setButtonLoading] = useState(false);

  const [showArchived, setShowArchived] = useState(buttons.archive ? false : true);

  const [showCheckNumberModal, setShowCheckNumberModal] = useState(false);
  const [checkNumberModalId, setCheckNumberModalId] = useState(null);

  const viewClickHandler = (id) => {
    let data = {
      redirect: redirect,
      showArchived: showArchived,
    }
    location.href = `/portal/purchase-orders/view/${id}?${encryptData(data)}`;
  };

  const adminMarkCheckPending = async (id) => {
    setloadingLong(true)
    await axios
      .post(`${api.path}/digitalpo_api/v1/markCheckPending`, {
        data: { id: id },
      })
      .then((res) => { return });
    updatePOList()
    setTimeout(setLoadingLongFalse, timeoutLength)
  }

  const adminViewClickHandler = (id) => {
    let data = {
      redirect: props.redirect,
      showArchived: showArchived,
    }
    location.href = `/portal/purchase-orders/admin/view/${id}?${encryptData(data)}`;
  };

  const attachFileHandler = (item) => {
    setShowFileListModal(true);
    setFileListModalId(item);
  };

  const archiveItemClickHandler = async (id) => {
    setloadingLong(true)
    await axios
      .post(`${api.path}/digitalpo_api/v1/archivePO`, {
        data: { archiveID: id },
      })
      .then((res) => { return });
    updatePOList()
    await setTimeout(setLoadingLongFalse, timeoutLength)
  };

  const unarchiveItemClickHandler = async (id) => {
    setloadingLong(true)
    await axios
      .post(`${api.path}/digitalpo_api/v1/unarchivePO`, {
        data: { unarchiveID: id },
      })
      .then((res) => { return });
    updatePOList()
    setTimeout(setLoadingLongFalse, timeoutLength)
  };

  const handleSubmitDraft = async (po) => {
    setloadingLong(true)
    const status = po.reviewer === 'N/A' ? 'pending' : 'review'
    await axios
      .post(`${api.path}/digitalpo_api/v1/submitdraft`, {
        data: {
          id: po.id,
          status: status
        },
      })
      .then((res) => {
        updatePOList()
        return
      });
    setTimeout(setLoadingLongFalse, timeoutLength)
  };

  const approveClickHandler = async (id) => {
    setShowCheckNumberModal(true);
    setCheckNumberModalId(id);
  };

  const formattedPoList = poList.map((item) => {
    return {
      ...item,
      total: "$" + parseFloat(item.total).toFixed(2),
      submittedToName: item.submittedTo2 !== null ? `${item.submittedToName} & ${item.submittedTo2Name}` : item.submittedToName,
      actions: (
        <React.Fragment>
          {(buttons.view) && (
            <Button
              justIcon
              color="primary"
              title='View'
              onClick={() => viewClickHandler(item.id)}
            >
              <Visibility />
            </Button>
          )}

          {(buttons.edit) && (
            <Button
              justIcon
              color="primary"
              href={`/portal/purchase-orders/edit/${item.id}?${redirect}`}
              title='Edit'
            >
              <Edit />
            </Button>
          )}

          {(buttons.adminView) && (
            <Button
              justIcon
              color="primary"
              title='View'
              onClick={() => adminViewClickHandler(item.id)}
            >
              <Visibility />
            </Button>
          )}

          {(buttons.adminEdit) && (
            <Button
              justIcon
              color="primary"
              href={`/portal/purchase-orders/admin/edit/${item.id}?${redirect}`}
              title='Edit'
            >
              <Edit />
            </Button>
          )}

          {(buttons.manageFiles) && (
            <Button
              justIcon
              color="primary"
              title='Manage Files'
              onClick={() => attachFileHandler(item)}
            >
              {item.attachedFiles ? <Folder /> : <FolderOpen />}
            </Button>
          )}

          {(buttons.checkPending) && (
            <Button
              justIcon
              color="primary"
              title='Check Pending'
              onClick={() => adminMarkCheckPending(item.id)}
            >
              <Check />
            </Button>
          )}

          {(buttons.checkProcessed) && (
            <Button
              justIcon
              title='Process Check'
              color="primary"
              onClick={() => approveClickHandler(item.id)}
            >
              <Check />
            </Button>
          )}



          {(buttons.archive) && (
            <>
              {item.archived ? (
                <Button
                  justIcon
                  color="primary"
                  title='Unarchive'
                  onClick={() => {
                    unarchiveItemClickHandler(item.id);
                  }}
                >
                  <Unarchive />
                </Button>

              ) : (
                <Button
                  justIcon
                  color="primary"
                  title='Archive'
                  onClick={() => {
                    archiveItemClickHandler(item.id);
                  }}
                >
                  <Archive />
                </Button>
              )}
            </>
          )}

          {(buttons.download) && (
            // <PDFDownloadLink
            //   document={<CurrentPDF id={item.id} currentPo={item} />}
            //   fileName={`purchase_order_${item.id}.pdf`}
            //   onClick={(e) => e.preventDefault()}
            // >
            <Button
              justIcon
              color="primary"
              title='Download PDF'
              onClick={() => { downloadPDF(item) }}
            >
              <GetApp />
            </Button>
            // </PDFDownloadLink>
          )}

          {(buttons.send) && (
            <Button
              justIcon
              color="primary"
              onClick={() => handleSubmitDraft(item)}
              title='Send'
            >
              <Send />
            </Button>
          )}

          {(item.status.includes('awaiting')) && (
            <>
              <br />
              <span style={{ color: "#f00", fontSize: "13px" }}>Status: {item.statusWithName}</span>
            </>
          )}

          {(item.status === 'approved') && (
            <>
              {/* <br />
              <span style={{ color: "#3195d9", fontSize: "13px" }}>
                RFP: {item.purchaseReason}
              </span> */}
              {(item.checkProcessed === 'yes') && (
                <>
                  <br />
                  <span style={{ color: "#3195d9", fontSize: "13px" }}>
                    {`Check Cut: ${item.checkProcessedDate} - #${item.checkNumber}`}
                  </span>
                </>
              )}
            </>
          )}

          {(item.status === 'rejected') && (
            <>
              <br />
              <span style={{ color: "#f00", fontSize: "13px" }}>
                Rejected Reason: {item.statusNotes || "None given"}
              </span>
            </>
          )}

        </React.Fragment>
      )
    }
  })

  const toggleArchivedClickHandler = () => {
    if (!showArchived) {
      setShowArchived(true);
    } else {
      setShowArchived(false);
    }
  };

  const downloadPDF = async (po) => {
    const newPDF = await pdf(<CurrentPDF id={po.id} currentPo={po} bulkDownload={true} />).toBlob()
    saveAs(newPDF, `purchase_order_${po.id}.pdf`)
  }

  const bulkDownloadClickHandler = () => {
    setButtonLoading(true);

    var zip = new JSZip();

    if (showArchived) {
      poList.map((item, index) => {
        zip.file(
          `${item.id}.pdf`,
          pdf(
            <CurrentPDF id={item.id} currentPo={item} bulkDownload={true} />
          ).toBlob()
        );
      });

      zip.generateAsync({ type: "blob" }).then(function (blob) {
        saveAs(blob, "purchase_orders.zip");
        setButtonLoading(false);
      });
    } else {
      // Filtered
      poList.filter((i) => !i.archived)
        .map((item, index) => {
          zip.file(
            `${item.id}.pdf`,
            pdf(
              <CurrentPDF id={item.id} currentPo={item} bulkDownload={true} />
            ).toBlob()
          );
        });

      zip.generateAsync({ type: "blob" }).then(function (blob) {
        saveAs(blob, "purchase_orders.zip");
        setButtonLoading(false);
      });
    }
  };

  const closeHandler = () => {
    setloadingLong(true)
    setShowFileListModal(false);
    setFileListModalId(null);
    setShowCheckNumberModal(false);
    setCheckNumberModalId(null);
    updatePOList()
    setTimeout(setLoadingLongFalse, timeoutLength)
  };

  const filteredPoList = showArchived ? formattedPoList : formattedPoList.filter(item => !item.archived)

  return (
    <GridContainer>
      <GridItem xs={12}>
        {showFileListModal && fileListModalId && (
          <FileListModal currentPo={fileListModalId} closeHandler={closeHandler} />
        )}

        {showCheckNumberModal && checkNumberModalId && (
          <CheckNumberModal
            id={checkNumberModalId}
            closeHandler={closeHandler}
          />
        )}

        {loadingLong ? <>
          <Loading color={'blue'} />
        </> : <>

          <React.Fragment>
            <div style={{ textAlign: "right" }}>

              {(buttons.archive) && (
                <>
                  {!buttonLoading ?
                    <Button onClick={toggleArchivedClickHandler} color="primary">
                      {showArchived ? "Hide" : "Show"} Archived (
                      {
                        poList.filter(
                          (item) => item.archived
                        ).length
                      }
                      )
                    </Button>
                    :
                    <Button color="primary">
                      <LoadingBars />
                    </Button>
                  }
                </>
              )}

              {(buttons.download) && (
                <>
                  {!buttonLoading ?
                    <>
                      {filteredPoList.length > 0 && (
                        <Button onClick={bulkDownloadClickHandler} color="warning">
                          Download All
                        </Button>
                      )}</>
                    :
                    <Button color="warning">
                      <LoadingBars />
                    </Button>
                  }
                </>
              )}
            </div>
            {filteredPoList.length > 0 && (
              <ReactTable
                columns={cols}
                data={filteredPoList}
                minWidthTable={'600px'}
              />
            )}
          </React.Fragment>

          {filteredPoList.length === 0 && (
            <p style={{ textAlign: "center" }}>No Purchase Orders.</p>
          )}
        </>}


      </GridItem>
    </GridContainer>
  )
}

const ListPOs = (props) => {
  return (
    <React.Suspense fallback={<Loading color="blue" />}>
      <ListPOsComp {...props} />
    </React.Suspense>
  )
}

export default ListPOs