import React, { useState, useEffect } from "react";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Button from "components/CustomButtons/Button";
import axios from "axios";
import update from "immutability-helper";
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';

import Datetime from "react-datetime";

import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import GoBack from "components/GoBack/GoBack";

// Card
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import CardText from "components/Card/CardText";

// Atom stuff
import { useRecoilValue } from "recoil";

import dashboardStyles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import Loading from "components/Loading/Loading";
import LoadingBars from "components/Loading/LoadingBars";

import { decryptData } from "assets/jss/EncriptDecrypt/EncryptDecrypt";
import {
  adminDepartments,
  reviewerDepartments,
  viewerDepartments,
  requestContractorByID,
  doesContractorExist,
  viewContractor
} from "state/invoiceSystem";
import { encryptData } from "assets/jss/EncriptDecrypt/EncryptDecrypt";

const useStyles = makeStyles(() => ({
  ...styles,
  ...dashboardStyles,
  formSection: {
    marginBottom: "1rem",
  },
}));

const ViewContractorComponent = (props) => {
  const classes = useStyles();

  const userType = props.match.params.userType
  const id = props.match.params.id
  const search = props.location.search?.split("?")[1] || null

  const contractor = useRecoilValue(viewContractor({ id: id, userType: userType }))

  const searchData = search ? decryptData(search) : {}

  return (
    <React.Fragment>
      <GridContainer>
        <GridItem xs={12} md={11} lg={8}>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <GoBack returnLocation={searchData.returnLocation} disableRound={true} />
            {(contractor && (userType === 'admin' || userType === 'reviewer')) && (
              <Button
                style={{ marginLeft: '5px' }}
                color='primary'
                onClick={(e) => {
                  e.preventDefault()
                  window.location.href = `/portal/contractors/addEdit/${userType}/${id}?${encryptData({
                    returnLocation: window.location.href
                  })}`
                }}
              >
                Edit
              </Button>)}
          </div>
          <Card>
            <CardHeader color="info" text>
              <CardText color="info">
                <h4 className={classes.cardTitleWhite}>Contractor</h4>
              </CardText>
            </CardHeader>
            <CardBody>
              {(!contractor) ?
                <p>Invalid contractor ID.</p>
                :
                <>
                  <GridContainer>
                    <GridItem xs={12} style={{ marginTop: '10px' }}>
                      <span style={{ fontSize: '1.8rem', fontWeight: 'bold' }}>{contractor.name}</span>
                    </GridItem>
                    <GridItem xs={12} md={6} style={{ display: 'flex', marginTop: '5px', fontSize: '0.9rem' }}>
                      <span style={{ marginRight: '5px', color: '#3195d9', fontWeight: 'bold' }}>Email:</span>
                      <span>{contractor.email}</span>
                    </GridItem>
                    <GridItem xs={12} md={6} style={{ display: 'flex', marginTop: '5px', fontSize: '0.9rem' }}>
                      <span style={{ marginRight: '5px', color: '#3195d9', fontWeight: 'bold' }}>Phone:</span>
                      <span>({contractor.phone.slice(0, 3)}){contractor.phone.slice(3, 6)}-{contractor.phone.slice(6, 10)}</span>
                    </GridItem>
                    <GridItem xs={12} style={{ display: 'flex', marginTop: '5px', fontSize: '0.9rem' }}>
                      <span style={{ marginRight: '5px', color: '#3195d9', fontWeight: 'bold' }}>Mailing Address:</span>
                    </GridItem>
                    <GridItem xs={12} md={6} style={{ display: 'flex', fontSize: '0.9rem' }}>
                      <span>{contractor.mailingAddress}</span>
                    </GridItem>
                    <GridItem xs={12} style={{ marginTop: '15px', marginLeft: '-10px', marginBottom: '5px' }}>
                      <span style={{ fontSize: '1rem', fontWeight: 'bold', color: '#3195d9' }}>Departments:</span>
                    </GridItem>
                    <GridItem xs={12}>
                      <GridContainer
                        className={classes.formSection}
                        style={{
                          borderRadius: "3px",
                          padding: "0 1.2rem",
                          marginBottom: '0'
                        }}
                      >
                        {contractor.departments.length === 0 && (
                          <GridItem xs={12} style={{ textAlign: 'center', marginTop: '10px' }}>
                            <span>Contractor not in any departments.</span>
                          </GridItem>
                        )}
                        {contractor.departments.map((dept, index) => {
                          return (
                            <React.Fragment key={index}>
                              <GridContainer
                                className={classes.formSection}
                                style={{
                                  border: "1px solid #ddd",
                                  borderRadius: "3px",
                                  background: "#fafafa",
                                  padding: "15px 0",
                                  marginBottom: '0',
                                  marginTop: index !== 0 ? '15px' : '0px'
                                }}
                              >
                                <GridItem xs={12}>
                                  <span style={{ fontSize: '1rem', fontWeight: 'bold' }}>{dept.departmentName}</span>
                                </GridItem>
                                <GridItem xs={12} md={6} style={{ display: 'flex', marginTop: '5px', fontSize: '0.9rem' }}>
                                  <span style={{ marginRight: '5px', color: '#3195d9', fontWeight: 'bold' }}>Start Date:</span>
                                  <span>{dept.startDate.split('-')[1]}/{dept.startDate.split('-')[2]}/{dept.startDate.split('-')[0]}</span>
                                </GridItem>
                                <GridItem xs={12} md={6} style={{ display: 'flex', marginTop: '5px', fontSize: '0.9rem' }}>
                                  <span style={{ marginRight: '5px', color: '#3195d9', fontWeight: 'bold' }}>Status:</span>
                                  <span>{dept.status}</span>
                                </GridItem>
                                {(dept.pay.type === 'fixed') && (
                                  <GridItem xs={12} style={{ display: 'flex', marginTop: '5px', fontSize: '0.9rem' }}>
                                    <span style={{ marginRight: '5px', color: '#3195d9', fontWeight: 'bold' }}>Pay:</span>
                                    <span>${dept.pay.amount}</span>
                                  </GridItem>
                                )}

                                {(dept.pay.type === 'options') && (
                                  <>
                                    <GridItem xs={12} style={{ display: 'flex', marginTop: '5px', fontSize: '0.9rem' }}>
                                      <span style={{ marginRight: '5px', color: '#3195d9', fontWeight: 'bold' }}>Pay Options:</span>
                                    </GridItem>
                                    {dept.pay.options.map((item, optIndex) => {
                                      return (
                                        <GridItem xs={12} style={{ display: 'flex', marginLeft: '5px', fontSize: '0.8rem' }} key={optIndex}>
                                          <span style={{ marginRight: '5px', color: 'black', fontWeight: 'bold' }}>{item.label}:</span>
                                          <span>${item.pay}</span>
                                        </GridItem>
                                      )
                                    })}
                                  </>
                                )}
                              </GridContainer>
                            </React.Fragment>
                          )
                        })}
                      </GridContainer>
                    </GridItem>
                  </GridContainer>
                </>
              }
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </React.Fragment >
  )
}

const ViewContractor = (props) => {
  return (
    <React.Suspense fallback={<Loading color="blue" />}>
      <ViewContractorComponent {...props} />
    </React.Suspense>
  )
}

export default ViewContractor