import React from "react";
import GridContainer from "../../components/Grid/GridContainer.js";
import GridItem from "../../components/Grid/GridItem.js";
import CustomTabs from "../../components/CustomTabs/CustomTabs";
import Table from "components/Table/Table";
import AccessLevel from "components/AccessLevel/AccessLevel.js";
import { Link } from "react-router-dom";
import loadingGif from "../../assets/img/loading-1.gif";
import { useRecoilValue } from "recoil";
import { checkinTeamState, checkinGroupsAtom } from "../../state/checkin";
import DateRange from "components/DateRange/DateRange";

const CheckinTable = (props) => {
  const teamData = useRecoilValue(checkinTeamState(props.email));
  const getTableData = (team) => {
    let data = [];

    team.map((item) => {
      data.push([
        item.user.firstname + " " + item.user.lastname,
        <span
          style={{ color: item.timeClocked.forgotToCheckOut ? "red" : "black" }}
        >
          {item.timeClocked.hours + "h " + item.timeClocked.mins + "m"}
        </span>,
        <Link
          to={{
            pathname: `/portal/staffcheckin/user/${item.user.email}`,
            state: {
              verified: true,
            },
          }}
        >
          View
        </Link>,
      ]);
    });
    return data;
  };

  const getTabData = (teamData) => {
    let data = teamData[0].userData;

    let tabData = [];
    let tabIndex = 1;
    let paginationLength = 5;

    while ((tabIndex - 1) * paginationLength < data.length) {
      tabData.push({
        tabName: tabIndex.toString(), // Page number
        tabContent: (
          <React.Fragment>
            <Table
              tableHead={["Name", "Hours", "View"]}
              tableData={getTableData(
                data.slice(
                  (tabIndex - 1) * paginationLength,
                  tabIndex * paginationLength
                )
              )}
            />
          </React.Fragment>
        ),
      });

      tabIndex++;
    }

    return tabData;
  };

  return (
    <>
      <GridItem xs={12} sm={12} md={6} lg={3} className="checkin-grid">
        <CustomTabs
          title={teamData.teamData[0].name}
          headerColor="info"
          tabs={getTabData(teamData.teamData)}
        />
      </GridItem>
    </>
  );
};

const StaffCheckin = (props) => {
  const checkinGroups = useRecoilValue(checkinGroupsAtom);

  let fallBackBox = (
    <GridItem xs={12} sm={12} md={12} lg={3} className="checkin-grid-loading">
      <CustomTabs
        title="Loading..."
        headerColor="info"
        tabs={[
          {
            tabName: "",
            tabContent: (
              <p style={{ textAlign: "center" }}>
                <img src={loadingGif} />
              </p>
            ),
          },
        ]}
      />
    </GridItem>
  );

  return (
    <AccessLevel groupName="checkin" groupRole="owner">
      <GridContainer>
        <GridItem xs={12} sm={7} md={7} lg={3}>
          <DateRange />
        </GridItem>
        {checkinGroups.map((item) => {
          return (
            <React.Suspense key={item} fallback={fallBackBox}>
              <React.Fragment>
                <CheckinTable email={item} />
              </React.Fragment>
            </React.Suspense>
          );
        })}
      </GridContainer>
    </AccessLevel>
  );
};

const StaffCheckinPage = (props) => {
  return (
    <React.Suspense
      fallback={
        <div>
          <p style={{ textAlign: "center" }}>
            <img src={loadingGif} />
          </p>
        </div>
      }
    >
      <StaffCheckin />
    </React.Suspense>
  );
};

export default StaffCheckinPage;
