import React, { useState } from "react";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import axios from "axios";
import api from "../../api.config";
import Loading from "components/Loading/Loading";
import ReactTable from "components/ReactTable/ReactTable.js";

import { userAtom } from "state/auth";

import { useRecoilValue } from "recoil";

import update from 'immutability-helper';
import { TextField } from "@material-ui/core";
import { getMyTeamsHBABForms } from "state/HBABFormRequest";
import ViewHBABForms from "./ViewHBABForms";


const MyTeamComponent = (props) => {
  const myTeamsHBAB = useRecoilValue(getMyTeamsHBABForms)

  const [showOldTeam, setShowOldTeam] = useState(false)
  return (
    <React.Fragment>
      <Card>
        <CardHeader color="primary" icon>
          <CardIcon color="primary">
            <h4 style={{ fontWeight: 'bold' }}>My Team</h4>
          </CardIcon>
        </CardHeader>
        <CardBody>
          {myTeamsHBAB.wasOnTeam.length > 0 && (
            <GridContainer>
              <GridItem xs={12} style={{ textAlign: "right" }}>
                <Button
                  color="warning"
                  onClick={() => {
                    showOldTeam ? setShowOldTeam(false) : setShowOldTeam(true)
                  }}
                >
                  {showOldTeam ? 'Show Current Team' : 'Show Past Team'}
                </Button>
              </GridItem>
            </GridContainer>
          )}

          {showOldTeam ?
            <ViewHBABForms HBABRoles={myTeamsHBAB.wasOnTeam} viewType={'Team'} />
            :
            <ViewHBABForms HBABRoles={myTeamsHBAB.onTeam} viewType={'Team'} />}

        </CardBody>
      </Card>
    </React.Fragment>
  )
}

const MyTeam = (props) => {
  return (
    <React.Suspense fallback={<Loading color="blue" />}>
      <GridContainer>
        <GridItem xs={12} lg={8}>
          <MyTeamComponent {...props} />
        </GridItem>
      </GridContainer>
    </React.Suspense>
  )
}

export default MyTeam