import React, { useState, useEffect } from "react";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Button from "components/CustomButtons/Button";
import axios from "axios";
import update from "immutability-helper";
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';

import Datetime from "react-datetime";

import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import GoBack from "components/GoBack/GoBack";

// Card
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import CardText from "components/Card/CardText";

// Atom stuff
import { useRecoilValue } from "recoil";

import dashboardStyles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import Loading from "components/Loading/Loading";
import LoadingBars from "components/Loading/LoadingBars";

import { decryptData, encryptData } from "assets/jss/EncriptDecrypt/EncryptDecrypt";
import { myProfile } from "state/invoiceSystem";

const useStyles = makeStyles(() => ({
  ...styles,
  ...dashboardStyles,
  formSection: {
    marginBottom: "0",
  },
}));

const CreateEditInvoiceComponent = (props) => {
  const classes = useStyles();

  const id = props.match.params.id
  const search = props.location.search?.split("?")[1] || null
  const searchData = search ? decryptData(search) : {}

  const contractor = useRecoilValue(myProfile)

  console.log(contractor)



  const emptyInvoice = {
    department: '',
    departmentID: '',
    status: 'draft',
    lineItems: [{}],
  }


  return (
    <React.Fragment>
      <GridContainer>
        <GridItem xs={12} md={11} lg={8}>
          {(id) && (
            <GoBack returnLocation={searchData.returnLocation} disableRound={true} />
          )}
          <Card>
            <CardHeader color="info" text>
              <CardText color="info">
                <h4 className={classes.cardTitleWhite}>{id ? 'Edit Invoice' : 'Create Invoice'}</h4>
              </CardText>
              <CardBody>
                {contractor ?
                  <>
                    {contractor.departments.length === 0 ?
                      <p style={{ color: 'black' }}>You are currently not a member of any department, please reach out to your direct report.</p>
                      :
                      <GridContainer>

                      </GridContainer>
                    }
                  </>
                  :
                  <p style={{ color: 'black' }}>
                    Your contractor profile hasn't been created, please reach out to your direct report.
                  </p>
                }
              </CardBody>
            </CardHeader>
          </Card>
        </GridItem>
      </GridContainer>
    </React.Fragment>
  )
}

const CreateEditInvoice = (props) => {
  return (
    <React.Suspense fallback={<Loading color="blue" />}>
      <CreateEditInvoiceComponent {...props} />
    </React.Suspense>
  )
}

export default CreateEditInvoice