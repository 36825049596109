import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import axios from "axios";
import ReactQuill from "react-quill-new";
import "assets/css/quill.snow.css";
import api from "../../api.config";
import Loading from "components/Loading/Loading";

import Close from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import TextFieldsIcon from '@material-ui/icons/TextFields';
import LinkIcon from '@material-ui/icons/Link';
import YouTubeIcon from '@material-ui/icons/YouTube';
import SlideshowIcon from '@material-ui/icons/Slideshow';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { ArrowBack } from "@material-ui/icons";

import { useRecoilValue } from "recoil";
import CustomTabs from "components/CustomTabs/CustomTabs";
import TrainingCreationTab from "./TrainingCreationTab";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {
  PagesAtom,
  TabsAtom,
  SelectedPageID,
  SelectedPageOption,
  googleGroupOptionsAtom
} from "state/trainingModule";
import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";

import ReactTooltip from "react-tooltip";
import ReactDOM from "react-dom";
import update from 'immutability-helper';
import { TextField } from "@material-ui/core";
import TrainingPageManagement from "./TrainingPageManagement";

const useStyles = makeStyles(styles);


const TrainingCreationComp = (props) => {
  const tab = +(props.location.search?.split("=")[1] || 0)
  const classes = useStyles();

  const pages = useRecoilValue(PagesAtom)
  const tabs = useRecoilValue(TabsAtom)
  const googleGroupOptions = useRecoilValue(googleGroupOptionsAtom)

  const sortTabs = (a, b) => {
    if (a.position > b.position) return 1
    if (a.position < b.position) return -1
    return 0
  }

  const [togglePopup, setTogglePopup] = useState(false)
  const [pagesTabs, setPagesTabs] = useState(tabs.filter(item => item.pageID === useRecoilValue(SelectedPageID)).sort(sortTabs))

  const [selectedPage, setSelectedPage] = useState(pages.find(item => item._id === useRecoilValue(SelectedPageID)))
  const [currentTab, setCurrentTab] = useState(null)

  const [pageOption, setPageOption] = useState(useRecoilValue(SelectedPageOption))

  const EmptyTab = {
    _id: null,
    pageID: sessionStorage.getItem("SPID"),
    position: pagesTabs.length,
    title: '',
    body: [{
      inputType: 'Text',
      value: {
        html: '',
        content: null
      },
    }],
  }

  const createPage = async (page) => {
    await axios
      .post(`${api.path}/staff_portal_api/v1/createPage`,
        page
      ).then((res) => {
        page._id = res.data.pageID
        sessionStorage.setItem('SPOption', 'Edit')
        selectPageHandler(page)
      })
  }

  const editPage = async (page) => {
    await axios
      .post(`${api.path}/staff_portal_api/v1/editPage`,
        page
      ).then((res) => {
        location.reload()
      })
  }

  const updatePageStatus = async (page, status) => {
    await axios
      .post(`${api.path}/staff_portal_api/v1/updatePageStatus`,
        {
          _id: page._id,
          status: status
        }
      ).then((res) => {
        location.reload()
      })
  }

  const deletePage = async (page) => {
    await axios
      .post(`${api.path}/staff_portal_api/v1/deletePage`,
        page
      ).then((res) => {
        location.reload()
      })
  }


  const selectPageHandler = (page) => {
    setSelectedPage(page)
    sessionStorage.setItem('SPID', page._id)
    location.reload()
  }

  const getCreatedTabs = () => {
    let tabs = []
    pagesTabs.forEach(tab => {
      tabs.push({
        tabName: tab.title,
        tabContent: (
          <TrainingCreationTab
            tab={tab}
            toggleEdit={setTogglePopup}
            removeTab={removeTab}
            maxIndex={pagesTabs.length}
            setCurrentTab={setCurrentTab}
          />
        )
      })
    })
    return tabs
  }

  const getTabPositions = () => {
    let tabPos = []
    pagesTabs.forEach(tab => {
      tabPos.push({
        _id: tab._id,
        title: tab.title,
        position: tab.position
      })
    })
    return tabPos
  }


  const submitFormHandler = async (tab) => {
    // if (JSON.stringify(tab.body).length <= 131000) {
    if (tab._id) {
      editTab(tab)
    } else {
      addTab(tab)
    }
    // } else {
    //   alert('Tab Contains to much information, reached overall page limit.\nCan be caused by to much information in text boxes')
    // }
  }

  const editTab = async (tab) => {
    let data = {
      _id: tab._id,
      pageID: tab.pageID,
      position: tab.position,
    }
    await axios
      .post(`${api.path}/staff_portal_api/v1/deleteTab`,
        data
      ).then((res) => {
        addTab(tab)
      })
  }

  const addTab = async (newTab) => {
    let data = {
      pageID: newTab.pageID,
      position: newTab.position,
      title: newTab.title,
      body: newTab.body,
    }
    await axios
      .post(`${api.path}/staff_portal_api/v1/createTab`,
        data
      ).then((res) => {
        location.href = `${props.location.pathname}?tab=${newTab.position}`;
      })
  }

  const removeTab = async (tab) => {
    let data = {
      _id: tab._id,
      pageID: tab.pageID,
      position: tab.position,
    }
    await axios
      .post(`${api.path}/staff_portal_api/v1/deleteTab`,
        data
      ).then((res) => {
        location.href = `${props.location.pathname}`;
      })
  }

  return (
    <React.Fragment>
      <GridContainer>
        <GridItem xs={12} md={7}>
          <GridContainer>
            <GridItem xs={12}>
              {/* {pageOption === 'Edit' && (
                <Button
                  color="white"
                  onClick={() => {
                    sessionStorage.setItem('SPOption', 'Manage')
                  }}
                >
                  <ArrowBack /> {`Page Manager`}
                </Button>
              )} */}
              {/*
              <Button
                color="primary"
                disabled={pageOption === 'Edit'}
                onClick={() => {
                  sessionStorage.setItem('SPOption', 'Edit')
                  setPageOption('Edit')
                }}
                tabIndex="-100"
              >
                Edit Page Contents
              </Button> */}
            </GridItem>
            {pageOption === 'Manage' ? <>
              <TrainingPageManagement
                pages={pages}
                createPage={createPage}
                deletePage={deletePage}
                editPage={editPage}
                updatePageStatus={updatePageStatus}
                googleGroupOptions={googleGroupOptions}
              />
            </> : <></>}


            {pageOption === 'Edit' ?
              <>
                <GridItem xs={7}>
                  <FormControl fullWidth className={classes.selectFormControl}>
                    <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
                      Select Page
                    </InputLabel>
                    <Select
                      MenuProps={{
                        className: classes.selectMenu,
                      }}
                      classes={{
                        select: classes.select,
                      }}
                      value={selectedPage}
                      onChange={(e) => selectPageHandler(e.target.value)}
                    >
                      {pages.map((item) => {
                        return (
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected,
                            }}
                            value={item}
                            key={item._id}
                          >
                            {`${item.title} - Status: ${item.status}`}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem xs={6}>
                  <Button
                    color="white"
                    onClick={() => {
                      sessionStorage.setItem('SPOption', 'Manage')
                      location.reload()
                    }}
                  >
                    <ArrowBack /> {`Page Manager`}
                  </Button>
                </GridItem>
                <GridItem
                  style={{
                    textAlign: "right",
                  }}
                  xs={6}
                >
                  {selectedPage ?
                    <Button
                      color="primary"
                      onClick={() => {
                        setCurrentTab(EmptyTab)
                        setTogglePopup(true)
                      }}
                      tabIndex="-100"
                    >
                      Create New Tab
                    </Button> : <></>}

                </GridItem>
                <GridItem xs={12}>
                  {togglePopup ?
                    <CreateTabPopup
                      tabValues={currentTab}
                      toggle={setTogglePopup}
                      submit={submitFormHandler}
                      tabPositions={getTabPositions()}
                    />
                    : <></>}
                </GridItem>
                <GridItem xs={12}>
                  {selectedPage ?
                    <>
                      {pagesTabs.length === 0 ? <p>Create a Tab.</p> :
                        <CustomTabs headerColor="info" tabs={getCreatedTabs()} value={tab} />
                      }
                    </> : <p>Select or Create a Page.</p>}
                </GridItem>
              </> : <></>}

          </GridContainer>
        </GridItem>
      </GridContainer>

    </React.Fragment>
  )
}


const TrainingCreation = (props) => {
  return (
    <React.Suspense fallback={<Loading color="blue" />}>
      <TrainingCreationComp {...props} />
    </React.Suspense>
  )
}

export default TrainingCreation

const CreateTabPopup = ({ tabValues, toggle, submit, tabPositions }) => {
  const formats = [
    'bold',
    'color',
    'italic',
    'strike',
    'underline',
    'link',
    'blockquote',
    'header',
    'indent',
    'list',
    'align',
    'direction',
  ];

  const modules = {
    toolbar: [
      [{ 'header': [1, 2, 3, false] }],
      ['bold', 'italic', 'underline', 'strike', 'link'],
      [{ list: "ordered" }],
      [{
        'color': [
          '#99a3a4', '#7f8c8d', '#707b7c', '#616a6b', '#515a5a', '#424949', '#000000',
          '#e6b0aa', '#d98880', '#cd6155', '#c0392b', '#a93226', '#922b21', '#641e16',
          '#f5cba7', '#f0b27a', '#eb984e', '#e67e22', '#ca6f1e', '#af601a', '#935116',
          '#f7dc6f', '#f4d03f', '#f1c40f', '#d4ac0d', '#b7950b', '#9a7d0a', '#7d6608',
          '#82e0aa', '#58d68d', '#2ecc71', '#28b463', '#239b56', '#1d8348', '#186a3b',
          '#7fb3d5', '#5499c7', '#2980b9', '#2471a3', '#1f618d', '#1a5276', '#154360',
          '#bb8fce', '#a569bd', '#8e44ad', '#7d3c98', '#6c3483', '#5b2c6f', '#4a235a',
        ]
      }],
    ],
  }

  const styles = {
    wrap: {
      background: "#000",
      position: "absolute",
      top: "0",
      left: "0",
      right: "0",
      bottom: "0",
      zIndex: 99998,
      opacity: ".7",
    },
    modal: {
      width: "96vw",
      maxWidth: '800px',
      zIndex: 99999,
      maxHeight: "80vh",
    },
  };

  const [tab, setTab] = useState(tabValues)
  const [tabPos, setTabPos] = useState(tab._id === null ? [...tabPositions, ...[tab]] : tabPositions)

  // console.log(tab)
  // console.log(tabPos)

  const formInputTypes = [
    {
      inputType: 'Text',
      value: {
        html: '',
        content: null
      },
      icon: <TextFieldsIcon />
    },
    {
      inputType: 'Youtube',
      value: '',
      icon: <YouTubeIcon />
    },
    {
      inputType: 'Scribe',
      value: '',
      icon: <SlideshowIcon />
    },
    {
      inputType: 'Links',
      value: {
        title: '',
        links: [{
          name: '',
          link: ''
        }]
      },
      icon: <LinkIcon />
    }
  ]

  const getFormInputTypeSelection = (position) => {
    return (
      <GridItem xs={12}
        style={{ display: 'flex', justifyContent: 'right', alignItems: 'center' }}
      >
        <p style={{ padding: '10px 5px 0px 0px', fontSize: '16px' }}>Add Item Here: </p>
        {formInputTypes.map((item, index) => {
          return (
            <div key={index}>
              <Button
                justIcon
                color="primary"
                size="sm"
                title={`Add ${item.inputType}`}
                // data-tip
                // data-for={`${item.inputType}-item`}
                onClick={(e) => bodyAddItem(item.value, item.inputType, position)}
              >
                {item.icon}
              </Button>
              {/* <ReactTooltip
                delayHide={50}
                delayShow={100}
                effect="solid"
                id={`${item.inputType}-item`}
              >
                Add {item.inputType}
              </ReactTooltip> */}
            </div>
          )
        })}
      </GridItem>
    )
  }


  const titleChangeHandler = (e) => {
    setTab((prevState) => {
      return update(prevState, {
        $merge: { title: e.target.value }
      })
    });
  };

  const moveBodyItem = (oldIndex, newIndex, value) => {
    setTab((prevState) => {
      let item = {
        inputType: prevState.body[oldIndex].inputType,
        value: value
      }
      let body = update(prevState, {
        body: { $splice: [[oldIndex, 1]] }
      })
      return update(body, {
        body: { $splice: [[newIndex, 0, item]] }
      })
    });
  }

  const bodyRemoveItem = (index) => {
    setTab((prevState) => {
      return update(prevState, {
        body: { $splice: [[index, 1]] }
      });
    });
  }

  const bodyAddItem = (value, type, index) => {
    setTab((prevState) => {
      let item = {
        inputType: type,
        value: value,
      }
      return update(prevState, {
        body: { $splice: [[index, 0, item]] }
      })
    });
  }

  const bodyChangeHandler = (type, value, index) => {
    setTab((prevState) => {
      let newVal = {
        inputType: type,
        value: value,
      }
      return update(prevState, {
        body: { [index]: { $merge: newVal } }
      })
    });
  };

  const linksChangeHandler = (item, value, index, i = null) => {
    setTab((prevState) => {
      if (item === 'title') {
        return update(prevState, {
          body: { [index]: { value: { $merge: { title: value } } } }
        })
      } else if (item === 'name') {
        return update(prevState, {
          body: { [index]: { value: { links: { [i]: { $merge: { name: value } } } } } }
        })
      } else if (item === 'link') {
        return update(prevState, {
          body: { [index]: { value: { links: { [i]: { $merge: { link: value } } } } } }
        })
      } else if (item === 'add') {
        return update(prevState, {
          body: { [index]: { value: { links: { $splice: [[i, 0, value]] } } } }
        })
      } else if (item === 'remove') {
        return update(prevState, {
          body: { [index]: { value: { links: { $splice: [[i, 1]] } } } }
        })
      }
    })
  }

  const createTextEntry = (index, value) => {
    return (
      <ReactQuill
        onChange={(content, delta, source, editor) => {
          let newVal = {
            html: editor.getHTML(),
            content: editor.getContents()
          }
          bodyChangeHandler('Text', newVal, index)
        }}
        style={{ height: '200px', paddingBottom: '65px' }}
        defaultValue={value.content}
        value={value.html}
        modules={modules}
        formats={formats}
      />
    )
  }

  const createYoutubeEmbed = (index, value) => {
    return (
      <TextField
        style={{ width: '100%' }}
        label='Link'
        variant='outlined'
        value={value}
        onChange={(e) => bodyChangeHandler('Youtube', e.target.value, index)}
      />
    )
  }

  const createScribeEmbed = (index, value) => {
    return (
      <TextField
        style={{ width: '100%' }}
        label='Link'
        variant='outlined'
        value={value}
        onChange={(e) => bodyChangeHandler('Scribe', e.target.value, index)}
      />
    )
  }

  return (
    <React.Fragment>
      {ReactDOM.createPortal(
        <div style={styles.wrap} onClick={(e) => toggle(false)}></div>,
        document.getElementById("file-list-modal-overlay")
      )}
      {ReactDOM.createPortal(
        <div style={{ position: 'absolute', width: '100vw', top: '4vh' }}>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <GridContainer style={styles.modal}>
              <Card>
                <CardHeader color="primary" icon>
                  <CardIcon color="primary">
                    <h4 style={{ fontWeight: 'bold', padding: '0px 10px' }}>{tab._id ? 'Edit' : 'Add'} Tab</h4>
                  </CardIcon>
                  <GridContainer style={{ alignItems: "center", }}>
                    <GridItem
                      xs={12}
                      style={{
                        textAlign: "right",
                      }}
                    >
                      <Button
                        justIcon
                        color="danger"
                        size="sm"
                        onClick={(e) => toggle(false)}
                      >
                        <Close />
                      </Button>
                    </GridItem>


                  </GridContainer>
                </CardHeader>
                <CardBody style={{ overflowY: "auto", maxHeight: "80vh" }}>
                  <GridContainer>
                    <GridItem xs={12}>
                      <CustomInput
                        labelText="Title"
                        id="title"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          value: tab.title,
                          onChange: titleChangeHandler,
                        }}
                      />
                    </GridItem>

                    <GridItem xs={12}><p><strong>Tab Position:</strong></p></GridItem>
                    <GridItem xs={12} style={{ display: 'flex', flexFlow: 'wrap' }}>
                      {tabPos.map((item, index) => {
                        return (
                          <div key={index} style={{ display: 'flex' }}>
                            {item._id === tab._id ?
                              <>
                                <Button
                                  justIcon
                                  color="primary"
                                  size="sm"
                                  disabled={index === 0}
                                  onClick={(e) => {
                                    setTab(prevState => {
                                      return update(prevState, {
                                        $merge: { position: index - 1 }
                                      })
                                    })

                                    setTabPos(prevState => {
                                      let removed = update(prevState, {
                                        $splice: [[index, 1]]
                                      })
                                      return update(removed, {
                                        $splice: [[index - 1, 0, tab]]
                                      })
                                    })
                                  }}
                                >
                                  <ChevronLeftIcon />
                                </Button>
                                <Button
                                  color="primary"
                                  size="sm"
                                >
                                  {tab.title ? tab.title : '<title>'}
                                </Button>
                                <Button
                                  justIcon
                                  color="primary"
                                  size="sm"
                                  disabled={index === tabPos.length - 1}
                                  onClick={(e) => {
                                    setTab(prevState => {
                                      return update(prevState, {
                                        $merge: { position: index + 1 }
                                      })
                                    })

                                    setTabPos(prevState => {
                                      let removed = update(prevState, {
                                        $splice: [[index, 1]]
                                      })
                                      return update(removed, {
                                        $splice: [[index + 1, 0, tab]]
                                      })
                                    })
                                  }}
                                >
                                  <ChevronRightIcon />
                                </Button>
                              </>
                              :
                              <>
                                <Button
                                  color="primary"
                                  size="sm"
                                  disabled
                                >
                                  {item.title}
                                </Button>
                              </>}
                          </div>
                        )
                      })}
                    </GridItem>

                    {getFormInputTypeSelection(0)}
                    <GridItem xs={12}>
                      {tab.body.map((item, index) => {
                        return (
                          <GridContainer key={index}>
                            <GridItem xs={1} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>

                              <Button
                                justIcon
                                color="primary"
                                size="sm"
                                disabled={index === 0}
                                onClick={(e) => moveBodyItem(index, index - 1, item.value)}
                              >
                                <ExpandLessIcon />
                              </Button>
                              <Button
                                justIcon
                                color="danger"
                                size="sm"
                                onClick={(e) => bodyRemoveItem(index)}
                              >
                                <Close />
                              </Button>
                              <Button
                                justIcon
                                color="primary"
                                size="sm"
                                disabled={index === tab.body.length - 1}
                                onClick={(e) => moveBodyItem(index, index + 1, item.value)}
                              >
                                <ExpandMoreIcon />
                              </Button>
                            </GridItem>
                            {item.inputType === 'Text' ?
                              <GridItem xs={11}>
                                {createTextEntry(index, item.value)}
                              </GridItem>
                              : item.inputType === 'Youtube' ?
                                <GridItem xs={11}>
                                  {createYoutubeEmbed(index, item.value)}
                                </GridItem>
                                : item.inputType === 'Links' ?
                                  <GridItem xs={11}>
                                    <CreateLinksList
                                      index={index}
                                      value={item.value}
                                      linksChangeHandler={linksChangeHandler}
                                    />
                                  </GridItem>
                                  : item.inputType === 'Scribe' ?
                                    <GridItem xs={11}>
                                      {createScribeEmbed(index, item.value)}
                                    </GridItem>
                                    : <p key={index}>Input Type: {item.inputType} not supported.</p>}
                            {getFormInputTypeSelection(index + 1)}
                          </GridContainer>
                        )
                      })}
                    </GridItem>
                    <GridItem xs={12} style={{ textAlign: 'right' }}>
                      <Button color="primary"
                        onClick={() => { submit(tab) }}
                      >
                        Save
                      </Button>
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </Card>
            </GridContainer>
          </div>
        </div>,
        document.getElementById("file-list-modal")
      )}
    </React.Fragment>
  )
}

const CreateLinksList = ({ index, value, linksChangeHandler }) => {

  return (
    <>
      <TextField
        style={{ width: '100%', paddingBottom: '10px' }}
        label='Title'
        variant='outlined'
        value={value.title}
        onChange={(e) => linksChangeHandler('title', e.target.value, index)}
      />
      {value.links.map((item, i) => {
        return (
          <div
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingBottom: '10px' }}
            key={i}
          >
            <TextField
              style={{ width: '50%', paddingRight: '5px' }}
              label='Link Name'
              variant='outlined'
              value={item.name}
              onChange={(e) => linksChangeHandler('name', e.target.value, index, i)}
            />
            <TextField
              style={{ width: '50%', paddingRight: '5px' }}
              label='Link'
              variant='outlined'
              value={item.link}
              onChange={(e) => linksChangeHandler('link', e.target.value, index, i)}

            />
            <Button
              justIcon
              color="danger"
              size="sm"
              onClick={(e) => linksChangeHandler('remove', null, index, i)}
            >
              <Close />
            </Button>
          </div>
        )
      })}
      <Button
        justIcon
        color="primary"
        size="sm"
        onClick={(e) => linksChangeHandler('add', { name: '', link: '' }, index, value.links.length)}
      >
        <AddIcon />
      </Button>
    </>
  )
}