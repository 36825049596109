import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import ReactTable from "components/ReactTable/ReactTable.js";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import { staffDirectoryAtom } from "state/directory";
import {
  useRecoilValue,
  useRecoilState,
  useSetRecoilState,
  RecoilRoot,
} from "recoil";
import OrgChart from "../../../components/OrgChart/OrgChart";
import Loading from "components/Loading/Loading";
import { poListSubmittedByAtom } from "state/purchaseOrders";
import Visibility from "@material-ui/icons/Visibility";
import Edit from "@material-ui/icons/Edit";
//import Folder from "@material-ui/icons/Folder";
//import SnippetFolder from "@material-ui/icons/SnippetFolder";
//import SnippetFolderIcon from "@mui/icons-material/SnippetFolder";
import {
  AttachFile,
  CenterFocusStrong,
  GetApp,
  Folder,
  FolderSpecial,
  FolderOpen,
} from "@material-ui/icons";
import { userAtom } from "state/auth";
import update from "immutability-helper";
import ReactTooltip from "react-tooltip";
import FileListModal from "components/FileListModal/FileListModal";
import { pdf, PDFDownloadLink } from "@react-pdf/renderer";
import CurrentPDF from "../CurrentPDF";
import { saveAs } from "file-saver";

import { useHistory } from "react-router";
import LoadingBars from "components/Loading/LoadingBars";
import { encryptData } from "assets/jss/EncriptDecrypt/EncryptDecrypt";

var JSZip = require("jszip");

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
};

const useStyles = makeStyles(styles);

const SubmittedForReviewListCompletedComponent = (props) => {
  const history = useHistory();

  const [showFileListModal, setShowFileListModal] = useState(false);
  const [fileListModalId, setFileListModalId] = useState(null);

  const [buttonLoading, setButtonLoading] = useState(false);

  const [showArchived, setShowArchived] = useState(false);

  const attachFileHandler = (id) => {
    setShowFileListModal(true);
    setFileListModalId(id);
  };

  const closeHandler = () => {
    location.reload();
    setShowFileListModal(false);
    setFileListModalId(null);
  };

  const viewClickHandler = (id) => {
    let data = {
      redirect: props.redirect,
    }

    history.push(`/portal/purchase-orders/view/${id}?${encryptData(data)}`);
  };

  const poList = props.items.map((item, index) => {
    let files = JSON.parse(item.attachedFiles);

    let newList = {
      ...item,
      actions: (
        <React.Fragment>
          <Button
            justIcon
            color="primary"
            // data-tip
            // data-for={`view-item-${index}`}
            title='View'
            onClick={() => viewClickHandler(item.id)}
          >
            <Visibility />
          </Button>
          {/* <ReactTooltip
            delayHide={50}
            delayShow={500}
            effect="solid"
            id={`view-item-${index}`}
          >
            View
          </ReactTooltip> */}

          <Button
            justIcon
            color="primary"
            title='Manage Files'
            // data-tip
            // data-for={`attach-item-${index}`}
            onClick={() => attachFileHandler(item.id)}
          >
            {files && files.length > 0 ? <Folder /> : <FolderOpen />}
          </Button>
          {/* <ReactTooltip
            delayHide={50}
            delayShow={500}
            effect="solid"
            id={`attach-item-${index}`}
          >
            Manage Files
          </ReactTooltip> */}

          <PDFDownloadLink
            document={<CurrentPDF id={item.id} currentPo={item} />}
            fileName={`purchase_order_${item.id}.pdf`}
            onClick={(e) => e.preventDefault()}
          >
            <Button
              justIcon
              color="primary"
              title='Download PDF'
              // data-tip
              // data-for={`download-item-${index}`}
              onClick={() => { }}
            >
              <GetApp />
            </Button>
            {/* <ReactTooltip
              delayHide={50}
              delayShow={500}
              effect="solid"
              id={`download-item-${index}`}
            >
              Download PDF
            </ReactTooltip> */}
          </PDFDownloadLink>
        </React.Fragment>
      ),
    };

    if (item.submittedTo2 !== null) {
      newList = update(newList, {
        $merge: {
          submittedToName: (
            `${item.submittedToName} & ${item.submittedTo2Name}`
          ),
        },
      });
    }

    newList = update(newList, {
      $merge: {
        total: "$" + parseFloat(item.total).toFixed(2),
      },
    });

    newList = update(newList, {
      $merge: {
        id: (
          <React.Fragment>
            {item.id}
            <br />
            <span style={{ color: "#3195d9", fontSize: "13px" }}>
              {item.purchaseReason}
            </span>
            <br />
            <span style={{ color: "#3195d9", fontSize: "13px" }}>
              {item.checkProcessed === "yes"
                ? `Check Cut: ${item.checkProcessedDate} - #${item.checkNumber}`
                : ``}
            </span>
          </React.Fragment>
        ),
      },
    });

    return newList;
  });

  const bulkDownloadClickHandler = () => {
    setButtonLoading(true);

    var zip = new JSZip();

    //console.log("full poList", poList);

    props.items.map((item, index) => {
      //console.log(`Bulk Download ${item.id}`, item);
      zip.file(
        `${item.id}.pdf`,
        pdf(
          <CurrentPDF id={item.id} currentPo={item} bulkDownload={true} />
        ).toBlob()
      );
    });

    zip.generateAsync({ type: "blob" }).then(function (blob) {
      saveAs(blob, "purchase_orders.zip");
      setButtonLoading(false);
    });
  };

  const toggleArchivedClickHandler = () => {
    if (!showArchived) {
      setShowArchived(true);
    } else {
      setShowArchived(false);
    }
  };

  return (
    <GridContainer>
      <GridItem xs={12}>
        {showFileListModal && fileListModalId && (
          <FileListModal id={fileListModalId} closeHandler={closeHandler} />
        )}

        {poList.length > 0 && (
          <React.Fragment>
            <div style={{ textAlign: "right" }}>
              {!buttonLoading ?
                <Button onClick={toggleArchivedClickHandler} color="primary">
                  {showArchived ? "Hide" : "Show"} Archived (
                  {
                    poList.filter(
                      (item) => item.archived
                    ).length
                  }
                  )
                </Button>
                :
                <Button color="primary">
                  <LoadingBars />
                </Button>
              }
              {!buttonLoading ? (
                <Button onClick={bulkDownloadClickHandler} color="warning">
                  Download All
                </Button>
              ) : (
                <Button color="warning">
                  <LoadingBars />
                </Button>
              )}

            </div>
            <ReactTable
              columns={[
                {
                  Header: "ID",
                  accessor: "id",
                  disableFilters: true,
                  disableSortBy: true,
                },
                {
                  Header: "Vendor",
                  accessor: "vendor",
                  disableFilters: false,
                  disableSortBy: false,
                },
                {
                  Header: "Created",
                  accessor: "submittedDate",
                  disableFilters: false,
                  disableSortBy: false,
                },
                {
                  Header: "Creator",
                  accessor: "submittedByName",
                  disableFilters: false,
                  disableSortBy: false,
                },
                ,
                {
                  Header: "Approver(s)",
                  accessor: "submittedToName",
                  disableFilters: false,
                  disableSortBy: false,
                },
                {
                  Header: "Total",
                  accessor: "total",
                  disableFilters: false,
                  disableSortBy: false,
                },
                {
                  Header: "Actions",
                  accessor: "actions",
                  disableFilters: false,
                  disableSortBy: false,
                },
              ]}
              data={showArchived ? poList : poList.filter(item => !item.archived)}
            />
          </React.Fragment>
        )}

        {poList.length === 0 && (
          <p style={{ textAlign: "center" }}>No Reviewed items.</p>
        )}
      </GridItem>
    </GridContainer>
  );
};

const SubmittedForReviewListCompleted = (props) => {
  return (
    <React.Suspense fallback={<Loading color="blue" />}>
      <SubmittedForReviewListCompletedComponent {...props} />
    </React.Suspense>
  );
};

export default SubmittedForReviewListCompleted;
