import React from "react";
import api from "api.config";

const OrgChart = (props) => {
  return (
    <React.Fragment>
      <iframe
        width="100%"
        style={{ height: "75vh" }}
        src={`${api.path}/staff_portal_api/static/orgchart.pdf`}
      ></iframe>
    </React.Fragment>
  );
};

export default OrgChart;
