import React, { useState } from "react";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Button from "components/CustomButtons/Button";
import { TextField } from "@material-ui/core";
import axios from "axios";
import { useRecoilValue } from "recoil";
import { userAtom, groupsAtom } from "state/auth";
import { poListSubmittedByAtom } from "state/purchaseOrders";
import {
  poListSubmittedToAtom,
  poListSubmittedToReviewAtom,
  ListSubmittedByTeamAtom,
  poListSubmittedByTeamAtom,
} from "state/purchaseOrders";
import { PDFDownloadLink } from "@react-pdf/renderer";
import CurrentPDF from "views/PurchaseOrders/CurrentPDF";
import { ArrowBack } from "@material-ui/icons";
import LoadingBars from "components/Loading/LoadingBars";
import POBudgetWidget from "views/PurchaseOrders/POBudgetsAndCodes/POBudgetWidget";
import PMEditPOAmounts from "components/PMEditPOAmounts/PMEditPOAmounts";
import PMEditPOCodes from "components/PMEditPOAmounts/PMEditPOCodes";
import { useHistory } from "react-router-dom";

import api from "api.config";
import { decryptData } from "assets/jss/EncriptDecrypt/EncryptDecrypt";
import { encryptData } from "assets/jss/EncriptDecrypt/EncryptDecrypt";
import { ListExpenseReportMembersAtom } from "state/purchaseOrders";
import { poListExpenseReportAtom } from "state/purchaseOrders";

const ActionBar = (props) => {

  const search = props.location.search ? decryptData(props.location.search.substring(1)) : null
  const returnLocation = search?.redirect?.split('_') || ''
  const showArchived = search?.showArchived || false
  //const updateAmountsFlag = search?.updateAmountsFlag || false
  const currentPo = props.currentPo

  const history = useHistory();

  const viewStatus = props.currentPo.status;

  const lineItems = currentPo && currentPo.lineItems ? JSON.parse(currentPo.lineItems) : [];

  const userData = useRecoilValue(userAtom);
  const userGroups = useRecoilValue(groupsAtom)

  const isReviewer = userGroups.find(item => item.name === 'internal-digitalpo-review@sagebrush.church') ? true : false

  //const isApprover = userGroups.find(item => item.name === 'internal-digital-pos@sagebrush.church' || item.name === 'internal-digitalpo-execs@sagebrush.church') ? true : false

  const userInMyCheckinTeam = useRecoilValue(ListSubmittedByTeamAtom).includes(currentPo.submittedBy)
  const userInExpenseReportGroup = useRecoilValue(ListExpenseReportMembersAtom).includes(currentPo.submittedBy)


  const [reason, setReason] = useState("");

  const [buttonLoading, setButtonLoading] = useState(false);

  //const [showHideBudgetData, setShowHideBudgetData] = useState(true)

  const [showHideUpdateAmounts, setShowHideUpdateAmounts] = useState(false)

  let allPurchaseOrders = null;

  const getViewerType = () => {
    var viewer = ''
    if (returnLocation[0] == 'submitted-by-team' && userInMyCheckinTeam) {
      viewer = 'byTeam'
    } else if (returnLocation[0] == 'submitted-for-review' && currentPo.reviewer === userData.email) {
      viewer = 'reviewer'
    } else if (returnLocation[0] == 'submitted-by-me' && currentPo.submittedBy === userData.email) {
      viewer = 'viewer'
    } else if (returnLocation[0] == 'submitted-to-me' && (currentPo.submittedTo == userData.email ||
      currentPo.submittedTo2 === userData.email)) {
      viewer = 'approver'
    } else if (returnLocation[0] == 'expense-report' && (currentPo.reviewer === userData.email || userInExpenseReportGroup)) {
      viewer = 'expenseReport'
    } else {
      viewer = currentPo.submittedBy === userData.email ? "viewer" :
        currentPo.reviewer === userData.email ? 'reviewer' :
          "approver"
    }
    return viewer
  }


  const [viewerType, setViewerType] = useState(getViewerType());


  if (viewerType === "viewer") {

    let tempPOList = useRecoilValue(poListSubmittedByAtom);
    if (viewStatus === 'draft' || viewStatus === 'review' || viewStatus === 'rejected') {
      allPurchaseOrders = tempPOList.filter((item) => item.status === viewStatus)
    } else if (viewStatus === "pending" || viewStatus.indexOf("awaiting") !== -1) {
      allPurchaseOrders = tempPOList.filter(
        (item) => item.status === "pending" || item.status.indexOf("awaiting") !== -1)
    } else if (viewStatus === "approved" && !currentPo.attachedFiles) {
      allPurchaseOrders = tempPOList.filter(
        (item) => item.status === "approved" && !item.attachedFiles)
    } else if (viewStatus === "approved" && currentPo.attachedFiles) {
      if (showArchived) {
        allPurchaseOrders = tempPOList.filter(
          (item) => { return (item.status === "approved" && item.attachedFiles); })
      } else {
        allPurchaseOrders = tempPOList.filter((item) => {
          return (item.status === "approved" && item.attachedFiles && !item.archived);
        })
      }
    } else {
      console.log('ERROR: PO does not match any option.')
      allPurchaseOrders = []
    }
    // if (viewStatus === "pending" || viewStatus.indexOf("awaiting") !== -1) {
    //   allPurchaseOrders = useRecoilValue(poListSubmittedByAtom).filter(
    //     (item) => item.status === "pending" || item.status.indexOf("awaiting") !== -1
    //   );
    // } else if (viewStatus === "approved" && (!currentPo.attachedFiles ||
    //   (currentPo.attachedFiles && JSON.parse(currentPo.attachedFiles).length === 0))) {
    //   allPurchaseOrders = useRecoilValue(poListSubmittedByAtom).filter(
    //     (item) =>
    //       item.status === "approved" &&
    //       (!item.attachedFiles ||
    //         (item.attachedFiles &&
    //           JSON.parse(item.attachedFiles).length === 0))
    //   )
    // } else if ((viewStatus === "approved" && (currentPo.attachedFiles
    //   && JSON.parse(currentPo.attachedFiles).length > 0)) || currentPo.archived) {
    //   allPurchaseOrders = useRecoilValue(poListSubmittedByAtom).filter((item) => {
    //     if (showArchived) {
    //       return (
    //         (item.status === "approved" &&
    //           item.attachedFiles &&
    //           JSON.parse(item.attachedFiles).length > 0) ||
    //         item.archived
    //       );
    //     } else {
    //       return (
    //         (item.status === "approved" &&
    //           item.attachedFiles &&
    //           JSON.parse(item.attachedFiles).length > 0)
    //       );
    //     }
    //   })
    // } else {
    //   allPurchaseOrders = useRecoilValue(poListSubmittedByAtom).filter(
    //     (item) => item.status === viewStatus
    //   );
    // }
  } else if (viewerType === 'reviewer') {

    let tempPOList = useRecoilValue(poListSubmittedToReviewAtom)
    if (viewStatus === 'review' || viewStatus === 'rejected') {
      allPurchaseOrders = tempPOList.filter((item) => item.status === viewStatus)
    } else if (viewStatus === "pending" || viewStatus.indexOf("awaiting") !== -1) {
      allPurchaseOrders = tempPOList.filter(
        (item) => item.status === "pending" || item.status.indexOf("awaiting") !== -1)
    } else if (viewStatus === "approved" && !currentPo.attachedFiles) {
      allPurchaseOrders = tempPOList.filter(
        (item) => item.status === "approved" && !item.attachedFiles)
    } else if (viewStatus === "approved" && currentPo.attachedFiles && currentPo.ReviewerVerifiedLineitems === 'no') {
      allPurchaseOrders = tempPOList.filter((item) => {
        return ((item.status === "approved" && item.attachedFiles &&
          item.ReviewerVerifiedLineitems === 'no'));
      })
    } else if (viewStatus === "approved" && currentPo.attachedFiles && currentPo.ReviewerVerifiedLineitems !== 'no') {
      if (showArchived) {
        allPurchaseOrders = tempPOList.filter(
          (item) => {
            return (item.status === "approved" && item.attachedFiles &&
              item.ReviewerVerifiedLineitems !== 'no');
          })
      } else {
        allPurchaseOrders = tempPOList.filter((item) => {
          return (item.status === "approved" && item.attachedFiles && !item.archived &&
            item.ReviewerVerifiedLineitems !== 'no');
        })
      }
    } else {
      console.log('ERROR: PO does not match any option.')
      allPurchaseOrders = []
    }
    // if (viewStatus === 'review') {
    //   allPurchaseOrders = useRecoilValue(poListSubmittedToReviewPendingAtom).filter(
    //     (item) =>
    //       item.status === "review"
    //   );
    // } else if (viewStatus === 'rejected') {
    //   allPurchaseOrders = useRecoilValue(poListSubmittedToReviewAtom).filter(
    //     (item) => item.status === viewStatus
    //   );
    // } else if (viewStatus === "pending" || viewStatus.indexOf("awaiting") !== -1 ||
    //   viewStatus === "approved" && (!currentPo.attachedFiles ||
    //     (currentPo.attachedFiles && JSON.parse(currentPo.attachedFiles).length === 0))) {
    //   allPurchaseOrders = useRecoilValue(poListSubmittedToReviewAtom).filter((item) =>
    //     item.status === "pending" || item.status.indexOf("awaiting") !== -1 ||
    //     item.status === "approved" && (!item.attachedFiles || (item.attachedFiles &&
    //       JSON.parse(item.attachedFiles).length === 0))
    //   )
    // } else {
    //   allPurchaseOrders = useRecoilValue(poListSubmittedToReviewAtom).filter((item) => {
    //     if (showArchived) {
    //       return (
    //         (item.status === "approved" &&
    //           item.attachedFiles &&
    //           JSON.parse(item.attachedFiles).length > 0) ||
    //         item.archived
    //       );
    //     } else {
    //       return (
    //         (item.status === "approved" &&
    //           item.attachedFiles &&
    //           JSON.parse(item.attachedFiles).length > 0)
    //       );
    //     }
    //   }).filter((item) => {
    //     if (currentPo.ReviewerVerifiedLineitems === 'no' || updateAmountsFlag) {
    //       return (item.ReviewerVerifiedLineitems === 'no')
    //     } else {
    //       return (item.ReviewerVerifiedLineitems !== 'no')
    //     }
    //   })
    // }
  } else if (viewerType === 'byTeam') {
    let tempPOList = useRecoilValue(poListSubmittedByTeamAtom);
    if (viewStatus === 'review' || viewStatus === 'rejected') {
      allPurchaseOrders = tempPOList.filter((item) => item.status === viewStatus)
    } else if (viewStatus === "pending" || viewStatus.indexOf("awaiting") !== -1) {
      allPurchaseOrders = tempPOList.filter(
        (item) => item.status === "pending" || item.status.indexOf("awaiting") !== -1)
    } else if (viewStatus === "approved" && !currentPo.attachedFiles) {
      allPurchaseOrders = tempPOList.filter(
        (item) => item.status === "approved" && !item.attachedFiles)
    } else if (viewStatus === "approved" && currentPo.attachedFiles) {
      if (showArchived) {
        allPurchaseOrders = tempPOList.filter(
          (item) => { return (item.status === "approved" && item.attachedFiles); })
      } else {
        allPurchaseOrders = tempPOList.filter((item) => {
          return (item.status === "approved" && item.attachedFiles && !item.archived);
        })
      }
    } else {
      console.log('ERROR: PO does not match any option.')
      allPurchaseOrders = []
    }
    // if (viewStatus === "approved" && (!currentPo.attachedFiles ||
    //   (currentPo.attachedFiles && JSON.parse(currentPo.attachedFiles).length === 0))) {
    //   allPurchaseOrders = useRecoilValue(poListSubmittedByTeamAtom).filter(
    //     (item) =>
    //       item.status === "approved" &&
    //       (!item.attachedFiles ||
    //         (item.attachedFiles &&
    //           JSON.parse(item.attachedFiles).length === 0))
    //   )
    // } else if ((viewStatus === "approved" && (currentPo.attachedFiles
    //   && JSON.parse(currentPo.attachedFiles).length > 0)) || currentPo.archived) {
    //   allPurchaseOrders = useRecoilValue(poListSubmittedByTeamAtom).filter((item) => {
    //     if (showArchived) {
    //       return (
    //         (item.status === "approved" &&
    //           item.attachedFiles &&
    //           JSON.parse(item.attachedFiles).length > 0) ||
    //         item.archived
    //       );
    //     } else {
    //       return (
    //         (item.status === "approved" &&
    //           item.attachedFiles &&
    //           JSON.parse(item.attachedFiles).length > 0)
    //       );
    //     }
    //   })
    // } else {
    //   allPurchaseOrders = useRecoilValue(poListSubmittedByTeamAtom).filter(
    //     (item) => item.status === viewStatus
    //   );
    // }
  } else if (viewerType === 'expenseReport') {
    let poList = useRecoilValue(poListExpenseReportAtom)
    if (viewStatus === 'review') {
      allPurchaseOrders = poList.filter(item => item.status === 'review')
    } else if (viewStatus === "pending" || viewStatus.indexOf("awaiting") !== -1) {
      allPurchaseOrders = poList.filter(item => item.status === "pending" || item.status.indexOf("awaiting") !== -1)
    } else if (viewStatus === "approved" && (!currentPo.attachedFiles)) {
      allPurchaseOrders = poList.filter(item => item.status === "approved" && (!item.attachedFiles))
    } else if (viewStatus === "approved" && currentPo.attachedFiles &&
      currentPo.ReviewerVerifiedLineitems === 'no') {
      allPurchaseOrders = poList.filter((item) => item.status === "approved" && item.attachedFiles
        && item.ReviewerVerifiedLineitems === 'no')
    } else if (viewStatus === "approved" && currentPo.attachedFiles &&
      currentPo.ReviewerVerifiedLineitems !== 'no') {
      if (showArchived) {
        allPurchaseOrders = poList.filter((item) => item.status === "approved" && item.attachedFiles
          && item.ReviewerVerifiedLineitems !== 'no')
      } else {
        allPurchaseOrders = poList.filter((item) => item.status === "approved" && item.attachedFiles
          && item.ReviewerVerifiedLineitems !== 'no' && !item.archived)
      }
    } else {
      console.log('ERROR: PO does not match any option.')
      allPurchaseOrders = []
    }
  } else if (viewerType === 'approver') {

    let tempPOList = useRecoilValue(poListSubmittedToAtom);
    if (viewStatus === 'review' || viewStatus === 'rejected') {
      allPurchaseOrders = tempPOList.filter((item) => item.status === viewStatus)
    } else if (viewStatus === "pending" ||
      (viewStatus.includes("awaiting") && viewStatus.includes(userData.email))) {
      allPurchaseOrders = tempPOList.filter(
        (item) => item.status === "pending" ||
          (item.status.includes("awaiting") && item.status.includes(userData.email)))
    } else if ((!currentPo.attachedFiles && viewStatus === "approved") ||
      (viewStatus.includes("awaiting") && !viewStatus.includes(userData.email))) {
      allPurchaseOrders = tempPOList.filter(
        (item) => (!item.attachedFiles && item.status === "approved") ||
          (item.status.includes("awaiting") && !item.status.includes(userData.email)))
    } else if (viewStatus === "approved" && currentPo.attachedFiles) {
      if (showArchived) {
        allPurchaseOrders = tempPOList.filter(
          (item) => { return (item.status === "approved" && item.attachedFiles); })
      } else {
        allPurchaseOrders = tempPOList.filter((item) => {
          return (item.status === "approved" && item.attachedFiles && !item.archived);
        })
      }
    } else {
      console.log('ERROR: PO does not match any option.')
      allPurchaseOrders = []
    }

    // if (viewStatus === "pending" || viewStatus.indexOf("awaiting") !== -1) {
    //   allPurchaseOrders = useRecoilValue(poListPendingSubmittedToAtom)
    // } else if (viewStatus === "approved" && (!currentPo.attachedFiles ||
    //   (currentPo.attachedFiles && JSON.parse(currentPo.attachedFiles).length === 0))) {
    //   allPurchaseOrders = useRecoilValue(poListSubmittedToAtom).filter(
    //     (item) =>
    //       item.status === "approved" &&
    //       (!item.attachedFiles ||
    //         (item.attachedFiles &&
    //           JSON.parse(item.attachedFiles).length === 0))
    //   )
    // } else if ((viewStatus === "approved" && (currentPo.attachedFiles
    //   && JSON.parse(currentPo.attachedFiles).length > 0)) || currentPo.archived) {
    //   allPurchaseOrders = useRecoilValue(poListSubmittedToAtom).filter((item) => {
    //     if (showArchived) {
    //       return (
    //         (item.status === "approved" &&
    //           item.attachedFiles &&
    //           JSON.parse(item.attachedFiles).length > 0) ||
    //         item.archived
    //       );
    //     } else {
    //       return (
    //         (item.status === "approved" &&
    //           item.attachedFiles &&
    //           JSON.parse(item.attachedFiles).length > 0)
    //       );
    //     }
    //   })
    // } else {
    //   allPurchaseOrders = useRecoilValue(poListSubmittedToAtom).filter(
    //     (item) => item.status === viewStatus)
    // }
  }

  else {
    console.log(`Error: viewer type doesnt match: ${viewerType}`)
    allPurchaseOrders = []
  }

  const getNextPo = () => {
    let next = allPurchaseOrders.findIndex(
      (item) => item.id === props.currentPo.id
    );

    if (next + 1 > allPurchaseOrders.length - 1) {
      return false;
    }
    if (props.location.search) {
      return `/portal/purchase-orders/view/${allPurchaseOrders[next + 1].id}${props.location.search}`;
    } else {
      return `/portal/purchase-orders/view/${allPurchaseOrders[next + 1].id}`;
    }
  };

  const getPrevPo = () => {
    let prev = allPurchaseOrders.findIndex(
      (item) => item.id === props.currentPo.id
    );

    if (prev - 1 < 0) {
      return false;
    }
    if (props.location.search) {
      return `/portal/purchase-orders/view/${allPurchaseOrders[prev - 1].id}${props.location.search}`;
    } else {
      return `/portal/purchase-orders/view/${allPurchaseOrders[prev - 1].id}`;
    }
  };

  const approveRejectredirectHandler = () => {
    var next, prev;
    next = getNextPo()
    prev = getPrevPo()

    if (next) {
      location.href = next
    } else if (prev) { location.href = prev }
    else {
      location.href = returnLocation ? `/portal/purchase-orders/${returnLocation[0]}?tab=${returnLocation[1]}` :
        `/portal/purchase-orders/${viewerType === "viewer" ? "submitted-by-me" : viewerType === 'reviewer' ?
          'submitted-for-review' : viewerType === 'byTeam' ? 'submitted-by-team' : "submitted-to-me"}`
    }
  }

  const updateAmountsRedirectHandler = () => {
    if (currentPo.ReviewerVerifiedLineitems === 'no') {
      let updatedSearch = search
      updatedSearch.updateAmountsFlag = true
      location.href = `/portal/purchase-orders/view/${currentPo.id}?${encryptData(updatedSearch)}`
    } else {
      location.reload()
    }
  }

  const approveHandler = async () => {
    setButtonLoading(true);
    await axios
      .post(`${api.path}/digitalpo_api/v1/acceptreject`, {
        auth: viewerType,
        status: viewerType === 'reviewer' ? 'reviewed' : 'approved',
        id: currentPo.id,
      })
      .then((res) => {
        approveRejectredirectHandler()
      });
  };

  const archiveItemClickHandler = (id) => {
    axios
      .post(`${api.path}/digitalpo_api/v1/archivePO`, {
        data: { archiveID: id },
      })
      .then((res) => {
        let newLocation = getNextPo()

        if (!newLocation) {
          newLocation = getPrevPo()
        }

        if (!newLocation) {
          newLocation = returnLocation ? `/portal/purchase-orders/${returnLocation[0]}?tab=${returnLocation[1]}` :
            `/portal/purchase-orders/${viewerType === "viewer" ? "submitted-by-me" :
              viewerType === 'reviewer' ? 'submitted-for-review' :
                viewerType === 'byTeam' ? 'submitted-by-team' :
                  "submitted-to-me"
            }`
        }
        location.href = newLocation
      });
  };

  const unarchiveItemClickHandler = (id) => {
    axios
      .post(`${api.path}/digitalpo_api/v1/unarchivePO`, {
        data: { unarchiveID: id },
      })
      .then((res) => {
        let newLocation = getNextPo()

        if (!newLocation) {
          newLocation = getPrevPo()
        }

        if (!newLocation) {
          newLocation = returnLocation ? `/portal/purchase-orders/${returnLocation[0]}?tab=${returnLocation[1]}` :
            `/portal/purchase-orders/${viewerType === "viewer" ? "submitted-by-me" :
              viewerType === 'reviewer' ? 'submitted-for-review' :
                viewerType === 'byTeam' ? 'submitted-by-team' :
                  "submitted-to-me"
            }`
        }
        location.href = newLocation

      });
  };

  const rejectHandler = async () => {
    setButtonLoading(true);
    await axios
      .post(`${api.path}/digitalpo_api/v1/acceptreject`, {
        auth: viewerType,
        status: 'reject',
        id: currentPo.id,
        reason: reason,
      })
      .then((res) => {
        approveRejectredirectHandler()
      });
  };

  const reasonChangeHandler = (e) => {
    setReason(e.target.value);
  };

  const nextClickHandler = () => {
    let next = getNextPo();

    if (next) {
      //location.href = next;
      history.push(next);
    }
  };

  const prevClickHandler = () => {
    let prev = getPrevPo();

    if (prev) {
      //location.href = prev;
      history.push(prev);
    }
  };

  const voidClickHandler = async () => {
    //console.log("voiding PO", currentPo.id);
    setButtonLoading(true);
    await axios
      .post(`${api.path}/digitalpo_api/v1/voidbyid`, {
        data: {
          voidID: currentPo.id,
        },
      })
      .then(
        (res) =>
          (location.href = "/portal/purchase-orders/submitted-by-me?tab=1")
      );
  };

  const verifyTotalsClickHandler = async () => {
    setButtonLoading(true);
    await axios
      .post(`${api.path}/digitalpo_api/v1/reviewerVerifyLineItems`, {
        poid: currentPo.id,
      })
      .then(
        (res) =>
          approveRejectredirectHandler()
      );
  };

  const handleSubmitDraft = async () => {
    setButtonLoading(true);
    const status = currentPo.reviewer === 'N/A' ? 'pending' : 'review'
    await axios
      .post(`${api.path}/digitalpo_api/v1/submitdraft`, {
        data: {
          submit: userData.email,
          id: currentPo.id,
          status: status
        },
      })
      .then(
        (res) =>
          (location.href = "/portal/purchase-orders/submitted-by-me?tab=0")
      );
  };

  /* const backClickHandler = () => {
    history.goBack();
  }; */
  return (
    <React.Fragment>
      <GridContainer style={{ display: 'flex', flexDirection: 'column' }}>
        <GridItem>
          <Button
            href={returnLocation ? `/portal/purchase-orders/${returnLocation[0]}?tab=${returnLocation[1]}` :
              `/portal/purchase-orders/${viewerType === "viewer" ? "submitted-by-me" :
                viewerType === 'reviewer' ? 'submitted-for-review' :
                  viewerType === 'byTeam' ? 'submitted-by-team' :
                    "submitted-to-me"
              }`}
            color="white"
          >
            <ArrowBack /> {buttonLoading ? <LoadingBars /> : `Back`}
          </Button>
          <Button
            onClick={prevClickHandler}
            disabled={getPrevPo() === false ? true : false}
            color="primary"
          >
            {buttonLoading ? <LoadingBars /> : `Previous`}
          </Button>
          <Button
            onClick={nextClickHandler}
            disabled={getNextPo() === false ? true : false}
            color="primary"
          >
            {buttonLoading ? <LoadingBars /> : `Next`}
          </Button>

          {viewStatus === "draft" && (
            <Button color="success" onClick={handleSubmitDraft}>
              {buttonLoading ? <LoadingBars /> : `Submit`}
            </Button>
          )}

          {viewStatus === "draft" && (
            <Button color="danger" onClick={voidClickHandler}>
              {buttonLoading ? <LoadingBars /> : `Delete Draft`}
            </Button>
          )}

          {(
            ((isReviewer && (currentPo.reviewer === userData.email || currentPo.submittedBy === userData.email)) // review check
              || (viewerType === 'expenseReport' && (userInExpenseReportGroup || currentPo.reviewer === userData.email))) // expense report check
            && currentPo.ReviewerEditedLineItems === 'no' && viewStatus === 'approved' && currentPo.attachedFiles // conditions for po

            // (isReviewer || (viewerType === 'expenseReport' && userInExpenseReportGroup)) &&
            // currentPo.ReviewerEditedLineItems === 'no' && (viewStatus === 'approved' &&
            //   currentPo.attachedFiles)) && (currentPo.reviewer === userData.email ||
            //     currentPo.submittedBy === userData.email || (viewerType === 'expenseReport' &&
            //       userInExpenseReportGroup)
          ) && (
              <React.Fragment>
                <Button color="primary" onClick={() => setShowHideUpdateAmounts(!showHideUpdateAmounts)}>
                  {buttonLoading ? <LoadingBars /> : showHideUpdateAmounts ? 'Hide Update Amounts' : `Update Amounts`}
                </Button>
              </React.Fragment>
            )}

          {(viewerType === 'reviewer' && viewStatus === 'review') && (
            <React.Fragment>
              <Button color="primary" onClick={() => setShowHideUpdateAmounts(!showHideUpdateAmounts)}>
                {buttonLoading ? <LoadingBars /> : showHideUpdateAmounts ? 'Hide Update Codes' : `Update Codes`}
              </Button>
            </React.Fragment>
          )}

          {((viewerType === 'reviewer' || (viewerType === 'expenseReport' && userInExpenseReportGroup))
            && viewStatus === 'approved' && (currentPo.attachedFiles) && currentPo.ReviewerVerifiedLineitems === 'no') &&
            (
              <React.Fragment>
                <Button color="success" onClick={() => { verifyTotalsClickHandler() }}>
                  {buttonLoading ? <LoadingBars /> : 'Verify Totals'}
                </Button>
              </React.Fragment>
            )}

          {(viewStatus === "approved" && currentPo.attachedFiles) && (
            <PDFDownloadLink
              document={
                <CurrentPDF id={currentPo.id} currentPo={currentPo} />
              }
              fileName={`purchase_order_${currentPo.id}.pdf`}
              onClick={(e) => e.preventDefault()}
            >
              <Button color="warning">
                {buttonLoading ? <LoadingBars /> : `Download`}
              </Button>
            </PDFDownloadLink>
          )}

          {/* {(isReviewer || isApprover) && (
            <React.Fragment>
              <Button color="primary" onClick={() => setShowHideBudgetData(!showHideBudgetData)}>
                {buttonLoading ? <LoadingBars /> : showHideBudgetData ? 'Hide Budgets' : `Show Budgets`}
              </Button>
            </React.Fragment>
          )} */}

          {viewerType === "reviewer" && (
            <React.Fragment>
              {(viewStatus === "review") && (
                <React.Fragment>
                  <Button onClick={approveHandler} color="success">
                    {buttonLoading ? <LoadingBars /> : `Approve`}
                  </Button>
                  <Button onClick={rejectHandler} color="danger">
                    {buttonLoading ? <LoadingBars /> : `Reject`}
                  </Button>
                  <TextField
                    label="Reason for rejection"
                    variant="outlined"
                    fullWidth
                    value={reason}
                    onChange={reasonChangeHandler}
                  />
                </React.Fragment>
              )}
            </React.Fragment>
          )}

          {viewerType === "approver" && (
            <React.Fragment>
              {(viewStatus === "pending" ||
                (viewStatus.includes("awaiting") &&
                  viewStatus.includes(userData.email))) && (
                  <React.Fragment>
                    <Button onClick={approveHandler} color="success">
                      {buttonLoading ? <LoadingBars /> : `Approve`}
                    </Button>
                    <Button onClick={rejectHandler} color="danger">
                      {buttonLoading ? <LoadingBars /> : `Reject`}
                    </Button>
                    <TextField
                      label="Reason for rejection"
                      variant="outlined"
                      fullWidth
                      value={reason}
                      onChange={reasonChangeHandler}
                    />
                  </React.Fragment>
                )}
            </React.Fragment>
          )}

          {(viewStatus === "approved" && currentPo.attachedFiles && currentPo.archived) && (
            <Button color="warning" onClick={() => { unarchiveItemClickHandler(currentPo.id) }}>
              {buttonLoading ? <LoadingBars /> : `Unarchive`}
            </Button>
          )}

          {(viewStatus === "approved" && currentPo.attachedFiles && !currentPo.archived) && (
            <Button color="warning" onClick={() => { archiveItemClickHandler(currentPo.id) }}>
              {buttonLoading ? <LoadingBars /> : `Archive`}
            </Button>
          )}
          {viewerType === "viewer" && (viewStatus !== 'rejected') && (
            <React.Fragment>
              <Button color="danger" onClick={voidClickHandler}>
                {buttonLoading ? <LoadingBars /> : `Void`}
              </Button>
            </React.Fragment>
          )}
        </GridItem>
        {currentPo.adminOverride && (
          <GridItem>
            <p style={{ color: 'red' }}>{currentPo.adminOverride === 'yes' ? 'Admin Overides: Yes' : currentPo.adminOverride}</p>
          </GridItem>)}
        {/* {(isReviewer || isApprover) && showHideBudgetData && (
          <GridItem>
            <POBudgetWidget lineItems={lineItems} />
          </GridItem>
        )} */}
        {(
          ((isReviewer && (currentPo.reviewer === userData.email || currentPo.submittedBy === userData.email)) // review check
            || (viewerType === 'expenseReport' && (userInExpenseReportGroup || currentPo.reviewer === userData.email))) // expense report check
          && currentPo.ReviewerEditedLineItems === 'no' && viewStatus === 'approved' && currentPo.attachedFiles && showHideUpdateAmounts // conditions for po
          // (isReviewer || (viewerType === 'expenseReport' && userInExpenseReportGroup)) &&
          // currentPo.ReviewerEditedLineItems === 'no' && showHideUpdateAmounts &&
          // (viewStatus === 'approved' && currentPo.attachedFiles) &&
          // (currentPo.reviewer === userData.email || currentPo.submittedBy === userData.email ||
          //   (viewerType === 'expenseReport' && userInExpenseReportGroup))
        ) && (
            <GridItem>
              <PMEditPOAmounts lineItems={lineItems} poID={currentPo.id} updateAmountsRedirectHandler={updateAmountsRedirectHandler} />
            </GridItem>
          )}

        {(viewerType === 'reviewer' && showHideUpdateAmounts && (viewStatus === 'review')) && (
          <GridItem>
            <PMEditPOCodes lineItems={lineItems} poID={currentPo.id} updateAmountsRedirectHandler={updateAmountsRedirectHandler} />
          </GridItem>
        )}
      </GridContainer>
    </React.Fragment>
  );
};

export default ActionBar;
