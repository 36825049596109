import axios from "axios";
import React, { useEffect, useState } from "react";
import Table from "components/Table/Table";
import { useLocation } from "react-router-dom";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Button from "components/CustomButtons/Button";
import Icon from "@material-ui/core/Icon";

import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";

import GoBack from "components/GoBack/GoBack";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";

import { DateRangePicker } from "react-date-range";

import {
  checkinSingleAtom,
  checkinSingleStartDateAtom,
  checkinSingleEndDateAtom,
  checkinSingleOverviewAtom,
} from "state/checkin";
import { useRecoilValue, useRecoilState } from "recoil";
import Loading from "components/Loading/Loading";

const useStyles = makeStyles(styles);

const Fallback = () => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Card>
        <CardHeader color="info" stats icon>
          <CardIcon color="info">
            <Icon>schedule</Icon>
          </CardIcon>

          <Loading color="blue" />
        </CardHeader>
        <CardFooter></CardFooter>
      </Card>
    </React.Fragment>
  );
};

const SingleUserOverviewComponent = (props) => {
  const userOverview = useRecoilValue(checkinSingleOverviewAtom(props.email));

  const userOverviewOutput = userOverview
    ? `${userOverview.timeClocked.hours}h ${userOverview.timeClocked.mins}m`
    : `Couldn't load data`;

  const classes = useStyles();

  return (
    <React.Fragment>
      <Card>
        <CardHeader color="info" stats icon>
          <CardIcon color="info">
            <Icon>schedule</Icon>
          </CardIcon>

          <p className={classes.cardCategory}>
            {props.startDateLabel} - {props.endDateLabel}
          </p>
          <h3 className={classes.cardTitle}>{userOverviewOutput}</h3>
        </CardHeader>
        <CardFooter></CardFooter>
      </Card>
    </React.Fragment>
  );
};

const SingleUserOverview = (props) => {
  return (
    <React.Suspense fallback={<Fallback />}>
      <SingleUserOverviewComponent {...props} />
    </React.Suspense>
  );
};

export default SingleUserOverview;
