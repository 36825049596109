import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody";
import CardText from "components/Card/CardText";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import { useRecoilValue } from "recoil";
import { monthlyVerseAtom } from "state/monthylVerse";
import Loading from "components/Loading/Loading";

const useStyles = makeStyles(styles);

const MonthlyVerseComponent = () => {
  const monthlyVerseData = useRecoilValue(monthlyVerseAtom);

  const classes = useStyles();
  return (
    <Card>
      <CardHeader color="info" text>
        <CardText color="info">
          <h4 className={classes.cardTitleWhite}>Monthly Verse</h4>
        </CardText>
      </CardHeader>
      <CardBody>
        <p>{monthlyVerseData[0].verse}</p>
        <p style={{ fontStyle: "italic", textAlign: "right" }}>
          &mdash; {monthlyVerseData[0].verseref}
        </p>
      </CardBody>
    </Card>
  );
};

const MonthlyVerse = () => {
  return (
    <React.Suspense fallback={<Loading color="blue" />}>
      <MonthlyVerseComponent />
    </React.Suspense>
  );
};

export default MonthlyVerse;
