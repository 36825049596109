import React, { useState } from "react";
import CustomTabs from "components/CustomTabs/CustomTabs";
import { useRecoilValue, useRecoilState } from "recoil";
import {
  poListSubmittedByAtom,
  poListSubmittedByStartDateMonthAtom,
  poListSubmittedByStartDateYearAtom,
  getPOListSubmittedBy
} from "state/purchaseOrders";
import Loading from "components/Loading/Loading";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";

import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import ListPOs from "./ListPOs";

//import { useSearchParams } from "react-router-dom";

const useStyles = makeStyles(styles);

const SubmittedByMeComponent = (props) => {
  const tab = +(props.location.search?.split("=")[1] || 0);
  const redirect = props.location.pathname.split('/purchase-orders/')[1] + '_' + tab

  const [poList, setPoList] = useRecoilState(poListSubmittedByAtom);

  const [month, setMonth] = useRecoilState(poListSubmittedByStartDateMonthAtom);
  const [year, setYear] = useRecoilState(poListSubmittedByStartDateYearAtom);

  const classes = useStyles();

  const updatePOList = async () => {
    let newList = await getPOListSubmittedBy(month, year)
    setPoList(newList)
  }

  const monthChangeHandler = async (e) => {
    setMonth(e.target.value);
    sessionStorage.setItem("plsbsdm", e.target.value);
    let newList = await getPOListSubmittedBy(e.target.value, year)
    setPoList(newList)
  };

  const yearChangeHandler = async (e) => {
    setYear(e.target.value);
    sessionStorage.setItem("plsbsdy", e.target.value);
    let newList = await getPOListSubmittedBy(month, e.target.value)
    setPoList(newList)
  };

  //console.log("poList SubmittedByMeComponent", poList);

  const cols = [
    {
      Header: "ID",
      accessor: "id",
      disableFilters: false,
      disableSortBy: true,
    },
    {
      Header: "Vendor",
      accessor: "vendor",
      disableFilters: false,
      disableSortBy: true,
    },
    {
      Header: "Created",
      accessor: "submittedDate",
      disableFilters: false,
      disableSortBy: true,
    },
    {
      Header: "Reviewer",
      accessor: "reviewerName",
      disableFilters: false,
      disableSortBy: true,
    },
    {
      Header: "Approver(s)",
      accessor: "submittedToName",
      disableFilters: false,
      disableSortBy: true,
    },
    {
      Header: "Total",
      accessor: "total",
      disableFilters: false,
      disableSortBy: true,
    },
    {
      Header: "Actions",
      accessor: "actions",
      disableFilters: true,
      disableSortBy: true,
    },
  ]

  const tabs = [
    {
      tabName: "Draft",
      tabContent: (
        <ListPOs
          cols={cols}
          redirect={redirect}
          buttons={{
            view: true,
            send: true,
            edit: true
          }}
          updatePOList={updatePOList}
          poList={poList.filter((item) => item.status === "draft")}
        />
      ),
    },
    {
      tabName: "Under Review",
      tabContent: (
        <ListPOs
          cols={cols}
          redirect={redirect}
          buttons={{
            view: true,
            manageFiles: true,
            edit: true
          }}
          updatePOList={updatePOList}
          poList={poList.filter(
            (item) =>
              item.status === "review"
          )}
        />
      ),
    },
    {
      tabName: "Pending",
      tabContent: (
        <ListPOs
          cols={cols}
          redirect={redirect}
          buttons={{
            view: true,
            manageFiles: true,
            edit: true
          }}
          updatePOList={updatePOList}
          poList={poList.filter(
            (item) =>
              item.status === "pending" ||
              item.status.indexOf("awaiting") !== -1
          )}
        />
      ),
    },
    {
      tabName: "Approved",
      tabContent: (
        <ListPOs
          cols={cols}
          redirect={redirect}
          buttons={{
            view: true,
            manageFiles: true,
            edit: true,
          }}
          updatePOList={updatePOList}
          poList={poList.filter((item) => item.status === "approved" && !item.attachedFiles)}
        />
      ),
    },
    {
      tabName: "Completed",
      tabContent: (
        <ListPOs
          cols={cols}
          redirect={redirect}
          buttons={{
            view: true,
            manageFiles: true,
            edit: true,
            download: true,
            archive: true
          }}
          updatePOList={updatePOList}
          poList={poList.filter((item) => {
            return (item.status === "approved" && item.attachedFiles);
          })}
        />
      ),
    },
    {
      tabName: "Rejected",
      tabContent: (
        <ListPOs
          cols={cols}
          redirect={redirect}
          buttons={{
            view: true,
            edit: true
          }}
          updatePOList={updatePOList}
          poList={poList.filter((item) => item.status === "rejected")}
        />
      ),
    },
  ];

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const years = ["2021", "2022", "2023", "2024"];

  return (
    <React.Fragment>
      <GridContainer>
        <GridItem xs={6} lg={2}>
          <FormControl fullWidth className={classes.selectFormControl}>
            <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
              Select Month
            </InputLabel>
            <Select
              MenuProps={{
                className: classes.selectMenu,
              }}
              classes={{
                select: classes.select,
              }}
              value={month}
              onChange={monthChangeHandler}
              inputProps={{
                name: "monthSelect",
                id: "month-select",
              }}
            >
              {months.map((item, index) => {
                return (
                  <MenuItem
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelected,
                    }}
                    value={index}
                    key={item}
                  >
                    {item}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </GridItem>
        <GridItem xs={6} lg={2}>
          <FormControl fullWidth className={classes.selectFormControl}>
            <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
              Select Year
            </InputLabel>
            <Select
              MenuProps={{
                className: classes.selectMenu,
              }}
              classes={{
                select: classes.select,
              }}
              value={year}
              onChange={yearChangeHandler}
              inputProps={{
                name: "yearSelect",
                id: "year-select",
              }}
            >
              {years.map((item, index) => {
                return (
                  <MenuItem
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelected,
                    }}
                    value={item}
                    key={item}
                  >
                    {item}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </GridItem>
      </GridContainer>
      <CustomTabs headerColor="info" tabs={tabs} value={tab} />
    </React.Fragment>
  );
};

const SubmittedByMeLoading = () => {
  const tabs = [
    {
      tabName: "Draft",
      tabContent: <Loading color="blue" />,
    },
    {
      tabName: "Under Review",
      tabContent: <Loading color="blue" />,
    },
    {
      tabName: "Pending",
      tabContent: <Loading color="blue" />,
    },
    {
      tabName: "Approved",
      tabContent: <Loading color="blue" />,
    },
    {
      tabName: "Completed",
      tabContent: <Loading color="blue" />,
    },
    {
      tabName: "Rejected",
      tabContent: <Loading color="blue" />,
    },
  ];

  return (
    <React.Fragment>
      <GridContainer>
        <GridItem xs={3}>
          <p>Select Month</p>
          <p>
            <select>
              <option>Select Month</option>
            </select>
          </p>
        </GridItem>
        <GridItem xs={3}>
          <p>Select Year</p>
          <p>
            <select>
              <option>Select Year</option>
            </select>
          </p>
        </GridItem>
      </GridContainer>
      <CustomTabs headerColor="info" tabs={tabs} />
    </React.Fragment>
  );
};

const SubmittedByMe = (props) => {
  return (
    <React.Suspense fallback={<SubmittedByMeLoading />}>
      <SubmittedByMeComponent {...props} />
    </React.Suspense>
  );
};

export default SubmittedByMe;
