import React, { useContext, useState, useEffect, Suspense } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardFooter from "components/Card/CardFooter.js";
import Button from "components/CustomButtons/Button";
import { TextField } from "@material-ui/core";

import Icon from "@material-ui/core/Icon";

import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";

import { Link } from "react-router-dom";

import axios from "axios";

import { userAtom, userCheckedInAtom } from "../state/auth";
import { atom, selector, useRecoilState, useRecoilValue } from "recoil";
import api from "../api.config";

const useStyles = makeStyles(styles);

const userOverviewSelector = selector({
  key: "userOverview",
  get: async () => {
    return await axios
      .post(`${api.path}/staff_portal_api/v1/usercheckinoverview`, {
        test: "test",
      })
      .then((res) => {
        return res.data[0];
      })
      .catch((err) => {});
  },
});

const Fallback = () => {
  const classes = useStyles();
  return (
    <Card>
      <CardHeader color="info" stats icon>
        <CardIcon color="info">
          <Icon>schedule</Icon>
        </CardIcon>

        <p className={classes.cardCategory}>This Week</p>
        <h3 className={classes.cardTitle}>Loading data</h3>
      </CardHeader>
      <CardFooter stats style={{ justifyContent: "flex-end" }}></CardFooter>
    </Card>
  );
};

const CheckinOverviewComponent = () => {
  const classes = useStyles();

  const [userData, setUserData] = useRecoilState(userAtom);
  const [userCheckedIn, setUserCheckedIn] = useRecoilState(userCheckedInAtom);
  const userOverview = useRecoilValue(userOverviewSelector);
  const [checkinNotes, setCheckinNotes] = useState("");

  const [pos, setPos] = useState(null);

  useEffect(() => {
    (() => {
      navigator.geolocation.getCurrentPosition((position) => {
        setPos(`${position.coords.latitude},${position.coords.longitude}`);
      });
    })();
  }, []);

  const userOverviewOutput = userOverview
    ? `${userOverview.timeClocked.hours}h ${userOverview.timeClocked.mins}m`
    : `Couldn't load data`;

  /* const linkPath = {
    
  }; */

  return (
    <Card>
      <CardHeader color="info" stats icon>
        <CardIcon color="info">
          <Icon>schedule</Icon>
        </CardIcon>

        <p className={classes.cardCategory}>This Week</p>
        <h3 className={classes.cardTitle}>{userOverviewOutput}</h3>

        {userData && (
          <div className={classes.stats}>
            <Link
              to={{
                pathname: `/portal/staffcheckin/user/${userData?.email}`,
                state: {
                  verified: true,
                },
              }}
            >
              View My Records
            </Link>
          </div>
        )}
      </CardHeader>
      {pos !== null && (
        <CardFooter stats style={{ justifyContent: "space-between" }}>
          {!userCheckedIn ? (
            <Button
              style={{ height: "40px" }}
              color="success"
              onClick={() => {
                axios
                  .post(`${api.path}/staff_portal_api/v1/checkin`, {
                    notes: checkinNotes,
                    coords: pos,
                  })
                  .then((res) => {
                    setUserCheckedIn(true);
                    setCheckinNotes("");
                  });
              }}
            >
              Check-in
            </Button>
          ) : (
            <Button
              style={{ height: "40px" }}
              color="danger"
              onClick={() => {
                axios
                  .post(`${api.path}/staff_portal_api/v1/checkout`, {
                    notes: checkinNotes,
                    coords: pos,
                  })
                  .then((res) => {
                    setUserCheckedIn(false);
                    setCheckinNotes("");
                  });
              }}
            >
              Checkout
            </Button>
          )}
          <TextField
            label="Check-in/out notes"
            variant="outlined"
            size="small"
            style={{ marginLeft: "1rem" }}
            onChange={(e) => setCheckinNotes(e.target.value)}
            value={checkinNotes}
            inputProps={{
              maxLength: 50,
            }}
          />
        </CardFooter>
      )}
    </Card>
  );
};

const CheckinOverview = () => {
  return (
    <Suspense fallback={<Fallback />}>
      <CheckinOverviewComponent />
    </Suspense>
  );
};

export default CheckinOverview;
