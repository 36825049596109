import React, { useState } from "react";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import { monthlyVerseAtom } from "state/monthylVerse";
import Loading from "components/Loading/Loading";
import { TextField } from "@material-ui/core";
import api from "../../api.config";

import { useRecoilValue } from "recoil";

import axios from "axios";


const MonthlyVerseComp = (props) => {
  const verseList = useRecoilValue(monthlyVerseAtom)
  const [verse, setVerse] = useState(verseList ? verseList[0] ? verseList[0].verse : '' : '')
  const [verseRef, setVerseRef] = useState(verseList ? verseList[0] ? verseList[0].verseref : '' : '')
  const id = verseList ? verseList[0] ? verseList[0].id : null : null

  const submitVerse = async () => {
    await axios
      .post(`${api.path}/staff_portal_api/v1/updateMonthyVerse`, {
        verseref: verseRef,
        verse: verse,
        id: id,
      })
      .then((res) => {
        return
      });
    window.location.reload()
  }

  return (
    <React.Fragment>
      <GridContainer>
        <GridItem xs={12}>
          <GridContainer style={{ alignItems: "center" }}>
            <GridItem xs={12}>
              <h3>Monthly Verse:</h3>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12}>
              <TextField
                label="Verse"
                variant="outlined"
                fullWidth
                placeholder={`Verse`}
                value={verse}
                multiline
                rows={4}
                onChange={(e) => setVerse(e.target.value)}
                style={{
                  margin: "15px 0",
                  backgroundColor: "#fff",
                }}
                required
              />
            </GridItem>
            <GridItem xs={12}>
              <TextField
                label="Verse Ref"
                variant="outlined"
                fullWidth
                value={verseRef}
                onChange={(e) => setVerseRef(e.target.value)}
                required
              />
            </GridItem>
            <GridItem xs={12}
              style={{ marginTop: '20px', textAlign: 'right' }}>
              <Button
                color="rose"
                onClick={submitVerse}
              >
                Submit
              </Button>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
    </React.Fragment>
  )
}


const MonthlyVerse = (props) => {
  return (
    <React.Suspense fallback={<Loading color="blue" />}>
      <MonthlyVerseComp {...props} />
    </React.Suspense>
  )
}

export default MonthlyVerse