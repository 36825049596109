import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Paper from "@material-ui/core/Paper";
import Grow from "@material-ui/core/Grow";
import Hidden from "@material-ui/core/Hidden";
import Popper from "@material-ui/core/Popper";
import Cookies from "js-cookie";

import googleLogo from '../../assets/img/Google-Logo.png'

// @material-ui/icons
import { VpnKey } from "@material-ui/icons";
import { Help } from "@material-ui/icons";
import WifiIcon from '@material-ui/icons/Wifi';

// core components
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";

import { userAtom } from "../../state/auth";

import { useRecoilValue } from "recoil";

import { googleLogout } from '@react-oauth/google';
import axios from "axios";

import api from "api.config";

const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
  const [openNotification, setOpenNotification] = React.useState(null);

  const userData = useRecoilValue(userAtom);

  const handleClickNotification = (event) => {
    if (openNotification && openNotification.contains(event.target)) {
      setOpenNotification(null);
    } else {
      setOpenNotification(event.currentTarget);
    }
  };
  const handleCloseNotification = () => {
    setOpenNotification(null);
  };
  const [openProfile, setOpenProfile] = React.useState(null);
  const handleClickProfile = (event) => {
    if (openProfile && openProfile.contains(event.target)) {
      setOpenProfile(null);
    } else {
      setOpenProfile(event.currentTarget);
    }
  };
  const handleCloseProfile = () => {
    setOpenProfile(null);
  };
  const classes = useStyles();
  const { rtlActive } = props;
  const searchButton =
    classes.top +
    " " +
    classes.searchButton +
    " " +
    classNames({
      [classes.searchRTL]: rtlActive,
    });
  const dropdownItem = classNames(classes.dropdownItem, classes.primaryHover, {
    [classes.dropdownItemRTL]: rtlActive,
  });
  const wrapper = classNames({
    [classes.wrapperRTL]: rtlActive,
  });
  const managerClasses = classNames({
    [classes.managerClasses]: true,
  });

  const logoutHandler = async () => {

    await axios.post(`${api.path}/staff_portal_api/v1/overrideGroupCache`).then((res) => { return });
    sessionStorage.removeItem("loggedIn");
    sessionStorage.removeItem("idToken");
    sessionStorage.removeItem("userData");
    sessionStorage.removeItem("groups");
    sessionStorage.removeItem('publishedPages')
    sessionStorage.removeItem("cdtndts");
    sessionStorage.removeItem("dtsdts");

    Cookies.remove("StaffPortalJWT");
    sessionStorage.removeItem("StaffPortalJWT")

    // page management storage items
    sessionStorage.removeItem('SPID')
    sessionStorage.removeItem('SPOption')

    sessionStorage.removeItem('publishedPagesDB')

    googleLogout();

    location.href = "/";
  };

  return (
    <>
      <div className={wrapper}>
        <div
          className={managerClasses}
          style={
            props.open
              ? {
                display: "flex",
              }
              : {}
          }
        >
          {(props.open !== undefined) && (
            <>
              {!openProfile ?
                <Button
                  color="transparent"
                  aria-label="Person"
                  justIcon
                  style={{
                    marginTop: '0px',
                    marginRight: '0px',
                    marginLeft: '12px',
                    paddingRight: '0px',
                    paddingLeft: '0px',
                    width: 'auto',
                    height: 'auto'
                  }}
                  aria-owns={openProfile ? "profile-menu-list" : null}
                  aria-haspopup="true"
                  onClick={handleClickProfile}
                  className={props.open !== undefined ? classes.buttonLinkRTL : classes.buttonLink}
                  muiClasses={{
                    label: props.open !== undefined ? classes.labelRTL : "",
                  }}
                >
                  <img
                    src={userData.avatar}
                    className={classes.avatarImg}
                    alt="..."
                    style={{ width: "34px", height: "34px", borderRadius: "50%" }}
                  />
                  <Hidden mdUp implementation="css"></Hidden>
                </Button>
                :
                <Button
                  className={props.open !== undefined ? classes.buttonLinkRTL : classes.buttonLink}
                  muiClasses={{
                    label: props.open !== undefined ? classes.labelRTL : "",
                  }}
                  style={{
                    marginTop: '10px',
                    marginRight: '0px',
                    marginLeft: '18px',
                    cursor: 'pointer',
                    color: 'black',
                    padding: '0px',
                    borderRadius: '30px',
                    height: '34px',
                    width: 'auto'
                  }}
                  color='white'
                  onClick={logoutHandler}
                >
                  <ClickAwayListener onClickAway={handleCloseProfile}>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '0px 0px', height: "25px" }}>
                      <img
                        src={userData.avatar}
                        className={classes.avatarImg}
                        alt="..."
                        style={{ width: "34px", height: "34px", borderRadius: '30px' }}
                      />
                      <span style={{ margin: '2.5px 10px 0px 5px', textTransform: 'none', fontSize: '14px' }}>Logout</span>
                    </div>
                  </ClickAwayListener>
                </Button>

              }
            </>)}
          <Button
            color="transparent"
            aria-label="User Guide"
            justIcon
            onClick={() => { }}
            className={props.open !== undefined ? classes.buttonLinkRTL : classes.buttonLink}
            muiClasses={{
              label: props.open !== undefined ? classes.labelRTL : "",
            }}
            style={{
              marginRight: '0px',
              marginLeft: '0px',
              paddingRight: '0px',
              paddingLeft: '0px',
              width: 'auto'
            }}
            href="/staff_portal_api/static/StaffPortalUserGuide.pdf"
            target="_blank"
            title="User Guide"
          >
            <Help />
          </Button>
          <Button
            style={{
              marginRight: '0px',
              marginLeft: '0px',
              paddingRight: '0px',
              paddingLeft: '0px',
              width: 'auto'
            }}
            color="transparent"
            aria-label="SBDaily Wifi"
            justIcon
            onClick={() => { }}
            className={props.open !== undefined ? classes.buttonLinkRTL : classes.buttonLink}
            muiClasses={{
              label: props.open !== undefined ? classes.labelRTL : "",
            }}
            href="https://sbdaily.sagebrush.work/qr.png"
            target="_blank"
            title="SBDaily Wifi"
          >
            <WifiIcon />
          </Button>
          <Button
            style={{
              marginRight: '0px',
              marginLeft: '0px',
              paddingRight: '0px',
              paddingLeft: '0px',
              width: 'auto'
            }}
            color="transparent"
            aria-label="Password Vault"
            justIcon
            onClick={() => { }}
            className={props.open !== undefined ? classes.buttonLinkRTL : classes.buttonLink}
            muiClasses={{
              label: props.open !== undefined ? classes.labelRTL : "",
            }}
            href="https://sagebrush.passwd.team/"
            target="_blank"
            title="Password Vault"
          >
            <VpnKey />
          </Button>
          {(props.open === undefined) && (
            <div
              className={managerClasses}
              style={
                props.open
                  ? {
                    display: "flex",
                  }
                  : {}
              }
            >

              {!openProfile ?
                <Button
                  color="transparent"
                  aria-label="Person"
                  justIcon
                  aria-owns={openProfile ? "profile-menu-list" : null}
                  aria-haspopup="true"
                  onClick={handleClickProfile}
                  style={{
                    margin: '5px 10px',
                    padding: '0px'
                  }}
                  className={classes.buttonLink}
                  muiClasses={{
                    label: "",
                  }}
                >
                  <img
                    src={userData.avatar}
                    className={classes.avatarImg}
                    alt="..."
                    style={{ width: "34px", height: "34px", borderRadius: "50%" }}
                  />
                  <Hidden mdUp implementation="css"></Hidden>
                </Button>
                :
                <ClickAwayListener onClickAway={handleCloseProfile}>
                  <Button
                    className={classes.buttonLink}
                    muiClasses={{
                      label: "",
                    }}
                    style={{
                      cursor: 'pointer',
                      color: 'black',
                      padding: '0px',
                      borderRadius: '30px',
                      margin: '5px 14px',
                    }}
                    color='white'
                    onClick={logoutHandler}
                  >
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '0px 0px' }}>
                      <img
                        src={userData.avatar}
                        className={classes.avatarImg}
                        alt="..."
                        style={{ width: "34px", height: "34px", borderRadius: '30px' }}
                      />
                      <span style={{ margin: '2.5px 10px 0px 5px', textTransform: 'none', fontSize: '14px' }}>Logout</span>

                    </div>
                  </Button>
                </ClickAwayListener>
              }
            </div>)}
        </div>
      </div >
    </>
  );
}

HeaderLinks.propTypes = {
  rtlActive: PropTypes.bool,
};
