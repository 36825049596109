import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import ReactTable from "components/ReactTable/ReactTable.js";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import { staffDirectoryAtom } from "state/directory";
import {
  useRecoilValue,
  useRecoilState,
  useSetRecoilState,
  RecoilRoot,
} from "recoil";
import OrgChart from "../../../components/OrgChart/OrgChart";
import Loading from "components/Loading/Loading";
import { poListSubmittedByAtom } from "state/purchaseOrders";
import Visibility from "@material-ui/icons/Visibility";
import Edit from "@material-ui/icons/Edit";
import { AttachFile, CenterFocusStrong, GetApp } from "@material-ui/icons";
import Check from "@material-ui/icons/Check";
import Close from "@material-ui/icons/Close";
import { userAtom } from "state/auth";
import axios from "axios";
import update from "immutability-helper";
import { useHistory } from "react-router";

import Badge from "components/Badge/Badge.js";

import ReactTooltip from "react-tooltip";

import FileListModal from "components/FileListModal/FileListModal";

import { pdf, PDFDownloadLink } from "@react-pdf/renderer";
import PurchaseOrderPDF from "../PurchaseOrderPDF";

import CurrentPDF from "../CurrentPDF";

import { saveAs } from "file-saver";
import { encryptData } from "assets/jss/EncriptDecrypt/EncryptDecrypt";

var JSZip = require("jszip");

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
};

const useStyles = makeStyles(styles);

const PurchaseOrderListRejectedComponent = (props) => {
  const classes = useStyles();
  const userData = useRecoilValue(userAtom);
  const history = useHistory();

  const viewClickHandler = (id) => {
    let data = {
      redirect: props.redirect,
    }

    history.push(`/portal/purchase-orders/view/${id}?${encryptData(data)}`);
  };

  const poList = props.items.map((item, index) => {
    let newList = {
      ...item,
      actions: (
        <React.Fragment>
          <Button
            justIcon
            color="primary"
            onClick={() => viewClickHandler(item.id)}
            // data-tip
            // data-for={`view-item-${index}`}
            title='View'
          >
            <Visibility />
          </Button>
          {/* <ReactTooltip
            delayHide={50}
            delayShow={500}
            effect="solid"
            id={`view-item-${index}`}
          >
            View
          </ReactTooltip> */}

          {!props.approver && (
            <>
              <Button
                justIcon
                color="primary"
                href={`/portal/purchase-orders/edit/${item.id}`}
                // data-tip
                // data-for={`edit-item-${index}`}
                title='Edit'
              >
                <Edit />
              </Button>
              {/* <ReactTooltip
                delayHide={50}
                delayShow={500}
                effect="solid"
                id={`edit-item-${index}`}
              >
                Edit
              </ReactTooltip> */}
            </>
          )}
        </React.Fragment>
      ),
    };

    if (item.submittedTo2 !== null) {
      newList = update(newList, {
        $merge: {
          submittedToName: (
            `${item.submittedToName} & ${item.submittedTo2Name}`
          ),
        },
      });
    }

    newList = update(newList, {
      $merge: {
        total: "$" + parseFloat(item.total).toFixed(2),
      },
    });

    newList = update(newList, {
      $merge: {
        id: (
          <React.Fragment>
            {item.id}
            <br />
            <span style={{ color: "#f00", fontSize: "13px" }}>
              Rejected Reason: {item.statusNotes || "None given"}
            </span>
          </React.Fragment>
        ),
      },
    });

    return newList;
  });

  return (
    <GridContainer>
      <GridItem xs={12}>
        {poList.length > 0 && (
          <ReactTable
            columns={[
              {
                Header: "ID",
                accessor: "id",
                disableFilters: true,
                disableSortBy: true,
              },
              {
                Header: "Vendor",
                accessor: "vendor",
                disableFilters: false,
                disableSortBy: false,
              },
              {
                Header: "Date Submitted",
                accessor: "submittedDate",
                disableFilters: false,
                disableSortBy: false,
              },
              {
                Header: props.approver ? "Submitted By" : "Submitted To",
                accessor: props.approver
                  ? "submittedByName"
                  : "submittedToName",
                disableFilters: false,
                disableSortBy: false,
              },
              {
                Header: "Total",
                accessor: "total",
                disableFilters: false,
                disableSortBy: false,
              },
              {
                Header: "Actions",
                accessor: "actions",
                disableFilters: false,
                disableSortBy: false,
              },
            ]}
            data={poList}
          />
        )}

        {poList.length === 0 && (
          <p style={{ textAlign: "center" }}>No rejected items.</p>
        )}
      </GridItem>
    </GridContainer>
  );
};

const PurchaseOrderListRejected = (props) => {
  return (
    <React.Suspense fallback={<Loading color="blue" />}>
      <PurchaseOrderListRejectedComponent {...props} />
    </React.Suspense>
  );
};

export default PurchaseOrderListRejected;
