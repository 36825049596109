import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Calendar as BigCalendar, momentLocalizer } from "react-big-calendar";
import axios from "axios";
import moment from "moment";
import {
  atom,
  selector,
  useRecoilState,
  useRecoilValue,
  selectorFamily,
} from "recoil";
import api from "../../api.config";

import { calendarSettingsAtom } from "state/settings";

import SweetAlert from "react-bootstrap-sweetalert";
import alertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";

import Tooltip from "@material-ui/core/Tooltip";

import { tooltip } from "assets/jss/material-dashboard-pro-react.js";

// NEW TOOLTIP
import ReactTooltip from "react-tooltip";

const tooltipStyles = { tooltip };

const useAlert = makeStyles(alertStyle);
const useTooltipStyles = makeStyles(tooltipStyles);

const MyCalendarEvent = (props) => {
  const startDate = new Date(props.event.start);
  const endDate = new Date(props.event.end);

  return (
    <React.Fragment>
      <div
        data-tip
        data-for={`${props.event.id}`}
        style={{ backgroundColor: props.event.color }}
      >
        {props.event.title}
      </div>
      <ReactTooltip delayHide={250} effect="solid" id={`${props.event.id}`}>
        <div style={{ textAlign: "left" }}>
          <p>{props.event.title}</p>
          {!props.event.allDay && (
            <React.Fragment>
              <p>
                Start Time:
                {` ${unmilitarize(startDate.getHours())}:${twoDigitize(
                  startDate.getMinutes()
                )} ${meridianize(startDate.getHours())}`}
              </p>
              <p>
                End Time:
                {` ${unmilitarize(endDate.getHours())}:${twoDigitize(
                  endDate.getMinutes()
                )} ${meridianize(endDate.getHours())}`}
              </p>
            </React.Fragment>
          )}
        </div>
      </ReactTooltip>
    </React.Fragment>
  );
};

const twoDigitize = (n) => {
  return n < 10 ? "0" + n : n;
};

const meridianize = (n) => {
  return n !== 0 && n > 12 ? "pm" : "am";
};

const unmilitarize = (n) => {
  return n > 12 ? n - 12 : n;
};

const gcalEventsSelector = selectorFamily({
  key: "gcalEvents",
  get: (index) => async ({ get }) => {
    const atom = get(calendarSettingsAtom);
    // console.log(atom)

    return Promise.all(
      atom.calendarSettings[index].calendars.map(async (atomItem) => {
        if (atomItem.id !== "") {
          // Google Events first
          if (atomItem.id.includes("@")) {
            return axios
              .post(`${api.path}/staff_portal_api/v1/getcalevents`, {
                calId: atomItem.id,
              })
              .then((res) => {
                let events = [];

                res.data.items.map((item, index) => {
                  if (item && item.start && item.start.date) {
                    let endDate = new Date(item.end.date);
                    endDate.setDate(endDate.getDate());

                    let startDate = new Date(item.start.date);
                    startDate.setDate(startDate.getDate() + 1);

                    events.push({
                      id: index + "-" + Date.now(),
                      title: item.summary || "No Title",
                      start: startDate,
                      end: endDate,
                      color: atomItem.eventColor,
                      allDay: true,
                    });
                  }

                  if (item && item.start && item.start.dateTime) {
                    events.push({
                      id: index + "-" + Date.now(),
                      title: item.summary || "No Title",
                      start: new Date(item.start.dateTime),
                      end: new Date(item.end.dateTime),
                      color: atomItem.eventColor,
                      allDay: false,
                    });
                  }
                });

                return events;
              });
          } else {
            // PCO calendars

            let date = new Date();

            let calendarStartDate = new Date(
              date.setMonth(date.getMonth() - 3)
            );

            // Have to set to 9 here because we already subtracted 3. The target goal is 6 months from today
            let calendarEndDate = new Date(date.setMonth(date.getMonth() + 9));

            return axios
              .post(`${api.path}/staff_portal_api/v1/pcocal`, {
                campus: atomItem.id,
                startDate: `${calendarStartDate.getFullYear()}-${twoDigitize(
                  calendarStartDate.getMonth() + 1
                )}-${twoDigitize(calendarStartDate.getDate())}`,
                endDate: `${calendarEndDate.getFullYear()}-${twoDigitize(
                  calendarEndDate.getMonth() + 1
                )}-${twoDigitize(calendarEndDate.getDate())}`,
              })
              .then((res) => {
                let events = [];

                res.data.map((item, index) => {
                  events.push({
                    id: index + "-" + Date.now(),
                    title: item.title || "No Title",
                    start: new Date(item.start),
                    end: new Date(item.end),
                    color: atomItem.eventColor,
                    allDay: false,
                  });
                });

                return events;
              });
          }
        } else {
          return [];
        }
      })
    )
      .then((values) => {
        let allItems = [];

        values.map((item) => {
          allItems = [...allItems, ...item];
        });
        return allItems;
      })
      .catch((err) => { });
  },
});

const localizer = momentLocalizer(moment);

const CalendarTabComponent = ({ tabIndex }) => {
  const gcalEvents = useRecoilValue(gcalEventsSelector(tabIndex));

  const alertClasses = useAlert();

  const [showMoreItemsModal, setShowMoreItemsModal] = useState(null);

  const eventColors = (event) => {
    /* var backgroundColor = "event-";
    event.color
      ? (backgroundColor = backgroundColor + event.color)
      : (backgroundColor = backgroundColor + "default");
    return {
      className: backgroundColor,
    }; */

    return {
      style: { backgroundColor: event.color },
    };
  };

  const submitHandler = () => {
    setShowMoreItemsModal(null);
  };

  const cancelHandler = () => {
    setShowMoreItemsModal(null);
  };

  const showMoreItemsModalHandler = (date, view) => {
    let dates = date.toString().split(" ");
    date = [dates[0], dates[1], dates[2], dates[3]].join(" ");

    let filteredEvents = gcalEvents.filter((item) => {
      return item.start.toString().includes(date) ||
        item.end.toString().includes(date)
        ? item
        : null;
    });

    if (filteredEvents.length) {
      setShowMoreItemsModal(
        <SweetAlert
          style={{ display: "block", marginTop: "-100px", color: "#000" }}
          title={`${date}`}
          onConfirm={submitHandler}
          onCancel={cancelHandler}
          confirmBtnCssClass={alertClasses.button + " " + alertClasses.success}
          confirmBtnText="OK"
        >
          {filteredEvents.map((item) => {
            return <p key={item.id}>{item.title}</p>;
          })}
        </SweetAlert>
      );
    }
  };

  return (
    <React.Fragment>
      {showMoreItemsModal}
      <BigCalendar
        /* selectable */
        popup
        localizer={localizer}
        events={gcalEvents}
        defaultView="month"
        //views={["month"]}
        scrollToTime={new Date(1970, 1, 1, 6)}
        defaultDate={new Date()}
        onSelectEvent={(event) => { }}
        onSelectSlot={(slotInfo) => { }}
        eventPropGetter={eventColors}
        //onDrillDown={showMoreItemsModalHandler}
        tooltipAccessor={null}
        components={{
          month: {
            event: MyCalendarEvent,
          },
        }}
      /* formats={{
        dateFormat: "dd",

        // http://jquense.github.io/react-big-calendar/examples/index.html#prop-formats
        dayRangeHeaderFormat: "YYYY",
      }} */
      />
    </React.Fragment>
  );
};

const CalendarTab = (props) => {
  return (
    <React.Suspense>
      <CalendarTabComponent {...props} />
    </React.Suspense>
  );
};

export default CalendarTab;
