import React, { useState } from "react";
import CustomTabs from "components/CustomTabs/CustomTabs";
import { useRecoilValue, useRecoilState } from "recoil";
import Loading from "components/Loading/Loading";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Button from "components/CustomButtons/Button.js";

import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import { adminDepartments, adminDepartmentsContractors } from "state/invoiceSystem";
import ListContractors from "./ListContractors";
import LoadingBars from "components/Loading/LoadingBars";
import { allContractors } from "state/invoiceSystem";

const useStyles = makeStyles(styles);

const AdminViewContractorsComp = (props) => {
  const tab = +(props.location.search?.split("=")[1] || 0);

  const departments = useRecoilValue(adminDepartments)
  const contractors = useRecoilValue(adminDepartmentsContractors)
  const listContractors = useRecoilValue(allContractors)

  const viewAllCols = [
    {
      Header: "Name",
      accessor: "name",
      disableFilters: false,
      disableSortBy: false,
    },
    {
      Header: "Email",
      accessor: "email",
      disableFilters: false,
      disableSortBy: false,
    },
    {
      Header: "Phone",
      accessor: "phone",
      disableFilters: false,
      disableSortBy: false,
    },
    {
      Header: "Actions",
      accessor: "actions",
      disableFilters: true,
      disableSortBy: true,
    },
  ]

  const departmentCols = [
    {
      Header: "Name",
      accessor: "contractorName",
      disableFilters: false,
      disableSortBy: false,
    },
    {
      Header: "Email",
      accessor: "contractorEmail",
      disableFilters: false,
      disableSortBy: false,
    },
    {
      Header: "Start Date",
      accessor: "startDate",
      disableFilters: false,
      disableSortBy: false,
    },
    {
      Header: "Status",
      accessor: "status",
      disableFilters: false,
      disableSortBy: false,
    },
    {
      Header: "Actions",
      accessor: "actions",
      disableFilters: true,
      disableSortBy: true,
    },
  ]

  const tabs = [{
    tabName: 'All',
    tabContent: (
      <ListContractors
        access={'admin'}
        contractors={listContractors}
        contractorListType='contractor'
        buttons={{
          view: true,
          edit: true,
          addContractor: true
        }}
        cols={viewAllCols}
      />
    ),
  }].concat(departments.map(dept => {
    return {
      tabName: dept.name,
      tabContent: (
        <ListContractors
          department={dept}
          access={'admin'}
          contractorListType='department'
          contractors={contractors.filter(item => item.departmentID === dept._id)}
          buttons={{
            view: true,
            edit: true,
            addContractor: true,
            manageDepartment: true
          }}
          cols={departmentCols}
        />
      ),
    }
  }))

  return (
    <React.Fragment>
      <CustomTabs headerColor="info" tabs={tabs} value={tab} />
    </React.Fragment>
  )
}

const AdminViewContractorsLoading = () => {
  const tabs = [
    {
      tabName: '',
      tabContent: <Loading color={'blue'} />,
    },
  ];

  return (
    <React.Fragment>
      <CustomTabs headerColor="info" tabs={tabs} />
    </React.Fragment>
  )
}

const AdminViewContractors = (props) => {
  return (
    <React.Suspense fallback={<AdminViewContractorsLoading />}>
      <AdminViewContractorsComp {...props} />
    </React.Suspense>
  )
}

export default AdminViewContractors