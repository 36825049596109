import React, { useState } from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Radio from "@material-ui/core/Radio";
import Button from "components/CustomButtons/Button";
import axios from "axios";
import update from "immutability-helper";
import api from "../../../api.config";

import Datetime from "react-datetime";

import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import GoBack from "components/GoBack/GoBack";

// Card
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import CardText from "components/Card/CardText";

// Atom stuff
import { useRecoilValue } from "recoil";
import { userAtom } from "state/auth";
import {
  approversListAtom,
  secondApproversListAtom,
  reviewersListAtom,
  poFundCodesAtom,
  poGlCodesAtom,
  poDeptCodesAtom,
  viewSinglePo,
} from "state/purchaseOrders";

import dashboardStyles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import Loading from "components/Loading/Loading";
import LoadingBars from "components/Loading/LoadingBars";

const useStyles = makeStyles(() => ({
  ...styles,
  ...dashboardStyles,
  formSection: {
    marginBottom: "2rem",
  },
}));

const AdminPurchaseOrderEditComponent = (props) => {
  const classes = useStyles();

  const currentId = +props.match.params.id;
  const currentPo = useRecoilValue(viewSinglePo(currentId));

  if (!currentPo) {
    return (
      <React.Fragment>
        <p>You aren't supposed to be here.</p>
      </React.Fragment>
    );
  }

  // if (currentPo.status !== "approved" && !currentPo.archived) {
  //   return (
  //     <React.Fragment>
  //       <p>You aren't supposed to be here.</p>
  //     </React.Fragment>
  //   );
  // }

  const [lineItems, setLineItems] = useState(
    currentPo && currentPo.lineItems ? JSON.parse(currentPo.lineItems) : []
  );

  const fundAutoCompleteRef = React.createRef();
  const glAutoCompleteRef = React.createRef();
  const deptAutoCompleteRef = React.createRef();

  const approverList = useRecoilValue(approversListAtom);
  const secondApproversList = useRecoilValue(secondApproversListAtom);
  const reviewerList = useRecoilValue(reviewersListAtom)

  // Error defaults
  const [errors, setErrors] = useState({
    vendor: {
      hasError: false,
      errorMessage: "",
    },
    purchaseReason: {
      hasError: false,
      errorMessage: "",
    },
    approver: {
      hasError: false,
      errorMessage: "",
    },
    reviewer: {
      hasError: false,
      errorMessage: "",
    },
  });

  // Misc data
  const userData = useRecoilValue(userAtom);
  const submittedDate = new Date().toISOString().slice(0, 10);

  const [buttonLoading, setButtonLoading] = useState(false);

  // Codes for dropdowns
  const fundCodes = useRecoilValue(poFundCodesAtom);
  const glCodes = useRecoilValue(poGlCodesAtom);
  const deptCodes = useRecoilValue(poDeptCodesAtom);

  // Radio Button States
  const [recurring, setRecurring] = useState(currentPo.recurring);
  const [paymentType, setPaymentType] = useState(
    currentPo.paymentType || "payment"
  );
  const [paymentBy, setPaymentBy] = useState(
    currentPo.paymentBy || "creditCard"
  );
  const [checkDeliveryMethod, setCheckDeliveryMethod] = useState(
    currentPo.checkToBe || "mailed"
  );
  const [alreadyPurchased, setAlreadyPurchased] = useState(
    currentPo.dateOrdered !== null ? "yes" : "no"
  );

  // Select states
  const [approver, setApprover] = useState(currentPo.submittedTo);
  const [secondApprover, setSecondApprover] = useState(currentPo.submittedTo2);
  const [reviewer, setReviewer] = useState(currentPo.reviewer)

  // Text field states
  const [vendor, setVendor] = useState(currentPo.vendor);
  const [address, setAddress] = useState(currentPo.address);
  const [purchaseReason, setPurchaseReason] = useState(
    currentPo.purchaseReason
  );


  const fundCodeChangeHandler = (index, value) => {
    // Value is the object from the option so it will look like this:
    // { id: 3, code: "102", title: "Worship" }

    setLineItems((prevState) => {
      return update(prevState, {
        [index]: { $merge: { fundCode: value } },
      });
    });
  };

  const glCodeChangeHandler = (index, value) => {
    setLineItems((prevState) => {
      return update(prevState, {
        [index]: { $merge: { glCode: value } },
      });
    });
  };

  const deptCodeChangeHandler = (index, value) => {
    setLineItems((prevState) => {
      return update(prevState, {
        [index]: { $merge: { deptCode: value } },
      });
    });
  };

  const lineItemAmountChangeHandler = (e, index, value) => {
    e.preventDefault();
    setLineItems((prevState) => {
      return update(prevState, {
        [index]: { $merge: { amount: value } },
      });
    });
  };


  const getTotalAmount = () => {
    let tempAmount = 0;

    lineItems.map((item) => {
      if (item.amount) {
        tempAmount += parseFloat(item.amount);
      }
    });

    return Math.round(tempAmount * 100 + Number.EPSILON) / 100;
  };

  // Submit handler
  const submitHandler = async () => {
    let cleanLineItems = lineItems.map(item => {
      return {
        ...item,
        eventCode: null,
        description: item.description.replaceAll('"', '').replaceAll("'", '').replaceAll('\\', '')
      }
    })
    let postData = {
      poid: currentId,
      lineItems: JSON.stringify(cleanLineItems),
      total: getTotalAmount(),
    };


    let lineItemErrors = false;
    lineItems.forEach((item) => {
      if (
        !item.fundCode ||
        !item.glCode ||
        !item.deptCode ||
        item.description === "" ||
        item.amount === ""
      ) {
        lineItemErrors = true;
      }
    });

    if (lineItemErrors) {
      alert(
        "Each line item is required to have all data entered. Please update or remove your line item information and resubmit when complete."
      );
    } else {
      setButtonLoading(true);
      await axios
        .post(`${api.path}/digitalpo_api/v1/adminEditPO`, postData)
        .then((res) => {
          const returnLocation = (props.location.search?.split('?')[1]?.split('_') || '')
          if (returnLocation) {
            location.href = `/portal/purchase-orders/${returnLocation[0]}?tab=${returnLocation[1]}`;
          } else {
            location.href = "/portal/purchase-orders/admin";
          }
        });
    }
  };

  return (
    <React.Fragment>
      <GoBack />
      <GridContainer>
        <GridItem xs={12} md={6}>
          <Card>
            <CardHeader color="info" text>
              <CardText color="info">
                <h4 className={classes.cardTitleWhite}>Admin Edit Purchase Order</h4>
              </CardText>
            </CardHeader>

            <CardBody>
              <GridContainer className={classes.formSection}>
                <GridItem xs={12} lg={12}>
                  <TextField
                    required
                    label="Vendor"
                    variant="outlined"
                    fullWidth
                    value={vendor}
                    disabled
                    helperText={errors.vendor.errorMessage || ""}
                    error={errors.vendor.hasError || false}
                  />
                </GridItem>
              </GridContainer>

              <GridContainer>
                <GridItem xs={12}>
                  <GridContainer
                    className={classes.formSection}
                    style={{ alignItems: "center" }}
                  >
                    <GridItem xs={12} md={4}>
                      <p>Already Purchased?</p>
                    </GridItem>

                    <GridItem xs={12} md={4}>
                      <FormControlLabel
                        control={
                          <Radio
                            checked={alreadyPurchased === "yes"}
                            name="alreadyPurchased"
                            value="yes"
                            disabled
                            classes={{
                              checked: classes.checked,
                              root: classes.checkRoot,
                            }}
                          />
                        }
                        classes={{
                          label: classes.label,
                          root: classes.labelRoot,
                        }}
                        label="Yes"
                      />

                      <FormControlLabel
                        control={
                          <Radio
                            checked={alreadyPurchased === "no"}
                            name="alreadyPurchased"
                            value="no"
                            disabled
                            classes={{
                              checked: classes.checked,
                              root: classes.checkRoot,
                            }}
                          />
                        }
                        classes={{
                          label: classes.label,
                          root: classes.labelRoot,
                        }}
                        label="No"
                      />
                    </GridItem>

                    <GridItem xs={12} md={4}>
                      {alreadyPurchased === "yes" && (
                        <React.Fragment>
                          Date Purchased?{" "}
                          <Datetime
                            dateFormat={`YYYY-MM-DD`}
                            timeFormat={false}
                            inputProps={{ placeholder: "Select Date" }}
                            disabled
                          />
                        </React.Fragment>
                      )}
                    </GridItem>
                  </GridContainer>

                  <GridContainer className={classes.formSection}>
                    <GridItem xs={12} md={4} style={{ alignItems: "center" }}>
                      <p>Type</p>
                    </GridItem>

                    <GridItem xs={12} md={8}>
                      <FormControlLabel
                        control={
                          <Radio
                            checked={paymentType === "payment"}
                            name="paymentType"
                            value="payment"
                            disabled
                            classes={{
                              checked: classes.checked,
                              root: classes.checkRoot,
                            }}
                          />
                        }
                        classes={{
                          label: classes.label,
                          root: classes.labelRoot,
                        }}
                        label="Payment"
                      />

                      <FormControlLabel
                        control={
                          <Radio
                            checked={paymentType === "reimbursement"}
                            name="paymentType"
                            value="reimbursement"
                            disabled
                            classes={{
                              checked: classes.checked,
                              root: classes.checkRoot,
                            }}
                          />
                        }
                        classes={{
                          label: classes.label,
                          root: classes.labelRoot,
                        }}
                        label="Reimbursement"
                      />
                    </GridItem>
                  </GridContainer>

                  <GridContainer className={classes.formSection}>
                    <GridItem xs={12} md={4} style={{ alignItems: "center" }}>
                      <p>Payment By</p>
                    </GridItem>

                    <GridItem xs={12} md={4}>
                      <FormControlLabel
                        control={
                          <Radio
                            checked={paymentBy === "creditCard"}
                            name="paymentBy"
                            value="creditCard"
                            disabled
                            classes={{
                              checked: classes.checked,
                              root: classes.checkRoot,
                            }}
                          />
                        }
                        classes={{
                          label: classes.label,
                          root: classes.labelRoot,
                        }}
                        label="Credit Card"
                      />

                      <FormControlLabel
                        control={
                          <Radio
                            checked={paymentBy === "check"}
                            name="paymentBy"
                            value="check"
                            disabled
                            classes={{
                              checked: classes.checked,
                              root: classes.checkRoot,
                            }}
                          />
                        }
                        classes={{
                          label: classes.label,
                          root: classes.labelRoot,
                        }}
                        label="Check"
                      />
                    </GridItem>
                  </GridContainer>

                  {paymentBy === "check" && (
                    <GridContainer className={classes.formSection}>
                      <GridItem xs={12} md={4}>
                        <p>Check will be</p>

                        <FormControlLabel
                          control={
                            <Radio
                              checked={checkDeliveryMethod === "mailed"}
                              name="checkDeliveryMethod"
                              value="mailed"
                              disabled
                              classes={{
                                checked: classes.checked,
                                root: classes.checkRoot,
                              }}
                            />
                          }
                          classes={{
                            label: classes.label,
                            root: classes.labelRoot,
                          }}
                          label="Mailed"
                        />

                        <FormControlLabel
                          control={
                            <Radio
                              checked={
                                checkDeliveryMethod === "givenToSubmitter"
                              }
                              name="checkDeliveryMethod"
                              value="givenToSubmitter"
                              disabled
                              classes={{
                                checked: classes.checked,
                                root: classes.checkRoot,
                              }}
                            />
                          }
                          classes={{
                            label: classes.label,
                            root: classes.labelRoot,
                          }}
                          label="Given to Submitter"
                        />
                      </GridItem>

                      <GridItem xs={12} md={8} className={classes.formSection}>
                        <TextField
                          label="Make check payable to"
                          variant="outlined"
                          fullWidth
                          multiline
                          rows={5}
                          value={address}
                          disabled
                        />
                      </GridItem>
                    </GridContainer>
                  )}

                  <GridContainer className={classes.formSection}>

                    <GridItem xs={12} md={12}>
                      <TextField
                        label="Reason for Purchase"
                        variant="outlined"
                        fullWidth
                        value={purchaseReason}
                        disabled
                        required
                        error={errors.purchaseReason.hasError}
                        helperText={errors.purchaseReason.errorMessage}
                      />
                    </GridItem>
                  </GridContainer>

                  <GridContainer
                    className={classes.formSection}
                    style={{
                      borderRadius: "3px",
                      padding: "1rem",
                    }}
                  >
                    {lineItems.map((item, index) => {
                      return (
                        <React.Fragment
                          key={index}>
                          <GridContainer
                            key={index}
                            className={classes.formSection}
                            style={{
                              alignItems: "center",
                              border: "1px solid #ddd",
                              borderRadius: "3px",
                              background: "#fafafa",
                              padding: "15px 0",
                            }}
                          >
                            <GridItem xs={9} md={4}>
                              <Autocomplete
                                ref={fundAutoCompleteRef}
                                disablePortal
                                options={fundCodes}
                                getOptionSelected={(selected) => item.fundCode.id === selected.id}
                                getOptionLabel={(option) =>
                                  `${option.code} - ${option.title}` || ""
                                }
                                value={item.fundCode}
                                sx={{ width: 300 }}
                                renderInput={(params) => (
                                  <TextField {...params} label="Fund Code" />
                                )}
                                onChange={(e, v) =>
                                  fundCodeChangeHandler(index, v)
                                }
                                autoSelect={true}
                                autoComplete={true}
                                autoHighlight={true}
                              />

                              <Autocomplete
                                ref={glAutoCompleteRef}
                                value={item.glCode}
                                getOptionSelected={(selected) => item.glCode.id === selected.id}
                                disablePortal
                                options={glCodes}
                                getOptionLabel={(option) =>
                                  `${option.code} - ${option.title}` || ""
                                }
                                sx={{ width: 300 }}
                                renderInput={(params) => (
                                  <TextField {...params} label="Gl Code" />
                                )}
                                onChange={(e, v) =>
                                  glCodeChangeHandler(index, v)
                                }
                                autoSelect={true}
                                autoComplete={true}
                                autoHighlight={true}
                              />

                              <Autocomplete
                                ref={deptAutoCompleteRef}
                                disablePortal
                                options={deptCodes}
                                getOptionSelected={(selected) => item.deptCode.id === selected.id}
                                getOptionLabel={(option) =>
                                  `${option.code} - ${option.title}` || ""
                                }
                                sx={{ width: 300 }}
                                renderInput={(params) => (
                                  <TextField {...params} label="Dept Code" />
                                )}
                                onChange={(e, v) =>
                                  deptCodeChangeHandler(index, v)
                                }
                                autoSelect={true}
                                autoComplete={true}
                                autoHighlight={true}
                                value={item.deptCode}
                              />

                            </GridItem>
                            <GridItem xs={12} md={8}>
                              <TextField
                                label=""
                                variant="outlined"
                                fullWidth
                                placeholder={`Line Item Description`}
                                value={item.description}
                                multiline
                                rows={4}
                                disabled
                                style={{
                                  margin: "15px 0",
                                  backgroundColor: "#fff",
                                }}
                              />

                              <GridContainer style={{ alignItems: "center" }}>
                                <GridItem md={8}>
                                  <TextField
                                    placeholder={`$ Amount`}
                                    value={item.amount}
                                    variant="outlined"
                                    onChange={(e) => {
                                      lineItemAmountChangeHandler(
                                        e,
                                        index,
                                        e.target.value
                                      );
                                    }}
                                    onWheel={(e) => e.target.blur()}
                                    type="number"
                                    inputProps={{
                                      step: 0.01,
                                    }}
                                    style={{ backgroundColor: "#fff" }}
                                  />
                                </GridItem>
                              </GridContainer>
                            </GridItem>

                          </GridContainer>
                        </React.Fragment>
                      );
                    })}
                  </GridContainer>

                  <GridContainer
                    className={classes.formSection}
                    style={{ marginTop: "-4rem" }}
                  >
                    <GridItem xs={12} md={3}>
                      <p
                        style={{
                          padding: "1rem",
                          fontSize: "1rem",
                          textAlign: "left",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <strong>Total:</strong>
                        <span>${getTotalAmount()}</span>
                      </p>
                    </GridItem>
                  </GridContainer>

                  <GridContainer className={classes.formSection}>
                    <GridItem xs={12} md={6}>
                      <Autocomplete
                        disablePortal
                        options={reviewerList}
                        getOptionLabel={(option) =>
                          `${option.name}` || ""
                        }
                        value={reviewerList.find(item => item.email === reviewer)}
                        sx={{ width: 300 }}
                        renderInput={(params) => (
                          <TextField {...params} label="Choose Reviewer" />
                        )}
                        disabled
                        autoSelect={true}
                        autoComplete={true}
                        autoHighlight={true}
                        required
                      />
                    </GridItem>
                  </GridContainer>

                  <GridContainer>
                    <GridItem xs={12} md={6} className={classes.formSection}>
                      <Autocomplete
                        disablePortal
                        options={approverList}
                        getOptionLabel={(option) =>
                          `${option.name}` || ""
                        }
                        value={approverList.find(item => item.email === approver)}
                        sx={{ width: 300 }}
                        renderInput={(params) => (
                          <TextField {...params} label="Choose Approver" />
                        )}
                        disabled
                        autoSelect={true}
                        autoComplete={true}
                        autoHighlight={true}
                        required
                      />
                    </GridItem>

                    {(getTotalAmount() >= 1000) && (
                      <GridItem xs={12} md={6} className={classes.formSection}>
                        <Autocomplete
                          disablePortal
                          options={secondApproversList}
                          getOptionLabel={(option) =>
                            `${option.name}` || ""
                          }
                          value={secondApproversList.find(item => item.email === secondApprover)}
                          sx={{ width: 300 }}
                          renderInput={(params) => (
                            <TextField {...params} label="Choose Approver" />
                          )}
                          disabled
                          autoSelect={true}
                          autoComplete={true}
                          autoHighlight={true}
                          required
                        />
                      </GridItem>
                    )}
                  </GridContainer>

                  <GridContainer className={classes.formSection}>
                    <GridItem xs={12} md={6} style={{ marginBottom: "15px" }}>
                      <TextField
                        label="Submitted By"
                        variant="outlined"
                        disabled
                        value={userData.email}
                        fullWidth
                      />
                    </GridItem>
                    <GridItem xs={12} md={6}>
                      <TextField
                        label="Date Submitted"
                        variant="outlined"
                        disabled
                        value={submittedDate}
                        fullWidth
                      />
                    </GridItem>
                  </GridContainer>

                  <GridContainer className={classes.formSection}>
                    <GridItem xs={12}>
                      <Button
                        color="primary"
                        onClick={() => { submitHandler() }}
                        disabled={buttonLoading}
                      >
                        {buttonLoading ? (
                          <LoadingBars />
                        ) : (
                          `Admin Override`
                        )}
                      </Button>
                    </GridItem>
                  </GridContainer>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </React.Fragment>
  );
};

export const AdminPurchaseOrderEdit = (props) => {
  return (
    <React.Suspense fallback={<Loading color="blue" />}>
      <AdminPurchaseOrderEditComponent {...props} />
    </React.Suspense>
  );
};

export default AdminPurchaseOrderEdit;