import React, { useState } from 'react'
import ReactTable from "components/ReactTable/ReactTable.js";
import Loading from "components/Loading/Loading";
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';

import { TextField } from '@material-ui/core';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";

import Button from "components/CustomButtons/Button.js";
import ReactTooltip from "react-tooltip";

import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Close from '@material-ui/icons/Close';
import PublishIcon from '@material-ui/icons/Publish';
import UndoIcon from '@material-ui/icons/Undo';
import TabIcon from '@material-ui/icons/Tab';

import ReactDOM from "react-dom";
import update from 'immutability-helper';

const TrainingPageManagementComponent = (props) => {

  const EmptyPage = {
    id: null,
    dropdown: null,
    position: 0,
    status: 'Editing',
    title: '',
    groupRequirment: null
  }

  const [selectedPage, setSelectedPage] = useState(EmptyPage)
  const [pageCreationPopup, setPageCreationPopup] = useState(false)

  const getTableCols = () => {
    var cols = [
      {
        Header: "Page Name",
        accessor: "title",
        disableFilters: false,
        disableSortBy: true,
      },
      {
        Header: "Group",
        accessor: "dropdown",
        disableFilters: false,
        disableSortBy: true,
      },
      {
        Header: "Status",
        accessor: "status",
        disableFilters: false,
        disableSortBy: true,
      },
      {
        Header: "View Restriction",
        accessor: "groupRequirmentName",
        disableFilters: false,
        disableSortBy: true,
      },
      {
        Header: "Actions",
        accessor: "actions",
        disableFilters: true,
        disableSortBy: true,
      },
    ]
    return cols
  }

  const UniqueGroups = [...new Set(props.pages.map(item => item.dropdown))]
  const groups = UniqueGroups.map((item, index) => {
    return {
      id: index,
      name: item
    }
  })


  const pageList = props.pages.map((item, index) => {

    let newList = {
      ...item,
      groupRequirmentName: props.googleGroupOptions.find(i => i.email === item.groupRequirment).name,
      actions: (
        <React.Fragment>

          <Button
            justIcon
            color="primary"
            title='Edit Page Name'
            // data-tip
            // data-for={`edit-item-${index}`}
            onClick={(e) => {
              setSelectedPage(item)
              setPageCreationPopup(true)
            }}
          >
            <EditIcon />
          </Button>
          {/* <ReactTooltip
            delayHide={50}
            delayShow={500}
            effect="solid"
            id={`edit-item-${index}`}
          >
            Edit Page Name
          </ReactTooltip> */}

          <Button
            justIcon
            color="primary"
            // data-tip
            // data-for={`tab-item-${index}`}
            title='Edit Page Contents'
            onClick={(e) => {
              sessionStorage.setItem('SPOption', 'Edit')
              sessionStorage.setItem('SPID', item._id)
              location.reload()
            }}
          >
            <TabIcon />
          </Button>
          {/* <ReactTooltip
            delayHide={50}
            delayShow={500}
            effect="solid"
            id={`tab-item-${index}`}
          >
            Edit Page Contents
          </ReactTooltip> */}

          {item.status === 'Published' ?
            <>
              <Button
                justIcon
                color="primary"
                title='Unpublish'
                // data-tip
                // data-for={`editing-item-${index}`}
                onClick={(e) => {
                  props.updatePageStatus(item, 'Editing')
                }}
              >
                <UndoIcon />
              </Button>
              {/* <ReactTooltip
                delayHide={50}
                delayShow={500}
                effect="solid"
                id={`editing-item-${index}`}
              >
                Unpublish
              </ReactTooltip> */}
            </> : <>
              <Button
                justIcon
                color="primary"
                title='Publish'
                // data-tip
                // data-for={`publish-item-${index}`}
                onClick={(e) => {
                  props.updatePageStatus(item, 'Published')
                }}
              >
                <PublishIcon />
              </Button>
              {/* <ReactTooltip
                delayHide={50}
                delayShow={500}
                effect="solid"
                id={`publish-item-${index}`}
              >
                Publish
              </ReactTooltip> */}
            </>}

          <Button
            justIcon
            color="danger"
            // data-tip
            // data-for={`delete-item-${index}`}
            title='Delete'
            onClick={(e) => {
              props.deletePage(item)
            }}
          >
            <DeleteForeverIcon />
          </Button>
          {/* <ReactTooltip
            delayHide={50}
            delayShow={500}
            effect="solid"
            id={`delete-item-${index}`}
          >
            Delete
          </ReactTooltip> */}
        </React.Fragment>
      ),
    };

    return newList;
  });

  const submitPageHandler = (page) => {
    if (!page._id) {
      props.createPage(page)
    } else {
      props.editPage(page)
    }
  }
  return (
    <GridItem xs={12}>
      <GridContainer>
        <GridItem
          style={{ textAlign: 'right' }}
          xs={12}
        >
          <Button
            color="primary"
            onClick={() => {
              setSelectedPage(EmptyPage)
              setPageCreationPopup(true)
            }}
            tabIndex="-100"
          >
            Create Page
          </Button>
        </GridItem>
      </GridContainer>
      <Card>
        <CardHeader color="primary" icon>
          <CardIcon color="primary">
            <h4 style={{ fontWeight: 'bold', padding: '0px 10px' }}>Page Management</h4>
          </CardIcon>
        </CardHeader>

        <CardBody>
          <GridContainer>
            {pageCreationPopup ?
              <PageCreatePopup
                pageValues={selectedPage}
                toggle={setPageCreationPopup}
                submit={submitPageHandler}
                groups={groups}
                googleGroupOptions={props.googleGroupOptions}
              />
              : <></>}
            <GridItem xs={12}>

              {pageList.length > 0 && (
                <React.Fragment>
                  <ReactTable
                    columns={getTableCols()}
                    data={pageList}
                  />
                </React.Fragment>
              )}

              {pageList.length === 0 && (
                <p style={{ textAlign: "center" }}>No Pages Created.</p>
              )}
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
    </GridItem>
  )
}

const TrainingPageManagement = (props) => {
  return (
    <React.Suspense fallback={<Loading color="blue" />}>
      <TrainingPageManagementComponent {...props} />
    </React.Suspense>
  )
}

const PageCreatePopup = ({ toggle, pageValues, submit, groups, googleGroupOptions }) => {
  const styles = {
    wrap: {
      background: "#000",
      position: "absolute",
      top: "0",
      left: "0",
      right: "0",
      bottom: "0",
      zIndex: 99998,
      opacity: ".7",
    },
    modal: {
      width: "96vw",
      maxWidth: '800px',
      zIndex: 99999,
      maxHeight: "80vh",
    },
  };

  const [page, setPage] = useState(pageValues)
  const filter = createFilterOptions();
  const defaultValue = groups.find(item => item.name === pageValues.dropdown)
  return (
    <React.Fragment>
      {ReactDOM.createPortal(
        <div style={styles.wrap} onClick={(e) => toggle(false)}></div>,
        document.getElementById("file-list-modal-overlay")
      )}
      {ReactDOM.createPortal(
        <div style={{ position: 'absolute', width: '100vw', top: '10vh' }}>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <GridContainer style={styles.modal}>
              <Card>
                <CardHeader color="primary" icon>
                  <CardIcon color="primary">
                    <h4 style={{ fontWeight: 'bold', padding: '0px 10px' }}>{page._id ? 'Edit' : 'Add'} Page</h4>
                  </CardIcon>
                  <GridContainer style={{ alignItems: "center" }}>
                    <GridItem
                      xs={12}
                      style={{
                        textAlign: "right",
                        marginTop: '-25px'
                      }}
                    >
                      <Button
                        justIcon
                        color="danger"
                        size="sm"
                        onClick={(e) => toggle(false)}
                      >
                        <Close />
                      </Button>
                    </GridItem>


                  </GridContainer>
                </CardHeader>
                <CardBody style={{ overflowY: "auto", maxHeight: "80vh", }}>
                  <GridContainer>
                    <GridItem xs={12} sm={6} style={{ marginBottom: '15px' }}>
                      <TextField
                        style={{ width: '100%' }}
                        label='Page Name'
                        variant='outlined'
                        value={page.title}
                        required
                        onChange={(e) => {
                          setPage(prevState => {
                            return update(prevState, {
                              $merge: { title: e.target.value }
                            })
                          })
                        }}
                      />
                    </GridItem>
                    <GridItem
                      xs={12}
                      sm={6}
                      style={{ marginBottom: '15px' }}
                    >
                      <Autocomplete
                        disablePortal
                        options={groups}
                        ListboxProps={{ style: { maxHeight: '140px' } }}
                        getOptionLabel={(option) =>
                          `${option.name}` || ""
                        }
                        style={{ width: 300 }}
                        renderInput={(params) => (
                          <TextField {...params} label="Group" variant="outlined" />
                        )}
                        defaultValue={defaultValue}
                        onChange={(e, v) => {
                          let value
                          if (v) {
                            if (v.inputValue !== undefined) {
                              value = v.inputValue
                            } else {
                              value = v.name
                            }
                          } else {
                            value = null
                          }
                          setPage(prevState => {
                            return update(prevState, {
                              $merge: { dropdown: value }
                            })
                          })
                        }}
                        filterOptions={(options, params) => {
                          const filtered = filter(options, params);

                          // Suggest the creation of a new value
                          if (params.inputValue !== '') {
                            filtered.push({
                              inputValue: params.inputValue,
                              name: `Add "${params.inputValue}"`,
                            });
                          }

                          return filtered;
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={6} style={{ marginBottom: '150px' }}>
                      <Autocomplete
                        disablePortal
                        disableClearable
                        options={googleGroupOptions}
                        ListboxProps={{ style: { maxHeight: '140px' } }}
                        getOptionLabel={(option) =>
                          `${option.name}` || ""
                        }
                        fullWidth
                        //style={{ width: 300 }}
                        value={googleGroupOptions.find(item => item.email === page.groupRequirment)}
                        renderInput={(params) => (
                          <TextField {...params} label="View Restriction" variant="outlined" />
                        )}
                        onChange={(e, v) => {
                          setPage(prevState => {
                            return update(prevState, {
                              $merge: { groupRequirment: v.email }
                            })
                          })
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} style={{ textAlign: 'right' }}>
                      <Button color="primary"
                        disabled={!page.dropdown || page.title.replaceAll(' ', '') === '' ? true : false}
                        onClick={() => { submit(page) }}
                      >
                        Submit
                      </Button>
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </Card>
            </GridContainer>
          </div>
        </div>,
        document.getElementById("file-list-modal")
      )}
    </React.Fragment>
  )
}

export default TrainingPageManagement