// Components
import Dashboard from "views/Dashboard/Dashboard.js";
import StaffCheckinPage from "views/StaffCheckin/StaffCheckin";
import OnlineForms from "views/OnlineForms/OnlineForms";
import Portal from "views/Portal/Portal";
import StaffDirectory from "views/StaffDirectory/StaffDirectory";
import Links from "views/Links/Links";
// @material-ui/icons
import Alarm from "@material-ui/icons/Alarm";
import DashboardIcon from "@material-ui/icons/Dashboard";
import AssignmentIcon from "@material-ui/icons/Assignment";
import Newsfeed from "views/Newsfeed/Newsfeed";
import { groupsAtom } from "state/auth";
import { dashboardNavAtom } from "state/dashboard";
import { useRecoilValue, useRecoilState } from "recoil";
import { useEffect } from "react";
import Loading from "components/Loading/Loading";

function useRoutes() {
  return (
    <React.Suspense fallback={<Loading color="blue" />}>
      <UseRoutesComp {...props} />
    </React.Suspense>
  )
}

function UseRoutesComp() {
  const userGroups = useRecoilValue(groupsAtom);
  const [routes, setRoutes] = useRecoilState(dashboardNavAtom);

  const hasAccess = (groupName, groupRole) => {
    if (!userGroups) return false;

    if (
      userGroups.find(
        (item) =>
          item.name === groupName &&
          item.role.toLowerCase() === groupRole.toLowerCase()
      )
    ) {
      return true;
    }

    return false;
  };

  const hasAdminAccess = () => {
    if (!userGroups) return false;

    if (userGroups.find((item) => item.name === "admin")) {
      return true;
    }

    return false;
  };

  const getAdminGroups = () => {
    if (!userGroups) return [];

    let newGroups = userGroups.filter((item) => item.adminGroup !== undefined);

    let returnGroups = [];

    newGroups.map((item) => {
      let returnObj = {};

      switch (item.adminGroup) {
        case "internal-admin-nf@sagebrush.church":
          returnObj = {
            path: "/newsfeed",
            name: "Newsfeed",
            icon: AssignmentIcon,
            component: Newsfeed,
            layout: "/portal",
            topLevel: true,
          };
          break;
        case "internal-admin-portal@sagebrush.church":
          /* returnObj = {
            path: "/portal",
            name: "Portal",
            icon: DashboardIcon,
            component: Portal,
            layout: "/portal",
            topLevel: true,
          }; */

          break;
      }

      returnGroups.push(returnObj);
    });

    return returnGroups;
  };

  useEffect(() => {
    var dashRoutes = [
      {
        path: "/dashboard",
        name: "Dashboard",
        icon: DashboardIcon,
        component: Dashboard,
        layout: "/portal",
        topLevel: true,
      },
      {
        path: "https://fileserver.sagebrush.work/",
        name: "File Server",
        icon: AssignmentIcon,
        type: "external",
        topLevel: true,
      },
      {
        path: "/onlineforms",
        name: "Online Forms",
        icon: AssignmentIcon,
        component: OnlineForms,
        layout: "/portal",
        topLevel: true,
      },
      {
        path: "/links",
        name: "Links",
        icon: AssignmentIcon,
        component: Links,
        layout: "/portal",
        topLevel: true,
      },
      {
        path: "/directory",
        name: "Staff Directory",
        icon: AssignmentIcon,
        component: StaffDirectory,
        layout: "/portal",
        topLevel: true,
      },
    ];
    if (hasAccess("checkin", "owner")) {
      dashRoutes.push({
        path: "/staffcheckin",
        name: "Checkin",
        icon: Alarm,
        layout: "/portal",
        component: StaffCheckinPage,
        topLevel: true,
      });
    }

    if (hasAdminAccess()) {
      let adminGroups = getAdminGroups();

      dashRoutes.push({
        path: "/administrator",
        name: "Admin",
        icon: Alarm,
        layout: "/portal",
        collapse: true,
        views: adminGroups,
        topLevel: true,
      });
    }

    setRoutes(dashRoutes);
  }, []);

  return routes;
}

export default useRoutes;
