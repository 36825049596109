import React, { useEffect, useState } from "react";
import CustomTabs from "components/CustomTabs/CustomTabs";
import { useRecoilValue, useRecoilState } from "recoil";
import Loading from "components/Loading/Loading";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { Autocomplete } from "@material-ui/lab";
import { TextField } from "@material-ui/core";
import ListTickets from "./ListTickets";
import {
  myDepartmentsAtom,
  departmentTicketSelectedDepartmentAtom,
  grabViewableDepartmentTickets,
  openViewableDepartmentTickets,
  closedViewableDepartmentTickets,
  closedDepartmentTicketNumDaysAtom,
  selectableDepartmentsAtom,
  departmentSelectableOptionsAtom,
  departmentTicketStatesAtom
} from "state/ticketSystem";

const DepartmentTicketsComp = (props) => {
  const tab = +(props.location.search?.split("=")[1] || 0);
  const redirect = `${props.location.pathname}${props.location.search}`
  const [departments, setDepartments] = useRecoilState(myDepartmentsAtom)
  const [selectedDepartmentID, setSelectedDepartmentID] = useRecoilState(departmentTicketSelectedDepartmentAtom)
  const foundDepartment = departments.find(i => i._id === selectedDepartmentID)
  const selectedDepartment = foundDepartment ? foundDepartment : departments[0]
  const selectableDepartments = useRecoilValue(selectableDepartmentsAtom(true))
  const departmentSelectableOptions = useRecoilValue(departmentSelectableOptionsAtom(selectedDepartmentID))
  const departmentTicketStates = useRecoilValue(departmentTicketStatesAtom(selectedDepartmentID))

  const updateSelectedDepartmentID = (id) => {
    setSelectedDepartmentID(id)
    sessionStorage.setItem('dtsdts', id)
  }
  useEffect(() => {
    if (selectedDepartmentID === null && departments.length !== 0) {
      updateSelectedDepartmentID(departments[0]._id)
    }
  }, []);

  const tabs = [
    {
      tabName: "Open",
      tabContent: (
        <React.Suspense fallback={<Loading color='blue' />}>
          <OpenTickets
            departmentID={selectedDepartmentID}
            departments={selectableDepartments.filter(i => i.id !== selectedDepartmentID)}
            deptOptions={departmentSelectableOptions}
            ticketStates={departmentTicketStates}
            redirect={redirect}
          />
        </React.Suspense>
      ),
    },
    {
      tabName: "Closed",
      tabContent: (
        <React.Suspense fallback={<Loading color='blue' />}>
          <ClosedTickets
            departmentID={selectedDepartmentID}
            redirect={redirect}
          />
        </React.Suspense>
      )
    },
  ];

  return (
    <React.Fragment>
      <GridContainer>
        <GridItem xs={12}>
          <Autocomplete
            style={{ maxWidth: '300px' }}
            disablePortal
            options={departments}
            getOptionLabel={(option) =>
              `${option.name}` || ""
            }
            sx={{ width: 300 }}
            renderInput={(params) => (
              <TextField {...params} label="Department" />
            )}
            onChange={(e, v) => {
              updateSelectedDepartmentID(v._id)
            }}
            autoSelect={true}
            autoComplete={true}
            autoHighlight={true}
            disableClearable={true}
            value={selectedDepartment}
          />
        </GridItem>
      </GridContainer>
      <div style={{ maxWidth: '1200px' }}>
        <CustomTabs headerColor="info" tabs={tabs} value={tab} />
      </div>
    </React.Fragment>
  )
}

const DepartmentTicketsLoading = (props) => {
  const tabs = [
    {
      tabName: "",
      tabContent: <Loading color="blue" />,
    },
  ];
  return (
    <React.Fragment>
      <GridContainer>
        <GridItem xs={12}>
          <Autocomplete
            style={{ maxWidth: '300px' }}
            disablePortal
            options={[]}
            getOptionLabel={(option) =>
              `${option.name}` || ""
            }
            sx={{ width: 300 }}
            renderInput={(params) => (
              <TextField {...params} label="Department" />
            )}
            autoSelect={true}
            autoComplete={true}
            autoHighlight={true}
            disableClearable={true}
            value={null}
          />
        </GridItem>
      </GridContainer>
      <div style={{ maxWidth: '1200px' }}>
        <CustomTabs headerColor="info" tabs={tabs} />
      </div>
    </React.Fragment>
  );
}

const OpenTickets = ({ departmentID, departments, deptOptions, redirect, ticketStates }) => {
  const [tickets, setTickets] = useRecoilState(openViewableDepartmentTickets(departmentID))

  const searchOpenTicket = (tickets, search) => {
    return tickets.filter(ticket => {
      return (ticket.subject.toLowerCase().includes(search.toLowerCase()) ||
        (ticket.number.toString()).includes(search) ||
        (ticket.assignedToNames.map(i => i.name).join(' ')).toLowerCase().includes(search.toLowerCase()) ||
        ticket.userName.toLowerCase().includes(search.toLowerCase()) ||
        ticket.tags.join(' ').includes(search.toLowerCase())
      )
    })
  }

  const updateTickets = async () => {
    let t = await grabViewableDepartmentTickets(departmentID, 'Open')
    setTickets(t)
    return
  }

  const getCols = () => {
    const cols = [
      {
        Header: "",
        accessor: "checkBox",
        disableFilters: true,
        disableSortBy: true,
        style: { maxWidth: '34px', padding: '0' },
        headerStyle: { maxWidth: '34px', padding: '0', margin: '0px', marginTop: '2px' },
        checkBox: true
      },
      {
        Header: "#",
        accessor: "number",
        disableFilters: true,
        disableSortBy: true,
        style: { maxWidth: '50px', fontSize: '11px' },
        headerStyle: { maxWidth: '50px', margin: '0px' }
      },
      {
        Header: "Updated",
        accessor: "lastMessage",
        disableFilters: true,
        disableSortBy: true,
        style: { fontSize: '11px', maxWidth: '74px' },
        headerStyle: { margin: '0px', maxWidth: '74px' }
      },
      {
        Header: "Subject",
        accessor: "subjectLink",
        disableFilters: true,
        disableSortBy: true,
        style: { minWidth: '200px', fontSize: '12px' },
        headerStyle: { minWidth: '200px', margin: '0px' }
      },
      {
        Header: "",
        accessor: "notifications",
        disableFilters: true,
        disableSortBy: true,
        style: { maxWidth: '70px', padding: '0', fontSize: '12px' },
        headerStyle: { maxWidth: '70px', padding: '0', margin: '0px' }
      },
      {
        Header: "From",
        accessor: "userName",
        disableFilters: true,
        disableSortBy: true,
        style: { maxWidth: '125px', fontSize: '12px' },
        headerStyle: { maxWidth: '125px', margin: '0px' }
      },
      {
        Header: "",
        accessor: "priorityIcon",
        disableFilters: true,
        disableSortBy: true,
        style: { maxWidth: '8px', padding: '0', fontSize: '12px', margin: '0px' },
        headerStyle: { maxWidth: '8px', padding: '0', margin: '0px' }
      },
      {
        Header: "Assigned To",
        accessor: "assignedToNamesComp",
        disableFilters: true,
        disableSortBy: true,
        style: { maxWidth: '125px', fontSize: '12px' },
        headerStyle: { maxWidth: '125px', margin: '0px' }
      },
    ]

    if (ticketStates.length > 0) {
      cols.splice(5, 0, {
        Header: "State",
        accessor: "state",
        disableFilters: true,
        disableSortBy: true,
        style: { maxWidth: '100px', fontSize: '12px' },
        headerStyle: { maxWidth: '100px', margin: '0px' }
      })
    }
    return cols
  }

  return (
    <ListTickets
      list={tickets}
      buttons={{
        checkBox: true,
        status: true,
        assign: true,
        merge: true,
        transfer: true,
        priority: true,
        tags: true,
        state: ticketStates.length > 0 ? true : false,
        refresh: true,
        assignTo: true
      }}
      viewType='agent'
      departments={departments}
      deptOptions={deptOptions}
      cols={getCols()}
      searchTicket={searchOpenTicket}
      updateList={updateTickets}
      redirect={redirect}
      ticketStates={ticketStates}
    />
  )
}

const ClosedTickets = ({ departmentID, redirect }) => {
  const [numDays, setNumDays] = useRecoilState(closedDepartmentTicketNumDaysAtom)
  const [tickets, setTickets] = useRecoilState(closedViewableDepartmentTickets({ deptID: departmentID, numDays: numDays }))

  const updateTickets = async (days) => {
    let t = await grabViewableDepartmentTickets(departmentID, 'Closed', days)
    setTickets(t)
    return
  }
  const updateNumDays = (days) => {
    sessionStorage.setItem('cdtndts', days)
    setNumDays(days)
  }

  const searchClosedTicket = (tickets, search) => {
    return tickets.filter(ticket => {
      return (ticket.subject.toLowerCase().includes(search.toLowerCase()) ||
        (ticket.number.toString()).includes(search) ||
        (ticket.assignedToNames.map(i => i.name).join(' ')).toLowerCase().includes(search.toLowerCase()) ||
        ticket.userName.toLowerCase().includes(search.toLowerCase()) ||
        ticket.tags.join(' ').includes(search.toLowerCase())
      )
    })
  }

  const cols = [
    {
      Header: '',
      accessor: "checkBox",
      disableFilters: true,
      disableSortBy: true,
      style: { maxWidth: '34px', padding: '0' },
      headerStyle: { maxWidth: '34px', padding: '0', margin: '0px', marginTop: '2px' },
      checkBox: true
    },
    {
      Header: "#",
      accessor: "number",
      disableFilters: true,
      disableSortBy: true,
      style: { maxWidth: '50px', fontSize: '11px' },
      headerStyle: { maxWidth: '50px', margin: '0px' }
    },
    {
      Header: "Closed",
      accessor: "lastMessage",
      disableFilters: true,
      disableSortBy: true,
      style: { fontSize: '11px', maxWidth: '74px' },
      headerStyle: { margin: '0px', maxWidth: '74px' }
    },
    {
      Header: "Subject",
      accessor: "subjectLink",
      disableFilters: true,
      disableSortBy: true,
      style: { minWidth: '200px', fontSize: '12px' },
      headerStyle: { minWidth: '200px', margin: '0px' }
    },
    {
      Header: "",
      accessor: "notifications",
      disableFilters: true,
      disableSortBy: true,
      style: { maxWidth: '70px', padding: '0', fontSize: '12px' },
      headerStyle: { maxWidth: '70px', padding: '0', margin: '0px' }
    },
    {
      Header: "From",
      accessor: "userName",
      disableFilters: true,
      disableSortBy: true,
      style: { maxWidth: '150px', fontSize: '12px' },
      headerStyle: { maxWidth: '150px', margin: '0px' }
    },
    {
      Header: "Assigned To",
      accessor: "assignedToNamesComp",
      disableFilters: true,
      disableSortBy: true,
      style: { maxWidth: '150px', fontSize: '12px' },
      headerStyle: { maxWidth: '150px', margin: '0px' }
    },
  ]

  return (
    <ListTickets
      list={tickets}
      buttons={{
        checkBox: true,
        numDays: true,
        status: true,
        tags: true,
        refresh: true,
        assignTo: true
      }}
      viewType='agent'
      cols={cols}
      searchTicket={searchClosedTicket}
      updateList={updateTickets}
      redirect={redirect}
      updateNumDays={updateNumDays}
      numDays={numDays}
    />
  )
}

const DepartmentTickets = (props) => {
  return (
    <React.Suspense fallback={<DepartmentTicketsLoading {...props} />}>
      <DepartmentTicketsComp {...props} />
    </React.Suspense>
  )
}

export default DepartmentTickets