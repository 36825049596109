import React, { useState } from "react";
import Loading from "components/Loading/Loading";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import CustomTabs from "components/CustomTabs/CustomTabs";
import ReactTable from "components/ReactTable/ReactTable.js";
import Button from "components/CustomButtons/Button.js";
import ReactTooltip from "react-tooltip";
import axios from "axios";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";

import api from '../../../api.config'

import LoadingBars from "components/Loading/LoadingBars";

import Close from "@material-ui/icons/Close";
import GetAppIcon from '@material-ui/icons/GetApp';

import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import dashboardStyles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";

import { useRecoilState } from "recoil";
import {
  poFundCodesAtom,
  poGlCodesAtom,
  poDeptCodesAtom,
  poBudgetsAtom
} from "state/purchaseOrders";
import { TextField } from "@material-ui/core";

import update from "immutability-helper";
import { Autocomplete } from "@material-ui/lab";

const useStyles = makeStyles(() => ({
  ...styles,
  ...dashboardStyles,
  formSection: {
    marginBottom: "2rem",
  },
}));

const POBudgetsAndCodesComponent = (props) => {
  const tab = +(props.location.search?.split("=")[1] || 0);

  const [fundCodes, setFundCodes] = useRecoilState(poFundCodesAtom);
  const [glCodes, setGlCodes] = useRecoilState(poGlCodesAtom);
  const [deptCodes, setdeptCodes] = useRecoilState(poDeptCodesAtom);

  const [budgets, setBudgets] = useRecoilState(poBudgetsAtom);

  const addBudget = async (budget) => {
    await axios.post(`${api.path}/digitalpo_api/v1/createPOBudget`, budget)
    location.reload()
  }

  const deleteBudget = async (budget) => {
    if (confirm(`Are you sure you want to delete the Budget for: ${budget.code}?`)) {
      await axios.post(`${api.path}/digitalpo_api/v1/deletePOBudget`, { id: budget.id })
    }
    deleteCodeUpdater(budget.id, setBudgets)
  }

  const deleteCodeUpdater = (id, set) => {
    set(prevState => {
      let index = prevState.findIndex(item => item.id === id)
      return update(prevState, { $splice: [[index, 1]] })
    })
  }

  const deleteCode = async (code, codeType) => {
    if (confirm(`Are you sure you want to delete ${codeType} code: ${code.code}?\nThis will also clear any associated budgets.`)) {
      await axios.post(`${api.path}/digitalpo_api/v1/deleteCode`, { id: code.id, type: codeType })

      switch (codeType) {
        case 'fund':
          console.log('fund')
          deleteCodeUpdater(code.id, setFundCodes)
          break
        case 'gl':
          deleteCodeUpdater(code.id, setGlCodes)
          break
        case 'dept':
          deleteCodeUpdater(code.id, setdeptCodes)
          break
      }
    }
  }

  const addCode = async (code) => {
    await axios.post(`${api.path}/digitalpo_api/v1/addCode`, code).then((res) => {
      location.reload()
    })
  }

  const tabs = [
    // {
    //   tabName: "Budgets",
    //   tabContent: <POBudgetManager
    //     fundCodes={fundCodes}
    //     glCodes={glCodes}
    //     deptCodes={deptCodes}
    //     budgets={budgets}
    //     addBudget={addBudget}
    //     deleteBudget={deleteBudget}
    //   />,
    // },
    {
      tabName: "Fund",
      tabContent: <POCodeManager
        codes={fundCodes}
        codeType='fund'
        addCode={addCode}
        deleteCode={deleteCode}
      />,
    },
    {
      tabName: "Gl",
      tabContent: <POCodeManager
        codes={glCodes}
        codeType='gl'
        addCode={addCode}
        deleteCode={deleteCode}
      />,
    },
    {
      tabName: "Dept",
      tabContent: <POCodeManager
        codes={deptCodes}
        codeType='dept'
        addCode={addCode}
        deleteCode={deleteCode}
      />,
    },
  ];

  return (
    <React.Fragment>
      <GridContainer>
        <GridItem xs={12} md={8}>
          <CustomTabs headerColor="info" tabs={tabs} value={tab} />
        </GridItem>
      </GridContainer>
    </React.Fragment>
  );
}

const POBudgetsAndCodesLoading = () => {
  const tabs = [
    // {
    //   tabName: "Budgets",
    //   tabContent: <Loading color="blue" />,
    // },
    {
      tabName: "Fund",
      tabContent: <Loading color="blue" />,
    },
    {
      tabName: "Gl",
      tabContent: <Loading color="blue" />,
    },
    {
      tabName: "Dept",
      tabContent: <Loading color="blue" />,
    },
  ];

  return (
    <React.Fragment>
      <GridContainer>
        <GridItem xs={12} md={8}>
          <CustomTabs headerColor="info" tabs={tabs} />
        </GridItem>
      </GridContainer>
    </React.Fragment>
  );
};

const POBudgetsAndCodes = (props) => {
  return (
    <React.Suspense fallback={<POBudgetsAndCodesLoading />}>
      <POBudgetsAndCodesComponent {...props} />
    </React.Suspense>
  )
}

export default POBudgetsAndCodes

const POBudgetManager = ({ budgets, fundCodes, glCodes, deptCodes, deleteBudget, addBudget }) => {

  const emptyBudget = {
    fund: null,
    gl: null,
    dept: null,
    amount: '',
    discretionary: 'no',
  }

  const cols = [
    {
      Header: "Code",
      accessor: "code",
      disableFilters: false,
      disableSortBy: false,
    },
    {
      Header: "Budget",
      accessor: "budget",
      disableFilters: false,
      disableSortBy: false,
    },
    {
      Header: "Budget Type",
      accessor: "budgetType",
      disableFilters: false,
      disableSortBy: false,
    },
    {
      Header: "Actions",
      accessor: "actions",
      disableFilters: true,
      disableSortBy: true,
    },
  ]

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  const getSpedingHistory = async (budget) => {
    return await axios.post(`${api.path}/digitalpo_api/v2/getSpendingHistoryForBudget`,
      { codes: [budget] }).then((res) => {
        if (res.data.length > 0) {
          const csvString = [
            [
              'PO',
              'Code',
              'Status',
              'Approved Date',
              'Amount',
              'Description',
            ],
            ...res.data[0].map(item => [
              item.poID,
              item.code,
              item.status,
              item.approvedDate,
              item.amount,
              item.description.replaceAll(',', '').replaceAll('\n', ' ').replaceAll('\r', ' ')
            ])
          ]
            .map(e => e.join(","))
            .join("\r\n");

          const csvFile = new Blob([csvString], { type: 'text/csv' })
          // Creating an object for downloading url
          const url = window.URL.createObjectURL(csvFile)

          // Creating an anchor(a) tag of HTML
          const a = document.createElement('a')

          // Passing the blob downloading url
          a.setAttribute('href', url)

          // Setting the anchor tag attribute for downloading
          // and passing the download file name
          a.setAttribute('download', `${budget.fund}-${budget.gl}-${budget.dept}_Spending_History.csv`);

          // Performing a download with click
          a.click()
        } else {
          alert('No spending history was found for this budget.')
        }
      })
  }

  const budgetList = budgets.map((item, index) => {
    return {
      ...item,
      budget: formatter.format(parseFloat(item.amount)),
      budgetType: item.discretionary === 'yes' ? 'Discretionary' : item.discretionary === 'capitalized' ? 'Capitalized' : 'Non-Discretionary',
      actions: (
        <React.Fragment>
          <BudgetHistoryDownloadButton item={item} index={index} getSpedingHistory={getSpedingHistory} />
          <Button
            justIcon
            color="danger"
            title='Delete'
            // data-tip
            // data-for={`delete-item-${index}`}
            onClick={() => deleteBudget(item)}
          >
            <Close />
          </Button>
          {/* <ReactTooltip
            delayHide={50}
            delayShow={500}
            effect="solid"
            id={`delete-item-${index}`}
          >
            Delete
          </ReactTooltip> */}
        </React.Fragment>
      )
    }
  })

  const [newBudget, setNewBudget] = useState(emptyBudget)

  const classes = useStyles();

  const submitBudget = () => {
    var errors = []
    if (!newBudget.fund) {
      errors.push("Select a fund code")
    }

    if (!newBudget.gl) {
      errors.push("Select a gl code")
    }

    if (!newBudget.dept) {
      errors.push("Select a dept code")
    }

    if (!newBudget.amount) {
      errors.push("Enter a valid amount")
    }

    let code = `${newBudget.fund.code}-${newBudget.gl.code}-${newBudget.dept.code}`
    budgets.forEach(item => {
      if (item.code === code) {
        errors.push(`A budget already exists for: ${code}`)
      }
    })

    if (errors.length === 0) {
      let budget = {
        fund: newBudget.fund.code,
        gl: newBudget.gl.code,
        dept: newBudget.dept.code,
        amount: newBudget.amount,
        discretionary: newBudget.discretionary
      }
      addBudget(budget)
    } else {
      let msg = ''
      errors.forEach((item, index) => {
        if (index !== 0) {
          msg += '\n'
        }
        msg += item
      })
      alert(msg)
    }
  }

  const updateNewBudget = (value) => {
    setNewBudget(prevState => {
      return update(prevState, {
        $merge: value
      })
    })
  }
  return (
    <React.Fragment>
      <GridContainer>
        <GridItem xs={12}>
          <GridContainer style={{ marginTop: '15px' }}>
            <GridItem xs={8}>
              <Autocomplete
                disablePortal
                options={fundCodes}
                getOptionLabel={(option) =>
                  `${option.code} - ${option.title}` || ""
                }
                renderInput={(params) => (
                  <TextField {...params} label="Fund Code" />
                )}
                onChange={(e, v) => updateNewBudget({ fund: v })}
                autoSelect={true}
                autoComplete={true}
                autoHighlight={true}
                value={newBudget.fund}
                getOptionSelected={(selected) => newBudget.fund.id === selected.id}
              />

              <Autocomplete
                disablePortal
                options={glCodes}
                getOptionLabel={(option) =>
                  `${option.code} - ${option.title}` || ""
                }
                renderInput={(params) => (
                  <TextField {...params} label="GL Code" />
                )}
                onChange={(e, v) => updateNewBudget({ gl: v })}
                autoSelect={true}
                autoComplete={true}
                autoHighlight={true}
                value={newBudget.gl}
                getOptionSelected={(selected) => newBudget.gl.id === selected.id}
              />

              <Autocomplete
                disablePortal
                options={deptCodes}
                getOptionLabel={(option) =>
                  `${option.code} - ${option.title}` || ""
                }
                renderInput={(params) => (
                  <TextField {...params} label="Dept Code" />
                )}
                onChange={(e, v) => updateNewBudget({ dept: v })}
                autoSelect={true}
                autoComplete={true}
                autoHighlight={true}
                value={newBudget.dept}
                getOptionSelected={(selected) => newBudget.dept.id === selected.id}
              />
              <GridContainer>
                <GridItem xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                  <p
                    style={{
                      color: '#444444',
                      fontWeight: 'bold',
                      fontSize: '14px',
                      marginRight: '10px',
                      marginBottom: '0px'
                    }}>
                    Budget Type?
                  </p>

                  <FormControlLabel
                    control={
                      <Radio
                        checked={newBudget.discretionary === 'yes'}
                        name="discretionary"
                        value="yes"
                        onChange={(e) => updateNewBudget({ discretionary: e.target.value })}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot,
                        }}
                      />
                    }
                    classes={{
                      label: classes.label,
                      root: classes.labelRoot,
                    }}
                    label="Discretionary"
                  />

                  <FormControlLabel
                    control={
                      <Radio
                        checked={newBudget.discretionary === 'no'}
                        name="discretionary"
                        value="no"
                        onChange={(e) => updateNewBudget({ discretionary: e.target.value })}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot,
                        }}
                      />
                    }
                    classes={{
                      label: classes.label,
                      root: classes.labelRoot,
                    }}
                    label="Non-Discretionary"
                  />

                  <FormControlLabel
                    control={
                      <Radio
                        checked={newBudget.discretionary === 'capitalized'}
                        name="discretionary"
                        value="capitalized"
                        onChange={(e) => updateNewBudget({ discretionary: e.target.value })}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot,
                        }}
                      />
                    }
                    classes={{
                      label: classes.label,
                      root: classes.labelRoot,
                    }}
                    label="Capitalized Account"
                  />
                </GridItem>
              </GridContainer>
            </GridItem>
            <GridItem xs={4} style={{ display: 'flex', justifyContent: 'space-between' }}>
              <GridContainer>
                <GridItem xs={12} style={{ display: 'flex', justifyContent: 'right', marginTop: '25px' }}>
                  <TextField
                    placeholder={`$ Amount`}
                    variant="outlined"
                    onWheel={(e) => e.target.blur()}
                    type="number"
                    inputProps={{
                      step: 0.01,
                    }}
                    value={newBudget.amount}
                    onChange={(v) => {
                      let val = v.target.value
                      let decimalIndex = val.indexOf('.')
                      if (decimalIndex === -1 || decimalIndex + 3 >= val.length) {
                        updateNewBudget({ amount: val })
                      }
                    }}
                    style={{ backgroundColor: "#fff" }}
                  />
                </GridItem>
                <GridItem xs={12} style={{ display: 'flex', justifyContent: 'right', marginTop: '10px', alignItems: 'end' }}>
                  <Button
                    style={{ height: '45px' }}
                    color="primary"
                    onClick={submitBudget}
                  >
                    Create Budget
                  </Button>
                </GridItem>
              </GridContainer>
            </GridItem>

          </GridContainer>
        </GridItem>
        <GridItem xs={12} style={{ marginTop: '20px' }}>
          <ReactTable
            columns={cols}
            data={budgetList}
          />
        </GridItem>
      </GridContainer >

    </React.Fragment >
  )
}

const POCodeManager = ({ codes, codeType, deleteCode, addCode }) => {

  const cols = [
    {
      Header: "Code",
      accessor: "code",
      disableFilters: false,
      disableSortBy: false,
    },
    {
      Header: "Title",
      accessor: "title",
      disableFilters: false,
      disableSortBy: false,
    },
    {
      Header: "Actions",
      accessor: "actions",
      disableFilters: true,
      disableSortBy: true,
    },
  ]

  const codeList = codes.map((item, index) => {
    let newCode = {
      ...item,
      actions: (
        <React.Fragment>
          <Button
            justIcon
            color="danger"
            title='Delete'
            // data-tip
            // data-for={`delete-item-${index}`}
            onClick={() => deleteCode(item, codeType)}
          >
            <Close />
          </Button>
          {/* <ReactTooltip
            delayHide={50}
            delayShow={500}
            effect="solid"
            id={`delete-item-${index}`}
          >
            Delete
          </ReactTooltip> */}
        </React.Fragment>
      )
    }
    return newCode
  })

  const emptyCode = {
    type: codeType,
    code: '',
    title: ''
  }

  const [newCode, setNewCode] = useState(emptyCode)

  const updateNewCodeCode = (code) => {
    if ((/^\d+$/.test(code) || code === '') && code.length <= 8) {
      setNewCode(prevState => {
        return update(prevState, {
          code: { $set: code }
        })
      })
    }
  }

  const updateNewCodeTitle = (title) => {
    if (title.length <= 40) {
      setNewCode(prevState => {
        return update(prevState, {
          title: { $set: title }
        })
      })
    }
  }

  const [errors, setErrors] = useState({
    code: {
      hasError: false,
      errorMessage: ''
    },
    title: {
      hasError: false,
      errorMessage: ''
    }
  })

  const validateNewCode = () => {
    let newErrors = {
      code: {
        hasError: false,
        errorMessage: ''
      },
      title: {
        hasError: false,
        errorMessage: ''
      }
    }
    let hasError = false

    if (newCode.title.length === 0) {
      newErrors.title.hasError = true
      newErrors.title.errorMessage = '*Required'
      hasError = true
    }

    if (newCode.code.length === 0) {
      newErrors.code.hasError = true
      newErrors.code.errorMessage = '*Required'
      hasError = true
    }

    if (codeList.findIndex(item => item.code === newCode.code) !== -1) {
      newErrors.code.hasError = true
      newErrors.code.errorMessage = '*Already Exists'
      hasError = true
    }

    setErrors(newErrors)

    return hasError
  }

  const submit = async () => {
    if (!validateNewCode()) {
      await addCode(newCode)
    }
  }

  return (
    <GridContainer>
      <GridItem xs={12} style={{ display: 'flex', marginTop: '15px' }}>
        <TextField
          style={{ marginRight: '15px', width: '10rem' }}
          label="Code"
          variant="outlined"
          type="text"
          value={newCode.code}
          error={errors.code.hasError}
          helperText={errors.code.errorMessage}
          onChange={(e) => updateNewCodeCode(e.target.value)}
          required
        />
        <TextField
          style={{ marginRight: '15px' }}
          label="Title"
          variant="outlined"
          type="text"
          value={newCode.title}
          error={errors.title.hasError}
          helperText={errors.title.hasError ? errors.title.errorMessage : `Remaining Characters: ${40 - newCode.title.length}`}
          onChange={(e) => updateNewCodeTitle(e.target.value)}
          fullWidth
          required
        />
        <Button
          color="primary"
          onClick={submit}
          style={{ height: '3rem' }}
        >
          Create Code
        </Button>
      </GridItem>
      <GridItem xs={12}>
        <ReactTable
          columns={cols}
          data={codeList}
        />

      </GridItem>
    </GridContainer>
  )
}

const BudgetHistoryDownloadButton = ({ item, getSpedingHistory, index }) => {
  const [downloading, setDownloading] = useState(false)
  return (
    <>
      <Button
        justIcon
        color="primary"
        title='Download Budget History'
        // data-tip
        // data-for={`download-item-${index}`}
        onClick={async () => {
          setDownloading(true)
          await getSpedingHistory(item)
          setDownloading(false)
        }}
      >
        {downloading ? <LoadingBars /> : <GetAppIcon />}
      </Button>
      {/* <ReactTooltip
        delayHide={50}
        delayShow={500}
        effect="solid"
        id={`download-item-${index}`}
      >
        Download Budget History
      </ReactTooltip> */}
    </>
  )
}