import { atom, selector, selectorFamily } from "recoil";
import { userAtom } from "./auth";
import axios from "axios";
import api from "../api.config";

export const allContractors = atom({
  key: 'allContractors',
  default: selector({
    key: 'allContractorsSelector',
    get: async () => {
      return await getAllContractors()
    }
  })
})

export const myProfile = atom({
  key: 'myProfile',
  default: selector({
    key: 'myProfileSelector',
    get: async () => {
      return await getMyProfile()
    }
  })
})

export const getAllContractors = async () => {
  return await axios
    .get(`${api.path}/digitalpo_api/v1/getAllContractors`)
    .then((res) => {
      return res.data;
    });
}

export const viewContractor = selectorFamily({
  id: "viewContractor",
  get: (request) => async () => {
    return await requestContractorByID(request.id, request.userType)
  },
});

export const viewDepartment = selectorFamily({
  id: 'viewDepartment',
  get: (request) => async () => {
    return await getDepartment(request.id, request.userType)
  }
})

export const viewerDepartmentsContractors = atom({
  key: 'viewerDepartmentsContractors',
  default: selector({
    key: 'viewerDepartmentsContractorsSelector',
    get: async () => {
      return await getViewableDepartmentsContractors('viewer')
    }
  })
})

export const reviewerDepartmentsContractors = atom({
  key: 'reviewerDepartmentsContractors',
  default: selector({
    key: 'reviewerDepartmentsContractorsSelector',
    get: async () => {
      return await getViewableDepartmentsContractors('reviewer')
    }
  })
})

export const adminDepartmentsContractors = atom({
  key: 'adminDepartmentsContractors',
  default: selector({
    key: 'adminDepartmentsContractorsSelector',
    get: async () => {
      return await getViewableDepartmentsContractors('admin')
    }
  })
})

export const getViewableDepartmentsContractors = async (userType) => {
  return await axios
    .post(`${api.path}/digitalpo_api/v1/getContractorsInViewableDepartments`, {
      userType: userType,
    })
    .then((res) => {
      return res.data;
    });
}

export const viewerDepartments = atom({
  key: 'viewerDepartments',
  default: selector({
    key: 'viewerDepartmentsSelector',
    get: async () => {
      return await getViewableDepartments('viewer')
    }
  })
})

export const reviewerDepartments = atom({
  key: 'reviewerDepartments',
  default: selector({
    key: 'reviewerDepartmentsSelector',
    get: async () => {
      return await getViewableDepartments('reviewer')
    }
  })
})

export const adminDepartments = atom({
  key: 'adminDepartments',
  default: selector({
    key: 'adminDepartmentsSelector',
    get: async () => {
      return await getViewableDepartments('admin')
    }
  })
})

export const getViewableDepartments = async (userType) => {
  return await axios
    .post(`${api.path}/digitalpo_api/v1/listViewableDepartments`,
      {
        userType: userType,
      })
    .then((res) => {
      return res.data;
    });
}

export const userInvoices = atom({
  key: 'userInvoices',
  default: selector({
    key: 'userInvoicesSelector',
    get: async () => {
      return await getInvoicesAs('user', get(invoiceUserStartYearAtom))
    }
  })
})

export const viewerInvoices = atom({
  key: 'viewerInvoices',
  default: selector({
    key: 'viewerInvoicesSelector',
    get: async () => {
      return await getInvoicesAs('viewer', get(invoiceViewerStartYearAtom))
    }
  })
})

export const reviewerInvoices = atom({
  key: 'reviewerInvoices',
  default: selector({
    key: 'reviewerInvoicesSelector',
    get: async () => {
      return await getInvoicesAs('reviewer', get(invoiceReviewerStartYearAtom))
    }
  })
})

export const adminInvoices = atom({
  key: 'adminInvoices',
  default: selector({
    key: 'adminInvoicesSelector',
    get: async ({ get }) => {
      return await getInvoicesAs('admin', get(invoiceAdminStartYearAtom))
    }
  })
})

export const contractorReviewers = atom({
  key: 'contractorReviewers',
  default: selector({
    key: 'contractorReviewersSelector',
    get: async () => {
      return await getContractorReviewers()
    }
  })
})

export const contractorViewers = atom({
  key: 'contractorViewers',
  default: selector({
    key: 'contractorViewersSelector',
    get: async () => {
      return await getContractorViewers()
    }
  })
})

export const getContractorReviewers = async () => {
  return await axios
    .get(`${api.path}/digitalpo_api/v1/getContractorReviewers`)
    .then((res) => {
      return res.data;
    });
}

export const getContractorViewers = async () => {
  return await axios
    .get(`${api.path}/digitalpo_api/v1/getContractorViewers`)
    .then((res) => {
      return res.data;
    });
}

export const getInvoicesAs = async (userType, year) => {
  return await axios
    .post(`${api.path}/digitalpo_api/v1/listInvoices`, {
      userType: userType,
      startYear: year,
    })
    .then((res) => {
      return res.data;
    });
}

export const invoiceAdminStartYearAtom = atom({
  key: "invoiceAdminStartYear",
  default: sessionStorage.getItem("iasy") || new Date().getFullYear(),
});

export const invoiceViewerStartYearAtom = atom({
  key: "invoiceViewerStartYear",
  default: sessionStorage.getItem("ivsy") || new Date().getFullYear(),
});

export const invoiceReviewerStartYearAtom = atom({
  key: "invoiceReviewerStartYear",
  default: sessionStorage.getItem("irsy") || new Date().getFullYear(),
});

export const invoiceUserStartYearAtom = atom({
  key: "invoiceUserStartYear",
  default: sessionStorage.getItem("iusy") || new Date().getFullYear(),
});

export const doesContractorExist = async (email) => {
  return await axios
    .post(`${api.path}/digitalpo_api/v1/doesContractorExist`, {
      email: email
    })
    .then((res) => {
      return res.data;
    });
}

export const requestContractorByID = async (id, userType) => {
  if (userType === 'user' && id === 'MyProfile') {
    return await axios
      .get(`${api.path}/digitalpo_api/v1/getMyProfile`)
      .then((res) => {
        return res.data
      })
  } else {
    return await axios
      .post(`${api.path}/digitalpo_api/v1/requestContractorByID`, {
        id: id,
        userType: userType
      })
      .then((res) => {
        return res.data;
      });
  }
}

export const createContractor = async (contractor) => {
  return await axios
    .post(`${api.path}/digitalpo_api/v1/createContractor`, contractor)
    .then((res) => {
      return res.data;
    });
}

export const updateContractorProfile = async (contractor) => {
  return await axios
    .post(`${api.path}/digitalpo_api/v1/updateContractor`, contractor)
    .then((res) => {
      return res.data;
    });
}

export const addContractorDepartmentDB = async (dept) => {
  return await axios
    .post(`${api.path}/digitalpo_api/v1/addContractorDepartment`, dept)
    .then((res) => {
      return res.data;
    });
}

export const editContractorDepartmentDB = async (dept) => {
  return await axios
    .post(`${api.path}/digitalpo_api/v1/editContractorDepartment`, dept)
    .then((res) => {
      return res.data;
    });
}

export const getDepartment = async (id, userType) => {
  return await axios
    .post(`${api.path}/digitalpo_api/v1/getDepartment`, {
      userType: userType,
      id: id
    })
    .then((res) => {
      return res.data;
    });
}

export const getMyProfile = async () => {
  return await axios
    .get(`${api.path}/digitalpo_api/v1/getMyProfile`)
    .then((res) => {
      return res.data;
    });
}

export const createDepartment = async (department) => {
  return await axios
    .post(`${api.path}/digitalpo_api/v1/createDepartment`, department)
    .then((res) => {
      return res.data;
    });
}

export const manageDepartment = async (department) => {
  return await axios
    .post(`${api.path}/digitalpo_api/v1/manageDepartment`, department)
    .then((res) => {
      return;
    });
}