import axios from "axios";
import { atom, selector } from "recoil";
import api from "../api.config";

export const formatPhoneNumber = (str) => {
  //Filter only numbers from the input
  let cleaned = ("" + str).replace(/\D/g, "");

  //Check if the input is of correct
  let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    //Remove the matched extension code
    //Change this to format for any country code.

    var intlCode = match[1] ? "+1 " : false;

    if (intlCode) {
      return ["(", match[2], ") ", match[3], "-", match[4]].join("");
    } else {
      return ["+501 ", match[3], "-", match[4]].join("");
    }
  }

  return null;
};

export const staffDirectoryAtom = atom({
  key: "staffDirectoryData",
  default: selector({
    key: "staffDirectoryDataSelector",
    get: async () => {
      return await axios
        .get(`${api.path}/staff_portal_api/v1/staffdirectory`)
        .then((res) => {
          return res.data.map((item) => {
            return { ...item, phone: formatPhoneNumber(item.phone) };
            //return { ...item, cellphone: item.cellphone };
          });
        });
    },
  }),
});
