import React, { useState } from "react";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import axios from "axios";
import api from "../../api.config";
import Loading from "components/Loading/Loading";

import { userAtom } from "state/auth";

import { useRecoilValue } from "recoil";

import update from 'immutability-helper';
import { TextField } from "@material-ui/core";
import { getWhoCompletedHBABForms } from "state/HBABFormRequest";
import ViewHBABForms from "./ViewHBABForms";


const HitByABusAdminComponent = (props) => {
  const allHBAB = useRecoilValue(getWhoCompletedHBABForms)
  return (
    <React.Fragment>
      <Card>
        <CardHeader color="primary" icon>
          <CardIcon color="primary">
            <h4 style={{ fontWeight: 'bold' }}>HBAB Forms</h4>
          </CardIcon>
        </CardHeader>
        <CardBody>
          <ViewHBABForms HBABRoles={allHBAB} viewType={'Admin'} />
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

const HitByABusAdmin = (props) => {
  return (
    <React.Suspense fallback={<Loading color="blue" />}>
      <GridContainer>
        <GridItem xs={12} lg={8}>
          <HitByABusAdminComponent {...props} />
        </GridItem>
      </GridContainer>
    </React.Suspense>
  )
}

export default HitByABusAdmin