import React from "react";
import CustomTabs from "components/CustomTabs/CustomTabs";
import { useRecoilValue, useRecoilState } from "recoil";
import {
  poListExpenseReportAtom,
  poListExpenseReportStartDateMonthAtom,
  poListExpenseReportStartDateYearAtom,
  getExpenseReportPOs
} from "state/purchaseOrders";
import Loading from "components/Loading/Loading";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";

import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import ListPOs from "views/PurchaseOrders/ListPOs";

const useStyles = makeStyles(styles);

const ExpenseReportComp = (props) => {
  const tab = +(props.location.search?.split("=")[1] || 0);
  const redirect = props.location.pathname.split('/purchase-orders/')[1] + '_' + tab
  const [poList, setPoList] = useRecoilState(poListExpenseReportAtom)

  const [month, setMonth] = useRecoilState(poListExpenseReportStartDateMonthAtom);
  const [year, setYear] = useRecoilState(poListExpenseReportStartDateYearAtom);

  const classes = useStyles();

  const updatePOList = async () => {
    let newList = await getExpenseReportPOs(month, year)
    setPoList(newList)
  }

  const monthChangeHandler = async (e) => {
    setMonth(e.target.value);
    sessionStorage.setItem("plerstsdm", e.target.value);
    let newList = await getExpenseReportPOs(e.target.value, year)
    setPoList(newList)
  };

  const yearChangeHandler = async (e) => {
    setYear(e.target.value);
    sessionStorage.setItem("plerstsdy", e.target.value);
    let newList = await getExpenseReportPOs(month, e.target.value)
    setPoList(newList)
  };

  const cols = [
    {
      Header: "ID",
      accessor: "id",
      disableFilters: false,
      disableSortBy: true,
    },
    {
      Header: "Vendor",
      accessor: "vendor",
      disableFilters: false,
      disableSortBy: true,
    },
    {
      Header: "Created",
      accessor: "submittedDate",
      disableFilters: false,
      disableSortBy: true,
    },
    {
      Header: "Creator",
      accessor: "submittedByName",
      disableFilters: false,
      disableSortBy: true,
    },
    {
      Header: "Reviewer",
      accessor: "reviewerName",
      disableFilters: false,
      disableSortBy: true,
    },
    {
      Header: "Approver(s)",
      accessor: "submittedToName",
      disableFilters: false,
      disableSortBy: true,
    },
    {
      Header: "RFP",
      accessor: "purchaseReason",
      disableFilters: false,
      disableSortBy: true,
    },
    {
      Header: "Total",
      accessor: "total",
      disableFilters: false,
      disableSortBy: true,
    },
    {
      Header: "Actions",
      accessor: "actions",
      disableFilters: true,
      disableSortBy: true,
    },
  ]

  const tabs = [
    {
      tabName: "Under Review",
      tabContent: (
        <ListPOs
          cols={cols}
          redirect={redirect}
          buttons={{
            view: true,
            manageFiles: true,
          }}
          updatePOList={updatePOList}
          poList={poList.filter(
            (item) =>
              item.status === "review"
          )}
        />
      ),
    },
    {
      tabName: "Pending",
      tabContent: (
        <ListPOs
          cols={cols}
          redirect={redirect}
          buttons={{
            view: true,
            manageFiles: true,
          }}
          updatePOList={updatePOList}
          poList={poList.filter((item) => item.status === "pending" || item.status.indexOf("awaiting") !== -1)}
        />
      ),
    },
    {
      tabName: "Approved",
      tabContent: (
        <ListPOs
          cols={cols}
          redirect={redirect}
          buttons={{
            view: true,
            manageFiles: true,
          }}
          updatePOList={updatePOList}
          poList={poList.filter((item) => item.status === "approved" && (!item.attachedFiles)
          )}
        />
      ),
    },
    {
      tabName: "Verify Totals",
      tabContent: (
        <ListPOs
          cols={cols}
          redirect={redirect}
          buttons={{
            view: true,
            manageFiles: true,
            download: true
          }}
          updatePOList={updatePOList}
          poList={poList.filter((item) => {
            return (
              (item.status === "approved" && item.attachedFiles &&
                item.ReviewerVerifiedLineitems === 'no'
              )
            )
          })}
        />
      ),
    },
    {
      tabName: "Completed",
      tabContent: (
        <ListPOs
          cols={cols}
          redirect={redirect}
          buttons={{
            view: true,
            manageFiles: true,
            archive: true,
            download: true
          }}
          updatePOList={updatePOList}
          poList={poList.filter((item) => {
            return (
              item.status === "approved" && item.attachedFiles &&
              item.ReviewerVerifiedLineitems !== 'no'
            );
          })}
        />
      ),
    }
  ]

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const years = ["2021", "2022", "2023", "2024"];

  return (
    <React.Fragment>
      <GridContainer>
        <GridItem xs={6} lg={2}>
          <FormControl fullWidth className={classes.selectFormControl}>
            <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
              Select Month
            </InputLabel>
            <Select
              MenuProps={{
                className: classes.selectMenu,
              }}
              classes={{
                select: classes.select,
              }}
              value={month}
              onChange={monthChangeHandler}
              inputProps={{
                name: "monthSelect",
                id: "month-select",
              }}
            >
              {months.map((item, index) => {
                return (
                  <MenuItem
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelected,
                    }}
                    value={index}
                    key={item}
                  >
                    {item}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </GridItem>
        <GridItem xs={6} lg={2}>
          <FormControl fullWidth className={classes.selectFormControl}>
            <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
              Select Year
            </InputLabel>
            <Select
              MenuProps={{
                className: classes.selectMenu,
              }}
              classes={{
                select: classes.select,
              }}
              value={year}
              onChange={yearChangeHandler}
              inputProps={{
                name: "yearSelect",
                id: "year-select",
              }}
            >
              {years.map((item, index) => {
                return (
                  <MenuItem
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelected,
                    }}
                    value={item}
                    key={item}
                  >
                    {item}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </GridItem>
      </GridContainer>
      <CustomTabs headerColor="info" tabs={tabs} value={tab} />
    </React.Fragment>
  )
}

const ExpenseReportLoading = () => {
  const tabs = [
    {
      tabName: "Under Review",
      tabContent: <Loading color="blue" />,
    },
    {
      tabName: "Pending",
      tabContent: <Loading color="blue" />,
    },
    {
      tabName: "Approved",
      tabContent: <Loading color="blue" />,
    },
    {
      tabName: "Verified Totals",
      tabContent: <Loading color="blue" />,
    },
    {
      tabName: "Completed",
      tabContent: <Loading color="blue" />,
    },
  ];

  return (
    <React.Fragment>
      <GridContainer>
        <GridItem xs={3}>
          <p>Select Month</p>
          <p>
            <select>
              <option>Select Month</option>
            </select>
          </p>
        </GridItem>
        <GridItem xs={3}>
          <p>Select Year</p>
          <p>
            <select>
              <option>Select Year</option>
            </select>
          </p>
        </GridItem>
      </GridContainer>
      <CustomTabs headerColor="info" tabs={tabs} />
    </React.Fragment>
  );
}


const ExpenseReport = (props) => {
  return (
    <React.Suspense fallback={<ExpenseReportLoading />}>
      <ExpenseReportComp {...props} />
    </React.Suspense>
  )
}

export default ExpenseReport