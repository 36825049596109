import React from "react";
import axios from "axios";
import {
  groupsAtom,
  loggedInAtom,
  idTokenAtom,
  userAtom,
  jwtAtom,
} from "../state/auth";
import { publishedPagesAtom, sortPages } from "../state/trainingModule";
import { useRecoilState } from "recoil";
import { unstable_createMuiStrictModeTheme } from "@material-ui/core";
import api from "../api.config";
import { encryptData } from "assets/jss/EncriptDecrypt/EncryptDecrypt";

import Cookies from "js-cookie";

export default function useReauth() {
  const [isLoggedIn, setIsLoggedIn] = useRecoilState(loggedInAtom);
  const [idToken, setIdToken] = useRecoilState(idTokenAtom);
  const [user, setUser] = useRecoilState(userAtom);
  const [groups, setGroups] = useRecoilState(groupsAtom);
  const [jwt, setJwt] = useRecoilState(jwtAtom);
  const [publishedPages, setPublishedPages] = useRecoilState(publishedPagesAtom)

  async function refreshToken(obj) {
    const newAuthRes = await obj.reloadAuthResponse();

    let refreshTiming = (3600 - 5 * 60) * 1000;
    // Save new Token --> newAuthRes.access_token

    /* (async () => { */
    await axios
      .post(`${api.path}/staff_portal_api/v1/gauth`, {
        idtoken: newAuthRes.id_token,
      })
      .then(async (res) => {
        setUser(res.data.profile);
        setIsLoggedIn(true);
        setJwt(res.data.jwt);
        setGroups(res.data.groups);

        Cookies.set("StaffPortalJWT", res.data.jwt);
        sessionStorage.setItem("StaffPortalJWT", res.data.jwt)

        // Set session storage
        sessionStorage.setItem("userData", encryptData(res.data.profile));
        sessionStorage.setItem("loggedIn", true);
        sessionStorage.setItem("groups", encryptData(res.data.groups));
        let sorted = await res.data.publishedPages.sort(sortPages)
        setPublishedPages(sorted)
        sessionStorage.setItem('publishedPagesDB', encryptData(sorted))
      });

    // await axios
    //   .get(`${api.path}/staff_portal_api/v1/getPublishedPages`)
    //   .then(async (res) => {
    //     let sorted = await res.data.sort(sortPages)
    //     setPublishedPages(sorted)
    //     sessionStorage.setItem('publishedPagesDB', encryptData(sorted))
    //   });
    /* })(); */

    setTimeout(() => {
      refreshToken(obj);
    }, refreshTiming);
  }

  return refreshToken;
}
