import axios from "axios";
import React, { useEffect, useState } from "react";
import Table from "components/Table/Table";
import { useLocation } from "react-router-dom";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Button from "components/CustomButtons/Button";
import Icon from "@material-ui/core/Icon";

import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";

import GoBack from "components/GoBack/GoBack";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";

import { DateRangePicker } from "react-date-range";

import {
  checkinSingleAtom,
  checkinSingleStartDateAtom,
  checkinSingleEndDateAtom,
  checkinSingleOverviewAtom,
} from "state/checkin";
import { useRecoilValue, useRecoilState } from "recoil";
import Loading from "components/Loading/Loading";

const useStyles = makeStyles(styles);

const Fallback = () => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Card>
        <CardHeader color="info" text>
          <CardText color="info">
            <h4 className={classes.cardTitleWhite}>Loading Data</h4>
          </CardText>
        </CardHeader>
        <CardBody>
          <Loading color="blue" />
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

const SingleUserTableComponent = (props) => {
  const classes = useStyles();

  const [startDate, setStartDate] = useRecoilState(checkinSingleStartDateAtom);
  const [endDate, setEndDate] = useRecoilState(checkinSingleEndDateAtom);

  const data = useRecoilValue(checkinSingleAtom(props.email));

  return (
    <React.Fragment>
      <Card>
        <CardHeader color="info" text>
          <CardText color="info">
            {data.userData && (
              <h4
                className={classes.cardTitleWhite}
              >{`${data.userData.firstname} ${data.userData.lastname}`}</h4>
            )}
          </CardText>
        </CardHeader>
        <CardBody>
          <Table
            tableHead={["In", "Out", "Location", "Time Clocked"]}
            tableData={data.tableData}
          />
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

const SingleUserTable = (props) => {
  return (
    <React.Suspense fallback={<Fallback />}>
      <SingleUserTableComponent {...props} />
    </React.Suspense>
  );
};

export default SingleUserTable;
