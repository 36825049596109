import { atom, selector } from "recoil";
import axios from "axios";
import api from "../api.config";

export const monthlyVerseAtom = atom({
  key: "monthlyVerse",
  default: selector({
    key: "montlyVerseSelector",
    get: async () => {
      return axios
        .get(`${api.path}/staff_portal_api/v1/listmonthlyverse`)
        .then((res) => {
          return res.data;
        });
    },
  }),
});
// remove me
