import React, { useEffect, useState } from "react";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Loading from "components/Loading/Loading";
import { useRecoilValue } from "recoil";
import CustomTabs from "components/CustomTabs/CustomTabs";
import {
  PagesAtom,
  TabsAtom,
  SelectedPageID,
  SelectedPageOption,
  pageTabs
} from "state/trainingModule";
const TrainingCreationTab = (props) => {

  const [videoHeight, setVideoHeight] = useState('200px')

  const renderBodyItem = (item) => {
    if (item.inputType === 'Text') {
      return (<div dangerouslySetInnerHTML={{ __html: item.value.html }} />)
    } else if (item.inputType === 'Youtube') {
      return createYoutubeEmbed(item.value)
    } else if (item.inputType === 'Links') {
      return (
        <GridContainer>
          <GridItem xs={12}>
            <h4><strong>{item.value.title}</strong></h4>
          </GridItem>
          <GridItem xs={12}>
            <ul>
              {item.value.links.map((link, index) => {
                return (
                  <li key={index}>
                    <a href={link.link} target="_blank">
                      {link.name}
                    </a>
                  </li>
                )
              })}
            </ul>
          </GridItem>
        </GridContainer>
      )
    } else if (item.inputType === 'Scribe') {
      return createScribeEmbed(item.value)
    }
    return <></>
  }

  const getVideoHeight = ({ target: vid }) => {
    const { offsetWidth } = vid

    let height = (offsetWidth * 9) / 16
    height = Math.round(height)


    setVideoHeight(`${height}px`)
  }

  const createYoutubeEmbed = (link) => {
    let embedID = null
    if (link.includes('www.youtube.com/watch?v=')) {
      embedID = link.split('=')[1]
    } else if (link.includes('https://youtu.be/') || link.includes('https://www.youtube.com/embed/')) {
      embedID = link.split('/')
      embedID = embedID[embedID.length - 1]
      embedID = embedID.replace('?t=', '?start=')
    }
    if (embedID) {
      return (
        <div style={{ display: 'flex', justifyContent: 'center', padding: '10px' }}>
          <iframe
            style={{ width: '85%', height: videoHeight }}
            onLoad={getVideoHeight}
            src={`https://www.youtube.com/embed/${embedID}`}
            frameBorder="0"
            allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Embedded youtube"
          />
        </div>
      )
    }
    return (<p>Invalid Youtube Link</p>)
  }

  const createScribeEmbed = (link) => {
    if (link.includes('scribehow.com')) {
      if (link.includes('scribehow.com/shared/')) {
        link = link.replace('shared', 'embed')
      }
      if (!link.includes('?')) {
        link += '?removeLogo=true'
      }
      return (
        <div style={{ display: 'flex', justifyContent: 'center', padding: '10px' }}>
          <iframe
            style={{ width: '85%', height: '640px' }}
            src={link}
            allowFullScreen frameBorder="0"
            title="Embedded Scribe"
          />
        </div>
      )
    }
    return (<p>Invalid Scribe Link</p>)
  }

  return (
    <React.Fragment>
      <GridContainer>
        <GridItem xs={12}>
          <h2><strong>{props.tab.title}</strong></h2>
        </GridItem>
        {props.tab.body.map((item, index) => {
          return (
            <GridItem
              xs={12}
              key={index}

            >
              {renderBodyItem(item)}
            </GridItem>
          )
        })}
      </GridContainer>
    </React.Fragment>
  )
}


const TrainingCreationDisplayComp = (props) => {


  const tab = +(props.location.search?.split("=")[1] || 0);
  var pageID = props.location.pathname.split('/')
  pageID = pageID[pageID.length - 1].split('-')
  pageID = pageID[pageID.length - 1]
  const tabs = useRecoilValue(pageTabs(pageID))

  const getCreatedTabs = () => {
    let newTabs = []
    tabs.forEach(tab => {
      newTabs.push({
        tabName: tab.title,
        tabContent: (
          <TrainingCreationTab
            tab={tab}
          />
        )
      })
    })
    return newTabs
  }

  return (
    <React.Fragment>
      <GridContainer>
        <GridItem xs={12} lg={7}>
          {tabs.length === 0 ? <p>Empty Page.</p> :
            <CustomTabs headerColor="info" tabs={getCreatedTabs()} value={tab} />
          }
        </GridItem>

      </GridContainer>

    </React.Fragment>
  )
}


const TrainingCreationDisplay = (props) => {
  return (
    <React.Suspense fallback={<Loading color="blue" />}>
      <TrainingCreationDisplayComp {...props} />
    </React.Suspense>
  )
}

export default TrainingCreationDisplay