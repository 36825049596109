import React, { useState, useEffect } from "react";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Button from "components/CustomButtons/Button";
import axios from "axios";
import update from "immutability-helper";
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';

import Datetime from "react-datetime";

import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import GoBack from "components/GoBack/GoBack";

// Card
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import CardText from "components/Card/CardText";

// Atom stuff
import { useRecoilValue } from "recoil";

import dashboardStyles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import Loading from "components/Loading/Loading";
import LoadingBars from "components/Loading/LoadingBars";

import { decryptData, encryptData } from "assets/jss/EncriptDecrypt/EncryptDecrypt";
import {
  adminDepartments,
  reviewerDepartments,
  doesContractorExist,
  createContractor,
  updateContractorProfile,
  viewContractor,
  addContractorDepartmentDB,
  editContractorDepartmentDB
} from "state/invoiceSystem";

const useStyles = makeStyles(() => ({
  ...styles,
  ...dashboardStyles,
  formSection: {
    marginBottom: "1rem",
  },
}));

const CreateEditContractorComponent = (props) => {
  const classes = useStyles();

  const userType = props.match.params.userType
  const id = props.match.params.id
  const search = props.location.search?.split("?")[1] || null

  const contractor = id !== 'Create' ? useRecoilValue(viewContractor({ id: id, userType: userType })) : null

  const searchData = search ? decryptData(search) : {}

  const editorsDepartments = useRecoilValue(userType === 'admin' ? adminDepartments : reviewerDepartments)

  const [profile, setProfile] = useState(contractor ? contractor : {
    name: '',
    email: searchData.email ? searchData.email : '',
    phone: '',
    mailingAddress: '',
    edited: false
  })

  const getContractorDepartmentList = () => {
    let list = []
    if (contractor) {
      list = contractor.departments.map(dept => {
        return {
          ...dept,
          department: editorsDepartments.find(item => item._id === dept.departmentID),
          edited: false
        }
      })
    }

    if (searchData.department) {
      if (!list.find(item => item.department._id === searchData.department)) {
        let newDepartment = editorsDepartments.find(item => item._id === searchData.department)
        if (newDepartment) {
          list.push({
            startDate: '',
            department: newDepartment,
            edited: true,
            status: 'Active',
            pay: {
              amount: 0,
              options: newDepartment.defaultPayOptions ? newDepartment.defaultPayOptions : [],
            }
          })
        }
      }
    }

    return list
  }

  const [contractorDepartments, setContractorDepartments] = useState(getContractorDepartmentList())

  const emptyError = {
    hasError: false,
    errorMessage: ''
  }

  const emptyDepartmentErrors = {
    department: emptyError,
    status: emptyError,
    startDate: emptyError,
    pay: {
      amount: emptyError,
      options: emptyError
    }
  }

  const [errors, setErrors] = useState({
    name: emptyError,
    phone: emptyError,
    mailingAddress: emptyError,
    departments: contractorDepartments.map(item => {
      return emptyDepartmentErrors
    })
  })

  const updateProfile = (value) => {
    setProfile((prevState) => {
      return update(prevState, {
        $merge: value
      })
    })
  }

  const updateDepartmentDept = (index, value) => {
    setContractorDepartments((prevState) => {
      return update(prevState, {
        [index]: {
          $merge: {
            department: value, edited: true, pay: {
              amount: '',
              options: value.defaultPayOptions ? value.defaultPayOptions : [],
            }
          }
        }
      })
    })
  }

  const updateDepartmentStatus = (index, value) => {
    setContractorDepartments((prevState) => {
      return update(prevState, {
        [index]: { $merge: { status: value, edited: true } }
      })
    })
  }

  const updateDepartmentStartDate = (index, value) => {
    setContractorDepartments((prevState) => {
      return update(prevState, {
        [index]: { $merge: { startDate: value, edited: true } }
      })
    })
  }

  const updateDepartmentPayOptionLabel = (deptIndex, optIndex, value) => {
    setContractorDepartments((prevState) => {
      return update(prevState, {
        [deptIndex]: { $merge: { edited: true } }
      })
    })
    setContractorDepartments((prevState) => {
      return update(prevState, {
        [deptIndex]: { pay: { options: { [optIndex]: { $merge: { label: value } } } } }
      })
    })
  }

  const updateDepartmentPayOptionPay = (deptIndex, optIndex, value) => {
    setContractorDepartments((prevState) => {
      return update(prevState, {
        [deptIndex]: { $merge: { edited: true } }
      })
    })
    setContractorDepartments((prevState) => {
      return update(prevState, {
        [deptIndex]: { pay: { options: { [optIndex]: { $merge: { pay: value } } } } }
      })
    })
  }

  const addDepartmentPayOption = (deptIndex) => {
    setContractorDepartments((prevState) => {
      return update(prevState, {
        [deptIndex]: { $merge: { edited: true } }
      })
    })
    setContractorDepartments((prevState) => {
      return update(prevState, {
        [deptIndex]: { pay: { options: { $push: [{ label: '', pay: '' }] } } }
      })
    })
  }

  const removeDepartmentPayOption = (deptIndex, optIndex) => {
    setContractorDepartments((prevState) => {
      return update(prevState, {
        [deptIndex]: { $merge: { edited: true } }
      })
    })
    setContractorDepartments((prevState) => {
      return update(prevState, {
        [deptIndex]: { pay: { options: { $splice: [[optIndex, 1]] } } }
      })
    })
  }

  const updateDepartmentPayAmount = (deptIndex, value) => {
    setContractorDepartments((prevState) => {
      return update(prevState, {
        [deptIndex]: { $merge: { edited: true } }
      })
    })
    value = value.replaceAll('-', '')
    if (value !== '') {
      value = parseInt(value * 100) / 100
    }
    setContractorDepartments((prevState) => {

      return update(prevState, {
        [deptIndex]: { pay: { amount: { $set: value } } }
      })
    })
  }

  const removeDepartment = (index) => {
    setContractorDepartments((prevState) => {
      return update(prevState, {
        [index]: { $merge: { edited: true } }
      })
    })
    setContractorDepartments((prevState) => {
      return update(prevState, { $splice: [[index, 1]] })
    })
    setErrors((prevState) => {
      return update(prevState, { departments: { $splice: [[index, 1]] } })
    })
  }

  const addContractorDepartment = () => {
    let unusedDepartments = filterOutUsedDepartments()
    if (unusedDepartments.length > 0) {
      let department = unusedDepartments[0]
      let entry = {
        startDate: '',
        department: department,
        edited: true,
        status: 'Active',
        pay: {
          amount: 0,
          options: department.defaultPayOptions ? department.defaultPayOptions : [],
        }
      }

      setContractorDepartments((prevState) => {
        return update(prevState, {
          $unshift: [entry]
        })
      })

      setErrors((prevState) => {
        return update(prevState, {
          departments: { $unshift: [emptyDepartmentErrors] }
        })
      })
    } else {
      alert('Contractor already in all of your departments.')
    }
  }

  useEffect(async () => {
    if (id === 'Create') {
      let user = await doesContractorExist(searchData.email)
      if (user.alreadyCreated) {
        let userSearch = encryptData({
          ...searchData,
          email: null
        })
        window.location.href = `/portal/contractors/addEdit/${userType}/${user.alreadyCreated}?${userSearch}`
      } else if (!user.validEmail) {
        searchData = {}
      }
    }
  }, []);

  const filterOutUsedDepartments = (id = null) => {
    let options = editorsDepartments
    let used = contractorDepartments.map(item => {
      return item.department
    })
    if (id) { used = used.filter(item => item._id !== id) }
    used.forEach(item => {
      options = options.filter(dept => dept._id !== item._id)
    })
    return options
  }

  const validateForm = () => {
    let validForm = true

    if (profile.name.trim() === '') {
      setErrors((prevState) => {
        return update(prevState, {
          name: {
            $merge: {
              hasError: true,
              errorMessage: "*This field cannot be left blank.",
            },
          },
        });
      })
      validForm = false
    } else if (profile.name.trim().split(' ').length < 2) {
      setErrors((prevState) => {
        return update(prevState, {
          name: {
            $merge: {
              hasError: true,
              errorMessage: "*Must provide first and last name.",
            },
          },
        });
      })
      validForm = false
    } else {
      setErrors((prevState) => {
        return update(prevState, {
          name: {
            $merge: {
              hasError: false,
              errorMessage: "",
            },
          },
        });
      })
    }

    if (profile.phone.trim() === '') {
      setErrors((prevState) => {
        return update(prevState, {
          phone: {
            $merge: {
              hasError: true,
              errorMessage: "*This field cannot be left blank.",
            },
          },
        });
      })
      validForm = false
    } else if (profile.phone.trim().length < 10) {
      setErrors((prevState) => {
        return update(prevState, {
          phone: {
            $merge: {
              hasError: true,
              errorMessage: "*Must be at least 10 numbers.",
            },
          },
        });
      })
      validForm = false
    } else {
      setErrors((prevState) => {
        return update(prevState, {
          phone: {
            $merge: {
              hasError: false,
              errorMessage: "",
            },
          },
        });
      })
    }

    if (profile.mailingAddress.trim() === '') {
      setErrors((prevState) => {
        return update(prevState, {
          mailingAddress: {
            $merge: {
              hasError: true,
              errorMessage: "*This field cannot be left blank.",
            },
          },
        });
      })
      validForm = false
    } else {
      setErrors((prevState) => {
        return update(prevState, {
          mailingAddress: {
            $merge: {
              hasError: false,
              errorMessage: "",
            },
          },
        });
      })
    }

    contractorDepartments.forEach((dept, deptIndex) => {

      if (!dept.department) {
        setErrors((prevState) => {
          return update(prevState, {
            departments: {
              [deptIndex]: {
                department: {
                  $merge: {
                    hasError: true,
                    errorMessage: "*This field cannot be left blank.",
                  }
                }
              }
            },
          });
        })
        validForm = false
      } else if (!editorsDepartments.find(item => item._id === dept.department._id)) {
        setErrors((prevState) => {
          return update(prevState, {
            departments: {
              [deptIndex]: {
                department: {
                  $merge: {
                    hasError: true,
                    errorMessage: "*Invalid Department.",
                  }
                }
              }
            },
          });
        })
        validForm = false
      } else {
        setErrors((prevState) => {
          return update(prevState, {
            departments: {
              [deptIndex]: {
                department: {
                  $merge: {
                    hasError: false,
                    errorMessage: "",
                  }
                }
              }
            },
          });
        })

        if (dept.department.payType === 'fixed') {
          if (!dept.pay.amount) {
            setErrors((prevState) => {
              return update(prevState, {
                departments: {
                  [deptIndex]: {
                    pay: {
                      amount: {
                        $merge: {
                          hasError: true,
                          errorMessage: "*This field cannot be left blank.",
                        }
                      }
                    }
                  }
                },
              });
            })
            validForm = false
          } else {
            setErrors((prevState) => {
              return update(prevState, {
                departments: {
                  [deptIndex]: {
                    pay: {
                      amount: {
                        $merge: {
                          hasError: false,
                          errorMessage: "",
                        }
                      }
                    }
                  }
                },
              });
            })
          }
        } else if (dept.department.payType === 'options') {

          if (dept.pay.options.length < 1) {
            setErrors((prevState) => {
              return update(prevState, {
                departments: {
                  [deptIndex]: {
                    pay: {
                      options: {
                        $merge: {
                          hasError: true,
                          errorMessage: "*This field cannot be left blank.",
                        }
                      }
                    }
                  }
                },
              });
            })
            validForm = false
          } else if (dept.pay.options.find(item => !item.label)) {
            setErrors((prevState) => {
              return update(prevState, {
                departments: {
                  [deptIndex]: {
                    pay: {
                      options: {
                        $merge: {
                          hasError: true,
                          errorMessage: "*All Options must have a label.",
                        }
                      }
                    }
                  }
                },
              });
            })
            validForm = false
          } else if (dept.pay.options.find(item => !item.pay)) {
            setErrors((prevState) => {
              return update(prevState, {
                departments: {
                  [deptIndex]: {
                    pay: {
                      options: {
                        $merge: {
                          hasError: true,
                          errorMessage: "*All Options must have an amount.",
                        }
                      }
                    }
                  }
                },
              });
            })
            validForm = false
          } else {
            setErrors((prevState) => {
              return update(prevState, {
                departments: {
                  [deptIndex]: {
                    pay: {
                      options: {
                        $merge: {
                          hasError: false,
                          errorMessage: "",
                        }
                      }
                    }
                  }
                },
              });
            })
          }
        }
      }

      if (!dept.status) {
        setErrors((prevState) => {
          return update(prevState, {
            departments: {
              [deptIndex]: {
                status: {
                  $merge: {
                    hasError: true,
                    errorMessage: "*This field cannot be left blank.",
                  }
                }
              }
            },
          });
        })
        validForm = false
      } else if (!(dept.status === 'Active' || dept.status === 'Inactive')) {
        setErrors((prevState) => {
          return update(prevState, {
            departments: {
              [deptIndex]: {
                status: {
                  $merge: {
                    hasError: true,
                    errorMessage: "*Invalid Status.",
                  }
                }
              }
            },
          });
        })
        validForm = false
      } else {
        setErrors((prevState) => {
          return update(prevState, {
            departments: {
              [deptIndex]: {
                status: {
                  $merge: {
                    hasError: false,
                    errorMessage: "",
                  }
                }
              }
            },
          });
        })
      }

      if (!dept.startDate) {
        setErrors((prevState) => {
          return update(prevState, {
            departments: {
              [deptIndex]: {
                startDate: {
                  $merge: {
                    hasError: true,
                    errorMessage: "*This field cannot be left blank.",
                  }
                }
              }
            },
          });
        })
        validForm = false
      } else {
        setErrors((prevState) => {
          return update(prevState, {
            departments: {
              [deptIndex]: {
                startDate: {
                  $merge: {
                    hasError: false,
                    errorMessage: "",
                  }
                }
              }
            },
          });
        })
      }
    })

    return validForm
  }

  const submitForm = async () => {
    if (validateForm()) {
      let contractorID = null
      if (profile.edited) {
        let cont = {
          name: profile.name,
          email: profile.email,
          phone: profile.phone,
          mailingAddress: profile.mailingAddress
        }
        if (contractor) {
          cont.id = contractor._id
          contractorID = contractor._id
          await updateContractorProfile(cont)
        } else {
          contractorID = await createContractor(cont)
        }
      } else {
        contractorID = profile._id
      }
      for (let ind = 0; ind < contractorDepartments.length; ind++) {
        let dept = contractorDepartments[ind]
        if (dept.edited) {
          let newDept = {
            contractorID: contractorID,
            contractorName: profile.name,
            contractorEmail: profile.email,
            departmentID: dept.department._id,
            departmentName: dept.department.name,
            startDate: dept.startDate,
            pay: dept.pay,
            status: dept.status
          }
          newDept.pay.type = dept.department.payType
          if (dept._id) {
            newDept.id = dept._id
            await editContractorDepartmentDB(newDept)
          } else {
            await addContractorDepartmentDB(newDept)
          }
        }
      }
      let searchDataEdited = searchData
      searchDataEdited.department = undefined
      window.location.href = `/portal/contractors/addEdit/${userType}/${contractorID}?${encryptData(searchDataEdited)}`
    }
  }
  return (
    <React.Fragment>

      <GridContainer>
        <GridItem xs={12} md={11} lg={8}>

          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <GoBack returnLocation={searchData.returnLocation} disableRound={true} />
            {((id === 'Create' && searchData.email) || (id !== 'Create' && contractor)) && (
              <Button
                style={{ marginLeft: '5px' }}
                color='primary'
                onClick={(e) => {
                  e.preventDefault()
                  submitForm()
                }}
              >
                Save
              </Button>)}
          </div>
          <Card>
            <CardHeader color="info" text>
              <CardText color="info">
                <h4 className={classes.cardTitleWhite}>{id === 'Create' ? 'Create Contractor' : 'Edit Contractor'}</h4>
              </CardText>
            </CardHeader>
            <CardBody>
              {(id === 'Create' && !searchData.email) || (id !== 'Create' && !contractor) ?
                <p>{id === 'Create' ? 'Invalid or no email provided.' : 'Invalid contractor ID.'}</p>
                :
                <>
                  <GridContainer className={classes.formSection} style={{ marginTop: '-15px' }}>
                    <GridItem xs={12}>
                      <TextField
                        style={{ marginTop: '25px' }}
                        required
                        label="Name"
                        variant="outlined"
                        fullWidth
                        value={profile.name}
                        onChange={(e) => updateProfile({ name: e.target.value, edited: true })}
                        helperText={errors.name.errorMessage}
                        error={errors.name.hasError}
                      />
                    </GridItem>
                    <GridItem xs={12} md={7}>
                      <TextField
                        style={{ marginTop: '25px' }}
                        required
                        label="Email"
                        variant="outlined"
                        disabled
                        fullWidth
                        value={profile.email}
                        onChange={(e) => updateProfile({ email: e.target.value, edited: true })}
                      />
                    </GridItem>
                    <GridItem xs={12} md={5}>
                      <TextField
                        style={{ marginTop: '25px' }}
                        required
                        label="Phone #"
                        variant="outlined"
                        fullWidth
                        value={profile.phone}
                        onChange={(e) => updateProfile({ phone: e.target.value.replace(/\D/g, ''), edited: true })}
                        helperText={errors.phone.errorMessage}
                        error={errors.phone.hasError}
                      />
                    </GridItem>
                    <GridItem xs={12}>
                      <TextField
                        style={{ marginTop: '25px' }}
                        required
                        label="Mailing Address"
                        variant="outlined"
                        fullWidth
                        value={profile.mailingAddress}
                        onChange={(e) => updateProfile({ mailingAddress: e.target.value, edited: true })}
                        helperText={errors.mailingAddress.errorMessage}
                        error={errors.mailingAddress.hasError}
                      />
                    </GridItem>

                  </GridContainer>
                  <GridContainer
                    className={classes.formSection}
                    style={{
                      borderRadius: "3px",
                      padding: "0 1.2rem",
                      marginBottom: '0'
                    }}
                  >
                    <React.Fragment>
                      <GridContainer
                        className={classes.formSection}
                        style={{
                          alignItems: "center",
                          justifyContent: 'space-between',
                        }}
                      >

                        <h4 style={{ margin: '0px' }}>Departments:</h4>
                        <Button
                          style={{ margin: '0px', padding: '7px' }}
                          color='primary'
                          title='Add Department'
                          onClick={addContractorDepartment}
                        >
                          <AddIcon style={{ marginRight: '0px' }} />
                        </Button>
                      </GridContainer>
                    </React.Fragment>
                  </GridContainer>
                  <GridContainer
                    className={classes.formSection}
                    style={{
                      borderRadius: "3px",
                      padding: "0 1.2rem",
                      marginBottom: '0'
                    }}
                  >
                    {contractorDepartments.length === 0 && (
                      <GridItem xs={12} style={{ textAlign: 'center' }}>
                        <span>Contractor not in any departments.</span>
                      </GridItem>
                    )}
                    {contractorDepartments.map((dept, index) => {
                      return (
                        <React.Fragment key={index}>
                          <GridContainer
                            className={classes.formSection}
                            style={{
                              //alignItems: "center",
                              border: "1px solid #ddd",
                              borderRadius: "3px",
                              background: "#fafafa",
                              padding: "15px 0",
                              marginBottom: '0',
                              marginTop: index !== 0 ? '15px' : '0px'
                            }}
                          >
                            <GridItem xs={12}>
                              <Autocomplete
                                disablePortal
                                options={filterOutUsedDepartments(dept.department._id)}
                                style={{ maxWidth: '400px' }}
                                getOptionSelected={(selected) => dept.department._id === selected._id}
                                getOptionLabel={(option) =>
                                  option.name}
                                value={dept.department}
                                renderInput={(params) => (
                                  <TextField {...params} label="Department" variant="outlined" />
                                )}
                                onChange={(e, v) => {
                                  updateDepartmentDept(index, v)
                                }
                                }
                                autoSelect={true}
                                autoComplete={true}
                                autoHighlight={true}
                                disableClearable
                              />
                              <HelperText error={errors.departments[index].department} />
                            </GridItem>
                            <GridItem xs={12} md={5} lg={4}>
                              <Autocomplete
                                disablePortal
                                options={['Active', 'Inactive']}
                                style={{ maxWidth: '400px', marginTop: '25px' }}
                                defaultValue="Active"
                                getOptionSelected={(selected) => dept.status === selected}
                                value={dept.status}
                                renderInput={(params) => (
                                  <TextField {...params} label="Status" variant="outlined" />
                                )}
                                onChange={(e, v) =>
                                  updateDepartmentStatus(index, v)
                                }
                                autoSelect={true}
                                autoComplete={true}
                                autoHighlight={true}
                                disableClearable
                              />
                              <HelperText error={errors.departments[index].status} />
                            </GridItem>
                            <GridItem xs={12} md={6}>
                              <TextField
                                style={{ marginTop: '25px' }}
                                label="Start Date"
                                variant="outlined"
                                type="date"
                                value={dept.startDate}
                                onChange={(e) =>
                                  updateDepartmentStartDate(index, e.target.value)
                                }
                                className={classes.textField}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                helperText={errors.departments[index].startDate.errorMessage}
                                error={errors.departments[index].startDate.hasError}
                              />
                            </GridItem>
                            <GridItem xs={12} style={{ marginTop: '15px' }}>
                              {dept.department.payType === 'fixed' && (
                                <TextField
                                  label={`Pay`}
                                  value={dept.pay.amount}
                                  variant="outlined"
                                  onChange={(e) => updateDepartmentPayAmount(index, e.target.value)}
                                  onWheel={(e) => e.target.blur()}
                                  type="number"
                                  inputProps={{
                                    step: 0.01,
                                  }}
                                  style={{ marginTop: '10px' }}
                                  helperText={errors.departments[index].pay.amount.errorMessage}
                                  error={errors.departments[index].pay.amount.hasError}
                                />
                              )}

                              {dept.department.payType === 'options' && (
                                <div
                                >
                                  <span style={{ fontSize: '16px' }}>Pay Options:</span>
                                  <div
                                    className={classes.formSection}
                                    style={{
                                      alignItems: "center",
                                      border: errors.departments[index].pay.options.hasError ? "1px solid red" : "1px solid #ddd",
                                      borderRadius: "3px",
                                      //background: "#fff",
                                      padding: "10px",
                                      marginBottom: '0'
                                    }}>
                                    {dept.pay.options.map((option, optIndex) => {
                                      return (
                                        <div key={optIndex} style={{ marginTop: optIndex === 0 ? '5px' : '15px', display: 'flex' }}>
                                          <TextField
                                            style={{ marginRight: '10px', flex: 2 }}
                                            required
                                            placeholder="Label"
                                            value={option.label}
                                            onChange={(e) => updateDepartmentPayOptionLabel(index, optIndex, e.target.value)}
                                          // helperText={errors.vendor.errorMessage || ""}
                                          // error={errors.vendor.hasError || false}
                                          />
                                          <TextField
                                            placeholder={`Amount`}
                                            value={option.pay}
                                            onChange={(e) => updateDepartmentPayOptionPay(index, optIndex, e.target.value)}
                                            onWheel={(e) => e.target.blur()}
                                            type="number"
                                            inputProps={{
                                              step: 0.01,
                                            }}
                                            style={{ flex: 1, marginRight: '10px' }}
                                          />
                                          <Button
                                            style={{ margin: '0px', padding: '7px' }}
                                            color='danger'
                                            title='Remove'
                                            onClick={(e) => {
                                              e.preventDefault()
                                              removeDepartmentPayOption(index, optIndex)
                                            }}
                                          >
                                            <CloseIcon style={{ marginRight: '0px' }} />
                                          </Button>
                                        </div>
                                      )
                                    })}
                                    <div style={{ textAlign: 'right' }}>
                                      <Button
                                        style={{ margin: '0px', padding: '7px', marginTop: '20px' }}
                                        color='primary'
                                        onClick={(e) => {
                                          e.preventDefault()
                                          addDepartmentPayOption(index)
                                        }}
                                      >
                                        Add Option
                                      </Button>
                                    </div>
                                  </div>
                                  <HelperText error={errors.departments[index].pay.options} />
                                </div>
                              )}
                              <div style={{ textAlign: 'right' }}>
                                {(!dept._id) && (<Button
                                  style={{ margin: '0px', padding: '7px', marginTop: '10px' }}
                                  color='danger'
                                  onClick={(e) => {
                                    e.preventDefault()
                                    removeDepartment(index)
                                  }}
                                >
                                  Remove
                                </Button>)}
                              </div>
                            </GridItem>

                          </GridContainer>
                        </React.Fragment>
                      )
                    })}
                  </GridContainer>
                </>
              }
            </CardBody>
          </Card>
          {((id === 'Create' && searchData.email) || (id !== 'Create' && contractor)) && (
            <div style={{ textAlign: 'right' }}>
              <Button
                style={{ margin: '0px', padding: '10px 20px', marginTop: '-10px' }}
                color='primary'
                onClick={(e) => {
                  e.preventDefault()
                  submitForm()
                }}
              >
                Save
              </Button>
            </div>)}
        </GridItem>
      </GridContainer>
    </React.Fragment >
  )
}

const HelperText = ({ error }) => {
  return error.hasError ? (
    <span
      style={{
        margin: 0,
        fontSize: '0.75rem',
        marginTop: '3px',
        textAlign: 'left',
        fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
        fontWeight: 400,
        lineHeight: 1.66,
        color: '#f44336',
        marginLeft: '14px'
      }}
    >
      {error.errorMessage}
    </span>
  )
    :
    <></>
}

const CreateEditContractor = (props) => {
  return (
    <React.Suspense fallback={<Loading color="blue" />}>
      <CreateEditContractorComponent {...props} />
    </React.Suspense>
  )
}

export default CreateEditContractor