import React, { useState, useEffect } from "react";
import { Calendar as BigCalendar, momentLocalizer } from "react-big-calendar";
import axios from "axios";
import moment from "moment";
import CustomTabs from "components/CustomTabs/CustomTabs";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import { Settings, DeleteForever } from "@material-ui/icons";
import Button from "components/CustomButtons/Button";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import CalendarTab from "components/Calendar/CalendarTab";
import { useRecoilValue } from "recoil";
import { calendarSettingsAtom } from "state/settings";
import Loading from "components/Loading/Loading";

const localizer = momentLocalizer(moment);

const Fallback = () => {
  return (
    <CustomTabs
      headerColor="info"
      className="calendar-widget-container"
      tabs={[
        {
          tabName: "Loading Calendar Data...",
          tabContent: <Loading color="blue" />,
        },
      ]}
    />
  );
};

const CalendarWidgetComponent = (props) => {
  const userCalendars = useRecoilValue(calendarSettingsAtom);

  const getTabs = () => {
    let tabs = [];

    if (userCalendars) {
      userCalendars.calendarSettings.map((item, index) => {
        tabs.push({
          tabName: item.title,
          tabContent: <CalendarTab tabIndex={index} />,
        });
      });
    }

    return tabs;
  };

  return (
    <div className={`calendar-widget-container`}>
      <CustomTabs
        headerColor="info"
        tabs={getTabs()}
        footer={
          <GridContainer>
            <GridItem xs={12} style={{ textAlign: "right" }}>
              <Button
                size="sm"
                round
                color="primary"
                href="/portal/settings"
                style={{ alignItems: "flex-end" }}
              >
                <Settings style={{ marginTop: "-1px" }} /> Settings
              </Button>
            </GridItem>
          </GridContainer>
        }
      />
    </div>
  );
};

const CalendarWidget = (props) => {
  return (
    <React.Suspense fallback={<Fallback />}>
      <CalendarWidgetComponent />
    </React.Suspense>
  );
};

export default CalendarWidget;
