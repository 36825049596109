import React, { useEffect, useState } from "react";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";

import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Edit from "@material-ui/icons/Edit";
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';

import ReactTooltip from "react-tooltip";

import './trainingCreationTab.css'

const TrainingCreationTab = (props) => {

  const [videoHeight, setVideoHeight] = useState('200px')

  const renderBodyItem = (item) => {
    if (item.inputType === 'Text') {
      return (<div dangerouslySetInnerHTML={{ __html: item.value.html }} />)
    } else if (item.inputType === 'Youtube') {
      return createYoutubeEmbed(item.value)
    } else if (item.inputType === 'Links') {
      return (
        <GridContainer>
          <GridItem xs={12}>
            <h4><strong>{item.value.title}</strong></h4>
          </GridItem>
          <GridItem xs={12}>
            <ul>
              {item.value.links.map((link, index) => {
                return (
                  <li key={index}>
                    <a href={link.link} target="_blank">
                      {link.name}
                    </a>
                  </li>
                )
              })}
            </ul>
          </GridItem>
        </GridContainer>
      )
    } else if (item.inputType === 'Scribe') {
      return createScribeEmbed(item.value)
    }
    return <></>
  }

  const getVideoHeight = ({ target: vid }) => {
    const { offsetWidth } = vid

    let height = (offsetWidth * 9) / 16
    height = Math.round(height)


    setVideoHeight(`${height}px`)
  }

  const createYoutubeEmbed = (link) => {
    let embedID = null
    if (link.includes('www.youtube.com/watch?v=')) {
      embedID = link.split('=')[1]
    } else if (link.includes('https://youtu.be/') || link.includes('https://www.youtube.com/embed/')) {
      embedID = link.split('/')
      embedID = embedID[embedID.length - 1]
      embedID = embedID.replace('?t=', '?start=')
    }
    if (embedID) {
      return (
        <div style={{ display: 'flex', justifyContent: 'center', padding: '10px' }}>
          <iframe
            style={{ width: '85%', height: videoHeight }}
            onLoad={getVideoHeight}
            src={`https://www.youtube.com/embed/${embedID}`}
            frameBorder="0"
            allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Embedded youtube"
          />
        </div>
      )
    }
    return (<p>Invalid Youtube Link</p>)
  }

  const createScribeEmbed = (link) => {
    if (link.includes('scribehow.com')) {
      if (link.includes('scribehow.com/shared/')) {
        link = link.replace('shared', 'embed')
      }
      if (!link.includes('?')) {
        link += '?removeLogo=true'
      }
      return (
        <div style={{ display: 'flex', justifyContent: 'center', padding: '10px' }}>
          <iframe
            style={{ width: '85%', height: '640px' }}
            src={link}
            allowFullScreen frameBorder="0"
            title="Embedded Scribe"
          />
        </div>
      )
    }
    return (<p>Invalid Scribe Link</p>)
  }

  return (
    <React.Fragment>
      <GridContainer>
        <GridItem xs={12}
          style={{ display: 'flex', justifyContent: 'right' }}
        >
          <Button
            justIcon
            color="primary"
            // data-tip
            // data-for={`edit-item`}
            title='Edit'
            onClick={() => {
              props.setCurrentTab(props.tab)
              props.toggleEdit(true)
            }}
          >
            <Edit />
          </Button>
          {/* <ReactTooltip
            delayHide={50}
            delayShow={100}
            effect="solid"
            id={`edit-item`}
          >
            Edit
          </ReactTooltip> */}

          <Button
            justIcon
            color="primary"
            onClick={() => {
              let tab = {
                _id: null,
                pageID: props.tab.pageID,
                position: props.maxIndex,
                title: props.tab.title,
                body: props.tab.body
              }
              props.setCurrentTab(tab)
              props.toggleEdit(true)
            }}
            title='Duplicate'
          // data-tip
          // data-for={`duplicate-item`}
          >
            <LibraryBooksIcon />
          </Button>
          {/* <ReactTooltip
            delayHide={50}
            delayShow={100}
            effect="solid"
            id={`duplicate-item`}
          >
            Duplicate
          </ReactTooltip> */}

          <Button
            justIcon
            color="danger"
            onClick={() => props.removeTab(props.tab)}
            title='Delete'
          // data-tip
          // data-for={`delete-item`}
          >
            <DeleteForeverIcon />
          </Button>
          {/* <ReactTooltip
            delayHide={50}
            delayShow={100}
            effect="solid"
            id={`delete-item`}
          >
            Delete
          </ReactTooltip> */}

        </GridItem>
        <GridItem xs={12} className='tabBodyItemGridItem'>
          <h2><strong>{props.tab.title}</strong></h2>
        </GridItem>
        {props.tab.body.map((item, index) => {
          return (
            <GridItem
              className='tabBodyItemGridItem'
              xs={12}
              key={index}

            >
              {renderBodyItem(item)}
            </GridItem>
          )
        })}
      </GridContainer>
    </React.Fragment>
  )
}

export default TrainingCreationTab