import React from "react";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { checkinStartDateAtom, checkinEndDateAtom } from "state/checkin";
import { useRecoilState } from "recoil";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody";
import CardText from "components/Card/CardText";
import Button from "components/CustomButtons/Button";

const useStyles = makeStyles(styles);

const d = new Date();
const sd = d.setDate(d.getDate() - (d.getDay() + 1));

const DateRange = (props) => {
  const classes = useStyles();

  const [startDate, setStartDate] = useRecoilState(checkinStartDateAtom);
  const [endDate, setEndDate] = useRecoilState(checkinEndDateAtom);

  const [dateRanges, setDateRanges] = React.useState([
    {
      startDate: new Date(sd),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const handleSelect = (ranges) => {
    setDateRanges([ranges.selection]);
  };

  const updateButtonHandler = (e) => {
    e.preventDefault();

    setStartDate(new Date(dateRanges[0].startDate).toISOString().slice(0, 10));
    setEndDate(new Date(dateRanges[0].endDate).toISOString().slice(0, 10));
  };

  return (
    <Card>
      <CardHeader color="info" text>
        <CardText color="info">
          <h4 className={classes.cardTitleWhite}>Date Range</h4>
        </CardText>
      </CardHeader>
      <CardBody>
        <div>
          <DateRangePicker
            ranges={dateRanges}
            onChange={handleSelect}
            //inputRanges={[]}
            //staticRanges={[]}
            singleDateRange={true}
          />
        </div>
        <div>
          <Button
            fullWidth={true}
            size="sm"
            color="primary"
            onClick={updateButtonHandler}
          >
            Update
          </Button>
        </div>
      </CardBody>
    </Card>
  );
};

export default DateRange;
