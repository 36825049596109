import React, { useState, useEffect } from "react";
import CustomTabs from "components/CustomTabs/CustomTabs";
import CalendarSettingsForm from "./CalendarSettingsForm";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { calendarSettingsAtom, removeTabAtom } from "state/settings";
import { userAtom } from "state/auth";
import update from "immutability-helper";
import axios from "axios";
import { useHistory } from "react-router-dom";
import GoBack from "components/GoBack/GoBack";
import Loading from "components/Loading/Loading";
import api from "../../api.config";

const CalendarSettingsComponent = (props) => {
  const [calendarData, setCalendarData] = useRecoilState(calendarSettingsAtom);
  const [tabs, setTabs] = useState([]);
  const history = useHistory();
  const user = useRecoilValue(userAtom);

  const removeTabHandler = async (index) => {
    if (
      confirm(
        "This action cannot be undone. Are you sure you want to delete this tab?"
      )
    ) {
      let newState = update(calendarData, {
        calendarSettings: { $splice: [[index, 1]] },
      });
      await axios
        .post(`${api.path}/staff_portal_api/v1/saveusersettings`, {
          settings: newState,
        })
        .then((res) => {
          setCalendarData(newState);
          history.push("/portal/dashboard");
        });
    }
  };

  useEffect(() => {
    (() => {
      let lastIndex = 0;

      if (calendarData && calendarData.calendarSettings) {
        calendarData.calendarSettings.map((item, index) => {
          setTabs((prev) => {
            return [
              ...prev,
              {
                tabName: item.title,
                tabContent: (
                  <CalendarSettingsForm
                    calendars={
                      item.calendars
                        ? item.calendars
                        : [
                          {
                            index: 0,
                            id: user.email,
                            eventColor: "#ff0000",
                          },
                        ]
                    }
                    title={item.title}
                    calendarIndex={index}
                    removeTabHandler={() => removeTabHandler(index)}
                  />
                ),
              },
            ];
          });
          lastIndex++;
        });
      }
    })();
  }, []);

  return (
    <CustomTabs
      headerColor="info"
      tabs={[
        ...tabs,
        {
          tabName: "Add New Tab",
          tabContent: (
            <CalendarSettingsForm
              calendars={[
                {
                  index: 0,
                  id: user.email,
                  eventColor: "#ff0000",
                },
              ]}
              title=""
              calendarIndex={
                calendarData && calendarData.calendarSettings
                  ? calendarData.calendarSettings.length
                  : 0
              }
            />
          ),
        },
      ]}
    />
  );
};

const CalendarSettings = () => {
  return (
    <React.Suspense fallback={<Loading color="blue" />}>
      <GoBack />
      <CalendarSettingsComponent />
    </React.Suspense>
  );
};

export default CalendarSettings;
