import React from 'react'
import CustomTabs from "components/CustomTabs/CustomTabs";
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import PackagingForm from './PackagingForm';
import * as numbers from './PackagingNumbers'

/**
 * this creates the packaging calculator page with tabs to switch between each enviornment form.
 * @param {object} props
 * @returns 
 */
const PackagingCalculator = (props) => {
  const tab = +(props.location.search?.split("=")[1] || 0);

  const tabs = [
    {
      tabName: "SS",
      tabContent: (
        <PackagingForm Regular={numbers.SSRegular}
          BigEvent={numbers.SSBigEvent}
          enviornment='Seaside Surf'
        />
      ),
    },
    {
      tabName: "AI",
      tabContent: (
        <PackagingForm Regular={numbers.AIRegular}
          BigEvent={numbers.AIBigEvent}
          enviornment='Adventure Island'
        />
      ),
    },
    {
      tabName: "W",
      tabContent: (
        <PackagingForm
          Regular={numbers.WRegular}
          BigEvent={numbers.WBigEvent}
          enviornment='Wired'
        />
      ),
    },
  ];

  return (
    <React.Fragment>
      <GridContainer>
        <GridItem xs={12} md={7}>
          <CustomTabs headerColor="info" tabs={tabs} value={tab} />
        </GridItem>
      </GridContainer>
    </React.Fragment>
  )
}

export default PackagingCalculator