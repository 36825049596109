import React, { useState } from "react";
import { BlobProvider, PDFDownloadLink } from "@react-pdf/renderer";

import { Document, Page, pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

import CurrentPDF from "../CurrentPDF";

import Loading from "components/Loading/Loading";
import { useRecoilValue } from "recoil";
import { viewSinglePo } from "state/purchaseOrders";
import AdminActionBar from "components/ActionBars/AdminActionBar";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

const PurchaseOrderPDFComponent = (props) => {
  const currentId = props.id ? props.id : +props.match.params.id;
  const currentPo = useRecoilValue(viewSinglePo(currentId));
  const widthModifier = window.innerWidth > 1000 ? 0.35 : 0.9;

  if (!currentPo) {
    return (
      <React.Fragment>
        <p>You aren't supposed to be here.</p>
      </React.Fragment>
    );
  }

  const calculateDescriptionSplits = (desc) => {
    let lines = 0
    let splits = desc.split('\n')
    splits.forEach(newLine => {
      let lineSplits = newLine.match(/.{1,72}/g)
      if (!lineSplits) {
        lines += 1
      } else {
        lines += lineSplits.length
      }
    });
    return lines
  }
  const calculateLineitemSpace = () => {
    let lineItemsSize = 0
    const descriptions = JSON.parse(currentPo.lineItems).map(item => { return item.description })
    lineItemsSize += descriptions.length * 32 //25
    let lines = 0
    descriptions.forEach(desc => { lines += calculateDescriptionSplits(desc) })
    lines -= descriptions.length
    lineItemsSize += lines * 15.5 //15
    return lineItemsSize
  }

  const lineitemSize = calculateLineitemSpace()

  if (currentPo.status.toLowerCase() === "void") {
    return <React.Fragment>This PO has been voided.</React.Fragment>;
  }

  const files = JSON.parse(currentPo.attachedFiles);

  return (
    currentPo && (
      <React.Fragment>
        <AdminActionBar {...props} currentPo={currentPo} />
        <TransformWrapper wheel={{ disabled: true }}>
          <TransformComponent>
            <BlobProvider
              document={<CurrentPDF {...props} currentPo={currentPo} />}
              fileName={`purchase_order_${currentId}.pdf`}
              onClick={(e) => e.preventDefault()}
            >
              {({ url, loading }) => {
                return loading ? (
                  <Loading color="blue" />
                ) : (
                  <React.Fragment>
                    <Document
                      file={url}
                      renderMode="svg"
                      loading={<Loading color="blue" />}
                    >
                      <div style={{ marginTop: "10px" }}>
                        <Page
                          width={window.innerWidth * widthModifier}
                          pageNumber={1}
                        />
                      </div>

                      {((lineitemSize > 490 && (currentPo.status === "approved" || currentPo.archived))
                        || (lineitemSize > 590 && !(currentPo.status === "approved" || currentPo.archived))) ?
                        <>
                          <div style={{ marginTop: "50px" }}>
                            <Page
                              width={window.innerWidth * widthModifier}
                              pageNumber={2}
                            />
                            {files !== null &&
                              Array.isArray(files) &&
                              files.map((item, index) => {
                                return (
                                  <div style={{ marginTop: "50px" }} key={index}>
                                    <Page
                                      width={window.innerWidth * widthModifier}
                                      pageNumber={index + 3}
                                    />
                                  </div>
                                );
                              })}
                          </div>
                        </>
                        :
                        <>
                          {files !== null &&
                            Array.isArray(files) &&
                            files.map((item, index) => {
                              return (
                                <div style={{ marginTop: "50px" }} key={index}>
                                  <Page
                                    width={window.innerWidth * widthModifier}
                                    pageNumber={index + 2}
                                  />
                                </div>
                              );
                            })}
                        </>
                      }
                    </Document>
                  </React.Fragment>
                );
              }}
            </BlobProvider>
          </TransformComponent>
        </TransformWrapper>
      </React.Fragment>
    )
  );
};

const PurchaseOrderPDF = (props) => {
  return (
    <React.Suspense fallback={<Loading color="blue" />}>
      <PurchaseOrderPDFComponent {...props} />
    </React.Suspense>
  );
};

export default PurchaseOrderPDF;
