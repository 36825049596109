import React, { useState } from "react";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomTabs from "components/CustomTabs/CustomTabs";
import Newsfeed from "./Newsfeed";
import MonthlyVerse from "./MonthlyVerse";

const EditDashboard = (props) => {
  const tab = +(props.location.search?.split("=")[1] || 0);

  const tabs = [
    {
      tabName: "Monthly Verse",
      tabContent: (
        <MonthlyVerse />
      ),
    },
    {
      tabName: "NewsFeed",
      tabContent: (
        <Newsfeed props />
      ),
    },
  ];

  return (
    <React.Fragment>
      <GridContainer>
        <GridItem xs={12} md={7}>
          <CustomTabs headerColor="info" tabs={tabs} value={tab} />
        </GridItem>
      </GridContainer>
    </React.Fragment>
  )
}

export default EditDashboard