import GridContainer from 'components/Grid/GridContainer'
import GridItem from "components/Grid/GridItem";
import update from 'immutability-helper';

import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import Button from "components/CustomButtons/Button";

import React, { useState } from 'react'
import { makeStyles } from "@material-ui/core/styles";
import dashboardStyles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import { TextField } from '@material-ui/core';
import Autocomplete from "@material-ui/lab/Autocomplete";
import * as numbers from './PackagingNumbers'
import PackagingPDF from './PackagingPDF';

import { PDFViewer } from '@react-pdf/renderer';

const useStyles = makeStyles(() => ({
  ...styles,
  ...dashboardStyles,
  formSection: {
    marginBottom: "2rem",
  },
}));

/**
 * Once a user has tried to submit the form, any field that calls this will verify the field isn't
 * empty
 * @param {boolean} submitted
 * @param {String} value
 * @returns {boolean} whether or not a value if invalid
 * @func
 */
const invalidEntry = (submitted, value) => {
  if (submitted) {
    if (value === '') {
      return true
    }
    return false
  }
  // not submitted yet
  return false
}

/**
 * Returns helper text based on whether the input is valid or not.
 * @param {boolean} submitted
 * @param {String} value
 * @returns {String}
 * @func
 */
const helperText = (submitted, value) => {
  if (invalidEntry(submitted, value)) {
    return 'This Field Cannot be Empty.'
  }
  return ''
}

/**
 * @typedef EnviornmentNumbers
 * @type {object}
 * @prop {LocationNumbers} RS numbers for Riverside
 * @prop {LocationNumbers} multisite numbers for multisites
 */

/**
 * @typedef LocationNumbers
 * @type {object}
 * @prop {String} label label describing the entries
 * @prop {Array<Room>} entries labels and numbers for a location
 */

/**
 * @typedef Room
 * @type {Object}
 * @prop {String} label the name/campus of the room
 * @prop {Number} value the number of kids in the room
 */

/**
 * Creates the form of the packaging calculator that allows a user to input the number of kids for
 * each room, the services at each campus, and the items each room needs.
 * @param {props} props
 * @prop {EnviornmentNumbers} BigEvent numbers for a large event weekend
 * @prop {EnviornmentNumbers} Regular regular weekend numbers
 * @prop {String} enviornment the name of the enviornment we are in
 * @component
 */
const PackagingForm = ({ BigEvent, Regular, enviornment }) => {
  const classes = useStyles();
  const [submitted, setSubmitted] = useState(false)

  const [formData, setFormData] = useState('')

  const [title, setTitle] = useState('')
  const [bigWeekend, setBigWeekend] = useState('no')
  const [packagingNumbers, setPackagingNumbers] = useState(bigWeekend === 'yes' ? BigEvent : Regular)
  const [selectedPackagingNumbers, setSelectedPackagingNumbers] = useState(bigWeekend === 'yes' ? BigEvent : Regular)
  const [selectedServiceNumbers, setSelectedServiceNumbers] = useState(numbers.NumberOfServices)
  const [supplyList, setSupplyList] = useState([{
    description: '',
    QTY: '',
    per: 'Kid',
    location: 'Ordered',
    extra: '',
    label: '#',
    returned: 'no'
  }])

  /**
   * @typedef Supply
   * @type {object}
   * @prop {string} description of the supply
   * @prop {string} QTY needed of the supply
   * @prop {string} per whether the QTY is per kid, enviornment, or service
   * @prop {string} location whether the supply is in the classroom, resource room or was ordered
   * @prop {string} extra number of extras needed
   * @prop {string} label whether extras is a number or percentage
   * @prop {string} returned whether or not the supply needs to be returned
   */
  /**
   * used to updates the list of supplies when one is changed
   * @param {Supply} supply supply we are updating
   * @param {Number} index the index of the supply in the supply list
   * @func
   */
  const updateSupplyList = (supply, index) => {
    setSupplyList(prevState => {
      return update(prevState, {
        [index]: { $set: supply }
      })
    })
  }

  /**
   * used to remove a supply from the supply list
   * @param {Number} index of the supply in the list
   * @func
   */
  const removeSupply = (index) => {
    if (supplyList.length === 1) {
      setSupplyList([{
        description: '',
        QTY: '',
        per: 'Kid',
        location: 'Ordered',
        extra: '',
        label: '#',
        returned: 'no'
      }])
    } else {
      setSupplyList([...supplyList.slice(0, index),
      ...supplyList.slice(index + 1, supplyList.length)])
    }
  }

  /**
   * adds a empty supply to the supply list
   * @func
   */
  const addSupply = () => {
    setSupplyList([
      {
        description: '',
        QTY: '',
        per: 'Kid',
        location: 'Ordered',
        extra: '',
        label: '#',
        returned: 'no'
      }, ...supplyList])
  }

  /**
   * called to set whether or not the form is using regular or big weekend numbers
   * @param {String} value yes | no
   * @func
   */
  const SetBigWeekend = (value) => {
    setBigWeekend(value)
    setPackagingNumbers(value === 'yes' ? BigEvent : Regular)
    setSelectedPackagingNumbers(value === 'yes' ? BigEvent : Regular)
  }

  /**
   * Called to update the numbers of a service in the service numbers list, making sure it fits the
   * format
   * @param {Number} index index of the service in the list
   * @param {String} value the new number of services
   * @param {Event} e
   * @func
   */
  const updateServiceNumbers = (index, value, e) => {
    if (value.length < 4 && (/^\d+$/.test(value) || value === '')) {
      var newValue = value === '' ? '' : parseInt(value)
      setSelectedServiceNumbers(prevState => {
        return update(prevState, {
          [index]: { value: { $set: newValue } }
        })
      })
    } else {
      e.preventDefault()
    }
  }

  /**
   * get the number of services for a location
   * @param {String} location we want the numbers for
   * @returns {Number} the number of services at that location
   * @func
   */
  const getNumServices = (location) => {
    var value = -1
    selectedServiceNumbers.forEach((service, index) => {
      if (location === service.label) {
        if (service.value === '') {
          value = numbers.NumberOfServices[index].value
        } else {
          value = service.value
        }
      }
    })
    return value
  }

  /**
   * @typedef RoomCalculation
   * @type {object}
   * @prop {String} location what campus/room
   * @prop {Number} kids number of kids in the room
   * @prop {Number} numberOfServices number of services at this rooms campus
   * @prop {Array<SupplyCalc>} supplies supplies that the room needs
   */

  /**
   * @typedef SupplyCalc
   * @type {object}
   * @prop {Number} quantity number of the supply that is needed for the room
   * @prop {String} desc the description of the supply
   * @prop {String} status where the supply is {ordered|classroom|resource room}
   * @prop {String} returned whether or not the suppply needs to be returned
   */

  /**
   * Calculates the number of supplies needed for each room
   * @returns {Array<RoomCalculation>}
   * @func
   */
  const calculateSuppliesPerRoom = () => {
    var rooms = []
    selectedPackagingNumbers.RS.entries.forEach((loc, index) => {
      var room = {
        location: ('RS - ' + loc.label),
        kids: loc.value,
        numberOfServices: (getNumServices('RS')),
        supplies: []
      }
      if (loc.value === '') {
        room.kids = packagingNumbers.RS[index].value
      }
      supplyList.forEach(supply => {
        var item = {
          quantity: -1,
          desc: supply.description,
          status: supply.location,
          returned: supply.returned
        }
        if (supply.per === 'Kid') {
          item.quantity = Math.ceil(room.kids * parseFloat(supply.QTY))
        } else if (supply.per === 'Service') {
          item.quantity = Math.ceil(room.numberOfServices * parseFloat(supply.QTY))
        } else if (supply.per === 'Enviornment') {
          item.quantity = Math.ceil(parseFloat(supply.QTY))
        }
        if (supply.extra !== '') {
          if (supply.label === '%') {
            item.quantity += Math.round(item.quantity * (parseInt(supply.extra) / 100))
          } else {
            item.quantity += parseInt(supply.extra)
          }
        }
        room.supplies.push(item)
      })
      rooms.push(room)
    })

    selectedPackagingNumbers.multisite.entries.forEach((loc, index) => {
      // do we have any kids in this room, if not skip
      if (loc.value !== 0) {
        var room = {
          location: (loc.label),
          kids: loc.value,
          numberOfServices: (getNumServices(loc.label)),
          supplies: []
        }
        if (loc.value === ' ') {
          room.kids = packagingNumbers.multisite[index].value
        }
        supplyList.forEach(supply => {
          var item = {
            quantity: -1,
            desc: supply.description,
            status: supply.location,
            returned: supply.returned
          }
          if (supply.per === 'Kid') {
            item.quantity = Math.ceil(room.kids * parseFloat(supply.QTY))
          } else if (supply.per === 'Service') {
            item.quantity = Math.ceil(room.numberOfServices * parseFloat(supply.QTY))
          } else if (supply.per === 'Enviornment') {
            item.quantity = Math.ceil(parseFloat(supply.QTY))
          }
          if (supply.extra !== '') {
            if (supply.label === '%') {
              item.quantity += Math.round(item.quantity * (parseInt(supply.extra) / 100))
            } else {
              item.quantity += parseInt(supply.extra)
            }
          }
          room.supplies.push(item)
        })
        rooms.push(room)
      }
    })
    return rooms
  }

  /**
   * Given the rooms with all their needed supplies, go through each room and total up the number of
   * each supply is needed total.
   * @param {Array<RoomCalculation>} rooms
   * @returns {Array<SupplyCalc>}
   * @func
   */
  const getTotals = (rooms) => {
    var totals = ''
    rooms.forEach((room) => {
      if (totals === '') {
        totals = structuredClone(room.supplies)
      } else {
        room.supplies.forEach((supply, index) => {
          totals[index].quantity += supply.quantity
        })
      }
    })
    return totals
  }

  /**
   * called when the form is submited to calculate the numbers and generate the pdf for the rooms
   * @func
   */
  const submitForm = () => {
    if (verifyForm()) {
      var rooms = calculateSuppliesPerRoom()

      var formInfo = {
        title: title,
        enviornment: enviornment,
        totals: getTotals(rooms),
        rooms: rooms
      }
      setFormData(formInfo)
    } else {
      alert('Some Required Fields Have Been Left Empty.')
    }
  }

  /**
   * verify the form is filled out properly
   * @returns  {boolean}
   * @func
   */
  const verifyForm = () => {
    // Supply list check
    setSubmitted(true)
    var valid = true
    supplyList.forEach((supply) => {
      if (supply.description === '' || supply.QTY === '') {
        valid = false
      }
    })
    if (title === '') {
      valid = false
    }
    return valid
  }

  /**
   * Called to update a rooms numbers in the RS packinging numbers list
   * @param {Number} index index of the room in the list
   * @param {String} value the updated number in the list
   * @param {Event} e
   * @func
   */
  const updateRSSelectedPackagingNumber = (index, value, e) => {
    if (value.length < 4 && (/^\d+$/.test(value) || value === '')) {
      var newValue = value === '' ? '' : parseInt(value)
      setSelectedPackagingNumbers(prevState => {
        return update(prevState, {
          RS: { entries: { [index]: { value: { $set: newValue } } } }
        })
      })
    } else {
      e.preventDefault()
    }
  }

  /**
   * Called to update a rooms numbers in the multisite packinging numbers list
   * @param {Number} index index of the room in the list
   * @param {String} value the updated number in the list
   * @param {Event} e
   * @func
   */
  const updateMultiSelectedPackagingNumber = (index, value, e) => {
    if (value.length < 4 && (/^\d+$/.test(value) || value === '')) {
      var newValue = value === '' ? '' : parseInt(value)
      setSelectedPackagingNumbers(prevState => {
        return update(prevState, {
          multisite: { entries: { [index]: { value: { $set: newValue } } } }
        })
      })
    } else {
      e.preventDefault()
    }
  }



  return (
    <GridContainer>
      <GridItem xs={12}>
        <GridContainer
          style={{ alignItems: "center" }}
        >
          <GridItem xs={12} lg={12}>
            <TextField
              label="Series, Week # (Date)"
              variant="outlined"
              fullWidth
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              helperText={helperText(submitted, title)}
              error={invalidEntry(submitted, title)}
            />
          </GridItem>
          <GridItem xs={12}>

            <label style={{ 'color': 'black', fontSize: '22px', marginRight: '20px' }}> Big Event?:</label>

            <FormControlLabel
              control={
                <Radio
                  checked={bigWeekend === "yes"}
                  name="BigWeekend"
                  value="yes"
                  onChange={(e) => SetBigWeekend(e.target.value)}
                  classes={{
                    checked: classes.checked,
                    root: classes.checkRoot,
                  }}
                />
              }
              classes={{
                label: classes.label,
                root: classes.labelRoot,
              }}
              label="Yes"
            />

            <FormControlLabel
              control={
                <Radio
                  checked={bigWeekend === "no"}
                  name="BigWeekend"
                  value="no"
                  onChange={(e) => SetBigWeekend(e.target.value)}
                  classes={{
                    checked: classes.checked,
                    root: classes.checkRoot,
                  }}
                />
              }
              classes={{
                label: classes.label,
                root: classes.labelRoot,
              }}
              label="No"
            />
          </GridItem>
        </GridContainer>

        <GridContainer
          className={classes.formSection}
        >
          <GridItem xs={12}>
            <label style={{ 'color': 'black', fontSize: '22px' }}> Number of Services:</label>
          </GridItem>
          <GridItem xs={12} md={10}>
            <GridContainer
              style={{
                alignItems: "center",
                border: "1px solid #ddd",
                borderRadius: "3px",
                background: "#fafafa",
                padding: "10px 0px",
                marginBottom: '5px'
              }}
            >
              <GridItem xs={12}>
                <GridContainer>
                  {selectedServiceNumbers.map((location, index) => {
                    return (
                      <GridItem
                        key={index}
                        xs={4}
                        md={2}
                      >
                        <label
                          style={
                            {
                              'color': 'black',
                              fontSize: '14px'
                            }}
                        >
                          {location.label}
                        </label>
                        <br />
                        <input
                          style={{
                            width: '34px',
                            align: 'center',
                          }}
                          type='text'
                          placeholder={numbers.NumberOfServices[index].value}
                          value={location.value}
                          onChange={(e) => updateServiceNumbers(index, e.target.value, e)}
                          onKeyPress={(e) => {
                            if (!/[0-9]/.test(e.key)) {
                              e.preventDefault();
                            }
                          }}

                        />
                      </GridItem>
                    )
                  })}
                </GridContainer>
              </GridItem>
            </GridContainer>
          </GridItem>

          <GridItem xs={12}>
            <label style={{ 'color': 'black', fontSize: '22px' }}> Number of Kids:</label>
          </GridItem>
          <GridItem xs={12} md={10}>
            <GridContainer
              style={{
                alignItems: "center",
                border: "1px solid #ddd",
                borderRadius: "3px",
                background: "#fafafa",
                padding: "10px 0px",
                marginBottom: '5px'
              }}
            >
              <GridItem xs={12}>
                <label style={{ 'color': 'black', fontSize: '18px' }}>{packagingNumbers.RS.label}:</label>
              </GridItem>
              <GridItem xs={12}>
                <GridContainer>
                  {selectedPackagingNumbers.RS.entries.map((location, index) => {
                    return (
                      <GridItem
                        key={index}
                        xs={4}
                        md={2}
                      >
                        <label
                          style={
                            {
                              'color': 'black',
                              fontSize: '14px'
                            }}
                        >
                          {location.label}
                        </label>
                        <br />
                        <input
                          style={{
                            width: '34px',
                            align: 'center'
                          }}
                          type='text'
                          placeholder={packagingNumbers.RS.entries[index].value}
                          value={location.value}
                          onChange={(e) => updateRSSelectedPackagingNumber(index, e.target.value, e)}
                          onKeyPress={(e) => {
                            if (!/[0-9]/.test(e.key)) {
                              e.preventDefault();
                            }
                          }}

                        />
                      </GridItem>
                    )
                  })}
                </GridContainer>
              </GridItem>
            </GridContainer>
          </GridItem>
          <GridItem xs={12} md={10}>
            <GridContainer
              style={{
                alignItems: "center",
                border: "1px solid #ddd",
                borderRadius: "3px",
                background: "#fafafa",
                padding: "10px 0px",
              }}
            >
              <GridItem xs={12}>
                <label style={{ 'color': 'black', fontSize: '18px' }}> {packagingNumbers.multisite.label}:</label>
              </GridItem>
              <GridItem xs={12}>
                <GridContainer>
                  {selectedPackagingNumbers.multisite.entries.map((location, index) => {
                    return (
                      <GridItem
                        key={index}
                        xs={4}
                        md={2}>
                        <label
                          style={
                            {
                              'color': 'black',
                              fontSize: '14px'
                            }}
                        >
                          {location.label}
                        </label>
                        <br />
                        <input
                          style={{
                            width: '30px',
                            align: 'center'
                          }}
                          type='text'
                          placeholder={packagingNumbers.multisite.entries[index].value}
                          value={location.value}
                          onChange={(e) => updateMultiSelectedPackagingNumber(index, e.target.value, e)}
                          onKeyPress={(e) => {
                            if (!/[0-9]/.test(e.key)) {
                              e.preventDefault();
                            }
                          }}
                        />
                      </GridItem>
                    )
                  })}
                </GridContainer>
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>

        <GridContainer
          className={classes.formSection}
        >
          <GridItem xs={6}>
            <label style={{ 'color': 'black', fontSize: '22px' }}> Supplies:</label>
          </GridItem>
          <GridItem xs={6}>
            <div
              style={{ display: 'flex', justifyContent: 'right' }}
            >
              <Button
                color="primary"
                onClick={() => addSupply()}
                tabIndex="-100"
              >
                Add Supply
              </Button>
            </div>
          </GridItem>
          {supplyList.map((supply, index) => {
            return (
              <GridItem
                xs={12}
                md={12}
                key={index}
                style={{ marginBottom: '10px' }}
              >
                <SupplyEntry
                  supply={supply}
                  updateSupplyList={updateSupplyList}
                  index={index}
                  removeSupply={removeSupply}
                  submitted={submitted}
                />
              </GridItem >
            )
          })}
        </GridContainer>
        <GridContainer>
          <GridItem xs={12}>
            <div
              style={{ display: 'flex', justifyContent: 'right' }}
            >
              <Button
                color="primary"
                onClick={submitForm}
                tabIndex="-100"
              >
                Calculate
              </Button>
            </div>
          </GridItem>
        </GridContainer>
      </GridItem >
      {formData !== '' ?
        <GridItem xs={12} style={{ marginTop: '15px' }}>
          <PDFViewer
            style={{ width: '100%', height: '900px' }}
          >
            <PackagingPDF formInfo={formData} />
          </PDFViewer>
        </GridItem>
        : <></>}

    </GridContainer >
  )
}

export default PackagingForm

/**
 * This component makes a editable supply window allowing a user to edit/create a supply on the
 * packaging calculator form.
 * @param {object} props
 * @prop {Supply} supply supply in the list of supplies
 * @prop {Function} updateSupplyList function used to update a supply in the list of supplies
 * @prop {Number} index of the supply in the list of supplies
 * @prop {Function} removeSupply function to remove this supply from the supply list
 * @prop {Boolean} submitted whether or not the form has been submitted yet
 * @component
 */
const SupplyEntry = ({ supply, updateSupplyList, index, removeSupply, submitted }) => {

  const classes = useStyles();

  const formPerOptions = [
    'Kid',
    'Service',
    'Enviornment'
  ]

  const formLocationOptions = [
    'Ordered',
    'Resource Room',
    'Classroom'
  ]

  const formExtraTag = [
    '#'
  ]

  return (
    <React.Fragment>
      <GridContainer
        style={{
          alignItems: "center",
          border: "1px solid #ddd",
          borderRadius: "3px",
          background: "#fafafa",
          padding: "10px 0px",
        }}
      >
        <GridItem xs={12}>
          <GridContainer>
            <GridItem xs={12} md={5}>
              <TextField
                label='Description'
                variant='outlined'
                value={supply.description}
                style={{ backgroundColor: "#fff", width: '100%', marginBottom: '10px' }}
                onChange={(e) => updateSupplyList(update(supply, { description: { $set: e.target.value } }), index)}
                error={invalidEntry(submitted, supply.description)}
                helperText={helperText(submitted, supply.description)}
              />
            </GridItem>

            <GridItem xs={6} md={4}>
              <TextField
                label='QTY'
                variant='outlined'
                type="number"
                value={supply.QTY}
                style={{ backgroundColor: "#fff", width: '100%', marginBottom: '10px' }}
                onChange={(e) => {
                  updateSupplyList(update(supply, { QTY: { $set: e.target.value } }), index)
                }}
                error={invalidEntry(submitted, supply.QTY)}
                helperText={helperText(submitted, supply.QTY)}
              />
            </GridItem>

            <GridItem xs={6} md={3}>
              <Autocomplete
                style={{ backgroundColor: "#fff", width: '100%', marginBottom: '10px' }}
                renderInput={(params) => (
                  <TextField {...params} label="Per" variant='outlined' />
                )}
                onChange={(e, v) => updateSupplyList(update(supply, { per: { $set: v } }), index)}
                value={supply.per}
                options={formPerOptions}
                autoSelect={true}
                autoComplete={true}
                autoHighlight={true}
                disableClearable
              />
            </GridItem>

          </GridContainer>
        </GridItem>

        <GridItem xs={12}>
          <GridContainer>

            <GridItem xs={12} md={5}>
              <Autocomplete
                style={{ backgroundColor: "#fff", width: '100%', marginBottom: '10px' }}
                renderInput={(params) => (
                  <TextField {...params} label="Location" variant='outlined' />
                )}
                onChange={(e, v) => updateSupplyList(update(supply, { location: { $set: v } }), index)}
                value={supply.location}
                options={formLocationOptions}
                autoSelect={true}
                autoComplete={true}
                autoHighlight={true}
                disableClearable
              />
            </GridItem>

            <GridItem xs={6} md={4}>
              <TextField
                label='Extra'
                variant='outlined'
                value={supply.extra}
                style={{ backgroundColor: "#fff", width: '100%', marginBottom: '10px' }}
                onChange={(e) => {
                  if ((/^\d+$/.test(e.target.value) || e.target.value === '')) {
                    updateSupplyList(update(supply, { extra: { $set: e.target.value } }), index)
                  } else {
                    e.preventDefault()
                  }
                }}
                onKeyPress={(e) => {
                  if (!/[0-9]/.test(e.key)) {
                    e.preventDefault();
                  }
                }}
              />
            </GridItem>

            <GridItem xs={6} md={3}>
              <Autocomplete
                style={{ backgroundColor: "#fff", width: '100%', marginBottom: '10px' }}
                renderInput={(params) => (
                  <TextField {...params} label="Label" variant='outlined' />
                )}
                onChange={(e, v) => updateSupplyList(update(supply, { label: { $set: v } }), index)}
                value={supply.label}
                options={formExtraTag}
                autoSelect={true}
                autoComplete={true}
                autoHighlight={true}
                disableClearable
              />
            </GridItem>
          </GridContainer>
        </GridItem>

        <GridItem xs={12}>
          <GridContainer>
            <GridItem xs={12} md={8}>

              <label style={{ 'color': 'black', fontSize: '18px', marginRight: '20px' }}> Returned?:</label>

              <FormControlLabel
                control={
                  <Radio
                    checked={supply.returned === "yes"}
                    name="returned"
                    value="yes"
                    onChange={(e) => updateSupplyList(update(supply, { returned: { $set: e.target.value } }), index)}
                    classes={{
                      checked: classes.checked,
                      root: classes.checkRoot,
                    }}
                  />
                }
                classes={{
                  label: classes.label,
                  root: classes.labelRoot,
                }}
                label="Yes"
              />

              <FormControlLabel
                control={
                  <Radio
                    checked={supply.returned === "no"}
                    name="returned"
                    value="no"
                    onChange={(e) => updateSupplyList(update(supply, { returned: { $set: e.target.value } }), index)}
                    classes={{
                      checked: classes.checked,
                      root: classes.checkRoot,
                    }}
                  />
                }
                classes={{
                  label: classes.label,
                  root: classes.labelRoot,
                }}
                label="No"
              />
            </GridItem>
            <GridItem xs={12} md={4} style={{ textAlign: "right" }}>
              <div
                style={{ display: 'flex', justifyContent: 'right' }}
              >
                <Button
                  color="danger"
                  tabIndex="-100"
                  onClick={() => removeSupply(index)}
                >
                  Remove
                </Button>
              </div>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
    </React.Fragment>
  )
}