import React, { useState } from "react";
import ReactTable from "components/ReactTable/ReactTable.js";
import { useRecoilValue, useRecoilState } from "recoil";

import Loading from "components/Loading/Loading";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Radio from "@material-ui/core/Radio";

import Edit from "@material-ui/icons/Edit";
import Clear from '@material-ui/icons/Clear';

import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "components/CustomButtons/Button";
import { Autocomplete } from "@material-ui/lab";

import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import {
  linksAtom,
  removeLink,
  createLink,
  editLink,
  getLinks
} from "state/onlineForms";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { TextField } from "@material-ui/core";

import update from "immutability-helper";
import dashboardStyles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";

const useStyles = makeStyles(() => ({
  ...styles,
  ...dashboardStyles,
}));

const ManageLinksPageComp = (props) => {
  const classes = useStyles();
  const emptyLink = {
    link: '',
    name: '',
    groupID: ''
  }
  const [newLink, setNewLink] = useState(emptyLink)
  const [links, setLinks] = useRecoilState(linksAtom)

  const emptyErrors = {
    name: false,
    link: false,
    groupID: false
  }

  const existingGroups = links.map(item => item.groupID).filter((item, i, ar) => ar.indexOf(item) === i)
  const [errors, setErrors] = useState(emptyErrors)
  const [toggleLoading, setToggleLoading] = useState(false)

  const linkOptions = links.map(item => {
    return {
      ...item,
      actions: (
        <React.Fragment>
          <Button
            justIcon
            color="primary"
            onClick={() => { setNewLink(item) }}
            title='Edit'
          >
            <Edit />
          </Button>
          <Button
            justIcon
            color="danger"
            onClick={async () => {
              if (confirm(`Delete Link: ${item.name}?`)) {
                setToggleLoading(true)
                await removeLink({ id: item._id })
                setLinks(getLinks())
                setToggleLoading(false)
              }
            }}
            title='Delete'
          >
            <Clear />
          </Button>
        </React.Fragment>
      )
    }
  })

  const validateForm = () => {
    let valid = true
    let newErrors = emptyErrors
    if (newLink.name.trim() === '') {
      valid = false
      newErrors.name = true
    }

    if (newLink.link.trim() === '') {
      valid = false
      newErrors.link = true
    }

    if (newLink.groupID.trim() === '' || !newLink.groupID) {
      valid = false
      newErrors.groupID = true
    }

    setErrors(newErrors)

    return valid
  }

  const submitHandler = async () => {
    if (validateForm()) {
      setToggleLoading(true)
      let form = {
        name: newLink.name,
        link: newLink.link,
        groupID: newLink.groupID
      }
      if (newLink._id) {
        form.id = newLink._id
        await editLink(form)
      } else {
        await createLink(form)
      }
      setNewLink(emptyLink)
      setErrors(emptyErrors)
      setLinks(getLinks())
      setToggleLoading(false)
    }
  }

  return (
    <React.Fragment>
      {toggleLoading ? <Loading color='blue' /> :
        <GridContainer
          className={classes.formSection}
        >
          <GridItem sm={6} xs={12} style={{ marginTop: '10px' }}>
            <TextField
              fullWidth
              label="Name"
              variant="outlined"
              type="text"
              value={newLink.name}
              error={errors.name}
              helperText={`${newLink.name.length}/75`}
              onChange={(e) => {
                let val = e.target.value.substring(0, 75)
                setNewLink(prevState => {
                  return update(prevState, {
                    name: { $set: val }
                  })
                })
              }}
              required
            />
          </GridItem>
          <GridItem sm={6} xs={12} style={{ marginTop: '10px' }}>
            <Autocomplete
              value={newLink.groupID}
              getOptionLabel={(option) => {
                // Value selected with enter, right from the input
                if (typeof option === 'string') {
                  return option;
                }
                // Regular option
                return option.name;
              }}
              onChange={(event, newValue) => {
                if (typeof newValue === 'string') {
                  setNewLink(prevState => {
                    return update(prevState, {
                      $merge: { groupID: newValue }
                    })
                  })
                }
                if (newValue === null) {
                  setNewLink(prevState => {
                    return update(prevState, {
                      $merge: { groupID: '' }
                    })
                  })
                } else {
                  if (newValue.inputValue) {
                    setNewLink(prevState => {
                      return update(prevState, {
                        $merge: { groupID: newValue.inputValue }
                      })
                    })
                  }
                }
              }}
              filterOptions={(options, params) => {
                let filtered = options

                // Suggest the creation of a new value
                if (params !== '' && params.inputValue !== '') {
                  filtered.push({
                    inputValue: params.inputValue,
                    name: `Add "${params.inputValue}"`,
                  });
                }

                return filtered;
              }}
              selectOnFocus
              clearOnBlur
              options={existingGroups}
              fullWidth
              freeSolo
              renderInput={(params) => (
                <TextField {...params} label="Group" variant="outlined" error={errors.groupID} required />
              )}
            />
          </GridItem>
          <GridItem xs={12} style={{ marginTop: '10px' }}>
            <TextField
              fullWidth
              label="Link"
              variant="outlined"
              type="text"
              value={newLink.link}
              error={errors.link}
              onChange={(e) => {
                setNewLink(prevState => {
                  return update(prevState, {
                    link: { $set: e.target.value }
                  })
                })
              }}
              required
            />
          </GridItem>
          <GridItem xs={12} style={{ textAlign: 'right' }}>
            <Button
              color="primary"
              onClick={() => { submitHandler() }}
            >
              Submit
            </Button>
          </GridItem>
          <GridItem xs={12}>
            <ReactTable
              columns={[
                {
                  Header: "Name",
                  accessor: "name",
                  disableFilters: false,
                  disableSortBy: false,
                },
                {
                  Header: "Group",
                  accessor: "groupID",
                  disableFilters: false,
                  disableSortBy: false,
                },
                {
                  Header: "Link",
                  accessor: 'link',
                  disableFilters: false,
                  disableSortBy: false,
                },
                {
                  Header: "Actions",
                  accessor: "actions",
                  disableFilters: true,
                  disableSortBy: true,
                  style: { maxWidth: '96px' }
                },
              ]}
              data={linkOptions}
            />
          </GridItem>
        </GridContainer>
      }
    </React.Fragment>
  )
}

const ManageLinksPage = (props) => {
  return (
    <React.Suspense fallback={<Loading color='blue' />}>
      <ManageLinksPageComp {...props} />
    </React.Suspense>
  )
}

export default ManageLinksPage