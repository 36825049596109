import React, { useState } from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

import Radio from "@material-ui/core/Radio";
import Button from "components/CustomButtons/Button";

import axios from "axios";
import update from "immutability-helper";
import api from "../../api.config";


import Datetime from "react-datetime";

import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

// Card
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import CardText from "components/Card/CardText";

// Atom stuff
import { useRecoilValue } from "recoil";
import { userAtom } from "state/auth";
import {
  approversListAtom,
  secondApproversListAtom,
  reviewersListAtom,
  poFundCodesAtom,
  poGlCodesAtom,
  poDeptCodesAtom,
} from "state/purchaseOrders";

import dashboardStyles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import Loading from "components/Loading/Loading";
import LoadingBars from "components/Loading/LoadingBars";
import { Prompt } from "react-router-dom";

const useStyles = makeStyles(() => ({
  ...styles,
  ...dashboardStyles,
  formSection: {
    marginBottom: "1rem",
  },
}));

const PurchaseOrderSubmitComponent = (props) => {
  /* useEffect(() => {
    const unloadCallback = (event) => {
      event.preventDefault();
      event.returnValue = "";
      return "";
    };

    if (!buttonLoading) {
      window.addEventListener("beforeunload", unloadCallback);
    } else {
      window.removeEventListener("beforeunload", unloadCallback);
    }

    return () => window.removeEventListener("beforeunload", unloadCallback);
  }, [buttonLoading]); */

  const classes = useStyles();

  const fundAutoCompleteRef = React.createRef();
  const glAutoCompleteRef = React.createRef();
  const deptAutoCompleteRef = React.createRef();
  const eventAutoCompleteRef = React.createRef();

  // Error defaults
  const [errors, setErrors] = useState({
    vendor: {
      hasError: false,
      errorMessage: "",
    },
    purchaseReason: {
      hasError: false,
      errorMessage: "",
    },
    checkPayBy: {
      hasError: false,
      errorMessage: "",
    },
    /* department: {
      hasError: false,
      errorMessage: "",
    }, */
    approver: {
      hasError: false,
      errorMessage: "",
    },
    dateOrdered: {
      hasError: false,
      errorMessage: "",
    },
    reviewer: {
      hasError: false,
      errorMessage: "",
    },
  });

  // Misc data
  const userData = useRecoilValue(userAtom);
  const submittedDate = new Date().toISOString().slice(0, 10);

  const [totalAmount, setTotalAmount] = useState(0);

  const [buttonLoading, setButtonLoading] = useState(false);

  // Codes for dropdowns
  const fundCodes = useRecoilValue(poFundCodesAtom);
  const glCodes = useRecoilValue(poGlCodesAtom);
  const deptCodes = useRecoilValue(poDeptCodesAtom);
  //const eventCodes = useRecoilValue(poEventCodesAtom);

  //console.log("fundCodes", fundCodes);

  // TODO Make this load from an atom most likely since it will need to be async (if pulling from Google Groups)
  /* const approverList = [
    {
      label: "Jon Krake",
      email: "jonk@sagebrush.church",
    },
    {
      label: "Maverick Medina",
      email: "maverickm@sagebrush.church",
    },
    {
      label: "Bob Church",
      email: "bobc@sagebrush.church",
    },
    {
      label: "Nick Young",
      email: "nicky@sagebrush.church",
    },
  ]; */

  const approverList = useRecoilValue(approversListAtom);
  const secondApproversList = useRecoilValue(secondApproversListAtom);
  const reviewerList = useRecoilValue(reviewersListAtom)

  // Radio Button States
  const [recurring, setRecurring] = useState("no");
  const [paymentType, setPaymentType] = useState("payment");
  const [paymentBy, setPaymentBy] = useState("");
  const [checkDeliveryMethod, setCheckDeliveryMethod] = useState("mailed");
  const [alreadyPurchased, setAlreadyPurchased] = useState("no");

  const [checkPayBy, setCheckPayBy] = useState('')

  // Select states
  const [approver, setApprover] = useState("");
  const [secondApprover, setSecondApprover] = useState("");
  const [reviewer, setReviewer] = useState("")

  // Text field states
  const [vendor, setVendor] = useState("");
  const [dateOrdered, setDateOrdered] = useState("");
  //const [department, setDepartment] = useState("");
  const [address, setAddress] = useState("");
  const [purchaseReason, setPurchaseReason] = useState("");

  //const departmentList = useRecoilValue(departmentListAtom);

  // Line items state
  const [lineItems, setLineItems] = useState([
    {
      //accountCode: "",
      fundCode: null,
      glCode: null,
      deptCode: null,
      eventCode: null,
      description: "",
      amount: "",
    },
  ]);

  // Radio button change handlers
  const recurringChangeHandler = (e) => {
    setRecurring(e.target.value);
  };

  const paymentTypeChangeHandler = (e) => {
    setPaymentType(e.target.value);
  };

  const paymentByChangeHandler = (e) => {
    setPaymentBy(e.target.value);
  };

  const checkDeliveryMethodChangeHandler = (e) => {
    setCheckDeliveryMethod(e.target.value);
  };

  const alreadyPurchasedChangeHandler = (e) => {
    setAlreadyPurchased(e.target.value);
  };

  const reviewerChangeHandler = (e) => {
    setReviewer(e)
  }

  // Select change handlers
  const approverChangeHandler = (event) => {
    setApprover(event);
  };

  const secondApproverChangeHandler = (event) => {
    setSecondApprover(event);
  };

  // Text field change handlers
  const vendorChangeHandler = (e) => {
    let val = e.target.value.slice(0, 100)
    setVendor(val);
  };

  const dateOrderedChangeHandler = (e) => {
    if (typeof e === "object") {
      setDateOrdered(e.format().slice(0, 10));
    } else {
      setDateOrdered(e);
    }
  };

  const checkPayByChangeHandler = (e) => {
    if (typeof e === "object") {
      setCheckPayBy(e.format().slice(0, 10));
    } else {
      setCheckPayBy(e);
    }
  };

  /*  const departmentChangeHandler = (e) => {
    setDepartment(e.target.value);
  }; */

  const addressChangeHandler = (e) => {
    setAddress(e.target.value);
  };

  const fundCodeChangeHandler = (index, value) => {
    // Value is the object from the option so it will look like this:
    // { id: 3, code: "102", title: "Worship" }

    setLineItems((prevState) => {
      return update(prevState, {
        [index]: { $merge: { fundCode: value } },
      });
    });
  };

  const glCodeChangeHandler = (index, value) => {
    setLineItems((prevState) => {
      return update(prevState, {
        [index]: { $merge: { glCode: value } },
      });
    });
  };

  const deptCodeChangeHandler = (index, value) => {
    setLineItems((prevState) => {
      return update(prevState, {
        [index]: { $merge: { deptCode: value } },
      });
    });
  };

  /*const eventCodeChangeHandler = (index, value) => {
    setLineItems((prevState) => {
      return update(prevState, {
        [index]: { $merge: { eventCode: value } },
      });
    });
  };*/

  const lineItemAccountCodeChangeHandler = (index, value) => {
    setLineItems((prevState) => {
      return update(prevState, {
        [index]: { $merge: { accountCode: value } },
      });
    });
  };

  const lineItemDescriptionChangeHandler = (index, value) => {
    let newVal = value.replaceAll('\\', '').replaceAll('\'', '').replaceAll('\"', '')
    setLineItems((prevState) => {
      return update(prevState, {
        [index]: { $merge: { description: newVal } },
      });
    });
  };

  const lineItemAmountChangeHandler = (e, index, value) => {
    e.preventDefault();
    setLineItems((prevState) => {
      return update(prevState, {
        [index]: { $merge: { amount: value } },
      });
    });

    //setTimeout(updateTotalAmount, 3000);
    //updateTotalAmount();
  };

  const purchaseReasonChangeHandler = (e) => {
    let val = e.target.value.slice(0, 100)
    setPurchaseReason(val);
  };

  // Click handlers
  const insertLineItem = (index) => {
    if (lineItems.length >= 25) {
      alert("You can't add any more line items to this PO.");
      return;
    }
    setLineItems((prevState) => {
      //console.log("prevState", prevState);
      //console.log("fundCode", prevState[prevState.length - 1].fundCode);

      let fc = prevState[prevState.length - 1].fundCode;
      let gc = prevState[prevState.length - 1].glCode;
      let dc = prevState[prevState.length - 1].deptCode;
      let ec = null;

      let newState = update(prevState, {
        $push: [
          {
            fundCode: fc,
            glCode: gc,
            deptCode: dc,
            eventCode: ec,
            description: "",
            amount: "",
          },
        ],
      });

      //console.log("newState", newState);

      return newState;
    });

    /* setLineItems((prevState) => {
      return update(prevState, {
        $splice: [
          [
            index + 1,
            0,
            {
              accountCode: prevState[index].accountCode,
              description: "",
              amount: "",
            },
          ],
        ],
      });
    }); */
  };

  const removeLineItem = (index) => {
    if (lineItems.length > 1) {
      setLineItems((prevState) => {
        return update(prevState, {
          $splice: [[index, 1]],
        });
      });
    } else {
      setLineItems([
        {
          fundCode: null,
          glCode: null,
          deptCode: null,
          eventCode: null,
          description: "",
          amount: "",
        },
      ])
    }
  };

  const getTotalAmount = () => {
    let tempAmount = 0;

    lineItems.map((item) => {
      if (item.amount) {
        tempAmount += parseFloat(item.amount);
      }
    });

    return Math.round(tempAmount * 100 + Number.EPSILON) / 100;
  };

  // Submit handler
  const submitHandler = async (e, status) => {
    let postData = {
      vendor: vendor,
      paymentBy: paymentBy,
      recurring: recurring,
      paymentType: paymentType,
      lineItems: JSON.stringify(lineItems),
      //dept: department,
      submittedBy: userData.email,
      submittedDate: submittedDate,
      submittedTo: approver,
      purchaseReason: purchaseReason,
      total: getTotalAmount(),
      status: status,
    };

    if (paymentBy === "check") {
      postData.address = address;
      postData.checkToBe = checkDeliveryMethod;
      postData.payCheckBy = checkPayBy
    }

    if (alreadyPurchased === "yes") {
      postData.dateOrdered = dateOrdered;
    }

    if (secondApprover && getTotalAmount() >= 1000) {
      postData.submittedTo2 = secondApprover;
    }

    if (reviewer) {
      postData.reviewer = reviewer
    }

    // check line items
    //console.log("lineItems", lineItems);

    let lineItemErrors = false;
    lineItems.forEach((item) => {
      //console.log("line item", item);
      if (
        !item.fundCode ||
        !item.glCode ||
        !item.deptCode ||
        item.description === "" ||
        item.amount === ""
      ) {
        lineItemErrors = true;
      }
    });

    if (lineItemErrors) {
      alert(
        "Each line item is required to have all data entered. Please update or remove your line item information and resubmit when complete."
      );
    } else if (paymentBy !== "check" && paymentBy !== 'creditCard') {
      alert("You must select a payment method.")
    } else if (
      reviewer === userData.email
    ) {
      alert("You cannot set yourself as an reviewer.");
    } else if (reviewer === "" || reviewer === "Choose Reviewer") {
      alert("You must select an reviewer");
    } else if (
      approver === userData.email ||
      secondApprover === userData.email
    ) {
      alert("You cannot set yourself as an approver.");
    } else if (approver === "" || approver === "Choose Approver") {
      alert("You must select an approver");
    } else if (
      getTotalAmount() >= 1000 &&
      (secondApprover === "" || secondApprover === "Choose Approver")
    ) {
      alert("You must select a second approver since your PO is over $1,000");
    } else if (getTotalAmount() >= 1000 && approver === secondApprover) {
      alert("You can't select the same approver twice.");
    } else if (validateForm()) {
      setButtonLoading(true);
      await axios
        .post(`${api.path}/digitalpo_api/v1/createpo`, postData)
        .then((res) => {
          if (status === 'pending') {
            location.href = "/portal/purchase-orders/submitted-by-me?tab=2";
          } else if (status === 'review') {
            location.href = "/portal/purchase-orders/submitted-by-me?tab=1";
          } else {
            location.href = "/portal/purchase-orders/submitted-by-me?tab=0";
          }
        });
    } else {
      alert(
        "There are errors in your submission. Please fix the errors shown and resubmit."
      );
    }
  };

  const validateForm = () => {
    let isValid = true;

    // validate VENDOR field
    if (vendor.trim() === "") {
      setErrors((prevState) => {
        return update(prevState, {
          vendor: {
            $merge: {
              hasError: true,
              errorMessage: "This field cannot be left blank",
            },
          },
        });
      });

      isValid = false;
    }

    // validate PURCHASE REASON field
    if (purchaseReason.trim() === "") {
      setErrors((prevState) => {
        return update(prevState, {
          purchaseReason: {
            $merge: {
              hasError: true,
              errorMessage: "This field cannot be left blank",
            },
          },
        });
      });

      isValid = false;
    }

    // validate DATE PURCHASED field
    if (alreadyPurchased === "yes" && dateOrdered.trim() === "") {
      setErrors((prevState) => {
        return update(prevState, {
          dateOrdered: {
            $merge: {
              hasError: true,
              errorMessage: "This field cannot be left blank",
            },
          },
        });
      });

      isValid = false;
    }

    if (paymentBy === 'check' && checkPayBy.trim() === "") {
      setErrors((prevState) => {
        return update(prevState, {
          checkPayBy: {
            $merge: {
              hasError: true,
              errorMessage: "This field cannot be left blank",
            },
          },
        });
      });

      isValid = false;
    }

    // Validate DEPARTMENT field
    /* if (department.trim() === "") {
      setErrors((prevState) => {
        return update(prevState, {
          department: {
            $merge: {
              hasError: true,
              errorMessage: "This field cannot be left blank",
            },
          },
        });
      });

      isValid = false;
    } */

    // validate APPROVER field
    /* if (approver || approver === userData.email) {
      setErrors((prevState) => {
        return update(prevState, {
          approver: {
            $merge: {
              hasError: true,
              errorMessage: "Invalid selection",
            },
          },
        });
      });

      isValid = false;
    }  */

    return isValid;
  };

  return (
    <GridContainer>
      {!buttonLoading && (
        <Prompt
          when={true}
          message={"If you proceed, you will lose any unsaved changes."}
        />
      )}

      <GridItem xs={12} md={6}>
        <Card>
          <CardHeader color="info" text>
            <CardText color="info">
              <h4 className={classes.cardTitleWhite}>
                Create New Purchase Order
              </h4>
            </CardText>
          </CardHeader>

          <CardBody>
            <GridContainer className={classes.formSection}>
              <GridItem xs={12} lg={12}>
                <TextField
                  required
                  label="Vendor"
                  variant="outlined"
                  fullWidth
                  value={vendor}
                  onChange={vendorChangeHandler}
                  helperText={errors.vendor.errorMessage || `${vendor.length}/100`}
                  error={errors.vendor.hasError || false}
                />
              </GridItem>
            </GridContainer>

            <GridContainer>
              <GridItem xs={12}>
                <GridContainer
                  className={classes.formSection}
                  style={{ alignItems: "center" }}
                >
                  <GridItem xs={12} md={4}>
                    <p>Already Purchased?</p>
                  </GridItem>

                  <GridItem xs={12} md={4}>
                    <FormControlLabel
                      control={
                        <Radio
                          checked={alreadyPurchased === "yes"}
                          name="alreadyPurchased"
                          value="yes"
                          onChange={alreadyPurchasedChangeHandler}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot,
                          }}
                        />
                      }
                      classes={{
                        label: classes.label,
                        root: classes.labelRoot,
                      }}
                      label="Yes"
                    />

                    <FormControlLabel
                      control={
                        <Radio
                          checked={alreadyPurchased === "no"}
                          name="alreadyPurchased"
                          value="no"
                          onChange={alreadyPurchasedChangeHandler}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot,
                          }}
                        />
                      }
                      classes={{
                        label: classes.label,
                        root: classes.labelRoot,
                      }}
                      label="No"
                    />
                  </GridItem>

                  <GridItem xs={12} md={4}>
                    {alreadyPurchased === "yes" && (
                      <div>
                        <span
                          style={{
                            color: errors.dateOrdered.hasError ? "#f00" : null,
                          }}
                        >
                          Date Purchased?
                        </span>
                        <Datetime
                          dateFormat={`YYYY-MM-DD`}
                          timeFormat={false}
                          inputProps={{ placeholder: "Select Date" }}
                          onChange={dateOrderedChangeHandler}
                          //ref={datePurchasedRef}
                          value={dateOrdered}
                        />
                        {errors.dateOrdered.hasError && (
                          <span style={{ color: "#f00" }}>
                            {errors.dateOrdered.errorMessage}
                          </span>
                        )}
                      </div>
                    )}
                  </GridItem>
                </GridContainer>

                <GridContainer className={classes.formSection}>
                  <GridItem xs={12} md={4} style={{ alignItems: "center" }}>
                    <p>Type</p>
                  </GridItem>

                  <GridItem xs={12} md={8}>
                    <FormControlLabel
                      control={
                        <Radio
                          checked={paymentType === "payment"}
                          name="paymentType"
                          value="payment"
                          onChange={paymentTypeChangeHandler}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot,
                          }}
                        />
                      }
                      classes={{
                        label: classes.label,
                        root: classes.labelRoot,
                      }}
                      label="Payment"
                    />

                    <FormControlLabel
                      control={
                        <Radio
                          checked={paymentType === "reimbursement"}
                          name="paymentType"
                          value="reimbursement"
                          onChange={paymentTypeChangeHandler}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot,
                          }}
                        />
                      }
                      classes={{
                        label: classes.label,
                        root: classes.labelRoot,
                      }}
                      label="Reimbursement"
                    />
                  </GridItem>
                </GridContainer>

                <GridContainer className={classes.formSection}>
                  <GridItem xs={12} md={4} style={{ alignItems: "center" }}>
                    <p>Payment By</p>
                  </GridItem>

                  <GridItem xs={12} md={4}>
                    <FormControlLabel
                      control={
                        <Radio
                          checked={paymentBy === "creditCard"}
                          name="paymentBy"
                          value="creditCard"
                          onChange={paymentByChangeHandler}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot,
                          }}
                        />
                      }
                      classes={{
                        label: classes.label,
                        root: classes.labelRoot,
                      }}
                      label="Credit Card"
                    />
                    <FormControlLabel
                      control={
                        <Radio
                          checked={paymentBy === "check"}
                          name="paymentBy"
                          value="check"
                          onChange={paymentByChangeHandler}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot,
                          }}
                        />
                      }
                      classes={{
                        label: classes.label,
                        root: classes.labelRoot,
                      }}
                      label="Check"
                    />
                  </GridItem>
                </GridContainer>

                {paymentBy === "check" && (
                  <GridContainer className={classes.formSection}
                    style={{
                      alignItems: "center",
                      border: "1px solid #ddd",
                      borderRadius: "3px",
                      background: "#fafafa",
                      paddingTop: "1rem",
                    }}
                  >
                    <GridItem xs={12} md={3}>
                      <p>Check will be</p>
                    </GridItem>
                    <GridItem xs={12} md={5} style={{ display: 'flex' }}>
                      <FormControlLabel
                        control={
                          <Radio
                            checked={checkDeliveryMethod === "mailed"}
                            name="checkDeliveryMethod"
                            value="mailed"
                            onChange={checkDeliveryMethodChangeHandler}
                            classes={{
                              checked: classes.checked,
                              root: classes.checkRoot,
                            }}
                          />
                        }
                        classes={{
                          label: classes.label,
                          root: classes.labelRoot,
                        }}
                        label="Mailed"
                      />

                      <FormControlLabel
                        control={
                          <Radio
                            checked={checkDeliveryMethod === "givenToSubmitter"}
                            name="checkDeliveryMethod"
                            value="givenToSubmitter"
                            onChange={checkDeliveryMethodChangeHandler}
                            classes={{
                              checked: classes.checked,
                              root: classes.checkRoot,
                            }}
                          />
                        }
                        classes={{
                          label: classes.label,
                          root: classes.labelRoot,
                        }}
                        label="Given to Submitter"
                      />
                    </GridItem>

                    <GridItem xs={12} md={4} style={{ marginBottom: '1rem' }}>
                      <div>
                        <span
                          style={{
                            color: errors.checkPayBy.hasError ? "#f00" : null,
                          }}
                        >
                          Need Check By?
                        </span>
                        <Datetime
                          dateFormat={`YYYY-MM-DD`}
                          timeFormat={false}
                          inputProps={{ placeholder: "Select Date" }}
                          onChange={checkPayByChangeHandler}
                          value={checkPayBy}
                        />
                        {errors.checkPayBy.hasError && (
                          <span style={{ color: "#f00" }}>
                            {errors.checkPayBy.errorMessage}
                          </span>
                        )}
                      </div>

                    </GridItem>

                    <GridItem xs={12} style={{ marginBottom: '1rem' }}>
                      <TextField
                        label="Make check payable to"
                        variant="outlined"
                        fullWidth
                        multiline
                        rows={5}
                        value={address}
                        onChange={addressChangeHandler}
                      />
                    </GridItem>

                  </GridContainer>
                )}

                <GridContainer className={classes.formSection}>
                  {/* <GridItem xs={12} md={4} style={{ marginBottom: "15px" }}>
                <FormControl fullWidth className={classes.selectFormControl}>
                  <InputLabel
                    htmlFor="simple-select"
                    className={classes.selectLabel}
                  >
                    Choose Department
                  </InputLabel>
                  <Select
                    MenuProps={{
                      className: classes.selectMenu,
                    }}
                    classes={{
                      select: classes.select,
                    }}
                    value={department}
                    onChange={departmentChangeHandler}
                  >
                    <MenuItem
                      disabled
                      classes={{
                        root: classes.selectMenuItem,
                      }}
                    >
                      Choose Department
                    </MenuItem>

                    {departmentList.map((item) => {
                      return (
                        <MenuItem
                          key={item}
                          classes={{
                            root: classes.selectMenuItem,
                          }}
                          value={item}
                        >
                          {item}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </GridItem> */}

                  <GridItem xs={12} md={12}>
                    <TextField
                      label="Reason for Purchase"
                      variant="outlined"
                      fullWidth
                      value={purchaseReason}
                      onChange={purchaseReasonChangeHandler}
                      required
                      error={errors.purchaseReason.hasError}
                      helperText={errors.purchaseReason.errorMessage || `${purchaseReason.length}/100`}
                    />
                  </GridItem>
                </GridContainer>

                <GridContainer
                  className={classes.formSection}
                  style={{
                    borderRadius: "3px",
                    padding: "1rem",
                  }}
                >
                  {lineItems.map((item, index) => {
                    return (
                      <React.Fragment
                        key={index}>
                        <GridContainer
                          key={index}
                          className={classes.formSection}
                          style={{
                            alignItems: "center",
                            border: "1px solid #ddd",
                            borderRadius: "3px",
                            background: "#fafafa",
                            padding: "15px 0",
                          }}
                        >
                          <GridItem xs={9} md={4}>
                            <Autocomplete
                              ref={fundAutoCompleteRef}
                              disablePortal
                              options={fundCodes}
                              getOptionLabel={(option) =>
                                `${option.code} - ${option.title}` || ""
                              }
                              sx={{ width: 300 }}
                              renderInput={(params) => (
                                <TextField {...params} label="Fund Code" />
                              )}
                              onChange={(e, v) =>
                                fundCodeChangeHandler(index, v)
                              }
                              autoSelect={true}
                              autoComplete={true}
                              autoHighlight={true}
                              value={item.fundCode}
                              getOptionSelected={(selected) => item.fundCode.id === selected.id}
                            />

                            <Autocomplete
                              ref={glAutoCompleteRef}
                              disablePortal
                              options={glCodes}
                              getOptionLabel={(option) =>
                                `${option.code} - ${option.title}` || ""
                              }
                              sx={{ width: 300 }}
                              renderInput={(params) => (
                                <TextField {...params} label="Gl Code" />
                              )}
                              onChange={(e, v) => glCodeChangeHandler(index, v)}
                              autoSelect={true}
                              autoComplete={true}
                              autoHighlight={true}
                              value={item.glCode}
                              getOptionSelected={(selected) => item.glCode.id === selected.id}
                            />

                            <Autocomplete
                              ref={deptAutoCompleteRef}
                              disablePortal
                              options={deptCodes}
                              getOptionLabel={(option) =>
                                `${option.code} - ${option.title}` || ""
                              }
                              sx={{ width: 300 }}
                              renderInput={(params) => (
                                <TextField {...params} label="Dept Code" />
                              )}
                              onChange={(e, v) =>
                                deptCodeChangeHandler(index, v)
                              }
                              autoSelect={true}
                              autoComplete={true}
                              autoHighlight={true}
                              value={item.deptCode}
                              getOptionSelected={(selected) => item.deptCode.id === selected.id}
                            />
                            {/**
                            <Autocomplete
                              ref={eventAutoCompleteRef}
                              disablePortal
                              options={eventCodes}
                              getOptionLabel={(option) =>
                                `${option.code} - ${option.title}` || ""
                              }
                              sx={{ width: 300 }}
                              renderInput={(params) => (
                                <TextField {...params} label="Event Code" />
                              )}
                              onChange={(e, v) =>
                                eventCodeChangeHandler(index, v)
                              }
                              autoSelect={true}
                              autoComplete={true}
                              autoHighlight={true}
                              value={item.eventCode}
                              getOptionSelected={(selected) => item.eventCode.id === selected.id}
                            />
                            */}

                            {/*  <AutoCompleteField
                          ref={fundAutoCompleteRef}
                          options={fundCodes}
                          label="Fund Code"
                        /> */}

                            {/* <AutoCompleteField
                          ref={glAutoCompleteRef}
                          options={glCodes}
                          label="Gl Code"
                        />

                        <AutoCompleteField
                          ref={eventAutoCompleteRef}
                          options={eventCodes}
                          label="Event Code"
                        /> */}
                          </GridItem>
                          <GridItem xs={12} md={8}>
                            <TextField
                              label=""
                              variant="outlined"
                              fullWidth
                              placeholder={`Line Item Description`}
                              value={item.description}
                              multiline
                              rows={4}
                              onChange={(e) => {
                                lineItemDescriptionChangeHandler(
                                  index,
                                  e.target.value
                                );
                              }}
                              style={{
                                margin: "15px 0",
                                backgroundColor: "#fff",
                              }}
                            />

                            <GridContainer style={{ alignItems: "center" }}>
                              <GridItem md={8}>
                                <TextField
                                  placeholder={`$ Amount`}
                                  value={item.amount}
                                  variant="outlined"
                                  onChange={(e) => {
                                    lineItemAmountChangeHandler(
                                      e,
                                      index,
                                      e.target.value
                                    );
                                  }}
                                  onWheel={(e) => e.target.blur()}
                                  type="number"
                                  inputProps={{
                                    step: 0.01,
                                  }}
                                  style={{ backgroundColor: "#fff" }}
                                />
                              </GridItem>
                              <GridItem md={4} style={{ textAlign: "right" }}>
                                <Button
                                  color="danger"
                                  tabIndex="-100"
                                  onClick={() => removeLineItem(index)}
                                >
                                  Remove
                                </Button>
                              </GridItem>
                            </GridContainer>
                          </GridItem>
                          {/* <GridItem xs={12} md={3}> */}
                          {/* <FormControl fullWidth> */}
                          {/* <TextField
                          placeholder={`$ Amount`}
                          value={item.amount}
                          variant="outlined"
                          onChange={(e) => {
                            lineItemAmountChangeHandler(
                              e,
                              index,
                              e.target.value
                            );
                          }}
                          onWheel={(e) => e.target.blur()}
                          type="number"
                          inputProps={{
                            step: 0.01,
                          }}
                          style={{ width: "100%" }}
                        /> */}

                          {/* </FormControl> */}
                          {/* </GridItem> */}
                          {/* <GridItem xs={3} md={1}>
                        <Button
                          justIcon
                          color="success"
                          onClick={() => insertLineItem(index)}
                          simple
                        >
                          <AddCircle />
                        </Button>

                        <Button
                          justIcon
                          color="danger"
                          onClick={() => removeLineItem(index)}
                          simple
                        >
                          <RemoveCircle />
                        </Button>
                      </GridItem> */}
                        </GridContainer>
                      </React.Fragment>
                    );
                  })}
                </GridContainer>

                <GridContainer
                  className={classes.formSection}
                  style={{ marginTop: "-2rem" }}
                >
                  <GridItem xs={9} md={9}>
                    <Button
                      color="primary"
                      onClick={() => insertLineItem()}
                      tabIndex="-100"
                    >
                      Add Line Item
                    </Button>
                  </GridItem>
                  {/* <GridItem xs={12} md={6}></GridItem> */}
                  <GridItem xs={12} md={3}>
                    <p
                      style={{
                        padding: "1rem",
                        fontSize: "1rem",
                        textAlign: "left",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <strong>Total:</strong>
                      <span>${getTotalAmount().toFixed(2)}</span>
                    </p>
                  </GridItem>
                </GridContainer>

                {/* <GridContainer className={classes.formSection}> */}
                {/* <GridItem xs={12} md={6} style={{ marginBottom: "15px" }}>
                <FormControl fullWidth className={classes.selectFormControl}>
                  <InputLabel
                    htmlFor="simple-select"
                    className={classes.selectLabel}
                  >
                    Choose Department
                  </InputLabel>
                  <Select
                    MenuProps={{
                      className: classes.selectMenu,
                    }}
                    classes={{
                      select: classes.select,
                    }}
                    value={department}
                    onChange={departmentChangeHandler}
                  >
                    <MenuItem
                      disabled
                      classes={{
                        root: classes.selectMenuItem,
                      }}
                    >
                      Choose Department
                    </MenuItem>

                    {departmentList.map((item) => {
                      return (
                        <MenuItem
                          key={item}
                          classes={{
                            root: classes.selectMenuItem,
                          }}
                          value={item}
                        >
                          {item}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </GridItem> */}
                {/* <GridItem xs={12} md={3} style={{ marginBottom: "15px" }}>
                <TextField
                  label="Submitted By"
                  variant="outlined"
                  disabled
                  value={userData.email}
                  fullWidth
                />
              </GridItem>
              <GridItem xs={12} md={3}>
                <TextField
                  label="Date Submitted"
                  variant="outlined"
                  disabled
                  value={submittedDate}
                  fullWidth
                />
              </GridItem> */}
                {/*  </GridContainer> */}
                <GridContainer className={classes.formSection}>
                  <GridItem xs={12} md={6}>
                    <Autocomplete
                      disablePortal
                      options={reviewerList}
                      getOptionLabel={(option) =>
                        `${option.name}` || ""
                      }
                      sx={{ width: 300 }}
                      renderInput={(params) => (
                        <TextField {...params} label="Choose Reviewer" />
                      )}
                      onChange={(e, v) => reviewerChangeHandler(v ? v.email : v)}
                      autoSelect={true}
                      autoComplete={true}
                      autoHighlight={true}
                      required
                    />
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={12} md={6} className={classes.formSection}>
                    <Autocomplete
                      disablePortal
                      options={approverList}
                      getOptionLabel={(option) =>
                        `${option.name}` || ""
                      }
                      sx={{ width: 300 }}
                      renderInput={(params) => (
                        <TextField {...params} label="Choose Approver" />
                      )}
                      onChange={(e, v) => approverChangeHandler(v ? v.email : v)}
                      autoSelect={true}
                      autoComplete={true}
                      autoHighlight={true}
                      required
                    />
                  </GridItem>

                  {(getTotalAmount() >= 1000) && (
                    <GridItem xs={12} md={6} className={classes.formSection}>
                      <Autocomplete
                        disablePortal
                        options={secondApproversList}
                        getOptionLabel={(option) =>
                          `${option.name}` || ""
                        }
                        sx={{ width: 300 }}
                        renderInput={(params) => (
                          <TextField {...params} label="Choose Approver" />
                        )}
                        onChange={(e, v) => secondApproverChangeHandler(v ? v.email : v)}
                        autoSelect={true}
                        autoComplete={true}
                        autoHighlight={true}
                        required
                      />
                    </GridItem>
                  )}
                </GridContainer>

                <GridContainer className={classes.formSection}>
                  <GridItem xs={12} md={6} style={{ marginBottom: "15px" }}>
                    <TextField
                      label="Submitted By"
                      variant="outlined"
                      disabled
                      value={userData.email}
                      fullWidth
                    />
                  </GridItem>
                  <GridItem xs={12} md={6}>
                    <TextField
                      label="Date Submitted"
                      variant="outlined"
                      disabled
                      value={submittedDate}
                      fullWidth
                    />
                  </GridItem>
                </GridContainer>

                <GridContainer className={classes.formSection}>
                  <GridItem xs={12}>
                    <Button
                      color="primary"
                      onClick={(e) => { reviewer === 'N/A' || reviewer === '' ? submitHandler(e, "pending") : submitHandler(e, "review") }}
                      disabled={buttonLoading}
                    >
                      {buttonLoading ? <LoadingBars /> : `Submit for Approval`}
                    </Button>
                    <Button
                      onClick={(e) => submitHandler(e, "draft")}
                      disabled={buttonLoading}
                    >
                      {buttonLoading ? <LoadingBars /> : `Save as Draft`}
                    </Button>
                  </GridItem>
                </GridContainer>
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export const PurchaseOrderSubmit = (props) => {
  return (
    <React.Suspense fallback={<Loading color="blue" />}>
      <PurchaseOrderSubmitComponent {...props} />
    </React.Suspense>
  );
};

export default PurchaseOrderSubmit;
