import React, { useEffect, useMemo, useState } from 'react'
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import axios from "axios";
import {
  groupsAtom,
  loggedInAtom,
  idTokenAtom,
  userAtom,
  jwtAtom,
  guestEmailJWT,
} from "../../state/auth";
import { publishedPagesAtom } from "../../state/trainingModule";
import { useRecoilState } from "recoil";
import Loading from "components/Loading/Loading";
import api from "../../api.config";
import { encryptData } from "assets/jss/EncriptDecrypt/EncryptDecrypt";

import Cookies from "js-cookie";

import { sortPages } from "../../state/trainingModule";
import Button from "components/CustomButtons/Button.js";
import update from "immutability-helper";
import ReactDOM from "react-dom";
import ReactQuill from "react-quill-new";
import "assets/css/quill.snow.css";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText";
import {
  AttachFile,
  Close,
  Flag,
  MergeType,
  DoubleArrow,
  People,
  MoreVert,
  Add,
} from "@material-ui/icons";
import {
  newTicketMessage,
} from "state/ticketSystem";
import { viewTicketAtom } from 'state/ticketSystem';
import { getTicket } from 'state/ticketSystem';
import { TextField } from '@material-ui/core';
import blankProfile from 'assets/img/blankProfile.png'
import DOMPurify from "dompurify";

const ViewTicketPublicComp = (props) => {

  const userType = props.match.params.userType
  const id = props.match.params.id

  const [isLoggedIn, setIsLoggedIn] = useRecoilState(loggedInAtom);
  const [idToken, setIdToken] = useRecoilState(idTokenAtom);
  const [user, setUser] = useRecoilState(userAtom);
  const [groups, setGroups] = useRecoilState(groupsAtom);
  const [jwt, setJwt] = useRecoilState(jwtAtom);
  const [publishedPages, setPublishedPages] = useRecoilState(publishedPagesAtom)

  const checkIfLoggedIn = async () => {
    await axios.post(`${api.path}/staff_portal_api/v1/getUserData`)
      .then(async (res) => {
        if (res.data) {
          res.data.jwt =
            setUser(res.data.profile);
          setJwt(Cookies.get(`StaffPortalJWT`));
          setGroups(res.data.groups);

          // Set session storage
          sessionStorage.setItem("userData", encryptData(res.data.profile));
          sessionStorage.setItem("loggedIn", true);
          sessionStorage.setItem("groups", encryptData(res.data.groups));
          let sorted = await res.data.publishedPages.sort(sortPages)
          setPublishedPages(sorted)
          sessionStorage.setItem('publishedPagesDB', encryptData(sorted))
          return
        }
      });
    if (jwt) {
      setIsLoggedIn(true);
      window.location = `/portal/ticketSystem/view/${userType}/${id}`
    } else if (userType !== 'user') {
      window.location = `/portal/ticketSystem/view/${userType}/${id}`
    }
  }

  const [guestLoginPopup, setGuestLoginPopup] = useState(false)
  const [guestEmail, setGuestEmail] = useRecoilState(guestEmailJWT)
  const [ticket, setTicket] = useRecoilState(viewTicketAtom({ id: id, requestAs: userType }))

  const handleSetGuestEmail = (email) => {
    if (email.toLowerCase().includes('@sagebrush.')) {
      if (confirm(`${email} is a sagebrush email, would you like to login as Staff?`)) {
        window.location = `/portal/ticketSystem/view/${userType}/${id}`
      }
    } else {
      sessionStorage.setItem('GuestEmail', email)
      setGuestEmail(email)
    }
  }

  useEffect(() => {
    if (Cookies.get(`StaffPortalJWT`)) {
      checkIfLoggedIn()
    } else if (userType !== 'user') {
      window.location = `/portal/ticketSystem/view/${userType}/${id}`
    } else if (!ticket) {
      setGuestLoginPopup(true)
    }
  }, [])

  useEffect(async () => {
    if (guestEmail) {
      let t = await getTicket({ id: id, requestAs: userType })
      setTicket(t)
      if (t) {
        setGuestLoginPopup(false)
      } else {
        setGuestLoginPopup(true)
        alert(`${guestEmail} does not have access to view this ticket.`)
        sessionStorage.removeItem('GuestEmail')
        setGuestEmail('')
      }
    }
  }, [guestEmail])

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "indent",
    "link",
    "color",
  ];

  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          [{ header: [2, 3, 4, false] }],
          ["bold", "italic", "underline", "blockquote"],
          [{ color: [] }],
          [
            { list: "ordered" },
            { indent: "-1" },
            { indent: "+1" },
          ],
          ["link"],
        ],
      },
      clipboard: {
        matchVisual: true,
      },
    })
  );

  const clientReply = [
    {
      label: 'Client Reply',
      value: 'client'
    }
  ]

  const maxUploadSize = 200000000 // 200MB
  const [text, setText] = useState('');
  const [files, setFiles] = useState([])
  const [replyType, setReplyType] = useState(clientReply[0])
  const totalUploadSize = files.map(i => i.size).reduce((a, b) => a + b, 0)
  const [loading, setLoading] = useState(false)
  const [messageLoading, setMessageLoading] = useState(false)

  const handleTicketChangeUpdate = async () => {
    let t = await getTicket({ id: id, requestAs: userType })
    setTicket(t)
    setText('')
    setFiles([])
    setLoading(false)
    setMessageLoading(false)
  }

  const submitTicketMessage = async () => {
    setMessageLoading(true)
    let formData = new FormData()
    let attachments = null
    formData.append('msgType', replyType.value)
    formData.append('html', text)
    if (files.length > 0 && (replyType.value === 'reply' || replyType.value === 'client')) {
      attachments = files
    }
    formData.append('ticketID', ticket.id)
    await newTicketMessage(formData, attachments)
    handleTicketChangeUpdate()
  }

  const styles = {
    topbarButtons: { height: '25px', width: '25px' },
    ticketInfoItem: { display: 'flex', alignItems: 'center', marginBottom: '5px', flexWrap: 'wrap' },
    clientMessage: { display: 'flex', alignItems: 'center', backgroundColor: '#feddbc', borderRadius: '6px 6px 0px 0px', justifyContent: 'space-between' },
    replyMessage: { display: 'flex', alignItems: 'center', backgroundColor: '#6babd5', borderRadius: '6px 6px 0px 0px', justifyContent: 'space-between' },
    internalMessage: { display: 'flex', alignItems: 'center', backgroundColor: '#c5e2f4', borderRadius: '6px 6px 0px 0px', justifyContent: 'space-between' }
  }

  return (
    <React.Fragment>
      <div style={{ height: '100vh', overflow: 'auto', padding: '20px' }}>
        {loading ? <GridContainer><GridItem xs={12}><Loading color="blue" /></GridItem></GridContainer> : <>
          <GridContainer style={{ display: 'flex', justifyContent: 'center' }}>
            <GridItem xs={12} style={{ maxWidth: '1100px', width: 'calc(100%-40px)' }}>
              <GuestLoginPopup
                guestLoginPopup={guestLoginPopup}
                setGuestLoginPopup={setGuestLoginPopup}
                userType={userType}
                id={id}
                setGuestEmail={handleSetGuestEmail}
              />
              {ticket && (
                <>
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '10px', flexWrap: 'wrap' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <p style={{ fontWeight: 600, fontSize: '28px', margin: '0px 10px' }}>Ticket #{ticket.number}</p>
                    </div>
                  </div>
                  <div>
                    <Card style={{ margin: '0px', marginBottom: '10px' }}>
                      <CardBody>
                        <GridContainer
                        >
                          <GridItem xs={12} sm={6} style={styles.ticketInfoItem}>
                            <span style={{ color: '#389ada', fontWeight: 500, marginRight: '5px' }}>Created:</span>
                            <span>{ticket.created}</span>
                          </GridItem>
                          <GridItem xs={12} sm={6} style={styles.ticketInfoItem}>
                            <span style={{ color: '#389ada', fontWeight: 500, marginRight: '5px' }}>User:</span>
                            <div
                              style={{ display: 'flex', cursor: 'pointer', alignItems: 'center' }}
                              title={[ticket.user.name ? `${ticket.user.name} (${ticket.user.email})` : ticket.user.email].concat(ticket.collaborators.map(i => {
                                if (i.name) {
                                  return `${i.name} (${i.email})`
                                } else {
                                  return i.email
                                }
                              })).join('\n')}
                            >
                              <span style={{ marginRight: '5px' }}>{ticket.user.name ? ticket.user.name : ticket.user.email}</span>
                              {ticket.collaborators.length > 0 && (
                                <div
                                  style={{ color: '#389ada', display: 'flex', alignItems: 'center' }}
                                >
                                  <People />
                                  <span>({ticket.collaborators.length})</span>
                                </div>
                              )}
                            </div>
                          </GridItem>
                          <GridItem xs={12} sm={6} style={styles.ticketInfoItem}>
                            <span style={{ color: '#389ada', fontWeight: 500, marginRight: '5px' }}>Status:</span>
                            <span>{ticket.status}</span>
                          </GridItem>
                          <GridItem xs={12} sm={6} style={styles.ticketInfoItem}>
                            <span style={{ color: '#389ada', fontWeight: 500, marginRight: '5px' }}>Location:</span>
                            <span>{ticket.location ? ticket.location : 'N/A'}</span>
                          </GridItem>
                          <GridItem xs={12} sm={6} style={styles.ticketInfoItem}>
                            <span style={{ color: '#389ada', fontWeight: 500, marginRight: '5px' }}>Need By:</span>
                            <span>{ticket.needBy ? ticket.needBy : 'N/A'}</span>
                          </GridItem>
                        </GridContainer>
                      </CardBody>
                    </Card>
                    <p style={{ fontWeight: 600, fontSize: '18px' }}>{ticket.subject}</p>
                    <div>
                      {ticket.messages.map(message => {
                        let elements = []

                        if (message.type === 'merge') {
                          elements.push(
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <Button justIcon style={{ margin: '0px 20px 0px 0px', backgroundColor: '#6babd5', cursor: 'default' }}>
                                <MergeType />
                              </Button>
                              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                <span style={{ marginRight: '10px' }}>{message.created}:</span>
                                <span>
                                  <strong>
                                    {message.user.name ? message.user.name : message.user.email}</strong> merged tickets</span>
                                {message.otherTicketID.map((other, index) => {
                                  return (
                                    <span key={other.id}>
                                      <a
                                        style={{ marginLeft: '3px', cursor: 'pointer', color: '#3695d7', textDecoration: 'none' }}
                                        href={`/portal/ticketSystem/view/${userType}/${other.id}`}
                                        target="_blank"
                                      >
                                        #{other.number}
                                      </a>
                                      {index !== message.otherTicketID.length - 1 && (<span>,</span>)}
                                    </span>
                                  )
                                })}
                                <span>.</span>
                              </div>
                            </div>
                          )
                        }

                        if (message.type === 'status') {
                          elements.push(
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <Button justIcon style={{ margin: '0px 20px 0px 0px', backgroundColor: '#6babd5', cursor: 'default' }}>
                                <Flag />
                              </Button>
                              <div>
                                <span style={{ marginRight: '10px' }}>{message.created}:</span>
                                <span>
                                  <strong>{message.user.name ? message.user.name : message.user.email}</strong> changed ticket status to {message.newStatus}.
                                </span>
                              </div>
                            </div>
                          )
                        }

                        if (message.type === 'transfer') {
                          elements.push(
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <Button justIcon style={{ margin: '0px 20px 0px 0px', backgroundColor: '#6babd5', cursor: 'default' }}>
                                <DoubleArrow />
                              </Button>
                              <div>
                                <span style={{ marginRight: '10px' }}>{message.created}:</span>
                                <span>
                                  <strong>{message.user.name ? message.user.name : message.user.email}</strong> transfered ticket to {message.newDepartmentID.name}.
                                </span>
                              </div>
                            </div>
                          )
                        }

                        if (message.type === 'collaborators') {
                          if (message.newTicketOwner) {
                            elements.push(
                              <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Button justIcon style={{ margin: '0px 20px 0px 0px', backgroundColor: '#6babd5', cursor: 'default' }}>
                                  <People />
                                </Button>
                                <div>
                                  <span style={{ marginRight: '10px' }}>{message.created}:</span>
                                  <span>
                                    <strong>{message.user.name ? message.user.name : message.user.email}</strong> changed the ticket Owner to <strong>{message.newTicketOwner.name ? message.newTicketOwner.name : message.newTicketOwner.email}</strong>.
                                  </span>
                                </div>
                              </div>
                            )
                          }
                          if (message.newCollaborators !== null) {
                            elements.push(
                              <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Button justIcon style={{ margin: '0px 20px 0px 0px', backgroundColor: '#6babd5', cursor: 'default' }}>
                                  <People />
                                </Button>
                                <div>
                                  <span style={{ marginRight: '10px' }}>{message.created}:</span>
                                  {message.newCollaborators.length === 0 ?
                                    <span>
                                      <strong>{message.user.name ? message.user.name : message.user.email}</strong> removed all ticket Collaborators.
                                    </span>
                                    :
                                    <span>
                                      <strong>{message.user.name ? message.user.name : message.user.email}</strong> changed the ticket Collaborators to <strong>{message.newCollaborators.map(c => c.name ? c.name : c.email).join(', ')}</strong>.
                                    </span>
                                  }
                                </div>
                              </div>
                            )
                          }
                        }

                        if (message.type === 'reply') {
                          elements.push(
                            <>
                              <Card style={{ margin: '0px' }}>
                                <div style={styles.replyMessage}>
                                  <div style={{ display: 'flex', marginRight: '10px', alignItems: 'center' }}>
                                    <img
                                      src={message.user.avatar ? message.user.avatar : blankProfile}
                                      className="profileImageLeft"
                                    />
                                    <span style={{ lineBreak: 'anywhere', marginLeft: '10px' }}>{message.user.name ? message.user.name : message.user.email}</span>
                                  </div>
                                  <span style={{ marginRight: '10px' }}>{message.created}</span>
                                </div>
                                <CardBody style={{ overflow: 'auto' }}>
                                  {message.html ? <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(message.html) }} /> :
                                    <>
                                      {message.text.split('\n').map((line, index) => {
                                        return (
                                          <>
                                            <span style={{ margin: '0px' }} key={index}>{line}</span>
                                            {message.text.split('\n').length - 1 !== index && (<br />)}
                                          </>
                                        )
                                      })}
                                    </>
                                  }
                                </CardBody>
                              </Card>
                              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                {(message.files).map((file, index) => {
                                  return (
                                    <a
                                      key={index}
                                      href={file.file}
                                      target="_blank"
                                      style={{ textDecoration: 'none' }}
                                    >
                                      <Button
                                        style={{
                                          width: '200px',
                                          height: '50px',
                                          backgroundColor: '#fff',
                                          borderRadius: '5px',
                                          margin: '10px 10px 0px 0px',
                                          display: 'flex',
                                          justifyContent: 'start',
                                          padding: '5px',
                                          color: 'black',
                                          overflow: 'hidden',
                                          alignItems: 'center'
                                        }}
                                        title={file.fileName}
                                      >
                                        <AttachFile style={{ color: '#3695d7', margin: '0px', padding: '0px' }} />
                                        <span style={{ margin: '0px', padding: '0px', textTransform: 'none' }}>{file.fileName.length > 30 ? `${file.fileName.substring(0, 27)}...` : file.fileName}</span>
                                      </Button>
                                    </a>
                                  )
                                })}
                              </div>
                            </>
                          )
                        }
                        if (message.type === 'client') {
                          elements.push(
                            <>
                              <Card style={{ margin: '0px' }}>
                                <div style={styles.clientMessage}>
                                  <span style={{ marginLeft: '10px' }}>{message.created}</span>
                                  <div style={{ display: 'flex', marginLeft: '10px', alignItems: 'center' }}>
                                    <span style={{ marginRight: '10px', lineBreak: 'anywhere' }}>{message.user.name ? message.user.name : message.user.email}</span>

                                    <img
                                      src={message.user.avatar ? message.user.avatar : blankProfile}
                                      className="profileImageRight"
                                    />
                                  </div>
                                </div>
                                <CardBody style={{ overflow: 'auto' }}>
                                  {message.html ? <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(message.html) }} /> : <p>{message.text}</p>}
                                </CardBody>
                              </Card>
                              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                {(message.files).map((file, index) => {
                                  return (
                                    <a
                                      key={index}
                                      href={file.file}
                                      target="_blank"
                                      style={{ textDecoration: 'none' }}
                                    >
                                      <Button
                                        style={{
                                          width: '200px',
                                          height: '50px',
                                          backgroundColor: '#fff',
                                          borderRadius: '5px',
                                          margin: '10px 10px 0px 0px',
                                          display: 'flex',
                                          justifyContent: 'start',
                                          padding: '5px',
                                          color: 'black',
                                          overflow: 'hidden',
                                          alignItems: 'center'
                                        }}
                                        title={file.fileName}
                                      >
                                        <AttachFile style={{ color: '#3695d7', margin: '0px', padding: '0px' }} />
                                        <span style={{ margin: '0px', padding: '0px', textTransform: 'none' }}>{file.fileName.length > 30 ? `${file.fileName.substring(0, 27)}...` : file.fileName}</span>
                                      </Button>
                                    </a>
                                  )
                                })}
                              </div>
                            </>
                          )
                        }
                        return (
                          <div key={message.id}>
                            {elements.map((i, index) => {
                              return (
                                <div key={index}>
                                  {i}
                                  <div style={{ display: 'flex', flexDirection: 'column', width: '41px', alignItems: 'center', margin: '5px 0px' }}>
                                    <div style={{ borderLeft: '2px solid #ccc', height: '15px' }} />
                                  </div>
                                </div>
                              )
                            })}
                          </div>
                        )

                      })}
                      {ticket.merged === null ?
                        <Card style={{ margin: '0px' }}>
                          <div style={replyType.value === 'client' ? styles.clientMessage : replyType.value === 'reply' ? styles.replyMessage : styles.internalMessage}>
                            {replyType.value === 'client' && (
                              <>
                                <div></div>
                                <div>
                                  <span>{guestEmail}</span>
                                  <img
                                    src={blankProfile}
                                    className="profileImageRight"
                                    style={{ marginLeft: '10px' }}
                                  />
                                </div>
                              </>
                            )}
                          </div>
                          <CardBody>
                            {messageLoading ? <Loading color='blue' /> : <>
                              <ReactQuill
                                onChange={(content, delta, source, editor) => {
                                  setText(editor.getHTML())
                                }}
                                preserveWhitespace
                                value={text}
                                style={{ marginBottom: '5px' }}
                                modules={modules}
                                formats={formats}
                              />
                              {(replyType.value === 'reply' || replyType.value === 'client') && (
                                <>
                                  <p style={{ margin: 0, padding: 0 }}>Attachments:</p>
                                  <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                    <input
                                      style={{ display: 'none' }}
                                      id="upload-button"
                                      multiple
                                      type="file"
                                      onChange={(e) => {
                                        let files = []
                                        for (let i = 0; i < e.target.files.length; i++) {
                                          files.push(e.target.files[i])
                                        }
                                        setFiles((prevState) => {
                                          return update(prevState, {
                                            $push: files
                                          })
                                        })
                                      }}
                                    />
                                    <label htmlFor="upload-button">
                                      <Button
                                        style={{
                                          height: '30px',
                                          borderRadius: '5px',
                                          margin: '5px 10px 0px 0px',
                                          display: 'flex',
                                          justifyContent: 'center',
                                          padding: '0px',
                                          overflow: 'hidden',
                                          alignItems: 'center',
                                        }}
                                        justIcon
                                        title='Upload'
                                        color='primary'
                                        component="span"
                                        variant="contained"
                                      >
                                        <Add style={{ margin: '0px', padding: '0px' }} />
                                      </Button>
                                    </label>
                                    {files.map((file, index) => {
                                      return (
                                        <div
                                          style={{
                                            display: 'flex',
                                            margin: '5px 10px 0px 0px',
                                            alignItems: 'center',
                                            backgroundColor: '#eee',
                                            borderRadius: '5px'
                                          }}
                                          key={index}
                                        >
                                          <div
                                            style={{
                                              display: 'flex',
                                              justifyContent: 'start',
                                              padding: '0px 5px',
                                              color: 'black',
                                              overflow: 'hidden',
                                              alignItems: 'center',
                                            }}
                                            title={file.name}
                                          >
                                            <AttachFile style={{ color: '#3695d7', margin: '0px', padding: '0px', fontSize: '18px' }} />
                                            <span style={{ margin: '0px', padding: '0px', textTransform: 'none' }}>{file.name}</span>
                                          </div>
                                          <Button
                                            style={{ padding: '5px', margin: '0px' }} color='primary'
                                            onClick={(e) => {
                                              e.preventDefault()
                                              setFiles((prevState) => {
                                                return update(prevState, {
                                                  $splice: [[index, 1]]
                                                })
                                              })
                                            }}
                                          >
                                            <Close style={{ margin: '0px', padding: '0px', }} />
                                          </Button>
                                        </div>
                                      )
                                    })}
                                  </div>
                                  <p style={{ marginTop: '5px', color: totalUploadSize > maxUploadSize ? 'red' : 'black' }}>Upload Size: {Math.round((totalUploadSize / 1000000) * 100) / 100} / {maxUploadSize / 1000000}MB</p>
                                </>
                              )}

                              <div style={{ textAlign: 'right' }}>
                                <Button color="primary"
                                  onClick={(e) => {
                                    e.preventDefault()
                                    submitTicketMessage()
                                  }}
                                  disabled={text.trim() === '' || !replyType || totalUploadSize > maxUploadSize}
                                >
                                  Send
                                </Button>
                              </div>
                            </>}
                          </CardBody>
                        </Card>
                        :
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <Button justIcon style={{ margin: '0px 20px 0px 0px', backgroundColor: '#6babd5', cursor: 'default' }}>
                            <MergeType />
                          </Button>
                          <div>
                            <span style={{ marginRight: '10px' }}>{ticket.lastMessage}:</span>
                            <span>
                              Ticket was merged into ticket <a
                                style={{ marginLeft: '3px', cursor: 'pointer', color: '#3695d7', textDecoration: 'none' }}
                                href={`/portal/ticketSystem/view/${userType}/${ticket.merged.id}`}
                                target="_blank"
                              >
                                #{ticket.merged.number}
                              </a>
                            </span>
                          </div>
                        </div>
                      }
                    </div>
                  </div>
                </>
              )}
            </GridItem>
          </GridContainer>
        </>
        }
      </div>
    </React.Fragment>
  )
}

const GuestLoginPopup = ({ guestLoginPopup, setGuestLoginPopup, userType, id, setGuestEmail }) => {

  const [continueAsGuest, setContinueAsGuest] = useState(false)
  const [email, setEmail] = useState('')

  const styles = {
    wrap: {
      background: "#000",
      position: "absolute",
      top: "0",
      left: "0",
      right: "0",
      bottom: "0",
      zIndex: 99998,
      opacity: ".7",
    },
    modal: {
      maxWidth: "96vw",
      width: '400px',
      margin: "0",
      boxShadow: "0px 0px 20px 5px #333",
      zIndex: 99999,
    },
  };
  return (
    <>
      {(guestLoginPopup) && (<React.Fragment>
        {ReactDOM.createPortal(
          <div style={styles.wrap}
          >

          </div>,
          document.getElementById("file-list-modal-overlay")
        )}
        {ReactDOM.createPortal(
          <div style={{ position: 'absolute', width: '100vw', top: '10vh' }}>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Card style={styles.modal}>
                <CardHeader color="info" text>
                  <CardText color="info" style={{ fontSize: '16px', fontWeight: '400', padding: '10px' }}>
                    Guest Login
                  </CardText>
                </CardHeader>

                <CardBody style={{ maxHeight: "80vh", overflowY: "auto" }}>
                  {continueAsGuest ?
                    <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '25px' }}>
                      <TextField
                        variant='outlined'
                        label='Email'
                        value={email}
                        fullWidth
                        onChange={(e) => setEmail(e.target.value)}
                        style={{ marginRight: '10px', maxWidth: '300px' }}
                      />
                      <Button
                        color='primary'

                        onClick={(e) => {
                          e.preventDefault()
                          setGuestEmail(email)
                        }}
                      >
                        Login
                      </Button>
                    </div>
                    :
                    <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', marginBottom: '25px' }}>
                      <Button
                        color='primary'
                        style={{ width: '200px' }}
                        onClick={(e) => {
                          e.preventDefault()
                          window.location = `/portal/ticketSystem/view/${userType}/${id}`
                        }}
                      >
                        Staff Login
                      </Button>
                      <Button
                        color='primary'
                        style={{ width: '200px' }}
                        onClick={(e) => {
                          e.preventDefault()
                          setContinueAsGuest(true)
                        }}
                      >
                        Continue As Guest
                      </Button>

                    </div>
                  }

                </CardBody>
              </Card>

            </div>
          </div>,
          document.getElementById("file-list-modal")
        )
        }
      </React.Fragment >)}
    </>
  )
}

const ViewTicketPublic = (props) => {
  return (
    <React.Suspense fallback={<Loading color="blue" />}>
      <ViewTicketPublicComp {...props} />
    </React.Suspense>
  )
}

export default ViewTicketPublic