import React, { useState } from "react";
import Loading from "components/Loading/Loading";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import axios from "axios";
import api from "../../api.config";

import update from "immutability-helper";
import Autocomplete from "@material-ui/lab/Autocomplete";

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";

import { TextField } from "@material-ui/core";
import Button from "components/CustomButtons/Button";
import {
  poFundCodesAtom,
  poGlCodesAtom,
  poDeptCodesAtom
} from "state/purchaseOrders";
import { useRecoilValue } from "recoil";

const PMEditPOCodesComp = ({ lineItems, poID }) => {
  const [newLineItems, setNewLineItems] = useState(lineItems.map(item => { return item }))

  const fundCodes = useRecoilValue(poFundCodesAtom);
  const glCodes = useRecoilValue(poGlCodesAtom);
  const deptCodes = useRecoilValue(poDeptCodesAtom);


  const fundCodeChangeHandler = (index, value) => {
    if (value !== null) {
      setNewLineItems((prevState) => {
        return update(prevState, {
          [index]: { $merge: { fundCode: value } },
        });
      });
    }
  };

  const glCodeChangeHandler = (index, value) => {
    if (value !== null) {
      setNewLineItems((prevState) => {
        return update(prevState, {
          [index]: { $merge: { glCode: value } },
        });
      });
    }
  };

  const deptCodeChangeHandler = (index, value) => {
    if (value !== null) {
      setNewLineItems((prevState) => {
        return update(prevState, {
          [index]: { $merge: { deptCode: value } },
        });
      });
    }
  };



  const submitHandler = async () => {
    let cleanLineItems = newLineItems.map(item => {
      let newItem = {
        ...item,
        description: item.description.replaceAll('"', '').replaceAll("'", '').replaceAll('\\', '')
      }

      return newItem
    })
    let data = {
      poid: poID,
      lineItems: JSON.stringify(cleanLineItems),
      EditType: 'Codes'
    }
    await axios
      .post(`${api.path}/digitalpo_api/v1/reviewerEditPO`, data).then((res) => {
        window.location.reload()
      })
  }

  return (
    <React.Fragment>
      <GridContainer style={{ marginBottom: '-15px', marginTop: '10px' }}>
        <GridItem>
          <Card>
            <CardHeader color="primary" icon>
              <CardIcon color="primary">
                <h4 style={{ fontWeight: 'bold' }}>Update Codes</h4>
              </CardIcon>
            </CardHeader>
            <CardBody>
              {newLineItems.map((item, index) => {
                return (
                  <div
                    style={{
                      display: 'flex',
                      backgroundColor: '#fafafa',
                      border: "1px solid #ddd",
                      borderRadius: "3px",
                      padding: '15px 5px',
                      margin: '5px -15px'
                    }}
                    key={index}>
                    <div style={{ width: '250px' }}>
                      <Autocomplete
                        disablePortal
                        options={fundCodes}
                        getOptionLabel={(option) =>
                          `${option.code} - ${option.title}` || ""
                        }
                        renderInput={(params) => (
                          <TextField {...params} label="Fund Code" />
                        )}
                        onChange={(e, v) =>
                          fundCodeChangeHandler(index, v)
                        }
                        autoSelect={true}
                        autoComplete={true}
                        autoHighlight={true}
                        value={item.fundCode}
                        getOptionSelected={(selected) => item.fundCode.id === selected.id}
                      />

                      <Autocomplete
                        disablePortal
                        options={glCodes}
                        getOptionLabel={(option) =>
                          `${option.code} - ${option.title}` || ""
                        }
                        renderInput={(params) => (
                          <TextField {...params} label="Gl Code" />
                        )}
                        onChange={(e, v) => glCodeChangeHandler(index, v)}
                        autoSelect={true}
                        autoComplete={true}
                        autoHighlight={true}
                        value={item.glCode}
                        getOptionSelected={(selected) => item.glCode.id === selected.id}
                      />

                      <Autocomplete
                        disablePortal
                        options={deptCodes}
                        getOptionLabel={(option) =>
                          `${option.code} - ${option.title}` || ""
                        }
                        renderInput={(params) => (
                          <TextField {...params} label="Dept Code" />
                        )}
                        onChange={(e, v) =>
                          deptCodeChangeHandler(index, v)
                        }
                        autoSelect={true}
                        autoComplete={true}
                        autoHighlight={true}
                        value={item.deptCode}
                        getOptionSelected={(selected) => item.deptCode.id === selected.id}
                      />
                    </div>
                    <div style={{ width: '200px', marginLeft: '10px' }}>
                      <span style={{ fontWeight: 'bold' }}>Description: </span>
                      <br />
                      <span style={{ lineBreak: 'anywhere' }}>{item.description}</span>
                    </div>

                  </div>
                )
              })}
              <div style={{ display: 'flex', justifyContent: 'right' }}>
                <div>
                  <Button
                    color="primary"
                    onClick={() => submitHandler()}
                  >
                    Submit
                  </Button>
                </div>
              </div>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </React.Fragment >
  )
}

const PMEditPOCodes = ({ lineItems, poID }) => {
  const fallback = (
    <React.Fragment>
      <GridContainer style={{ marginBottom: '-15px', marginTop: '10px' }}>
        <GridItem>
          <Card>
            <CardHeader color="primary" icon>
              <CardIcon color="primary">
                <h4 style={{ fontWeight: 'bold' }}>Update Codes</h4>
              </CardIcon>
            </CardHeader>
            <CardBody>
              <Loading color="blue" />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </React.Fragment>
  )
  return (
    <React.Suspense fallback={fallback}>
      <PMEditPOCodesComp lineItems={lineItems} poID={poID} />
    </React.Suspense>
  )
}

export default PMEditPOCodes