import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import AuthLayout from "layouts/Auth.js";
import AdminLayout from "layouts/Portal.js";

import "assets/scss/material-dashboard-pro-react.scss?v=2.0.0";

import { RecoilRoot } from "recoil";
import AxiosInterceptor from "components/AxiosInterceptor/AxiosInterceptor";

import { GoogleOAuthProvider } from "@react-oauth/google";
import ViewTicketPublic from "views/TicketSystem/ViewTicketPublic";

ReactDOM.render(
  <RecoilRoot>
    {/**
     * used by new google login
     */}
    <GoogleOAuthProvider
      clientId={"121609586991-fulco8rikl7vmjbr4rcebbl0g379cfkk.apps.googleusercontent.com"}
    >
      <AxiosInterceptor />
      <BrowserRouter>
        <Switch>
          <Route path="/portal" component={AdminLayout} />
          <Route path="/auth" component={AuthLayout} />
          <Route
            exact
            path="/ticketSystem/view/:userType/:id"
            component={ViewTicketPublic}
          />
          {/* <Route path="*" render={() => <Redirect to="/auth" />} /> */}
          <Redirect from="/" to="/auth" />
        </Switch>
      </BrowserRouter>
    </GoogleOAuthProvider>
  </RecoilRoot>,
  document.getElementById("root")
);
