import React from "react";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Button from "components/CustomButtons/Button";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon";
import classNames from "classnames";
import { useRecoilValue } from "recoil";
import { onlineFormsAtom } from "state/onlineForms";
import Loading from "components/Loading/Loading";

const useStyles = makeStyles(styles);

const OnlineFormsComp = (props) => {
  const classes = useStyles();

  const buttonStyle = {
    textTransform: 'none',
    whiteSpace: 'normal',
    width: '100%',
    minHeight: '110px',
    color: '#555555',
    borderRadius: '20px'
  }

  const color = 'white'

  const formOptions = useRecoilValue(onlineFormsAtom)

  // const formOptions = [
  //   {
  //     link: "https://forms.monday.com/forms/64cbc231626fb4ffcf017f7ca984516c?r=use1",
  //     title: 'Warehouse & Storage',
  //     desc: 'This form is used to request storage or retrieve items stored.',
  //     newTab: true
  //   },
  //   {
  //     link: "https://forms.monday.com/forms/7a04444093b560c9385d22a987c8e76d?r=use1",
  //     title: 'Creative Request',
  //     desc: 'This is for any request needed within the Creative Department. (Communications, Graphic Design, and Proofing)',
  //     newTab: true
  //   },
  //   {
  //     link: "https://forms.monday.com/forms/7cf6124b02bdf1778c1d6c83effe0ce3?r=use1",
  //     title: 'Weekend Service Evaluation',
  //     desc: '',
  //     newTab: true
  //   },
  //   {
  //     link: "/portal/onlineforms/webrequest",
  //     title: 'PCO Requests & Support',
  //     desc: 'This is for PCO help and requesting lists/data/access.',
  //     newTab: false
  //   },
  //   {
  //     link: "https://www.sagebrush.church/forms.php?form=480",
  //     title: 'PCO Form & Registration Request',
  //     desc: 'This is for communication needed through PCO registrations and PCO forms.',
  //     newTab: true
  //   },
  //   {
  //     link: "https://www.sagebrush.church/forms.php?form=513",
  //     title: 'Talent Request/Change Form',
  //     desc: '',
  //     newTab: true
  //   },
  //   {
  //     link: "https://www.sagebrush.church/forms.php?form=518",
  //     title: 'Ministry Team Project Proposal',
  //     desc: '',
  //     newTab: true
  //   },
  //   {
  //     link: "https://www.sagebrush.church/forms.php?form=521",
  //     title: 'Tuition & Education Assistance',
  //     desc: '',
  //     newTab: true
  //   },
  //   {
  //     link: "/portal/onlineforms/techsupport",
  //     title: 'Tech Support',
  //     desc: '',
  //     newTab: false
  //   },
  //   {
  //     link: "/portal/onlineforms/maintenance",
  //     title: 'Maintenance Request',
  //     desc: '',
  //     newTab: false
  //   },
  //   {
  //     link: 'https://www.sagebrush.church/forms.php?form=523',
  //     title: 'Kidcare Request',
  //     desc: '',
  //     newTab: true
  //   },
  //   {
  //     link: 'https://sagebrush.work/staff_portal_api/static/LostReceiptForm.pdf',
  //     title: 'Lost Receipt',
  //     desc: '',
  //     newTab: true
  //   },
  //   {
  //     link: 'https://sagebrush.work/staff_portal_api/static/Extra_Hours_Preauthorization.pdf',
  //     title: 'Extra Hours Preauthorization',
  //     desc: '',
  //     newTab: true
  //   },
  //   {
  //     link: 'https://forms.monday.com/forms/a7415a24a6a208d9d4413844f4e98a66?r=use1',
  //     title: 'Recording Request',
  //     desc: '',
  //     newTab: true
  //   },
  // ].sort(sortByTitle)

  return (
    <React.Fragment>
      <GridContainer>

        {formOptions.map((item, index) => {
          return (
            <GridItem xs={12} sm={6} lg={3} key={index}>
              {item.newTab ?
                <Button
                  color={color}
                  target="_blank"
                  style={buttonStyle}
                  href={item.link}
                >
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                    <span style={{ fontWeight: 'bold', fontSize: '16px' }}>{item.title}</span>
                    <span style={{ lineBreak: 'auto' }}>
                      {item.desc}
                    </span>
                  </div>
                </Button>
                :
                <Button
                  color={color}
                  style={buttonStyle}
                  href={item.link}
                >
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                    <span style={{ fontWeight: 'bold', fontSize: '16px' }}>{item.title}</span>
                    <span style={{ lineBreak: 'auto' }}>
                      {item.desc}
                    </span>
                  </div>
                </Button>
              }
            </GridItem>
          )
        })}

        {/* <GridItem xs={12} sm={12} lg={3}>
          <Card pricing>
            <CardBody pricing>
              <h6 className={classes.cardCategory}>Warehouse &amp; Storage</h6>
              <div className={classes.icon}></div>
              <p className={classes.cardDescription}>
                This form is used to request storage or retrieve items stored.
              </p>
              <Button
                round
                color="info"
                target="_blank"
                href="https://forms.monday.com/forms/64cbc231626fb4ffcf017f7ca984516c?r=use1"
              >
                Create Request
              </Button>
            </CardBody>
          </Card>
        </GridItem>

        <GridItem xs={12} sm={12} lg={3}>
          <Card pricing>
            <CardBody pricing>
              <h6 className={classes.cardCategory}>Creative Request</h6>
              <div className={classes.icon}></div>
              <p className={classes.cardDescription}>
                This is for any request needed within the Creative Department.
                (Communications, Graphic Design, and Proofing)
              </p>
              <Button
                round
                color="info"
                href="https://forms.monday.com/forms/7a04444093b560c9385d22a987c8e76d?r=use1"
                target="_blank"
              >
                Create Request
              </Button>
            </CardBody>
          </Card>
        </GridItem>

        <GridItem xs={12} sm={12} lg={3}>
          <Card pricing>
            <CardBody pricing>
              <h6 className={classes.cardCategory}>
                Weekend Service Evaluation
              </h6>
              <div className={classes.icon}></div>
              <p className={classes.cardDescription}></p>
              <Button
                round
                color="info"
                href="https://forms.monday.com/forms/7cf6124b02bdf1778c1d6c83effe0ce3?r=use1"
                target="_blank"
              >
                Create Request
              </Button>
            </CardBody>
          </Card>
        </GridItem>

        <GridItem xs={12} sm={12} lg={3}>
          <Card pricing>
            <CardBody pricing>
              <h6 className={classes.cardCategory}>
                Form &amp; Registration Request
              </h6>
              <div className={classes.icon}></div>
              <p className={classes.cardDescription}>
                This is for communication needed through PCO registrations and
                PCO forms.
              </p>
              <Button
                round
                color="info"
                href="https://www.sagebrush.church/forms.php?form=480"
                target="_blank"
              >
                Create Request
              </Button>
            </CardBody>
          </Card>
        </GridItem>

        <GridItem xs={12} sm={12} lg={3}>
          <Card pricing>
            <CardBody pricing>
              <h6 className={classes.cardCategory}>
                Talent Request/Change Form
              </h6>
              <div className={classes.icon}></div>
              <p className={classes.cardDescription}></p>
              <Button
                round
                color="info"
                href="https://www.sagebrush.church/forms.php?form=513"
                target="_blank"
              >
                Create Request
              </Button>
            </CardBody>
          </Card>
        </GridItem>

        <GridItem xs={12} sm={12} lg={3}>
          <Card pricing>
            <CardBody pricing>
              <h6 className={classes.cardCategory}>
                Ministry Team Project Proposal
              </h6>
              <div className={classes.icon}></div>
              <p className={classes.cardDescription}></p>
              <Button
                round
                color="info"
                href="https://www.sagebrush.church/forms.php?form=518"
                target="_blank"
              >
                Create Request
              </Button>
            </CardBody>
          </Card>
        </GridItem>

        <GridItem xs={12} sm={12} lg={3}>
          <Card pricing>
            <CardBody pricing>
              <h6 className={classes.cardCategory}>
                Tuition and Education Assistance
              </h6>
              <div className={classes.icon}></div>
              <p className={classes.cardDescription}></p>
              <Button
                round
                color="info"
                href="https://www.sagebrush.church/forms.php?form=521"
                target="_blank"
              >
                Create Request
              </Button>
            </CardBody>
          </Card>
        </GridItem>

        <GridItem xs={12} sm={12} lg={3}>
          <Card pricing>
            <CardBody pricing>
              <h6 className={classes.cardCategory}>Tech Support</h6>
              <div className={classes.icon}></div>
              <p className={classes.cardDescription}></p>
              <Button
                round
                color="info"
                href="/portal/onlineforms/techsupport"
              //target="_blank"
              >
                Create Request
              </Button>
            </CardBody>
          </Card>
        </GridItem>

        <GridItem xs={12} sm={12} lg={3}>
          <Card pricing>
            <CardBody pricing>
              <h6 className={classes.cardCategory}>Maintenance Request</h6>
              <div className={classes.icon}></div>
              <p className={classes.cardDescription}></p>
              <Button
                round
                color="info"
                href="/portal/onlineforms/maintenance"
              //target="_blank"
              >
                Create Request
              </Button>
            </CardBody>
          </Card>
        </GridItem>

        <GridItem xs={12} sm={12} lg={3}>
          <Card pricing>
            <CardBody pricing>
              <h6 className={classes.cardCategory}>Kidcare Request</h6>
              <div className={classes.icon}></div>
              <p className={classes.cardDescription}></p>
              <Button
                round
                color="info"
                href="https://www.sagebrush.church/forms.php?form=523"
                target="_blank"
              >
                Create Request
              </Button>
            </CardBody>
          </Card>
        </GridItem>

        <GridItem xs={12} sm={12} lg={3}>
          <Card pricing>
            <CardBody pricing>
              <h6 className={classes.cardCategory}>Lost Receipt</h6>
              <div className={classes.icon}></div>
              <p className={classes.cardDescription}></p>
              <Button
                round
                color="info"
                href="https://sagebrush.work/staff_portal_api/static/LostReceiptForm.pdf"
                target="_blank"
              >
                Create Request
              </Button>
            </CardBody>
          </Card>
        </GridItem>

        <GridItem xs={12} sm={12} lg={3}>
          <Card pricing>
            <CardBody pricing>
              <h6 className={classes.cardCategory}>Extra Hours Preauthorization</h6>
              <div className={classes.icon}></div>
              <p className={classes.cardDescription}></p>
              <Button
                round
                color="info"
                href="https://sagebrush.work/staff_portal_api/static/Extra_Hours_Preauthorization.pdf"
                target="_blank"
              >
                Create Request
              </Button>
            </CardBody>
          </Card>
        </GridItem>

        <GridItem xs={12} sm={12} lg={3}>
          <Card pricing>
            <CardBody pricing>
              <h6 className={classes.cardCategory}>Recording Request</h6>
              <div className={classes.icon}></div>
              <p className={classes.cardDescription}></p>
              <Button
                round
                color="info"
                href="https://forms.monday.com/forms/a7415a24a6a208d9d4413844f4e98a66?r=use1"
                target="_blank"
              >
                Create Request
              </Button>
            </CardBody>
          </Card>
        </GridItem> */}
      </GridContainer >
    </React.Fragment>
  );
};

const OnlineForms = (props) => {
  return (
    <React.Suspense fallback={<Loading color='blue' />}>
      <OnlineFormsComp {...props} />
    </React.Suspense>
  )
}


export default OnlineForms;
