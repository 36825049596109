import React from "react";
import { atom, selector, selectorFamily } from "recoil";
import { groupsAtom, userAtom } from "./auth";
import axios from "axios";
import api from "../api.config";

export const getPOListSubmittedBy = async (month, year) => {
  return await axios
    .post(`${api.path}/digitalpo_api/v1/listpos`, {
      data: {
        submittedBy: 'submittedBy',
        startDate: new Date(
          year,
          month,
          1
        ),
      },
    })
    .then((res) => {
      return res.data;
    });
}

export const poListSubmittedByAtom = atom({
  key: "poListSubmittedBy",
  default: selector({
    key: "poListSubmittedBySelector",
    get: async ({ get }) => {
      return await getPOListSubmittedBy(get(poListSubmittedByStartDateMonthAtom),
        get(poListSubmittedByStartDateYearAtom))
    },
  }),
});

export const getPOListSubmittedTo = async (month, year) => {
  return await axios
    .post(`${api.path}/digitalpo_api/v1/listpos`, {
      data: {
        submittedTo: 'submittedTo',
        startDate: new Date(year, month, 1),
      },
    })
    .then((res) => {
      return res.data;
    });
}

export const poListSubmittedToAtom = atom({
  key: "poListSubmittedTo",
  default: selector({
    key: "poListSubmittedToSelector",
    get: async ({ get }) => {
      return await getPOListSubmittedTo(
        get(poListSubmittedToStartDateMonthAtom),
        get(poListSubmittedToStartDateYearAtom))

    },
  }),
});

export const getPOListPendingSubmittedTo = async () => {
  return await axios
    .post(`${api.path}/digitalpo_api/v1/listpendingpos`, {
      data: {
        submittedTo: 'submittedTo',
      },
    })
    .then((res) => {
      return res.data;
    });
}

export const poListPendingSubmittedToAtom = atom({
  key: "poListPendingSubmittedTo",
  default: selector({
    key: "poListPendingSubmittedToSelector",
    get: async () => {
      return await getPOListPendingSubmittedTo()
    },
  }),
});

export const getPOListSubmittedByTeam = async (month, year) => {
  return await axios
    .post(`${api.path}/digitalpo_api/v1/listpos`, {
      data: {
        submittedByTeam: 'submittedByTeam',
        startDate: new Date(year, month, 1),
      },
    })
    .then((res) => {
      return res.data;
    });
}

export const poListSubmittedByTeamAtom = atom({
  key: "poListSubmittedByTeam",
  default: selector({
    key: "poListSubmittedByTeamSelector",
    get: async ({ get }) => {
      return await getPOListSubmittedByTeam(
        get(poListSubmittedByTeamStartDateMonthAtom),
        get(poListSubmittedByTeamStartDateYearAtom))
    },
  }),
});


export const ListSubmittedByTeamAtom = atom({
  key: "ListSubmittedByTeam",
  default: selector({
    key: "ListSubmittedByTeamSelector",
    get: async ({ get }) => {
      let userData = get(userAtom);
      return await axios
        .post(`${api.path}/digitalpo_api/v1/getMyTeam`, {
          data: {
            submittedByTeam: userData.email,
          },
        })
        .then((res) => {
          return res.data;
        });
    },
  }),
});

export const ListExpenseReportMembersAtom = atom({
  key: "ListExpenseReportMembers",
  default: selector({
    key: "ListExpenseReportMembersSelector",
    get: async () => {
      return await axios
        .get(`${api.path}/digitalpo_api/v1/getExpenseReportMembers`)
        .then((res) => {
          return res.data;
        });
    },
  }),
});

export const getPOListSubmittedToReview = async (month, year) => {
  return await axios
    .post(`${api.path}/digitalpo_api/v1/listpos`, {
      data: {
        reviewer: 'reviewer',
        startDate: new Date(year, month, 1),
      },
    })
    .then((res) => {
      return res.data;
    });
}

export const poListSubmittedToReviewAtom = atom({
  key: "poListSubmittedToReview",
  default: selector({
    key: "poListSubmittedToReviewSelector",
    get: async ({ get }) => {
      return await getPOListSubmittedToReview(
        get(poListSubmittedToReviewStartDateMonthAtom),
        get(poListSubmittedToReviewStartDateYearAtom),
      )
    },
  }),
});

export const getPOListSubmittedToReviewPending = async () => {
  return await axios
    .post(`${api.path}/digitalpo_api/v1/listpendingpos`, {
      data: {
        reviewer: 'reviewer',
      },
    })
    .then((res) => {
      return res.data;
    });
}

export const poListSubmittedToReviewPendingAtom = atom({
  key: "poListSubmittedToReviewPending",
  default: selector({
    key: "poListSubmittedToReviewPendingSelector",
    get: async () => {
      return await getPOListSubmittedToReviewPending()
    },
  }),
});

export const poBudgetsAtom = atom({
  key: 'poBudgets',
  default: selector({
    key: "poBudgetsSelector",
    get: async () => {
      return await axios
        .post(`${api.path}/digitalpo_api/v1/getPOBudgets`, {
        })
        .then((res) => {
          return res.data;
        });
    },
  }),
});

export const getPOListAdminViewPendingReviewAtom = async () => {
  return await axios
    .post(`${api.path}/digitalpo_api/v1/listpendingpos`, {
      data: {
        admin: 'admin',
      },
    })
    .then((res) => {
      return res.data;
    });
}

export const poListAdminViewPendingReviewAtom = atom({
  key: "poListAdminViewPendingReview",
  default: selector({
    key: "poListAdminViewPendingReviewSelector",
    get: async () => {
      return getPOListAdminViewPendingReviewAtom()

    },
  }),
});

export const getPOListAdminViewNeedCheck = async () => {
  return await axios
    .post(`${api.path}/digitalpo_api/v1/listneedscheck`, {
      data: {
        admin: 'admin',
      },
    })
    .then((res) => {
      return res.data;
    });
}

export const poListAdminViewNeedCheckAtom = atom({
  key: "poListAdminViewNeedCheckAtom ",
  default: selector({
    key: "poListAdminViewNeedCheckAtomSelector",
    get: async () => {
      return await getPOListAdminViewNeedCheck()

    },
  }),
});

export const getPOListAdminView = async (month, year) => {
  return await axios
    .post(`${api.path}/digitalpo_api/v1/listpos`, {
      data: {
        admin: 'admin',
        startDate: new Date(year, month, 1),
      },
    })
    .then((res) => {
      return res.data;
    });
}

export const poListAdminViewAtom = atom({
  key: "poListAdminViewAtom",
  default: selector({
    key: "poListAdminViewSelector",
    get: async ({ get }) => {
      return await getPOListAdminView(get(poListAdminViewStartDateMonthAtom), get(poListAdminViewStartDateYearAtom))
    },
  }),
});

export const getSinglePO = async (id) => {
  return await axios
    .post(`${api.path}/digitalpo_api/v1/pobyid`, {
      emailaddr: 'old',
      id: id,
    })
    .then((res) => {
      return res.data[0]
    });
}

export const viewSinglePo = selectorFamily({
  id: "viewSinglePo",
  get: (id) => async () => {
    return await getSinglePO(id)
  },
});

export const poListExpenseReportAtom = atom({
  key: 'expenseReportAtom',
  default: selector({
    key: 'expenseReportSelector',
    get: async ({ get }) => {
      return getExpenseReportPOs(
        get(poListExpenseReportStartDateMonthAtom),
        get(poListExpenseReportStartDateYearAtom))
    }
  })
})

export const getExpenseReportPOs = async (month, year) => {
  return await axios
    .post(`${api.path}/digitalpo_api/v1/listpos`, {
      data: {
        expenseReports: 'expenseReports',
        startDate: new Date(
          year,
          month,
          1
        ),
      },
    })
    .then((res) => {
      return res.data;
    });
}

export const departmentListAtom = atom({
  key: "departmentListAtom",
  default: selector({
    key: "departmentAtomSelector",
    get: async () => {
      return axios
        .get(`${api.path}/digitalpo_api/v1/departments`)
        .then((res) => {
          return res.data;
        });
    },
  }),
});

export const approversListAtom = atom({
  key: "approversListAtom",
  default: selector({
    key: "approversListAtomSelector",
    get: async () => {
      return axios.get(`${api.path}/digitalpo_api/v1/approvers`).then((res) => {
        return res.data;
      });
    },
  }),
});

export const reviewersListAtom = atom({
  key: "reviewersListAtom",
  default: selector({
    key: "reviewersListAtomSelector",
    get: async ({ get }) => {
      const userGroups = get(groupsAtom)
      return axios.get(`${api.path}/digitalpo_api/v1/reviewers`).then((res) => {
        if (userGroups.find(item => item.adminGroup === 'internal-admin-digitalpo@sagebrush.church' ||
          item.name === 'internal-digitalpo-review@sagebrush.church')) {
          res.data.unshift({
            email: 'N/A',
            name: 'N/A'
          })
        }
        return res.data;
      });
    },
  }),
});

// https://sagebrush.work/digitalpo_api/v1/execapprovers
export const secondApproversListAtom = atom({
  key: "secondApproversListAtom",
  default: selector({
    key: "secondApproversListAtomSelector",
    get: async () => {
      return axios
        .get(`${api.path}/digitalpo_api/v1/execapprovers`)
        .then((res) => {
          return res.data;
        });
    },
  }),
});
export const poListSubmittedByTeamStartDateMonthAtom = atom({
  key: "poListSubmittedByTeamStartDateMonthAtom",
  default: sessionStorage.getItem("plstbdm") || new Date().getMonth(),
});

export const poListSubmittedByTeamStartDateYearAtom = atom({
  key: "poListSubmittedByTeamStartDateYearAtom",
  default: sessionStorage.getItem("plstbdy") || new Date().getFullYear(),
});
export const poListSubmittedToReviewStartDateMonthAtom = atom({
  key: "poListSubmittedToReviewStartDateMonthAtom",
  default: sessionStorage.getItem("plstrdm") || new Date().getMonth(),
});

export const poListSubmittedToReviewStartDateYearAtom = atom({
  key: "poListSubmittedToReviewStartDateYearAtom",
  default: sessionStorage.getItem("plstrdy") || new Date().getFullYear(),
});

export const poListSubmittedByStartDateMonthAtom = atom({
  key: "poListSubmittedByStartDateMonthAtom",
  default: sessionStorage.getItem("plsbsdm") || new Date().getMonth(),
});

export const poListSubmittedByStartDateYearAtom = atom({
  key: "poListSubmittedByStartDateYearAtom",
  default: sessionStorage.getItem("plsbsdy") || new Date().getFullYear(),
});

export const poListSubmittedToStartDateMonthAtom = atom({
  key: "poListSubmittedToStartDateMonthAtom",
  default: sessionStorage.getItem("plstsdm") || new Date().getMonth(),
});

export const poListSubmittedToStartDateYearAtom = atom({
  key: "poListSubmittedToStartDateYearAtom",
  default: sessionStorage.getItem("plstsdy") || new Date().getFullYear(),
});

export const poListAdminViewStartDateMonthAtom = atom({
  key: "poListAdminViewStartDateMonthAtom",
  default: sessionStorage.getItem("plastsdm") || new Date().getMonth(),
});

export const poListAdminViewStartDateYearAtom = atom({
  key: "poListAdminViewStartDateYearAtom",
  default: sessionStorage.getItem("plavsdya") || new Date().getFullYear(),
});

export const poListExpenseReportStartDateMonthAtom = atom({
  key: "poListExpenseReportStartDateMonthAtom",
  default: sessionStorage.getItem("plerstsdm") || new Date().getMonth(),
});

export const poListExpenseReportStartDateYearAtom = atom({
  key: "poListExpenseReportStartDateYearAtom",
  default: sessionStorage.getItem("plerstsdy") || new Date().getFullYear(),
});

// Fund codes
export const poFundCodesAtom = atom({
  key: "poFundCodesAtom",
  default: selector({
    key: "poFundCodesAtomSelector",
    get: async () => {
      return axios.get(`${api.path}/digitalpo_api/v1/fundcodes`).then((res) => {
        return res.data;
      });
    },
  }),
});

// GL codes
export const poGlCodesAtom = atom({
  key: "poGlCodesAtom",
  default: selector({
    key: "poGlCodesAtomSelector",
    get: async () => {
      return axios.get(`${api.path}/digitalpo_api/v1/glcodes`).then((res) => {
        return res.data;
      });
    },
  }),
});

// Dept codes
export const poDeptCodesAtom = atom({
  key: "poDeptCodesAtom",
  default: selector({
    key: "poDeptCodesAtomSelector",
    get: async () => {
      return axios.get(`${api.path}/digitalpo_api/v1/deptcodes`).then((res) => {
        return res.data;
      });
    },
  }),
});

export const getPendingSpending = selectorFamily({
  key: 'getPendingSpending',
  get: (data) => async () => {
    return await axios.post(`${api.path}/digitalpo_api/v1/getAwaitingSpending`,
      { codes: data }).then((res) => {
        return res.data
      })
  }
})

export const getBudgetInfo = selectorFamily({
  key: 'getBudgetInfo',
  get: (data) => async () => {
    return await axios.post(`${api.path}/digitalpo_api/v1/getSpendingForCodes`,
      { codes: data }).then((res) => {
        return res.data
      })
  }
})
/*
// Event codes
export const poEventCodesAtom = atom({
  key: "poEventCodesAtom",
  default: selector({
    key: "poEventCodesAtomSelector",
    get: async () => {
      return axios
        .get(`${api.path}/digitalpo_api/v1/eventcodes`)
        .then((res) => {
          return res.data;
        });
    },
  }),
});*/
