import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import GoBack from "components/GoBack/GoBack";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { DateRangePicker } from "react-date-range";
import {
  checkinSingleStartDateAtom,
  checkinSingleEndDateAtom,
} from "state/checkin";
import { userAtom, canViewCheckInDataAtom } from "state/auth";
import { useRecoilState, useRecoilValue } from "recoil";
import SingleUserTable from "components/SingleUser/SingleUserTable";
import SingleUserOverview from "components/SingleUser/SingleUserOverview";
import axios from "axios";
import Loading from "components/Loading/Loading";

const useStyles = makeStyles(styles);

const SingleUserComponent = (props) => {
  const verifiedState = useLocation();
  const user = useRecoilValue(userAtom);
  const email = props.location.pathname.split("/").slice(-1).join("");

  const canViewCheckInData = useRecoilValue(canViewCheckInDataAtom(email));

  // We want to return as soon as possible if not verified
  if (verifiedState.state && verifiedState.state.verified) {
  } else if (canViewCheckInData) {
  } else if (user.email === props.match.params.user) {
  } else {
    return (
      <>
        <p>You shouldn't be here.</p>
      </>
    );
  }

  const classes = useStyles();

  const [startDate, setStartDate] = useRecoilState(checkinSingleStartDateAtom);
  const [endDate, setEndDate] = useRecoilState(checkinSingleEndDateAtom);

  // Sorry whoever sees this, we were short on time - no pun intended.
  const [dateRanges, setDateRanges] = useState([
    {
      startDate: new Date(
        new Date(startDate).setDate(new Date(startDate).getDate() + 1)
      ),
      endDate: new Date(
        new Date(endDate).setDate(new Date(endDate).getDate() + 1)
      ),
      key: "selection",
    },
  ]);

  const handleSelect = (ranges) => {
    setDateRanges([ranges.selection]);
  };

  const updateDateRangeHandler = () => {
    setStartDate(new Date(dateRanges[0].startDate).toISOString().slice(0, 10));
    setEndDate(new Date(dateRanges[0].endDate).toISOString().slice(0, 10));
  };

  return (
    <>
      <GoBack />
      <GridContainer>
        <GridItem xs={12} style={{ display: 'flex', flexWrap: 'wrap' }}>
          <div style={{ width: '328px', marginRight: '20px' }}>
            <Card>
              <CardHeader color="info" text>
                <CardText color="info">
                  <h4 className={classes.cardTitleWhite}>Date Range</h4>
                </CardText>
              </CardHeader>
              <CardBody>
                <div>
                  <DateRangePicker
                    ranges={dateRanges}
                    onChange={handleSelect}
                    //inputRanges={[]}
                    //staticRanges={[]}
                    singleDateRange={true}
                  />
                </div>
                <div>
                  <Button
                    size="sm"
                    color="primary"
                    onClick={updateDateRangeHandler}
                    fullWidth={true}
                  >
                    Update
                  </Button>
                </div>
              </CardBody>
            </Card>
          </div>
          <div style={{ width: '328px' }}>
            <SingleUserOverview
              email={email}
              startDateLabel={startDate.slice(5).split("-").join("/")}
              endDateLabel={endDate.slice(5).split("-").join("/")}
            />
          </div>
        </GridItem>

        <GridItem xs={12} md={6}>

        </GridItem>
      </GridContainer>

      <GridContainer>
        <GridItem xs={12}>
          <SingleUserTable email={email} />
        </GridItem>
      </GridContainer>
    </>
  );
};

export const SingleUser = (props) => {
  return (
    <React.Suspense fallback={<Loading color="blue" />}>
      <SingleUserComponent {...props} />
    </React.Suspense>
  );
};

export default SingleUser;
