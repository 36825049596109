import React, { useState } from "react";
import CustomTabs from "components/CustomTabs/CustomTabs";
import { useRecoilValue, useRecoilState } from "recoil";
import Loading from "components/Loading/Loading";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Button from "components/CustomButtons/Button.js";

import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import ListContractors from "./ListContractors";
import LoadingBars from "components/Loading/LoadingBars";
import { allContractors } from "state/invoiceSystem";
import { reviewerDepartments } from "state/invoiceSystem";
import { reviewerDepartmentsContractors } from "state/invoiceSystem";

const useStyles = makeStyles(styles);

const ReviewerViewMyDepartmentsComp = (props) => {
  const tab = +(props.location.search?.split("=")[1] || 0);

  const departments = useRecoilValue(reviewerDepartments)
  const contractors = useRecoilValue(reviewerDepartmentsContractors)

  const departmentCols = [
    {
      Header: "Name",
      accessor: "contractorName",
      disableFilters: false,
      disableSortBy: false,
    },
    {
      Header: "Email",
      accessor: "contractorEmail",
      disableFilters: false,
      disableSortBy: false,
    },
    {
      Header: "Start Date",
      accessor: "startDate",
      disableFilters: false,
      disableSortBy: false,
    },
    {
      Header: "Status",
      accessor: "status",
      disableFilters: false,
      disableSortBy: false,
    },
    {
      Header: "Actions",
      accessor: "actions",
      disableFilters: true,
      disableSortBy: true,
    },
  ]

  const tabs = departments.map(dept => {
    return {
      tabName: dept.name,
      tabContent: (
        <ListContractors
          department={dept}
          access={'reviewer'}
          contractorListType='department'
          contractors={contractors.filter(item => item.departmentID === dept._id)}
          buttons={{
            view: true,
            edit: true,
            addContractor: true,
            manageDepartment: true
          }}
          cols={departmentCols}
        />
      ),
    }
  })

  return (
    <React.Fragment>
      {tabs.length > 0 ?
        <CustomTabs headerColor="info" tabs={tabs} value={tab} />
        :
        <p>Not a Reviewer for any department.</p>
      }
    </React.Fragment>
  )
}

const ReviewerViewMyDepartmentsLoading = () => {
  const tabs = [
    {
      tabName: '',
      tabContent: <Loading color={'blue'} />,
    },
  ];

  return (
    <React.Fragment>
      <CustomTabs headerColor="info" tabs={tabs} />
    </React.Fragment>
  )
}

const ReviewerViewMyDepartments = (props) => {
  return (
    <React.Suspense fallback={<ReviewerViewMyDepartmentsLoading />}>
      <ReviewerViewMyDepartmentsComp {...props} />
    </React.Suspense>
  )
}

export default ReviewerViewMyDepartments