import { atom, selector, selectorFamily, atomFamily } from "recoil";
import axios from "axios";
import api from "../api.config";

const PORTAL_API = 'staff_portal_api'

export const myDepartmentsAtom = atom({
  key: 'myDepartments',
  default: selector({
    key: 'myDepartmentsSelector',
    get: async () => {
      return await getMyDepartments()
    }
  })
})

export const getMyDepartments = async () => {
  return await axios
    .get(`${api.path}/${PORTAL_API}/v1/getMyDepartments`)
    .then((res) => {
      return res.data;
    });
}

export const manageableDepartmentsAtom = atom({
  key: 'manageableDepartments',
  default: selector({
    key: 'manageableDepartmentsSelector',
    get: async () => {
      return await getManageableDepartments()
    }
  })
})

export const getManageableDepartments = async () => {
  return await axios
    .get(`${api.path}/${PORTAL_API}/v1/getManageableDepartments`)
    .then((res) => {
      return res.data;
    });
}

export const ownedDepartmentsAtom = atom({
  key: 'ownedableDepartments',
  default: selector({
    key: 'ownedableDepartmentsSelector',
    get: async () => {
      return await getOwnedDepartments()
    }
  })
})

export const getOwnedDepartments = async () => {
  return await axios
    .get(`${api.path}/${PORTAL_API}/v1/getDepartmentsOwned`)
    .then((res) => {
      return res.data;
    });
}

export const departmentOwnerAtom = atom({
  key: "departmentOwner",
  default: JSON.parse(sessionStorage.getItem("departmentOwner")) || null,
});

export const getDepartmentsGroups = async (departmentID) => {
  return await axios
    .post(`${api.path}/${PORTAL_API}/v1/getDepartmentsGroups`, { 'departmentID': departmentID })
    .then((res) => {
      return res.data
    });
}

export const isDepartmentOwnerOrAdminAtom = atom({
  key: "isDepartmentOwnerOrAdmin",
  default: selector({
    key: "isDepartmentOwnerOrAdminSelecter",
    get: async () => {
      return await axios.get(`${api.path}/${PORTAL_API}/v1/isDepartmentOwnerOrAdmin`)
        .then((res) => {
          return res.data;
        });
    }
  })
})

export const inADepartmentAtom = atom({
  key: "inADepartment",
  default: selector({
    key: "inADepartmentSelector",
    get: async () => {
      return await axios.get(`${api.path}/${PORTAL_API}/v1/inADepartment`)
        .then((res) => {
          return res.data;
        });
    }
  })
})

export const isTicketSystemAdminAtom = atom({
  key: "isTicketSystemAdmin",
  default: selector({
    key: "isTicketSystemAdminSelector",
    get: async () => {
      return await axios.get(`${api.path}/${PORTAL_API}/v1/isDepartmentAdmin`)
        .then((res) => {
          return res.data;
        });
    }
  })
})

export const editTicketDepartment = async (dept) => {
  return await axios
    .post(`${api.path}/${PORTAL_API}/v1/editDepartment`, dept)
    .then((res) => {
      return res.data
    });
}

export const createTicketDepartment = async (dept) => {
  return await axios
    .post(`${api.path}/${PORTAL_API}/v1/createTicketDepartment`, dept)
    .then((res) => {
      return res.data
    });
}

export const sortActiveUsersByname = (a, b) => {
  if (a.name > b.name) return 1
  if (a.name < b.name) return -1
  return 0
}

export const activeUsersAtom = atom({
  key: 'activeUsers',
  default: selector({
    key: 'activeUsersSelector',
    get: async () => {
      return await getAllActiveUsers()
    }
  })
})

export const getAllActiveUsers = async () => {
  return await axios.get(`${api.path}/${PORTAL_API}/v1/getAllActiveUsers`)
    .then((res) => {
      return res.data.sort(sortActiveUsersByname);
    });
}

export const addUserToDepartment = async (user) => {
  return await axios
    .post(`${api.path}/${PORTAL_API}/v1/addUserToDepartment`, user)
    .then((res) => {
      if (typeof res.data === 'string') {
        alert(res.data)
      }
      return res.data
    });
}

export const editUserDepartmentRole = async (user) => {
  return await axios
    .post(`${api.path}/${PORTAL_API}/v1/editDepartmentUserRole`, user)
    .then((res) => {
      return res.data
    });
}

export const removeDepartmentUser = async (user) => {
  return await axios
    .post(`${api.path}/${PORTAL_API}/v1/removeDepartmentUser`, user)
    .then((res) => {
      return res.data
    });
}

export const createDepartmentGroup = async (group) => {
  return await axios
    .post(`${api.path}/${PORTAL_API}/v1/createDepartmentGroup`, group)
    .then((res) => {
      return res.data
    });
}

export const editDepartmentGroup = async (group) => {
  return await axios
    .post(`${api.path}/${PORTAL_API}/v1/editDepartmentGroup`, group)
    .then((res) => {
      return res.data
    });
}

export const deleteDepartmentGroup = async (group) => {
  return await axios
    .post(`${api.path}/${PORTAL_API}/v1/deleteDepartmentGroup`, group)
    .then((res) => {
      return res.data
    });
}

export const sortByPriority = (a, b) => {
  let priorityList = {
    'high': 3,
    'medium': 2,
    'stale': 1,
    'normal': 0,
  }
  var aLevel, bLevel
  if (a.priority) {
    aLevel = priorityList[a.priority]
  } else { aLevel = 0 }
  if (b.priority) {
    bLevel = priorityList[b.priority]
  } else { bLevel = 0 }
  if (aLevel > bLevel) return -1
  if (aLevel < bLevel) return 1
  return 0
}

export const openViewableDepartmentTickets = atomFamily({
  key: 'openViewableDepartmentTickets',
  default: selectorFamily({
    key: 'openViewableDepartmentTicketsSelector',
    get: (deptID) => async () => {
      return await grabViewableDepartmentTickets(deptID, 'Open')
    }
  })

})

export const closedViewableDepartmentTickets = atomFamily({
  key: 'closedViewableDepartmentTickets',
  default: selectorFamily({
    id: 'closedViewableDepartmentTicketsSelector',
    get: ({ deptID, numDays }) => async () => {
      return await grabViewableDepartmentTickets(deptID, 'Closed', numDays)
    }
  })
})

export const blockedDepartmentTicketsAtom = atomFamily({
  key: 'blockedDepartmentTickets',
  default: selectorFamily({
    id: 'blockedDepartmentTicketsSelector',
    get: ({ deptID, numDays }) => async () => {
      return await grabViewableDepartmentTickets(deptID, 'Blocked', numDays)
    }
  })
})

export const grabViewableDepartmentTickets = async (deptID, status, numDays = null) => {
  if (!deptID) {
    return []
  }
  let search = {
    departmentID: deptID,
    status: status
  }
  if (numDays) { search.numDays = numDays }
  return await axios
    .post(`${api.path}/${PORTAL_API}/v1/grabViewableTickets`, search)
    .then((res) => {
      if (status === 'Open') {
        return res.data.sort(sortByPriority)
      }
      return res.data
    });
}

export const myTicketsAtom = atom({
  key: 'myTickets',
  default: selector({
    key: 'myTicketsSelector',
    get: async () => {
      return await getMyTickets()
    }
  })
})

export const getMyTickets = async () => {
  return await axios
    .get(`${api.path}/${PORTAL_API}/v1/grabMyTickets`)
    .then((res) => {
      return res.data;
    });
}

export const newTicketMessage = async (formData, files = null) => {
  if (files) {
    for (let i = 0; i < files.length; i++) {
      formData.append("files", files[i], files[i].name);
    }
  }
  return await axios
    .post(`${api.path}/${PORTAL_API}/v1/newMessage`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
}

export const newTicket = async (formData, files = null) => {
  if (files) {
    for (let i = 0; i < files.length; i++) {
      formData.append("files", files[i], files[i].name);
    }
  }
  return await axios
    .post(`${api.path}/${PORTAL_API}/v1/createTicket`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
}

export const selectableDepartmentsAtom = atomFamily({
  key: 'selectableDepartments',
  default: selectorFamily({
    key: 'selectableDepartmentsSelector',
    get: (hidden = false) => async () => {
      return await getSelectableDepartments(hidden)
    }
  })
})

export const getSelectableDepartments = async (hidden) => {
  if (hidden) {
    return await axios
      .get(`${api.path}/${PORTAL_API}/v1/getSelectableDepartments`)
      .then((res) => {
        return res.data;
      });
  } else {
    return await axios
      .get(`${api.path}/${PORTAL_API}/v1/getPublicDepartments`)
      .then((res) => {
        return res.data;
      });
  }
}

export const departmentSelectableOptionsAtom = atomFamily({
  key: 'departmentSelectableOptions',
  default: selectorFamily({
    key: 'departmentSelectableOptionsSelector',
    get: (deptID) => async () => {
      return await grabDepartmentSelectableOptions(deptID)
    }
  })
})

export const grabDepartmentSelectableOptions = async (deptID) => {
  if (deptID === null) {
    return {
      groups: [],
      users: []
    }
  }
  return await axios
    .post(`${api.path}/${PORTAL_API}/v1/getDepartmentSelectableOptions`, { id: deptID })
    .then((res) => {
      return res.data
    });
}

export const departmentBlockedEmailListAtom = atomFamily({
  key: 'departmentBlockedEmailList',
  default: selectorFamily({
    key: 'departmentBlockedEmailListSelector',
    get: (deptID) => async () => {
      return await getDepartmentBlockedEmailList(deptID)
    }
  })
})

export const getDepartmentBlockedEmailList = async (deptID) => {
  if (!deptID) {
    return []
  }
  return await axios
    .post(`${api.path}/${PORTAL_API}/v1/getDepartmentBlockedEmailList`, { departmentID: deptID })
    .then((res) => {
      return res.data
    })
}

export const blockEmail = async (email) => {
  return await axios
    .post(`${api.path}/${PORTAL_API}/v1/blockEmail`, email);
}

export const editBlockedEmail = async (email) => {
  return await axios
    .post(`${api.path}/${PORTAL_API}/v1/editBlockedEmail`, email);
}

export const removeBlockedEmail = async (email) => {
  return await axios
    .post(`${api.path}/${PORTAL_API}/v1/removeBlockedEmail`, email);
}

export const departmentTicketStatesAtom = atomFamily({
  key: 'departmentTicketStatesList',
  default: selectorFamily({
    key: 'departmentTicketStatesListSelector',
    get: (deptID) => async () => {
      return await getDepartmentTicketStates(deptID)
    }
  })
})

export const getDepartmentTicketStates = async (deptID) => {
  if (!deptID) {
    return []
  }
  return await axios
    .post(`${api.path}/${PORTAL_API}/v1/getDepartmentTicketStates`, { departmentID: deptID })
    .then((res) => {
      return res.data
    })
}

export const createTicketState = async (state) => {
  return await axios
    .post(`${api.path}/${PORTAL_API}/v1/createTicketState`, state);
}

export const editTicketState = async (state) => {
  return await axios
    .post(`${api.path}/${PORTAL_API}/v1/editTicketState`, state);
}

export const removeTicketState = async (state) => {
  return await axios
    .post(`${api.path}/${PORTAL_API}/v1/removeTicketState`, state);
}

export const departmentNoReplyEmailListAtom = atomFamily({
  key: 'departmentNoReplyEmailList',
  default: selectorFamily({
    key: 'departmentNoReplyEmailListSelector',
    get: (deptID) => async () => {
      return await getDepartmentNoReplyEmailList(deptID)
    }
  })
})

export const getDepartmentNoReplyEmailList = async (deptID) => {
  if (!deptID) {
    return []
  }
  return await axios
    .post(`${api.path}/${PORTAL_API}/v1/getDepartmentNoReplyEmailList`, { departmentID: deptID })
    .then((res) => {
      return res.data
    })
}

export const noReplyEmail = async (email) => {
  return await axios
    .post(`${api.path}/${PORTAL_API}/v1/noReplyEmail`, email);
}

export const editNoReplyEmail = async (email) => {
  return await axios
    .post(`${api.path}/${PORTAL_API}/v1/editNoReplyEmail`, email);
}

export const removeNoReplyEmail = async (email) => {
  return await axios
    .post(`${api.path}/${PORTAL_API}/v1/removeNoReplyEmail`, email);
}

export const viewTicketAtom = atomFamily({
  key: 'viewTicket',
  default: selectorFamily({
    id: 'viewTicketSelector',
    get: (ticket) => async () => {
      return await getTicket(ticket)
    }
  })
})

export const getTicket = async (ticket) => {
  return await axios.post(`${api.path}/${PORTAL_API}/v1/getTicket`, ticket)
    .then((res) => {
      return res.data
    });
}

export const departmentAnalyticsAtom = selectorFamily({
  id: 'departmentAnalyticsSelector',
  get: (search) => async () => {
    return await getDepartmentAnalytics(search)
  }
})

export const getDepartmentAnalytics = async (search) => {
  if (search) {
    return await axios.post(`${api.path}/${PORTAL_API}/v1/getDeparmentTicketStats`, search)
      .then((res) => {
        return res.data
      });
  } else {
    return {
      tickets: [],
      closedBy: [],
      createdBy: [],
    }
  }
}

export const departmentTicketSelectedDepartmentAtom = atom({
  key: 'departmentTicketSelectedDepartment',
  default: sessionStorage.getItem('dtsdts') || null
})

export const closedDepartmentTicketNumDaysAtom = atom({
  key: 'closedDepartmentTicketNumDaysAtom',
  default: sessionStorage.getItem('cdtndts') || '31'
})

export const blockedDepartmentTicketNumDaysAtom = atom({
  key: 'blockedDepartmentTicketNumDaysAtom',
  default: sessionStorage.getItem('bdtndts') || '31'
})

export const departmentAnalyticsStartDateAtom = atom({
  key: "departmentAnalyticsStartDate",
  default: selector({
    key: "departmentAnalyticsStartDateSelector",
    get: () => {
      const d = new Date();
      return new Date(d.getFullYear(), d.getMonth() - 1, 1).toISOString().slice(0, 10);
    },
  }),
});

export const departmentAnalyticsEndDateAtom = atom({
  key: "departmentAnalyticsEndDate",
  default: selector({
    key: "departmentAnalyticsEndDateSelector",
    get: () => {
      const d = new Date();
      return new Date(d.getFullYear(), d.getMonth(), 0).toISOString().slice(0, 10);
    },
  }),
});