import React, { useState } from "react";
import Loading from "components/Loading/Loading";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import axios from "axios";
import api from "../../api.config";

import update from "immutability-helper";

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";

import { TextField } from "@material-ui/core";
import Button from "components/CustomButtons/Button";

const PMEditPOAmountsComp = ({ lineItems, poID, updateAmountsRedirectHandler }) => {
  const [newLineItems, setNewLineItems] = useState(lineItems.map(item => { return item }))

  const lineItemAmountChangeHandler = (index, value) => {
    setNewLineItems((prevState) => {
      return update(prevState, {
        [index]: { $merge: { amount: value } },
      });
    });
  };

  const getTotalAmount = (items) => {
    let tempAmount = 0;

    items.map((item) => {
      if (item.amount) {
        tempAmount += parseFloat(item.amount);
      }
    });

    return Math.round(tempAmount * 100 + Number.EPSILON) / 100;
  };

  const checkForEmpty = (items) => {
    var empty = false
    items.forEach(item => {
      if (item.amount === '') {
        empty = true
      }
    });
    return empty
  }


  const submitHandler = async () => {
    let cleanLineItems = newLineItems.map(item => {
      let newItem = {
        ...item,
        description: item.description.replaceAll('"', '').replaceAll("'", '').replaceAll('\\', '')
      }

      return newItem
    })
    let data = {
      poid: poID,
      total: getTotalAmount(newLineItems),
      lineItems: JSON.stringify(cleanLineItems),
      EditType: 'Amounts'
    }
    await axios
      .post(`${api.path}/digitalpo_api/v1/reviewerEditPO`, data).then((res) => {
        updateAmountsRedirectHandler()
      })
  }

  const total = getTotalAmount(lineItems)
  return (
    <React.Fragment>
      <GridContainer style={{ marginBottom: '-15px', marginTop: '10px' }}>
        <GridItem>
          <Card>
            <CardHeader color="primary" icon>
              <CardIcon color="primary">
                <h4 style={{ fontWeight: 'bold' }}>Update Amounts</h4>
              </CardIcon>
            </CardHeader>
            <CardBody>
              <div style={{ display: 'flex', paddingBottom: '10px', justifyContent: 'space-between', fontSize: '18px' }}>
                <span style={{ fontWeight: 'bold' }}>Items</span>
                <span style={{ fontWeight: 'bold' }}>New Amounts</span>
              </div>
              {lineItems.map((item, index) => {
                return (
                  <div
                    style={{
                      display: 'flex',
                      backgroundColor: '#fafafa',
                      border: "1px solid #ddd",
                      borderRadius: "3px",
                      padding: '15px 5px',
                      margin: '5px -15px'
                    }}
                    key={index}>
                    <div style={{ width: '250px' }}>
                      <span style={{ fontWeight: 'bold' }}>Description: </span>
                      <br />
                      <span>{item.description > 50 ? item.description.substring(0, 50) : item.description}</span>
                    </div>
                    <TextField
                      label={`Old Amount: ${item.amount}`}
                      placeholder={'$ Amount'}
                      value={newLineItems[index].amount}
                      variant="outlined"
                      onWheel={(e) => e.target.blur()}
                      type="number"
                      inputProps={{
                        step: 0.01,
                      }}
                      style={{ backgroundColor: "#fff" }}
                      onChange={(e) => lineItemAmountChangeHandler(index, e.target.value)}
                    />
                  </div>
                )
              })}
              <div style={{ display: 'flex', paddingBottom: '10px', justifyContent: 'right' }}>
                {(getTotalAmount(newLineItems) > total + 100) && (
                  <span style={{ color: 'red', fontSize: '14px' }}>*Edit Limit ${total + 100}</span>
                )}
              </div>
              <div style={{ display: 'flex', paddingBottom: '10px', justifyContent: 'space-between', fontSize: '18px' }}>
                <div>
                  <span style={{ fontWeight: 'bold' }}>Total:</span>
                  <br />
                  <span>${total}</span>
                </div>
                <div style={{ textAlign: 'right' }}>
                  <span style={{ fontWeight: 'bold' }}>New Total:</span>
                  <br />
                  <span>${getTotalAmount(newLineItems)}</span>
                </div>
              </div>
              <div style={{ display: 'flex', justifyContent: 'right' }}>
                <div>
                  <Button
                    color="primary"
                    disabled={getTotalAmount(newLineItems) > total + 100 || checkForEmpty(newLineItems)}
                    onClick={() => submitHandler()}
                  >
                    Submit
                  </Button>
                </div>
              </div>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </React.Fragment >
  )
}

const PMEditPOAmounts = ({ lineItems, poID, updateAmountsRedirectHandler }) => {
  const fallback = (
    <React.Fragment>
      <GridContainer style={{ marginBottom: '-15px', marginTop: '10px' }}>
        <GridItem>
          <Card>
            <CardHeader color="primary" icon>
              <CardIcon color="primary">
                <h4 style={{ fontWeight: 'bold' }}>Update Amount</h4>
              </CardIcon>
            </CardHeader>
            <CardBody>
              <Loading color="blue" />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </React.Fragment>
  )
  return (
    <React.Suspense fallback={fallback}>
      <PMEditPOAmountsComp lineItems={lineItems} poID={poID} updateAmountsRedirectHandler={updateAmountsRedirectHandler} />
    </React.Suspense>
  )
}

export default PMEditPOAmounts