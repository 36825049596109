import { atom, atomFamily, selector, selectorFamily } from "recoil";
import axios from "axios";
import update from "immutability-helper";
import { userAtom } from "state/auth";
import api from "../api.config";
/* import { useHistory } from "react-router-dom";

const history = useHistory(); */

export const calendarListAtom = atom({
  key: "calendarList",
  default: selector({
    key: "calendarListSelector",
    get: async () => {
      return await axios
        .get(`${api.path}/staff_portal_api/v1/listcals`)
        .then((res) => {
          return [
            ...res.data.items
              .reduce(
                (map, obj) =>
                  map.set(obj.id, {
                    ...obj,
                  }),
                new Map()
              )
              .values(),
            { id: "RS", summary: "RS Campus Calendar" },
            { id: "NE", summary: "NE Campus Calendar" },
            { id: "EH", summary: "EH Campus Calendar" },
            { id: "HL", summary: "HL Campus Calendar" },
            { id: "SF", summary: "SF Campus Calendar" },
            { id: "FM", summary: "FM Campus Calendar" },
            { id: "BZ", summary: "BZ Campus Calendar" },
          ];
        });
    },
  }),
});

export const calendarSettingsAtom = atom({
  key: "calendarSettings",
  default: selector({
    key: "calendarSettingsSelector",
    get: async () => {
      return await axios
        .get(`${api.path}/staff_portal_api/v1/restoreusersettings`)
        .then((res) => {
          return res.data;
        });
    },
  }),
});

export const calendarSettingsFamily = atomFamily({
  key: "calendarSettingsFamily",
  default: selectorFamily({
    key: "calendarSettingsFamilySelector",
    get: (index) => ({ get }) => {
      const calendarSettings = get(calendarSettingsAtom).calendarSettings;
      const user = get(userAtom);

      if (calendarSettings && calendarSettings[index]) {
        return calendarSettings[index];
      }
      return {
        title: "",
        calendars: [
          {
            index: 0,
            id: user.email,
            eventColor: "#ff0000",
          },
        ],
      };
    },
  }),
});

export const saveCalendarSettingsSelector = selectorFamily({
  key: "saveCalendarSettings",
  set: (index) => ({ get, set }, params) => {
    const calendarSettings = get(calendarSettingsAtom);

    let newSettings = update(calendarSettings, {
      calendarSettings: { [index]: { $set: params.newCalendars } },
    });

    set(calendarSettingsAtom, newSettings);

    axios
      .post(`${api.path}/staff_portal_api/v1/saveusersettings`, {
        settings: newSettings,
      })
      .then((res) => {
        //history.push("/portal/dashboard");
      });

    //
  },
});

export const removeTabAtom = selectorFamily({
  key: "removeTab",
  set: ({ get, set }, index) => {
    alert("deleting from atom: " + index);
  },
});
