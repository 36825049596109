import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import React, { useState, useRef } from "react";
import ReactDOM from "react-dom";
import Button from "components/CustomButtons/Button";
import { ArrowRightAltTwoTone, Close, DeleteForever } from "@material-ui/icons";
import axios from "axios";
import { useRecoilValue } from "recoil";
import { viewSinglePo } from "state/purchaseOrders";
import update from "immutability-helper";
import {
  poListAdminViewAtom,
  poListAdminViewStartDateMonthAtom,
  poListAdminViewStartDateYearAtom,
} from "state/purchaseOrders";
import LoadingBars from "components/Loading/LoadingBars";
import { pdf, PDFDownloadLink } from "@react-pdf/renderer";
import CurrentPDF from "views/PurchaseOrders/CurrentPDF";
import { saveAs } from "file-saver";

const styles = {
  wrap: {
    background: "#000",
    position: "absolute",
    top: "0",
    left: "0",
    right: "0",
    bottom: "0",
    zIndex: 99998,
    opacity: ".7",
  },
  modal: {
    position: "absolute",
    top: "10vh",
    left: "calc(50% - 400px)",
    width: "800px",
    margin: "0 auto",
    boxShadow: "0px 0px 20px 5px #333",
    padding: "30px",
    paddingTop: "0",
    background: "#fff",
    zIndex: 99999,
    borderRadius: "10px",
  },
};

var JSZip = require("jszip");

const AdminDownloadModal = (props) => {
  const poList = useRecoilValue(poListAdminViewAtom);
  const [user, setUser] = useState("russellb@sagebrush.church");
  const [buttonLoading, setButtonLoading] = useState(false);

  const month = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ][useRecoilValue(poListAdminViewStartDateMonthAtom)];
  const year = useRecoilValue(poListAdminViewStartDateYearAtom);

  const buttonClickHandler = () => {
    const downloadItems = poList.filter(
      (item) => item.submittedBy === user && item.status === "approved"
    );

    if (!(downloadItems.length > 0)) {
      alert("User has no files!");
      return;
    }

    setButtonLoading(true);
    var zip = new JSZip();
    downloadItems.map((item, index) => {
      zip.file(
        `${item.id}.pdf`,
        pdf(
          <CurrentPDF id={item.id} currentPo={item} bulkDownload={true} />
        ).toBlob()
      );
    });

    zip.generateAsync({ type: "blob" }).then(function (blob) {
      saveAs(blob, `purchase_orders_${user.split("@")[0]}.zip`);
      setButtonLoading(false);
    });
  };

  const userChangeHandler = (e) => {
    setUser(e.target.value);
  };

  return (
    <React.Fragment>
      {ReactDOM.createPortal(
        <div style={styles.wrap} onClick={props.closeHandler}></div>,
        document.getElementById("file-list-modal-overlay")
      )}
      {ReactDOM.createPortal(
        <GridContainer style={styles.modal}>
          <GridContainer style={{ alignItems: "center" }}>
            <GridItem xs={11}>
              <h3>Download Purchase Orders</h3>
            </GridItem>
            <GridItem
              xs={1}
              style={{
                textAlign: "right",
              }}
            >
              <Button
                justIcon
                round
                color="danger"
                size="sm"
                onClick={props.closeHandler}
              >
                <Close />
              </Button>
            </GridItem>
          </GridContainer>

          <GridContainer>
            <GridItem xs={12}>
              <p>
                Select files to download for {month} {year}
              </p>
              <p>
                <select
                  style={{ padding: ".25rem", width: "50%" }}
                  onChange={userChangeHandler}
                >
                  <option value="russellb@sagebrush.church">
                    Russell Brown
                  </option>
                  <option value="ronc@sagebrush.church">Ron Clark</option>
                  <option value="davide@sagebrush.church">
                    David Helgensen
                  </option>
                </select>
              </p>
            </GridItem>
          </GridContainer>

          <GridContainer>
            <GridItem>
              {!buttonLoading ? (
                <Button color="primary" onClick={buttonClickHandler}>
                  Download
                </Button>
              ) : (
                <Button>
                  <LoadingBars />
                </Button>
              )}
            </GridItem>
          </GridContainer>
        </GridContainer>,
        document.getElementById("file-list-modal")
      )}
    </React.Fragment>
  );
};

export default AdminDownloadModal;
