import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import React, { useState, useRef } from "react";
import ReactDOM from "react-dom";
import Button from "components/CustomButtons/Button";
import { ArrowRightAltTwoTone, Close, DeleteForever } from "@material-ui/icons";
import axios from "axios";
import { useRecoilValue } from "recoil";
import { viewSinglePo } from "state/purchaseOrders";
import update from "immutability-helper";
import { TextField } from "@material-ui/core";
import { userAtom } from "../../state/auth";
import api from "../../api.config";
//import Button from "../../components/CustomButtons/Button";

const CheckNumberModal = (props) => {
  const currentPo = useRecoilValue(viewSinglePo(props.id));
  const userData = useRecoilValue(userAtom);

  const [checkNumber, setCheckNumber] = useState("");

  const styles = {
    wrap: {
      background: "#000",
      position: "absolute",
      top: "0",
      left: "0",
      right: "0",
      bottom: "0",
      zIndex: 99998,
      opacity: ".7",
    },
    modal: {
      position: "absolute",
      top: "10vh",
      left: "calc(50% - 400px)",
      width: "800px",
      margin: "0 auto",
      boxShadow: "0px 0px 20px 5px #333",
      padding: "30px",
      paddingTop: "0",
      background: "#fff",
      zIndex: 99999,
      borderRadius: "10px",
    },
  };

  const checkNumberChangeHandler = (e) => {
    setCheckNumber(e.target.value);
  };

  const checkNumberSubmitHandler = () => {
    axios
      .post(`${api.path}/digitalpo_api/v1/processcheck`, {
        data: {
          processCheck: userData.email,
          id: currentPo.id,
          checkNumber: checkNumber.toString(),
        },
      })
      .then((res) => {
        props.closeHandler();
        return
      });
  };

  return (
    <React.Fragment>
      {ReactDOM.createPortal(
        <div style={styles.wrap} onClick={props.closeHandler}></div>,
        document.getElementById("file-list-modal-overlay")
      )}
      {ReactDOM.createPortal(
        <GridContainer style={styles.modal}>
          <GridContainer style={{ alignItems: "center" }}>
            <GridItem xs={11}>
              <h3>
                #{currentPo.id} - {currentPo.vendor}
              </h3>
            </GridItem>
            <GridItem
              xs={1}
              style={{
                textAlign: "right",
              }}
            >
              <Button
                justIcon
                round
                color="danger"
                size="sm"
                onClick={props.closeHandler}
              >
                <Close />
              </Button>
            </GridItem>
          </GridContainer>

          <GridContainer>
            <GridItem>
              <p>Enter the check #</p>

              <TextField
                value={checkNumber}
                onChange={checkNumberChangeHandler}
              />

              <p>
                <Button color="primary" onClick={checkNumberSubmitHandler}>
                  Submit
                </Button>
              </p>
            </GridItem>
          </GridContainer>
        </GridContainer>,
        document.getElementById("file-list-modal")
      )}
    </React.Fragment>
  );
};

export default CheckNumberModal;
