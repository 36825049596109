import { atom, selector, selectorFamily } from "recoil";
import axios from "axios";
import api from "../api.config";
import { decryptData } from "assets/jss/EncriptDecrypt/EncryptDecrypt";

export const sortPages = (a, b) => {
  if (a.title.toLowerCase() < b.title.toLowerCase()) return -1
  if (a.title.toLowerCase() > b.title.toLowerCase()) return 1
  return 0
}

export const googleGroupOptionsAtom = atom({
  key: 'googleGroupOptions',
  default: selector({
    key: 'googleGroupOptionsSelector',
    get: async () => {
      return getGoogleGroupOptions()
    },
  })
})

export const getGoogleGroupOptions = async () => {
  return await axios
    .get(`${api.path}/staff_portal_api/v1/getGoogleGroupOptions`)
    .then((res) => {
      return res.data
    });
}

export const PagesAtom = atom({
  key: 'pages',
  default: selector({
    key: 'pagesSelector',
    get: async () => {
      return await axios
        .get(`${api.path}/staff_portal_api/v1/getPages`)
        .then((res) => {
          return res.data.sort(sortPages);
        });
    },
  }),
});

export const TabsAtom = atom({
  key: 'tabs',
  default: selector({
    key: 'tabsSelector',
    get: async () => {
      return await axios
        .get(`${api.path}/staff_portal_api/v1/getTabs`)
        .then((res) => {
          let tabs = res.data.map(item => {
            //item.body = JSON.parse(item.body)
            item.body = item.body.map(component => {
              if (component.inputType === 'Text' && typeof component.value === 'string') {
                return {
                  inputType: component.inputType,
                  value: {
                    html: component.value,
                    content: component.value
                  }
                }
              }
              return component
            });
            return item
          })
          return tabs;
        });
    },
  }),
});

export const pageTabs = selectorFamily({
  id: "pageTabs",
  get: (id) => async () => {
    return await getPagesTabs(id)
  },
})

export const sortTabs = (a, b) => {
  if (a.position > b.position) return 1
  if (a.position < b.position) return -1
  return 0
}

export const getPagesTabs = async (pageID) => {
  return await axios
    .post(`${api.path}/staff_portal_api/v1/getPageTabs`, { '_id': pageID })
    .then((res) => {

      let tabs = res.data.map(item => {
        //item.body = JSON.parse(item.body)
        item.body = item.body.map(component => {
          if (component.inputType === 'Text' && typeof component.value === 'string') {
            return {
              inputType: component.inputType,
              value: {
                html: component.value,
                content: component.value
              }
            }
          }
          return component
        });
        return item
      })
      return tabs.sort(sortTabs);
    })
}

export const SelectedPageID = atom({
  key: "selectedPageID",
  default: sessionStorage.getItem("SPID") || 0,
})

export const SelectedPageOption = atom({
  key: 'selectedPageOption',
  default: sessionStorage.getItem("SPOption") || 'Manage',
})

export const publishedPagesAtom = atom({
  key: 'publishedPages',
  default: decryptData(sessionStorage.getItem('publishedPagesDB'), true) || null,
})