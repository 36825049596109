import CryptoJS from "crypto-js";

const secretPass = "h2j36Z11jRbj5ZDRtthVsisJvFy7ezpOH8hsUHn7ZJzbuTeU7l";

export const encryptData = (data) => {
  const text = CryptoJS.AES.encrypt(
    JSON.stringify(data),
    secretPass
  ).toString();

  return text
};

export const decryptData = (text, nullOnError = false) => {
  try {
    const bytes = CryptoJS.AES.decrypt(text, secretPass);
    const data = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return data
  } catch (error) {
    if (nullOnError) {
      return null
    }
    return {}
  }
};
