import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { NavLink } from "react-router-dom";
import Icon from "@material-ui/core/Icon";
import ListItemText from "@material-ui/core/ListItemText";

import SweetAlert from "react-bootstrap-sweetalert";

import Button from "@material-ui/core/Button";

import sidebarStyle from "assets/jss/material-dashboard-pro-react/components/sidebarStyle.js";
import alertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";

import axios from "axios";

import { userAtom } from "state/auth";
import { useRecoilValue } from "recoil";

import cx from "classnames";

import ReactDOM from "react-dom";
import api from "../../api.config";

const useStyles = makeStyles(sidebarStyle);
const useAlert = makeStyles(alertStyle);

const FeatureRequestButton = (props) => {
  const classes = useStyles();
  const alertClasses = useAlert();

  //const [alert, setAlert] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const [message, setMessage] = useState();
  const [showSuccess, setShowSuccess] = useState(false);

  const user = useRecoilValue(userAtom);

  const submitHandler = async () => {
    if (message) {
      let formData = new FormData();
      formData.append("formid", "featurerequest");
      formData.append(
        "data",
        JSON.stringify({
          emailaddr: user.email,
          title: "Feature Request " + new Date().toDateString(),
          body: message,
        })
      );

      setShowAlert(false);
      setShowSuccess(true);
      await axios
        .post(`${api.path}/form_api_be/v1/formsubmit`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          setMessage("");
        });
    }
  };

  const cancelHandler = () => {
    setShowAlert(false);
  };

  const featureRequestButtonHandler = (e) => {
    e.preventDefault();
    setShowAlert(true);
  };

  const successSubmitHandler = () => {
    setShowSuccess(false);
  };

  const itemText =
    classes.itemText +
    " " +
    cx({
      [classes.itemTextMini]: props.mini,
      [classes.itemTextMiniRTL]: false,
      [classes.itemTextRTL]: false,
    });

  const collapseItemText =
    classes.collapseItemText +
    " " +
    cx({
      [classes.collapseItemTextMini]: props.mini,
      [classes.collapseItemTextMiniRTL]: false,
      [classes.collapseItemTextRTL]: false,
    });

  const innerNavLinkClasses = classes.collapseItemLink;

  const navLinkClasses = classes.itemLink;

  return (
    <React.Fragment>
      {showSuccess &&
        ReactDOM.createPortal(
          <SweetAlert
            success
            style={{ display: "block", marginTop: "-100px", color: "#000" }}
            title="Thank you!"
            onConfirm={successSubmitHandler}
            onCancel={successSubmitHandler}
            confirmBtnCssClass={
              alertClasses.button + " " + alertClasses.success
            }
            confirmBtnText="OK"
          >
            <p>
              You should receive an email about your submission. If you do not
              receive a confirmation, then please email
              techsupport@sagebrush.church
            </p>
          </SweetAlert>,
          document.getElementById("sweetalert-portal")
        )}

      {showAlert &&
        ReactDOM.createPortal(
          <SweetAlert
            style={{ display: "block", marginTop: "-100px", color: "#000" }}
            title="Request A Feature"
            onConfirm={submitHandler}
            onCancel={cancelHandler}
            confirmBtnCssClass={
              alertClasses.button + " " + alertClasses.success
            }
            cancelBtnCssClass={alertClasses.button + " " + alertClasses.danger}
            confirmBtnText="Submit"
            cancelBtnText="Cancel"
            showCancel
          >
            <p>Please let us know your idea below, thanks!</p>

            <p>
              <textarea
                style={{ width: "100%" }}
                rows="5"
                onChange={(e) => {
                  setMessage(e.target.value);
                }}
                value={message}
              ></textarea>
            </p>
          </SweetAlert>,
          document.getElementById("sweetalert-portal")
        )}
      <NavLink
        to={{}}
        className={cx(
          { [navLinkClasses]: true },
          { [innerNavLinkClasses]: true }
        )}
        style={{
          backgroundColor: "#5cb860",
          minHeight: "50px",
        }}
        onClick={featureRequestButtonHandler}
      >
        <Icon className={classes.itemIcon}>build</Icon>

        <ListItemText
          primary="Request Feature"
          disableTypography={true}
          className={cx({ [itemText]: true }, { [collapseItemText]: false })}
        />
      </NavLink>
    </React.Fragment>
  );
};

export default FeatureRequestButton;
