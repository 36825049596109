import React, { useEffect, useState } from "react";
import CustomTabs from "components/CustomTabs/CustomTabs";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { FormControlLabel, Radio, TextField } from "@material-ui/core";
import { useRecoilValue, useRecoilState } from "recoil";
import Loading from "components/Loading/Loading";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Button from "components/CustomButtons/Button.js";
import ReactTable from "components/ReactTable/ReactTable.js";
import Edit from "@material-ui/icons/Edit";
import CloseIcon from '@material-ui/icons/Close';
import update from "immutability-helper";
import { makeStyles } from "@material-ui/core/styles";

import { formatPhoneNumber } from "state/directory";

import {
  manageableDepartmentsAtom,
  isTicketSystemAdminAtom,
  editTicketDepartment,
  createTicketDepartment,
  getManageableDepartments,
  activeUsersAtom,
  addUserToDepartment,
  editUserDepartmentRole,
  removeDepartmentUser,
  createDepartmentGroup,
  editDepartmentGroup,
  deleteDepartmentGroup,
  departmentBlockedEmailListAtom,
  getDepartmentBlockedEmailList,
  blockEmail,
  editBlockedEmail,
  removeBlockedEmail,
  grabViewableDepartmentTickets,
  blockedDepartmentTicketsAtom,
  blockedDepartmentTicketNumDaysAtom,
  selectableDepartmentsAtom,
  getSelectableDepartments,
  departmentTicketStatesAtom,
  getDepartmentTicketStates,
  createTicketState,
  editTicketState,
  removeTicketState
} from "state/ticketSystem";

import { useHistory } from "react-router-dom";
import { Close } from "@material-ui/icons";
import { userAtom } from "state/auth";

import blankProfile from 'assets/img/blankProfile.png'
import ListTickets from "./ListTickets";
import { editNoReplyEmail } from "state/ticketSystem";
import { noReplyEmail } from "state/ticketSystem";
import { removeNoReplyEmail } from "state/ticketSystem";
import { getDepartmentNoReplyEmailList } from "state/ticketSystem";
import { departmentNoReplyEmailListAtom } from "state/ticketSystem";
const useStyles = makeStyles({});

const createEditGroupTab = 7

const DepartmentManagerComp = (props) => {
  const curTab = +(props.location.search?.split("=")[1] || 0)
  const redirect = `${props.location.pathname}${props.location.search}`

  const emptyDepartment = {
    name: '',
    email: '',
    settings: {
      defaultOwners: [{ id: 'All', name: 'All', type: 'All' }],
      canViewAll: [{ id: 'All', name: 'All', type: 'All' }],
      stale: 30,
      highPriority: 1,
      mediumPriority: 7
    },
    hiddenDepartment: false,
    redirectDepartment: null,
    groups: [],
    users: []
  }

  const [manageableDepartments, setManageableDepartments] = useRecoilState(manageableDepartmentsAtom)
  const ticketSystemAdmin = useRecoilValue(isTicketSystemAdminAtom)
  const activeUsers = useRecoilValue(activeUsersAtom)
  const user = useRecoilValue(userAtom)
  const history = useHistory();
  const [submitting, setSubmitting] = useState(false)
  const [selectedDepartment, setSelectedDepartment] = useState(manageableDepartments.length > 0 ? manageableDepartments[0] : emptyDepartment)
  const [selectedGroup, setSelectedGroup] = useState(null)
  const [tab, setTab] = useState(curTab);
  const [numDays, setNumDays] = useRecoilState(blockedDepartmentTicketNumDaysAtom)
  const [blockedTickets, setBlockedTickets] = useRecoilState(blockedDepartmentTicketsAtom({ deptID: selectedDepartment._id, numDays: numDays }))
  const [blockedEmails, setBlockedEmails] = useRecoilState(departmentBlockedEmailListAtom(selectedDepartment._id))
  const [noReplyEmails, setNoReplyEmails] = useRecoilState(departmentNoReplyEmailListAtom(selectedDepartment._id))
  const [departments, setDepartments] = useRecoilState(selectableDepartmentsAtom(false))
  const selectableDepartments = departments.filter(i => i.id !== selectedDepartment._id)

  const [ticketStates, setTicketStates] = useRecoilState(departmentTicketStatesAtom(selectedDepartment._id))

  const validateTab = () => {
    if (selectedGroup && tab !== createEditGroupTab) {
      setSelectedGroup(null)
    }
    if (tab === createEditGroupTab && !selectedGroup) {
      setTab(0)
      history.replace(`${props.location.pathname}?tab=0`);
    }
  }

  useEffect(() => {
    validateTab()
  }, [tab])

  useEffect(() => {
    validateTab()
  }, [])

  const cols = [
    {
      Header: "",
      accessor: "checkBox",
      disableFilters: true,
      disableSortBy: true,
      style: { maxWidth: '34px', padding: '0' },
      headerStyle: { maxWidth: '34px', padding: '0', margin: '0px', marginTop: '2px' },
      checkBox: true
    },
    {
      Header: "#",
      accessor: "number",
      disableFilters: true,
      disableSortBy: true,
      style: { maxWidth: '50px', fontSize: '11px' },
      headerStyle: { maxWidth: '50px', margin: '0px' }
    },
    {
      Header: "Updated",
      accessor: "lastMessage",
      disableFilters: true,
      disableSortBy: true,
      style: { fontSize: '11px', maxWidth: '74px' },
      headerStyle: { margin: '0px', maxWidth: '74px' }
    },
    {
      Header: "Subject",
      accessor: "subjectLink",
      disableFilters: true,
      disableSortBy: true,
      style: { minWidth: '200px', fontSize: '12px' },
      headerStyle: { minWidth: '200px', margin: '0px' }
    },
    {
      Header: "",
      accessor: "notifications",
      disableFilters: true,
      disableSortBy: true,
      style: { maxWidth: '70px', padding: '0', fontSize: '12px' },
      headerStyle: { maxWidth: '70px', padding: '0', margin: '0px' }
    },
    {
      Header: "From",
      accessor: "userName",
      disableFilters: true,
      disableSortBy: true,
      style: { maxWidth: '150px', fontSize: '12px' },
      headerStyle: { maxWidth: '150px', margin: '0px' }
    },
    {
      Header: "",
      accessor: "priorityIcon",
      disableFilters: true,
      disableSortBy: true,
      style: { maxWidth: '8px', padding: '0', fontSize: '12px', margin: '0px' },
      headerStyle: { maxWidth: '8px', padding: '0', margin: '0px' }
    },
    {
      Header: "Assigned To",
      accessor: "assignedToNamesComp",
      disableFilters: true,
      disableSortBy: true,
      style: { maxWidth: '150px', fontSize: '12px' },
      headerStyle: { maxWidth: '150px', margin: '0px' }
    },
  ]

  const searchTickets = (tickets, search) => {
    return tickets.filter(ticket => {
      return (ticket.subject.toLowerCase().includes(search.toLowerCase()) ||
        (ticket.number.toString()).includes(search) ||
        (ticket.assignedToNames.map(i => i.name).join(' ')).toLowerCase().includes(search.toLowerCase()) ||
        ticket.userName.toLowerCase().includes(search.toLowerCase()))
    })
  }

  const updateBlockedEmails = async () => {
    let emails = await getDepartmentBlockedEmailList(selectedDepartment._id)
    setBlockedEmails(emails)
    return
  }

  const updateDepartmentTicketStates = async () => {
    let t = await getDepartmentTicketStates(selectedDepartment._id)
    setTicketStates(t)
    return
  }

  const updateNoReplyEmails = async () => {
    let emails = await getDepartmentNoReplyEmailList(selectedDepartment._id)
    setNoReplyEmails(emails)
    return
  }

  const updateTickets = async (days) => {
    let t = await grabViewableDepartmentTickets(selectedDepartment._id, 'Blocked', days)
    setBlockedTickets(t)
    return
  }

  const updateNumDays = (days) => {
    sessionStorage.setItem('bdtndts', days)
    setNumDays(days)
  }

  const refreshDepartments = async (departmentID = selectedDepartment._id) => {
    let manageableDept = await getManageableDepartments()
    setManageableDepartments(manageableDept)
    setSelectedDepartment(manageableDept.find(item => item._id === departmentID))
    let dept = await getSelectableDepartments(false)
    setDepartments(dept)
    return
  }

  const createEditGroup = (group) => {
    setSelectedGroup(group)
    setTab(createEditGroupTab)
    history.replace(`${props.location.pathname}?tab=${createEditGroupTab}`);
  }

  const submitCreateEditGroup = async (group) => {
    setSubmitting(true)
    if (group._id) {
      await editDepartmentGroup({
        id: group._id,
        name: group.name,
        userIDs: group.userIDs,
        departmentID: group.departmentID
      })
    } else {
      await createDepartmentGroup({
        name: group.name,
        userIDs: group.userIDs,
        departmentID: group.departmentID
      })
    }
    setTab(2)
    history.replace(`${props.location.pathname}?tab=2`);
    await refreshDepartments()
    setSubmitting(false)
  }

  const removeUser = async (user) => {
    setSubmitting(true)
    let resp = await removeDepartmentUser(user)
    if (typeof resp === 'string') {
      alert(resp)
    }
    await refreshDepartments()
    setSubmitting(false)
  }

  const deleteGroup = async (group) => {
    setSubmitting(true)
    await deleteDepartmentGroup(group)
    await refreshDepartments()
    setSubmitting(false)
  }

  const selectDepartment = async (dept) => {
    setSelectedDepartment(dept)
  }

  const submitUser = async (user) => {
    let departmentID = selectedDepartment._id
    setSubmitting(true)
    if (user.user.id) {
      await editUserDepartmentRole({
        userDeptID: user.user.id,
        departmentID: departmentID,
        role: user.role
      })
    } else {
      await addUserToDepartment({
        userID: user.user.userID,
        departmentID: departmentID,
        role: user.role
      })
    }
    await refreshDepartments()

    setSubmitting(false)
    return
  }

  const submitDepartmentForm = async () => {
    setSubmitting(true)
    let department = {
      name: selectedDepartment.name,
      email: selectedDepartment.email,
      settings: {
        defaultOwners: selectedDepartment.settings.defaultOwners.map(item => item.id),
        canViewAll: selectedDepartment.settings.canViewAll.map(item => item.id),
        stale: selectedDepartment.settings.stale,
        highPriority: selectedDepartment.settings.highPriority,
        mediumPriority: selectedDepartment.settings.mediumPriority,
      },
      hiddenDepartment: selectedDepartment.hiddenDepartment,
    }

    if (selectedDepartment.hiddenDepartment) {
      department.redirectDepartment = selectedDepartment.redirectDepartment
    }

    let selectedDeptID = null
    if (selectedDepartment._id) {
      department._id = selectedDepartment._id
      selectedDeptID = selectedDepartment._id
      await editTicketDepartment(department)
    } else {
      selectedDeptID = await createTicketDepartment(department)
    }

    await refreshDepartments(selectedDeptID)
    setSubmitting(false)
  }
  const tabs = [
    {
      tabName: "Info",
      tabContent: <DepartmentInfoComp department={selectedDepartment} setDepartment={setSelectedDepartment} submitForm={submitDepartmentForm} selectableDepartments={selectableDepartments} />,
    },
    {
      tabName: "Members",
      tabContent: (
        <DepartmentMembers department={selectedDepartment} submit={submitUser} removeUser={removeUser} myUser={user} activeUsers={activeUsers} />),
    },
    {
      tabName: "Groups",
      tabContent: <DepartmentGroups department={selectedDepartment} createEditGroup={createEditGroup} deleteGroup={deleteGroup} />,
    },
    {
      tabName: "Ticket States",
      tabContent: <TicketStates updateTicketStates={updateDepartmentTicketStates} ticketStates={ticketStates} departmentID={selectedDepartment._id} />,
    },
    {
      tabName: "No Reply Emails",
      tabContent: <NoReplyEmails updateNoReplyEmails={updateNoReplyEmails} noReplyEmails={noReplyEmails} departmentID={selectedDepartment._id} />,
    },
    {
      tabName: "Blocked Emails",
      tabContent: <BlockedEmails updateBlockedEmails={updateBlockedEmails} blockedEmails={blockedEmails} departmentID={selectedDepartment._id} />,
    },
    {
      tabName: "Blocked Tickets",
      tabContent: <ListTickets
        list={blockedTickets}
        buttons={{
          checkBox: true,
          numDays: true,
          status: true,
          refresh: true,
          assignTo: true
        }}
        viewType='agent'
        cols={cols}
        searchTicket={searchTickets}
        updateList={updateTickets}
        redirect={redirect}
        updateNumDays={updateNumDays}
        numDays={numDays}
      />,
    },
  ];

  const createGroupTabs = [
    {
      tabName: "Info",
      tabContent: <DepartmentInfoComp department={selectedDepartment} setDepartment={setSelectedDepartment} submitForm={submitDepartmentForm} selectableDepartments={selectableDepartments} />,
    },
    {
      tabName: "Members",
      tabContent: <DepartmentMembers department={selectedDepartment} submit={submitUser} removeUser={removeUser} myUser={user} activeUsers={activeUsers} />,
    },
    {
      tabName: "Groups",
      tabContent: <DepartmentGroups department={selectedDepartment} createEditGroup={createEditGroup} deleteGroup={deleteGroup} />,
    },
    {
      tabName: "Ticket States",
      tabContent: <TicketStates updateTicketStates={updateDepartmentTicketStates} ticketStates={ticketStates} departmentID={selectedDepartment._id} />,
    },
    {
      tabName: "No Reply Emails",
      tabContent: <NoReplyEmails updateNoReplyEmails={updateNoReplyEmails} noReplyEmails={noReplyEmails} departmentID={selectedDepartment._id} />,
    },
    {
      tabName: "Blocked Emails",
      tabContent: <BlockedEmails updateBlockedEmails={updateBlockedEmails} blockedEmails={blockedEmails} departmentID={selectedDepartment._id} />,
    },
    {
      tabName: "Blocked Tickets",
      tabContent: <ListTickets
        list={blockedTickets}
        buttons={{
          checkBox: true,
          numDays: true,
          status: true,
          refresh: true,
          assignTo: true
        }}
        viewType='agent'
        cols={cols}
        searchTicket={searchTickets}
        updateList={updateTickets}
        redirect={redirect}
        updateNumDays={updateNumDays}
        numDays={numDays}
      />,
    },
    {
      tabName: selectedGroup?._id ? "Edit Group" : 'Create Group' || '',
      tabContent: <ManageGroup department={selectedDepartment} group={selectedGroup} createGroup={submitCreateEditGroup} />,
    },
  ]

  const tabsCreate = [
    {
      tabName: "Info",
      tabContent: <DepartmentInfoComp department={selectedDepartment} setDepartment={setSelectedDepartment} submitForm={submitDepartmentForm} selectableDepartments={selectableDepartments} />,
    }]


  return (
    <>
      {submitting ? <DepartmentManagerLoading {...props} /> :
        <React.Fragment>
          <GridContainer>
            <GridItem xs={12}>
              <div style={{ display: 'flex', width: '100%' }}>
                <div style={{ width: '100%', maxWidth: '300px' }}>
                  <Autocomplete
                    style={{ maxWidth: '300px' }}
                    disablePortal
                    options={manageableDepartments}
                    getOptionLabel={(option) =>
                      `${option.name}` || ""
                    }
                    renderInput={(params) => (
                      <TextField {...params} label="Department" />
                    )}
                    onChange={(e, v) => {
                      selectDepartment(v)
                    }}
                    autoSelect={true}
                    autoComplete={true}
                    autoHighlight={true}
                    disableClearable={true}
                    value={selectedDepartment._id === undefined ? null : selectedDepartment}
                    getOptionSelected={(selected) => {
                      return selectedDepartment._id === selected._id
                    }}
                  />
                </div>
                {(ticketSystemAdmin) && (
                  <div style={{ display: 'flex', alignItems: 'end', marginLeft: '5px' }}>
                    <Button
                      style={{ margin: '0px', padding: '7px 7px 4px 7px', height: '32px' }}
                      color='primary'
                      title='Create New'
                      onClick={(e) => {
                        e.preventDefault()
                        setSelectedDepartment(emptyDepartment)
                        setTab(0)
                        history.replace(`${props.location.pathname}?tab=0`);
                      }}
                    >
                      Create New
                    </Button>
                  </div>)}
              </div>
            </GridItem>
          </GridContainer>
          <div style={{ display: 'flex', maxWidth: '1100px' }}>
            {selectedDepartment._id ?
              <>
                {selectedGroup ?
                  <CustomTabs headerColor="info" tabs={createGroupTabs} value={tab} setValue={setTab} />
                  :
                  <CustomTabs headerColor="info" tabs={tabs} value={tab} setValue={setTab} />
                }

              </>
              :
              <CustomTabs headerColor="info" tabs={tabsCreate} value={tab} setValue={setTab} />
            }
          </div>
        </React.Fragment>
      }
    </>
  )
}

const DepartmentManagerLoading = (props) => {
  const tab = +(props.location.search?.split("=")[1] || 0);
  const tabs = [
    {
      tabName: "Info",
      tabContent: <Loading color="blue" />,
    },
    {
      tabName: "Members",
      tabContent: <Loading color="blue" />,
    },
    {
      tabName: "Groups",
      tabContent: <Loading color="blue" />,
    },
    {
      tabName: "No Reply Emails",
      tabContent: <Loading color="blue" />,
    },
    {
      tabName: "Blocked Emails",
      tabContent: <Loading color="blue" />,
    },
    {
      tabName: "Blocked Tickets",
      tabContent: <Loading color="blue" />,
    },
  ];
  return (
    <React.Fragment>
      <GridContainer>
        <GridItem xs={12}>
          <Autocomplete
            style={{ maxWidth: '300px' }}
            disablePortal
            options={[]}
            getOptionLabel={(option) =>
              `${option.name}` || ""
            }
            renderInput={(params) => (
              <TextField {...params} label="Department" />
            )}
            autoSelect={true}
            autoComplete={true}
            autoHighlight={true}
            disableClearable={true}
            value={null}
          />
        </GridItem>
      </GridContainer>
      <div style={{ display: 'flex', maxWidth: '1100px' }}>
        <CustomTabs headerColor="info" tabs={tabs} value={tab} />
      </div>
    </React.Fragment>
  );
}

const DepartmentManager = (props) => {
  return (
    <React.Suspense fallback={<DepartmentManagerLoading {...props} />}>
      <DepartmentManagerComp {...props} />
    </React.Suspense>
  );
}

const DepartmentInfoComp = ({ department, setDepartment, submitForm, selectableDepartments }) => {

  const emptyError = {
    hasError: false,
    errorMessage: ''
  }

  const emptyErrors = {
    name: { ...emptyError },
    email: { ...emptyError },
    defaultOwners: { ...emptyError },
    canViewAll: { ...emptyError },
    priority: { ...emptyError },
    hiddenDepartment: { ...emptyError },
    redirectDepartment: { ...emptyError }
  }

  const [errors, setErrors] = useState(emptyErrors)

  const getDropDownTypes = () => {
    let items = ['All']
    if (department.groups.length > 0) {
      items.push('Group')
    }
    if (department.users.length > 0) {
      items.push('User')
    }
    return items
  }

  const dropdownTypes = getDropDownTypes()

  const addNewTicketOwnerEntry = () => {
    let entry = {
      id: null,
      name: null,
      type: 'User'
    }
    setDepartment((prevState) => {
      return update(prevState, {
        settings: { defaultOwners: { $push: [entry] } }
      })
    })
  }

  const updateDefaultTicketOwner = (item, index) => {
    setDepartment((prevState) => {
      return update(prevState, {
        settings: { defaultOwners: { [index]: { $set: item } } }
      })
    })
  }

  const removeDefaultTicketOwner = (index) => {
    setDepartment((prevState) => {
      return update(prevState, {
        settings: { defaultOwners: { $splice: [[index, 1]] } }
      })
    })
    if (department.settings.defaultOwners.length === 1) {
      addNewTicketOwnerEntry()
    }
  }

  const addNewCanViewAll = () => {
    let entry = {
      id: null,
      name: null,
      type: 'User'
    }
    setDepartment((prevState) => {
      return update(prevState, {
        settings: { canViewAll: { $push: [entry] } }
      })
    })
  }

  const updateCanViewAll = (item, index) => {
    setDepartment((prevState) => {
      return update(prevState, {
        settings: { canViewAll: { [index]: { $set: item } } }
      })
    })
  }

  const removeCanViewAll = (index) => {
    setDepartment((prevState) => {
      return update(prevState, {
        settings: { canViewAll: { $splice: [[index, 1]] } }
      })
    })
    if (department.settings.canViewAll.length === 1) {
      addNewCanViewAll()
    }
  }

  const filterOutExisting = (options, alreadySelected, type, currentVal = null) => {
    let newOptions = []
    if (type === 'User') {
      options.forEach(item => {
        if (!alreadySelected.find(i => i.id === item.userProfile.googleID)) {
          newOptions.push(item)
        }
      })
    }

    if (type === 'Group') {
      options.forEach(item => {
        if (!alreadySelected.find(i => i.id === item._id)) {
          newOptions.push(item)
        }
      })
    }

    if (type === 'Type') {
      if (alreadySelected.find(i => i.type === 'All') && currentVal !== 'All') {
        return options.filter(i => i !== 'All')
      }
      return options
    }

    return newOptions
  }

  const validateForm = () => {
    let validForm = true
    setErrors(emptyErrors)

    if (department.name.trim() === '') {
      validForm = false
      setErrors((prevState) => {
        return update(prevState, {
          name: {
            $merge: {
              hasError: true,
              errorMessage: "*This field cannot be left blank.",
            },
          },
        });
      })
    }

    if (department.email.trim() === '') {
      validForm = false
      setErrors((prevState) => {
        return update(prevState, {
          email: {
            $merge: {
              hasError: true,
              errorMessage: "*This field cannot be left blank.",
            },
          },
        });
      })
    }

    if (!department.email.includes('@sagebrush.')) {
      validForm = false
      setErrors((prevState) => {
        return update(prevState, {
          email: {
            $merge: {
              hasError: true,
              errorMessage: "*Must be a @sagebrush email.",
            },
          },
        });
      })
    }

    if (department.settings.defaultOwners.find(item => item.id === null || item.id === undefined)) {
      validForm = false
      setErrors((prevState) => {
        return update(prevState, {
          defaultOwners: {
            $merge: {
              hasError: true,
              errorMessage: "*Fields cannot be left blank.",
            },
          },
        });
      })
    }

    if (department.settings.canViewAll.find(item => item.id === null || item.id === undefined)) {
      validForm = false
      setErrors((prevState) => {
        return update(prevState, {
          canViewAll: {
            $merge: {
              hasError: true,
              errorMessage: "*Fields cannot be left blank.",
            },
          },
        });
      })
    }

    if (department.settings.highPriority === '' || department.settings.mediumPriority === '' ||
      department.settings.stale === '') {
      validForm = false
      setErrors((prevState) => {
        return update(prevState, {
          priority: {
            $merge: {
              hasError: true,
              errorMessage: "*Fields cannot be left blank.",
            },
          },
        });
      })
    } else if (department.settings.highPriority < 1 || department.settings.mediumPriority < 1 ||
      department.settings.stale < 1) {
      validForm = false
      setErrors((prevState) => {
        return update(prevState, {
          priority: {
            $merge: {
              hasError: true,
              errorMessage: "*Fields cannot be less than 1.",
            },
          },
        });
      })
    }

    if (validForm) {
      setErrors(emptyErrors)
    }

    return validForm
  }

  return (
    <React.Fragment>
      <GridContainer>
        <GridItem xs={12}>
          <div style={{ display: 'flex', overflow: 'auto', padding: '10px 0px', flexWrap: 'wrap', margin: '-5px' }}>
            <TextField
              style={{ minWidth: '290px', flex: 1, margin: '5px' }}
              required
              label="Name"
              variant="outlined"
              fullWidth
              value={department.name}
              onChange={(e) => {
                setDepartment((prevState) => {
                  return update(prevState, {
                    name: { $set: e.target.value }
                  })
                })
              }}
              helperText={errors.name.errorMessage}
              error={errors.name.hasError}
            />
            <TextField
              style={{ minWidth: '290px', flex: 1, margin: '5px' }}
              required
              label="Email"
              variant="outlined"
              fullWidth
              value={department.email}
              onChange={(e) => {
                setDepartment((prevState) => {
                  return update(prevState, {
                    email: { $set: e.target.value }
                  })
                })
              }}
              helperText={errors.email.errorMessage}
              error={errors.email.hasError}
            />
          </div>

          <span style={errors.priority.hasError ? { color: 'red' } : {}}>Priority Change:</span>
          <div
            style={{
              border: errors.priority.hasError ? "1px solid red" : "1px solid #ddd",
              borderRadius: "3px",
              background: "#fafafa",
              padding: "10px",
              minWidth: '290px',
              overflowX: 'auto',
            }}
          >
            <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', maxWidth: '640px', marginBottom: '5px' }}>
              <div style={{ display: 'flex', alignItems: 'end' }}>
                <span
                  style={{ marginRight: '5px', fontSize: '16px', fontWeight: '400', marginBottom: '2px' }}>
                  High:
                </span>
                <span style={{ marginRight: '5px' }}>due in</span>
                <TextField
                  style={{ width: '60px' }}
                  value={department.settings.highPriority}
                  type="number"
                  onChange={(e) => {
                    let val = e.target.value
                    if (val !== '') {
                      if (val < 0) {
                        val = val * -1
                      }
                      if (val > 999) {
                        val = 999
                      } else {
                        val = Math.round(val)
                      }
                    }
                    setDepartment((prevState) => {
                      return update(prevState, {
                        settings: { highPriority: { $set: val } }
                      })
                    })
                  }}
                />
                <span>day{(department.settings.highPriority !== 1) && ('s')}</span>
              </div>

              <div style={{ display: 'flex', alignItems: 'end' }}>
                <span
                  style={{ marginRight: '5px', fontSize: '16px', fontWeight: '400', marginBottom: '2px' }}>
                  Medium:
                </span>
                <span style={{ marginRight: '5px' }}>due in</span>
                <TextField
                  style={{ width: '60px' }}
                  value={department.settings.mediumPriority}
                  type="number"
                  onChange={(e) => {
                    let val = e.target.value
                    if (val !== '') {
                      if (val < 0) {
                        val = val * -1
                      }
                      if (val > 999) {
                        val = 999
                      } else {
                        val = Math.round(val)
                      }
                    }
                    setDepartment((prevState) => {
                      return update(prevState, {
                        settings: { mediumPriority: { $set: val } }
                      })
                    })
                  }}
                />
                <span>day{(department.settings.mediumPriority !== 1) && ('s')}</span>
              </div>

              <div style={{ display: 'flex', alignItems: 'end' }}>
                <span
                  style={{ marginRight: '5px', fontSize: '16px', fontWeight: '400', marginBottom: '2px' }}>
                  Stale:
                </span>
                <span style={{ marginRight: '5px' }}>after</span>
                <TextField
                  style={{ width: '60px' }}
                  value={department.settings.stale}
                  type="number"
                  onChange={(e) => {
                    let val = e.target.value
                    if (val !== '') {
                      if (val < 0) {
                        val = val * -1
                      }
                      if (val > 999) {
                        val = 999
                      } else {
                        val = Math.round(val)
                      }
                    }
                    setDepartment((prevState) => {
                      return update(prevState, {
                        settings: { stale: { $set: val } }
                      })
                    })
                  }}
                />
                <span>day{(department.settings.stale !== 1) && ('s')}</span>
              </div>
            </div>

          </div>
          <HelperText error={errors.priority} />

          <span style={errors.defaultOwners.hasError ? { color: 'red' } : {}}>Default Ticket Owners:</span>
          <div
            style={{
              border: errors.defaultOwners.hasError ? "1px solid red" : "1px solid #ddd",
              borderRadius: "3px",
              background: "#fafafa",
              padding: "10px",
              minWidth: '290px',
              overflowX: 'auto',
            }}
          >
            {department.settings.defaultOwners.map((item, index) => {
              return (
                <div key={index} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <div style={{ display: 'flex', padding: '10px 0px', margin: '-5px', width: '100%', flexWrap: 'wrap' }} key={item.id}>
                    <div style={{ minWidth: '225px', flex: 1, margin: '0px 5px' }}>
                      <Autocomplete
                        disablePortal
                        options={filterOutExisting(dropdownTypes, department.settings.defaultOwners, 'Type', item.type)}
                        fullWidth
                        disabled={department._id === undefined}
                        getOptionLabel={(option) =>
                          option}
                        value={item.type}
                        renderInput={(params) => (
                          <TextField {...params} label="Type" />
                        )}
                        onChange={(e, v) => {
                          if (v !== item.type) {
                            let entry = {
                              id: null,
                              name: null,
                              type: v
                            }
                            if (v === 'All') {
                              entry.name = 'All'
                              entry.id = 'All'
                            }
                            updateDefaultTicketOwner(entry, index)
                          }
                        }
                        }
                        autoSelect={true}
                        autoComplete={true}
                        autoHighlight={true}
                        disableClearable
                      />
                    </div>
                    <div style={{ minWidth: '225px', flex: 1, margin: '0px 5px' }}>
                      {item.type !== 'All' && (
                        <>
                          {item.type === 'Group' ?
                            <Autocomplete
                              disablePortal
                              options={filterOutExisting(department.groups, department.settings.defaultOwners, item.type)}
                              fullWidth
                              getOptionSelected={(selected) => {
                                return selected._id === item.id
                              }}
                              getOptionLabel={(option) => {
                                if (typeof option === 'string') {
                                  let opt = department.groups.find(item => item._id === option)
                                  if (opt) { return opt.name }
                                  return null
                                }
                                return option.name
                              }}
                              value={item.id}
                              renderInput={(params) => (
                                <TextField {...params} label={item.type} />
                              )}
                              onChange={(e, v) => {
                                let entry = {
                                  id: v._id,
                                  name: v.name,
                                  type: item.type
                                }
                                updateDefaultTicketOwner(entry, index)
                              }
                              }
                              autoSelect={true}
                              autoComplete={true}
                              autoHighlight={true}
                              disableClearable
                            />
                            :
                            <Autocomplete
                              disablePortal
                              options={filterOutExisting(department.users, department.settings.defaultOwners, item.type)}
                              fullWidth
                              getOptionSelected={(selected) => {
                                return selected.userProfile.googleID === item.id
                              }}
                              getOptionLabel={(option) => {
                                if (typeof option === 'string') {
                                  let opt = department.users.find(item => item.userProfile.googleID === option)
                                  if (opt) { return `${opt.userProfile.firstName} ${opt.userProfile.lastName}` }
                                  return null
                                }
                                return `${option.userProfile.firstName} ${option.userProfile.lastName}`
                              }}
                              value={item.id}
                              renderInput={(params) => (
                                <TextField {...params} label={item.type} />
                              )}
                              onChange={(e, v) => {
                                let entry = {
                                  id: v.userProfile.googleID,
                                  name: `${v.userProfile.firstName} ${v.userProfile.lastName}`,
                                  type: item.type
                                }
                                updateDefaultTicketOwner(entry, index)
                              }
                              }
                              autoSelect={true}
                              autoComplete={true}
                              autoHighlight={true}
                              disableClearable
                            />
                          }
                        </>
                      )
                      }
                    </div>
                  </div>
                  {(department._id !== undefined) && (
                    <Button
                      style={{ margin: '0px', padding: '7px', height: '32px', marginLeft: '10px' }}
                      color='danger'
                      title='Remove'
                      onClick={(e) => {
                        e.preventDefault()
                        removeDefaultTicketOwner(index)
                      }}
                    >
                      <CloseIcon style={{ margin: 0, padding: 0 }} />
                    </Button>
                  )}
                </div>
              )
            })}
            {(department._id !== undefined) && (
              <div style={{ width: '100%', textAlign: 'right' }}>
                <Button
                  style={{ margin: '0px', padding: '7px', height: '32px' }}
                  color='primary'
                  title='Add'
                  onClick={(e) => {
                    e.preventDefault()
                    addNewTicketOwnerEntry()
                  }}
                >
                  Add
                </Button>
              </div>
            )}
          </div>
          <HelperText error={errors.defaultOwners} />

          <span style={errors.canViewAll.hasError ? { color: 'red' } : {}}>Can View All Tickets:</span>
          <div
            style={{
              border: errors.canViewAll.hasError ? "1px solid red" : "1px solid #ddd",
              borderRadius: "3px",
              background: "#fafafa",
              padding: "10px",
              minWidth: '290px',
              overflowX: 'auto',
            }}
          >
            {department.settings.canViewAll.map((item, index) => {
              return (
                <div key={index} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <div style={{ display: 'flex', padding: '10px 0px', margin: '-5px', width: '100%', flexWrap: 'wrap' }} key={item.id}>
                    <div style={{ minWidth: '225px', flex: 1, margin: '0px 5px' }}>
                      <Autocomplete
                        disablePortal
                        options={filterOutExisting(dropdownTypes, department.settings.canViewAll, 'Type', item.type)}
                        fullWidth
                        disabled={department._id === undefined}
                        getOptionLabel={(option) =>
                          option}
                        value={item.type}
                        renderInput={(params) => (
                          <TextField {...params} label="Type" />
                        )}
                        onChange={(e, v) => {
                          if (v !== item.type) {
                            let entry = {
                              id: null,
                              name: null,
                              type: v
                            }
                            if (v === 'All') {
                              entry.name = 'All'
                              entry.id = 'All'
                            }
                            updateCanViewAll(entry, index)
                          }
                        }
                        }
                        autoSelect={true}
                        autoComplete={true}
                        autoHighlight={true}
                        disableClearable
                      />
                    </div>
                    <div style={{ minWidth: '225px', flex: 1, margin: '0px 5px' }}>
                      {item.type !== 'All' && (
                        <>
                          {item.type === 'Group' ?
                            <Autocomplete
                              disablePortal
                              options={filterOutExisting(department.groups, department.settings.canViewAll, item.type)}
                              fullWidth
                              getOptionSelected={(selected) => {
                                return selected._id === item.id
                              }}
                              getOptionLabel={(option) => {
                                if (typeof option === 'string') {
                                  let opt = department.groups.find(item => item._id === option)
                                  if (opt) { return opt.name }
                                  return null
                                }
                                return option.name
                              }}
                              value={item.id}
                              renderInput={(params) => (
                                <TextField {...params} label={item.type} />
                              )}
                              onChange={(e, v) => {
                                let entry = {
                                  id: v._id,
                                  name: v.name,
                                  type: item.type
                                }
                                updateCanViewAll(entry, index)
                              }
                              }
                              autoSelect={true}
                              autoComplete={true}
                              autoHighlight={true}
                              disableClearable
                            />
                            :
                            <Autocomplete
                              disablePortal
                              options={filterOutExisting(department.users, department.settings.canViewAll, item.type)}
                              fullWidth
                              getOptionSelected={(selected) => {
                                return selected.userProfile.googleID === item.id
                              }}
                              getOptionLabel={(option) => {
                                if (typeof option === 'string') {
                                  let opt = department.users.find(item => item.userProfile.googleID === option)
                                  if (opt) { return `${opt.userProfile.firstName} ${opt.userProfile.lastName}` }
                                  return null
                                }
                                return `${option.userProfile.firstName} ${option.userProfile.lastName}`
                              }}
                              value={item.id}
                              renderInput={(params) => (
                                <TextField {...params} label={item.type} />
                              )}
                              onChange={(e, v) => {
                                let entry = {
                                  id: v.userProfile.googleID,
                                  name: `${v.userProfile.firstName} ${v.userProfile.lastName}`,
                                  type: item.type
                                }
                                updateCanViewAll(entry, index)
                              }
                              }
                              autoSelect={true}
                              autoComplete={true}
                              autoHighlight={true}
                              disableClearable
                            />
                          }
                        </>
                      )
                      }
                    </div>
                  </div>
                  {(department._id !== undefined) && (
                    <Button
                      style={{ margin: '0px', padding: '7px', height: '32px', marginLeft: '10px' }}
                      color='danger'
                      title='Remove'
                      onClick={(e) => {
                        e.preventDefault()
                        removeCanViewAll(index)
                      }}
                    >
                      <CloseIcon style={{ margin: 0, padding: 0 }} />
                    </Button>
                  )}
                </div>
              )
            })}
            {(department._id !== undefined) && (
              <div style={{ width: '100%', textAlign: 'right' }}>
                <Button
                  style={{ margin: '0px', padding: '7px', height: '32px' }}
                  color='primary'
                  title='Add'
                  onClick={(e) => {
                    e.preventDefault()
                    addNewCanViewAll()
                  }}
                >
                  Add
                </Button>
              </div>
            )}
          </div>
          <HelperText error={errors.canViewAll} />

          {selectableDepartments.length > 0 && (
            <div style={{ display: 'flex', marginTop: '15px', flexWrap: 'wrap', height: '56px' }}>
              <div style={{ display: 'flex', alignItems: 'center', flex: 1, minWidth: '270px' }}>
                <p style={{ padding: 0, margin: 0 }}>Hidden Department?</p>
                <FormControlLabel
                  label="Yes"
                  style={{ margin: 0, padding: 0 }}
                  control={
                    <Radio
                      color='primary'
                      value={true}
                      style={department.hiddenDepartment ? { color: '#3a9ddc' } : {}}
                      checked={department.hiddenDepartment}
                      onChange={(e) => {
                        setDepartment((prevState) => {
                          return update(prevState, {
                            hiddenDepartment: { $set: true }
                          })
                        })
                      }}
                    />
                  }
                />
                <FormControlLabel
                  label="No"
                  style={{ margin: 0, padding: 0 }}
                  control={
                    <Radio
                      color='primary'
                      style={!department.hiddenDepartment ? { color: '#3a9ddc' } : {}}
                      value={false}
                      checked={!department.hiddenDepartment}
                      onChange={(e) => {
                        setDepartment((prevState) => {
                          return update(prevState, {
                            hiddenDepartment: { $set: false }
                          })
                        })
                      }}
                    />
                  }
                />
              </div>
              {department.hiddenDepartment && (
                <div style={{ flex: 1, minWidth: '250px' }}>
                  <Autocomplete
                    disablePortal
                    options={selectableDepartments}
                    fullWidth
                    getOptionLabel={(option) => {
                      return option.name
                    }}
                    value={department.redirectDepartment ? selectableDepartments.find(i => i.id === department.redirectDepartment) : null}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={'Department Redirect'}
                        helperText={errors.redirectDepartment.errorMessage}
                        error={errors.redirectDepartment.hasError}
                        variant="outlined"
                      />
                    )}
                    onChange={(e, v) => {
                      setDepartment((prevState) => {
                        return update(prevState, {
                          redirectDepartment: { $set: v.id }
                        })
                      })
                    }
                    }
                    autoSelect={true}
                    autoComplete={true}
                    autoHighlight={true}
                    disableClearable
                    ListboxProps={
                      {
                        style: {
                          maxHeight: '200px',
                        }
                      }
                    }
                  />
                </div>
              )}
            </div>)}
          <div style={{ width: '100%', textAlign: 'right', marginTop: '10px' }}>
            <Button
              style={{ margin: '0px', padding: '7px', height: '32px' }}
              color='primary'
              title='Save'
              onClick={(e) => {
                e.preventDefault()
                if (validateForm()) {
                  submitForm()
                }
              }}
            >
              Save
            </Button>
          </div>
        </GridItem>
      </GridContainer>
    </React.Fragment >
  )
}

const DepartmentGroups = ({ department, createEditGroup, deleteGroup }) => {
  const emptyGroup = {
    name: '',
    departmentID: department._id,
    userIDs: [null]
  }
  const tableEntries = department.groups.map(item => {
    let entry = {
      ...item,
      numUsers: item.userIDs.length,
      actions: (
        <React.Fragment>

          <Button
            justIcon
            color="primary"
            onClick={() => createEditGroup(item)}
            title='Edit'
          >
            <Edit />
          </Button>
          <Button
            justIcon
            color="danger"

            onClick={() => deleteGroup({
              id: item._id,
              departmentID: department._id
            })}
            title='Delete'
          >
            <Close />
          </Button>
        </React.Fragment>
      )
    }
    return entry
  })

  const cols = [
    {
      Header: "Name",
      accessor: "name",
      disableFilters: false,
      disableSortBy: true,
    },
    {
      Header: "# of Members",
      accessor: "numUsers",
      disableFilters: false,
      disableSortBy: true,
    },
    {
      Header: "",
      accessor: "actions",
      disableFilters: true,
      disableSortBy: true,
      style: { maxWidth: '54px', display: 'flex', alignItems: 'center', justifyContent: 'center', flexWrap: 'wrap' }
    },
  ]
  return (
    <React.Fragment>
      <GridContainer>
        <GridItem xs={12} style={{ textAlign: 'right' }}>
          <Button
            style={{ padding: '10px 12px 7px 12px' }}
            color="primary"
            onClick={() => createEditGroup(emptyGroup)}
            title='Create New'
          >
            Create New
          </Button>
        </GridItem>
        <GridItem xs={12}>
          {tableEntries.length > 0 && (
            <ReactTable
              columns={cols}
              data={tableEntries}
            />
          )}

          {tableEntries.length === 0 && (
            <p style={{ textAlign: "center" }}>No groups have been created.</p>
          )}
        </GridItem>
      </GridContainer>
    </React.Fragment>
  )
}

const DepartmentMembers = ({ department, submit, removeUser, myUser, activeUsers }) => {

  const emptyUser = {
    user: null,
    role: 'Member',
  }

  const [user, setUser] = useState(emptyUser)
  const classes = useStyles();

  const tableEntries = department.users.map(item => {
    let entry = {
      ...item,
      name: `${item.userProfile.firstName} ${item.userProfile.lastName}`,
      email: item.userProfile.email,
      phone: formatPhoneNumber(item.userProfile.phone),
    }

    let newItem = {
      ...entry,
      profilePhoto: (
        <img
          src={item.userProfile.avatar ? item.userProfile.avatar : blankProfile}
          className={classes.avatarImg}
          style={{ width: "42px", height: "42px", borderRadius: '30px' }}
        />),
      actions: (
        <React.Fragment>
          <Button
            justIcon
            color="primary"
            disabled={myUser.googleID === item.userID && department.myRole !== 'Admin'}
            onClick={() => {
              setUser({
                user: entry,
                role: item.role
              })
            }}
            title='Edit'
          >
            <Edit />
          </Button>
          <Button
            justIcon
            color="danger"
            disabled={myUser.googleID === item.userID && department.myRole !== 'Admin'}
            onClick={() => {
              if (confirm(`Remove ${entry.name} from department?`)) {
                removeUser({
                  userDeptID: item.id,
                  departmentID: department._id
                })
              }
            }}
            title='Delete'
          >
            <Close />
          </Button>
        </React.Fragment>
      )
    }
    return newItem
  })

  const cols = [
    {
      Header: "",
      accessor: "profilePhoto",
      disableFilters: true,
      disableSortBy: true,
      style: {
        maxWidth: '48px',
        margin: '0px 5px',
        display: 'flex',
        alignItems: 'center'
      }
    },
    {
      Header: "Name",
      accessor: "name",
      disableFilters: false,
      disableSortBy: true,
    },
    {
      Header: "Email",
      accessor: "email",
      disableFilters: false,
      disableSortBy: true,
    },
    {
      Header: "Phone",
      accessor: "phone",
      disableFilters: false,
      disableSortBy: true,
    },
    {
      Header: "Role",
      accessor: "role",
      disableFilters: false,
      disableSortBy: true,
    },
    {
      Header: "",
      accessor: "actions",
      disableFilters: true,
      disableSortBy: true,
      style: { maxWidth: '54px', display: 'flex', alignItems: 'center', justifyContent: 'center', flexWrap: 'wrap' }
    },
  ]

  const submitForm = () => {
    submit(user)
  }

  return (
    <React.Fragment>
      <GridContainer>
        <GridItem xs={12}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div style={{ display: 'flex', padding: '10px 0px', margin: '-5px', width: '100%', flexWrap: 'wrap' }}>
              <div style={{ minWidth: '225px', flex: 1, margin: '5px' }}>
                <Autocomplete
                  disablePortal
                  options={activeUsers}
                  fullWidth
                  getOptionLabel={(option) =>
                    option.name}
                  getOptionSelected={(selected) => {
                    return selected.userID === user.user.userID
                  }}
                  value={user.user}
                  renderInput={(params) => (
                    <TextField {...params} label="User" variant="outlined" />
                  )}
                  onChange={(e, v) => {
                    setUser(prevState => {
                      return update(prevState, {
                        user: { $set: v }
                      })
                    })
                  }
                  }
                  autoSelect={true}
                  autoComplete={true}
                  autoHighlight={true}
                  disableClearable
                />
              </div>
              <div style={{ minWidth: '225px', flex: 1, margin: '5px' }}>
                <Autocomplete
                  disablePortal
                  options={['Owner', 'Member']}
                  fullWidth
                  value={user.role}
                  renderInput={(params) => (
                    <TextField {...params} label='Role' variant="outlined" />
                  )}
                  onChange={(e, v) => {
                    setUser(prevState => {
                      return update(prevState, {
                        role: { $set: v }
                      })
                    })
                  }
                  }
                  autoSelect={true}
                  autoComplete={true}
                  autoHighlight={true}
                  disableClearable
                />
              </div>
            </div>
            {(department._id !== undefined) && (
              <Button
                style={{ margin: '0px', padding: '7px 20px 4px 20px', height: '54px', marginLeft: '10px' }}
                color='primary'
                title='Add'
                disabled={!user.role || !user.user}
                onClick={async (e) => {
                  e.preventDefault()
                  submitForm()
                }}
              >
                Add
              </Button>
            )}
          </div>
          {tableEntries.length > 0 && (
            <ReactTable
              columns={cols}
              data={tableEntries}
              defaultPageSize={tableEntries.length}
              minWidthTable={'600px'}
            />
          )}

          {tableEntries.length === 0 && (
            <p style={{ textAlign: "center" }}>No members in department.</p>
          )}
        </GridItem>
      </GridContainer>
    </React.Fragment>
  )
}

const ManageGroup = ({ department, group, createGroup }) => {
  const emptyError = {
    hasError: false,
    errorMessage: ''
  }

  const emptyErrors = {
    name: emptyError,
    userIDs: emptyError
  }
  const [managedGroup, setManagedGroup] = useState(group)
  const [errors, setErrors] = useState(emptyErrors)

  const users = department.users

  const getAvailableUsers = (userID) => {
    let options = users.filter(item => !managedGroup.userIDs.find(userID => userID === item.userID))
    let user = users.find(i => i.userID === userID)
    if (user) {
      options.push(user)
    }
    return options
  }
  const addNewUser = () => {
    let entry = null
    setManagedGroup((prevState) => {
      return update(prevState, {
        userIDs: { $push: [entry] }
      })
    })
  }

  const updateUser = (item, index) => {
    setManagedGroup((prevState) => {
      return update(prevState, {
        userIDs: { [index]: { $set: item } }
      })
    })
  }

  const removeUser = (index) => {
    setManagedGroup((prevState) => {
      return update(prevState, {
        userIDs: { $splice: [[index, 1]] }
      })
    })
    if (managedGroup.userIDs.length === 1) {
      addNewUser()
    }
  }

  const validateForm = () => {
    let valid = true
    setErrors(emptyErrors)

    if (managedGroup.name.trim() === '') {
      valid = false
      setErrors((prevState) => {
        return update(prevState, {
          name: {
            $merge: {
              hasError: true,
              errorMessage: "*Required.",
            },
          },
        });
      })
    }
    if (!managedGroup.userIDs) {
      valid = false
      setErrors((prevState) => {
        return update(prevState, {
          userIDs: {
            $merge: {
              hasError: true,
              errorMessage: "*Required.",
            },
          },
        });
      })
    } else {
      if (managedGroup.userIDs.length === 0) {
        valid = false
        setErrors((prevState) => {
          return update(prevState, {
            userIDs: {
              $merge: {
                hasError: true,
                errorMessage: "*Required.",
              },
            },
          });
        })
      }
      if (managedGroup.userIDs.indexOf(null) !== -1) {
        valid = false
        setErrors((prevState) => {
          return update(prevState, {
            userIDs: {
              $merge: {
                hasError: true,
                errorMessage: "*Fields cannot be left blank.",
              },
            },
          });
        })
      }
    }
    return valid
  }
  return (
    <GridContainer>
      <GridItem xs={12}>
        <div style={{ display: 'flex', overflow: 'auto', padding: '10px 0px', flexWrap: 'wrap', margin: '-5px' }}>
          <TextField
            style={{ minWidth: '290px', flex: 1, margin: '5px' }}
            required
            label="Name"
            variant="outlined"
            fullWidth
            value={managedGroup.name}
            onChange={(e) => {
              setManagedGroup((prevState) => {
                return update(prevState, {
                  name: { $set: e.target.value }
                })
              })
            }}
            helperText={errors.name.errorMessage}
            error={errors.name.hasError}
          />
        </div>
        <span style={errors.userIDs.hasError ? { color: 'red' } : {}}>Users:</span>
        <div
          style={{
            border: errors.userIDs.hasError ? "1px solid red" : "1px solid #ddd",
            borderRadius: "3px",
            background: "#fafafa",
            padding: "10px",
            minWidth: '290px',
            overflowX: 'auto',
          }}
        >
          {managedGroup.userIDs.map((item, index) => {
            return (
              <div key={index} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
                <div style={{ minWidth: '225px', margin: '0px 5px', width: '400px' }}>
                  <Autocomplete
                    disablePortal
                    options={getAvailableUsers(item)}
                    fullWidth
                    getOptionLabel={(option) => {
                      if (typeof option === 'string') {
                        let user = users.find(i => i.userID === option)
                        if (user) {
                          return `${user.userProfile.firstName} ${user.userProfile.lastName}`
                        }
                      }
                      return `${option.userProfile.firstName} ${option.userProfile.lastName}`
                    }}
                    getOptionSelected={(selected) => {
                      return selected.userID === item
                    }}
                    value={item}
                    renderInput={(params) => (
                      <TextField {...params} label="User" />
                    )}
                    onChange={(e, v) => {
                      updateUser(v.userID, index)
                    }
                    }
                    autoSelect={true}
                    autoComplete={true}
                    autoHighlight={true}
                    disableClearable
                  />
                </div>
                <Button
                  style={{ margin: '0px', padding: '7px', height: '32px', marginLeft: '10px' }}
                  color='danger'
                  title='Remove'
                  onClick={(e) => {
                    e.preventDefault()
                    removeUser(index)
                  }}
                >
                  <CloseIcon
                    style={{ margin: 0, padding: 0 }}
                  />
                </Button>
              </div>
            )
          })}
          <div style={{ width: '100%', textAlign: 'right' }}>
            <Button
              style={{ margin: '0px', padding: '7px 7px 4px 7px', height: '32px' }}
              color='primary'
              title='Add'
              disabled={users.length === managedGroup.userIDs.length}
              onClick={(e) => {
                e.preventDefault()
                addNewUser()
              }}
            >
              Add
            </Button>
          </div>
        </div>
        <HelperText error={errors.userIDs} />
        <div style={{ width: '100%', textAlign: 'right', marginTop: '10px' }}>
          <Button
            style={{ margin: '0px', padding: '7px', height: '32px' }}
            color='primary'
            title='Save'
            onClick={(e) => {
              e.preventDefault()
              if (validateForm()) {
                createGroup(managedGroup)
              }
            }}
          >
            Save
          </Button>
        </div>
      </GridItem>
    </GridContainer>
  )
}

const BlockedEmails = ({ updateBlockedEmails, blockedEmails, departmentID }) => {
  const [loading, setLoading] = useState(false)
  const newEmail = {
    email: '',
  }
  const [blockedEmail, setBlockedEmail] = useState(newEmail)
  const validEmail = blockedEmail.email.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

  const submitBlockEmail = async () => {
    setLoading(true)
    if (blockedEmail.id) {
      await editBlockedEmail({
        id: blockedEmail.id,
        email: blockedEmail.email,
        departmentID: departmentID
      })
    } else {
      await blockEmail({
        email: blockedEmail.email,
        departmentID: departmentID
      })
    }
    setBlockedEmail(newEmail)
    await updateBlockedEmails()
    setLoading(false)
  }

  const deleteBlockedEmail = async (id) => {
    setLoading(true)
    await removeBlockedEmail({
      id: id,
      departmentID: departmentID
    })
    await updateBlockedEmails()
    setLoading(false)
  }

  const listBlockedEmails = blockedEmails.map(email => {
    let entry = {
      id: email._id,
      email: email.email,
      actions: (
        <React.Fragment>
          <Button
            justIcon
            color="primary"
            onClick={() => {
              setBlockedEmail({
                id: email._id,
                email: email.email
              })
            }}
            title='Edit'
          >
            <Edit />
          </Button>
          <Button
            justIcon
            color="danger"
            onClick={() => {
              if (confirm(`Unblock email ${email.email}?`)) {
                deleteBlockedEmail(email._id)
              }
            }}
            title='Delete'
          >
            <Close />
          </Button>
        </React.Fragment>
      )
    }
    return entry
  })

  const cols = [
    {
      Header: "Email",
      accessor: "email",
      disableFilters: false,
      disableSortBy: true,
    },
    {
      Header: "",
      accessor: "actions",
      disableFilters: true,
      disableSortBy: true,
      style: { maxWidth: '110px', display: 'flex', alignItems: 'center', justifyContent: 'center', flexWrap: 'wrap' }
    },
  ]
  return (
    <React.Fragment>
      <GridContainer>
        <GridItem xs={12}>
          {loading ? <Loading color='blue' /> :
            <>
              <div style={{ display: 'flex', justifyContent: 'right', marginBottom: '10px' }}>
                <TextField
                  variant="outlined"
                  label='Email'
                  fullWidth
                  style={{ maxWidth: '400px' }}
                  value={blockedEmail.email}
                  onChange={(e) => {
                    setBlockedEmail((prev) => {
                      return update(prev, {
                        email: { $set: e.target.value }
                      })
                    })
                  }}
                />
                {(departmentID !== undefined) && (
                  <Button
                    style={{ margin: '0px', padding: '7px 20px 4px 20px', height: '54px', marginLeft: '10px' }}
                    color='primary'
                    title='Add'
                    disabled={!validEmail}
                    onClick={(e) => {
                      e.preventDefault()
                      submitBlockEmail()
                    }}
                  >
                    Add
                  </Button>
                )}
              </div>
              {listBlockedEmails.length > 0 ?
                <ReactTable
                  columns={cols}
                  data={listBlockedEmails}
                  defaultPageSize={listBlockedEmails.length}
                  minWidthTable={'600px'}
                />
                : <p style={{ textAlign: "center" }}>No emails have been blocked.</p>}
            </>
          }
        </GridItem>

      </GridContainer>
    </React.Fragment>
  )
}

const NoReplyEmails = ({ updateNoReplyEmails, noReplyEmails, departmentID }) => {
  const [loading, setLoading] = useState(false)
  const newEmail = {
    email: '',
  }
  const [blockedEmail, setBlockedEmail] = useState(newEmail)
  const validEmail = blockedEmail.email.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

  const submitBlockEmail = async () => {
    setLoading(true)
    if (blockedEmail.id) {
      await editNoReplyEmail({
        id: blockedEmail.id,
        email: blockedEmail.email,
        departmentID: departmentID
      })
    } else {
      await noReplyEmail({
        email: blockedEmail.email,
        departmentID: departmentID
      })
    }
    setBlockedEmail(newEmail)
    await updateNoReplyEmails()
    setLoading(false)
  }

  const deleteBlockedEmail = async (id) => {
    setLoading(true)
    await removeNoReplyEmail({
      id: id,
      departmentID: departmentID
    })
    await updateNoReplyEmails()
    setLoading(false)
  }

  const listBlockedEmails = noReplyEmails.map(email => {
    let entry = {
      id: email._id,
      email: email.email,
      actions: (
        <React.Fragment>
          <Button
            justIcon
            color="primary"
            onClick={() => {
              setBlockedEmail({
                id: email._id,
                email: email.email
              })
            }}
            title='Edit'
          >
            <Edit />
          </Button>
          <Button
            justIcon
            color="danger"
            onClick={() => {
              if (confirm(`Remove email ${email.email}?`)) {
                deleteBlockedEmail(email._id)
              }
            }}
            title='Delete'
          >
            <Close />
          </Button>
        </React.Fragment>
      )
    }
    return entry
  })

  const cols = [
    {
      Header: "Email",
      accessor: "email",
      disableFilters: false,
      disableSortBy: true,
    },
    {
      Header: "",
      accessor: "actions",
      disableFilters: true,
      disableSortBy: true,
      style: { maxWidth: '110px', display: 'flex', alignItems: 'center', justifyContent: 'center', flexWrap: 'wrap' }
    },
  ]
  return (
    <React.Fragment>
      <GridContainer>
        <GridItem xs={12}>
          {loading ? <Loading color='blue' /> :
            <>
              <div style={{ display: 'flex', justifyContent: 'right', marginBottom: '10px' }}>
                <TextField
                  variant="outlined"
                  label='Email'
                  fullWidth
                  style={{ maxWidth: '400px' }}
                  value={blockedEmail.email}
                  onChange={(e) => {
                    setBlockedEmail((prev) => {
                      return update(prev, {
                        email: { $set: e.target.value }
                      })
                    })
                  }}
                />
                {(departmentID !== undefined) && (
                  <Button
                    style={{ margin: '0px', padding: '7px 20px 4px 20px', height: '54px', marginLeft: '10px' }}
                    color='primary'
                    title='Add'
                    disabled={!validEmail}
                    onClick={(e) => {
                      e.preventDefault()
                      submitBlockEmail()
                    }}
                  >
                    Add
                  </Button>
                )}
              </div>
              {listBlockedEmails.length > 0 ?
                <ReactTable
                  columns={cols}
                  data={listBlockedEmails}
                  defaultPageSize={listBlockedEmails.length}
                  minWidthTable={'600px'}
                />
                : <p style={{ textAlign: "center" }}>No no reply emails.</p>}
            </>
          }
        </GridItem>

      </GridContainer>
    </React.Fragment>
  )
}

const TicketStates = ({ updateTicketStates, ticketStates, departmentID }) => {
  const [loading, setLoading] = useState(false)
  const newState = {
    state: '',
  }
  const [ticketState, setTicketState] = useState(newState)

  const submitTicketState = async () => {
    setLoading(true)
    if (ticketState.id) {
      await editTicketState({
        id: ticketState.id,
        state: ticketState.state,
        departmentID: departmentID
      })
    } else {
      await createTicketState({
        state: ticketState.state,
        departmentID: departmentID
      })
    }
    setTicketState(newState)
    await updateTicketStates()
    setLoading(false)
  }

  const deleteTicketState = async (id) => {
    setLoading(true)
    await removeTicketState({
      id: id,
      departmentID: departmentID
    })
    await updateTicketStates()
    setLoading(false)
  }

  const listTicketStates = ticketStates.map(state => {
    let entry = {
      id: state._id,
      state: state.state,
      actions: (
        <React.Fragment>
          <Button
            justIcon
            color="primary"
            onClick={() => {
              setTicketState({
                id: state._id,
                state: state.state
              })
            }}
            title='Edit'
          >
            <Edit />
          </Button>
          <Button
            justIcon
            color="danger"
            onClick={() => {
              if (confirm(`Remove state ${state.state}?`)) {
                deleteTicketState(state._id)
              }
            }}
            title='Delete'
          >
            <Close />
          </Button>
        </React.Fragment>
      )
    }
    return entry
  })

  const cols = [
    {
      Header: "State",
      accessor: "state",
      disableFilters: false,
      disableSortBy: true,
    },
    {
      Header: "",
      accessor: "actions",
      disableFilters: true,
      disableSortBy: true,
      style: { maxWidth: '110px', display: 'flex', alignItems: 'center', justifyContent: 'center', flexWrap: 'wrap' }
    },
  ]
  return (
    <React.Fragment>
      <GridContainer>
        <GridItem xs={12}>
          {loading ? <Loading color='blue' /> :
            <>
              <div style={{ display: 'flex', justifyContent: 'right', marginBottom: '10px' }}>
                <TextField
                  variant="outlined"
                  label='State'
                  fullWidth
                  style={{ maxWidth: '400px' }}
                  value={ticketState.state}
                  onChange={(e) => {
                    setTicketState((prev) => {
                      return update(prev, {
                        state: { $set: e.target.value }
                      })
                    })
                  }}
                />
                {(departmentID !== undefined) && (
                  <Button
                    style={{ margin: '0px', padding: '7px 20px 4px 20px', height: '54px', marginLeft: '10px' }}
                    color='primary'
                    title='Add'
                    disabled={ticketState.state.trim() === ''}
                    onClick={(e) => {
                      e.preventDefault()
                      submitTicketState()
                    }}
                  >
                    Add
                  </Button>
                )}
              </div>
              {listTicketStates.length > 0 ?
                <ReactTable
                  columns={cols}
                  data={listTicketStates}
                  defaultPageSize={listTicketStates.length}
                  minWidthTable={'600px'}
                />
                : <p style={{ textAlign: "center" }}>No Ticket States.</p>}
            </>
          }
        </GridItem>

      </GridContainer>
    </React.Fragment>
  )
}

const HelperText = ({ error }) => {
  return error.hasError ? (
    <p
      style={{
        margin: 0,
        fontSize: '0.75rem',
        marginTop: '3px',
        textAlign: 'left',
        fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
        fontWeight: 400,
        lineHeight: 1.66,
        color: '#f44336',
        marginLeft: '14px'
      }}
    >
      {error.errorMessage}
    </p>
  )
    :
    <></>
}

export default DepartmentManager