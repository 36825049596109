import React, { useState, useEffect } from "react";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Button from "components/CustomButtons/Button";
import axios from "axios";
import update from "immutability-helper";
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';

import Datetime from "react-datetime";

import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import GoBack from "components/GoBack/GoBack";

// Card
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import CardText from "components/Card/CardText";

// Atom stuff
import { useRecoilValue } from "recoil";

import dashboardStyles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import Loading from "components/Loading/Loading";
import LoadingBars from "components/Loading/LoadingBars";

import { decryptData, encryptData } from "assets/jss/EncriptDecrypt/EncryptDecrypt";
import {
  viewDepartment,
  contractorViewers,
  contractorReviewers,
  createDepartment,
  manageDepartment
} from "state/invoiceSystem";
import { Add, Delete } from "@material-ui/icons";

const useStyles = makeStyles(() => ({
  ...styles,
  ...dashboardStyles,
  formSection: {
    marginBottom: "1rem",
  },
}));

const AddEditContractorDepartmentComp = (props) => {
  const classes = useStyles();

  const userType = props.match.params.userType
  const id = props.match.params.id
  const search = props.location.search?.split("?")[1] || null

  const searchData = search ? decryptData(search) : {}

  const department = id !== 'Create' ? useRecoilValue(viewDepartment({ id: id, userType: userType })) : null

  const reviewers = useRecoilValue(contractorReviewers)
  const viewers = useRecoilValue(contractorViewers)

  const [submittingForm, setSubmittingForm] = useState(false)

  const [selectedViewer, setSelectedViewer] = useState(null)

  const [departmentInfo, setDepartmentInfo] = useState(department ? department : {
    defaultPayOptions: null,
    name: '',
    payType: 'fixed',
    reviewerEmail: '',
    reviewerName: '',
    viewAccess: [],
    customField: null
  })

  const emptyError = {
    hasError: false,
    errorMessage: ''
  }

  const [errors, setErrors] = useState({
    name: emptyError,
    reviewer: emptyError,
    payType: emptyError,
    defaultPayOptions: emptyError,
    customField: emptyError
  })

  const updateDepartment = (value) => {
    setDepartmentInfo((prevState) => {
      return update(prevState, {
        $merge: value
      })
    })
  }

  const updateDepartmentPayOption = (value) => {
    if (value === 'options') {
      setDepartmentInfo((prevState) => {
        return update(prevState, {
          $merge: {
            payType: value,
            defaultPayOptions: [{
              label: '',
              pay: ''
            }]
          }
        })
      })
    } else {
      setDepartmentInfo((prevState) => {
        return update(prevState, {
          $merge: {
            payType: value,
            defaultPayOptions: null
          }
        })
      })
    }
  }

  const removeViewAccess = (index) => {
    setDepartmentInfo((prevState) => {
      return update(prevState, {
        viewAccess: { $splice: [[index, 1]] }
      })
    })
  }

  const addViewAccess = (email) => {
    setDepartmentInfo((prevState) => {
      return update(prevState, {
        viewAccess: { $push: [email] }
      })
    })
  }

  const addDefaultPayOption = () => {
    setDepartmentInfo((prevState) => {
      if (prevState.defaultPayOptions) {
        return update(prevState, {
          defaultPayOptions: {
            $push: [{
              label: '',
              pay: ''
            }]
          }
        })
      } else {
        return update(prevState, {
          defaultPayOptions: {
            $set: [{
              label: '',
              pay: ''
            }]
          }
        })
      }
    })
  }

  const removeDefaultPayOption = (index) => {
    setDepartmentInfo((prevState) => {
      return update(prevState, {
        defaultPayOptions: { $splice: [[index, 1]] }
      })
    })
  }

  const updateDefaultPayOptionPay = (pay, index) => {
    setDepartmentInfo((prevState) => {
      return update(prevState, {
        defaultPayOptions: { [index]: { $merge: { pay: pay } } }
      })
    })
  }

  const updateDefaultPayOptionLabel = (label, index) => {
    setDepartmentInfo((prevState) => {
      return update(prevState, {
        defaultPayOptions: { [index]: { $merge: { label: label } } }
      })
    })
  }

  const addCustomField = (type) => {
    if (type === 'N/A') {
      setDepartmentInfo((prevState) => {
        return update(prevState, {
          customField: { $set: null }
        })
      })
    } else {
      setDepartmentInfo((prevState) => {
        return update(prevState, {
          customField: {
            $set: {
              type: type,
              options: [{ label: '', amount: '' }]
            }
          }
        })
      })
    }
  }

  const updateDepartmentCustomField = (index, value) => {
    setDepartmentInfo((prevState) => {
      return update(prevState, {
        customField: { options: { [index]: { $merge: value } } }
      })
    })
  }

  const addDepartmentCustomField = () => {
    setDepartmentInfo((prevState) => {
      return update(prevState, {
        customField: { options: { $push: [{ label: '', amount: '' }] } }
      })
    })
  }

  const removeDepartmentCustomField = (index) => {
    setDepartmentInfo((prevState) => {
      return update(prevState, {
        customField: { options: { $splice: [[index, 1]] } }
      })
    })
  }
  const validForm = () => {
    let validForm = true

    if (departmentInfo.name.trim() === '') {
      setErrors((prevState) => {
        return update(prevState, {
          name: {
            $merge: {
              hasError: true,
              errorMessage: "*This field cannot be left blank.",
            }
          }
        })
      })
      validForm = false
    } else {
      setErrors((prevState) => {
        return update(prevState, {
          name: {
            $merge: {
              hasError: false,
              errorMessage: "",
            }
          }
        })
      })
    }

    if (departmentInfo.reviewerEmail === '' || departmentInfo.reviewerName === '') {
      setErrors((prevState) => {
        return update(prevState, {
          reviewer: {
            $merge: {
              hasError: true,
              errorMessage: "*This field cannot be left blank.",
            }
          }
        })
      })
      validForm = false
    } else {
      setErrors((prevState) => {
        return update(prevState, {
          reviewer: {
            $merge: {
              hasError: false,
              errorMessage: "",
            }
          }
        })
      })
    }

    if (departmentInfo.payType === '') {
      setErrors((prevState) => {
        return update(prevState, {
          payType: {
            $merge: {
              hasError: true,
              errorMessage: "*This field cannot be left blank.",
            }
          }
        })
      })
      validForm = false
    } else {
      setErrors((prevState) => {
        return update(prevState, {
          payType: {
            $merge: {
              hasError: false,
              errorMessage: "",
            }
          }
        })
      })
    }

    if (departmentInfo.payType === 'options') {
      if (departmentInfo.defaultPayOptions.length === 0) {
        setErrors((prevState) => {
          return update(prevState, {
            defaultPayOptions: {
              $merge: {
                hasError: true,
                errorMessage: "*This field cannot be left blank.",
              }
            }
          })
        })
        validForm = false
      } else {
        if (departmentInfo.defaultPayOptions.find(item => !item.label || item.pay === '')) {
          setErrors((prevState) => {
            return update(prevState, {
              defaultPayOptions: {
                $merge: {
                  hasError: true,
                  errorMessage: "*All Options must have a label and amount.",
                }
              }
            })
          })
          validForm = false
        } else {
          setErrors((prevState) => {
            return update(prevState, {
              defaultPayOptions: {
                $merge: {
                  hasError: false,
                  errorMessage: "",
                }
              }
            })
          })
        }
      }
    } else {
      setErrors((prevState) => {
        return update(prevState, {
          defaultPayOptions: {
            $merge: {
              hasError: false,
              errorMessage: "",
            }
          }
        })
      })
    }
    if (departmentInfo.customField) {
      if (departmentInfo.customField.type === 'Rate Multiplier') {
        if (departmentInfo.customField.options.length === 0) {
          setErrors((prevState) => {
            return update(prevState, {
              customField: {
                $merge: {
                  hasError: true,
                  errorMessage: "*This field cannot be left blank.",
                }
              }
            })
          })
          validForm = false
        } else if (departmentInfo.customField.options.find(item => !item.label || item.amount === '')) {
          setErrors((prevState) => {
            return update(prevState, {
              customField: {
                $merge: {
                  hasError: true,
                  errorMessage: "*All Options must have a label and amount.",
                }
              }
            })
          })
          validForm = false
        } else {
          setErrors((prevState) => {
            return update(prevState, {
              customField: {
                $merge: {
                  hasError: false,
                  errorMessage: "",
                }
              }
            })
          })
        }
      }
    } else {
      setErrors((prevState) => {
        return update(prevState, {
          customField: {
            $merge: {
              hasError: false,
              errorMessage: "",
            }
          }
        })
      })
    }

    return validForm
  }



  const submitForm = async () => {
    if (validForm()) {
      setSubmittingForm(true)
      if (id === 'Create') {
        let deptID = await createDepartment(departmentInfo)
        window.location.href = `/portal/contractors/manageDepartment/${userType}/${deptID}?${search}`
      } else {
        await manageDepartment(departmentInfo)
        location.reload()
      }
      setSubmittingForm(false)
    }
  }

  return (
    <React.Fragment>
      <GridContainer>
        <GridItem xs={12} md={11} lg={8}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <GoBack returnLocation={searchData.returnLocation} disableRound={true} />
            {!((!department && id !== 'Create') || (userType !== 'admin' && userType !== 'reviewer') || (userType === 'reviewer' && id === 'Create')) &&
              (<Button
                style={{ marginLeft: '5px' }}
                color='primary'
                onClick={(e) => {
                  e.preventDefault()
                  submitForm()
                }}
              >
                {submittingForm ? <LoadingBars /> : 'Save'}
              </Button>)}
          </div>
          {(!department && id !== 'Create') || (userType !== 'admin' && userType !== 'reviewer') || (userType === 'reviewer' && id === 'Create') ?
            <p style={{ marginTop: '15px' }}>You shouldn't be here.</p>
            :
            <>
              <Card>
                <CardHeader color="info" text>
                  <CardText color="info">
                    <h4 className={classes.cardTitleWhite}>{id === 'Create' ? 'Create Department' : 'Manage Department'}</h4>
                  </CardText>
                </CardHeader>
                <CardBody>
                  {(userType === 'admin' || userType === 'reviewer') && (
                    <GridContainer className={classes.formSection} style={{ marginTop: '-15px' }}>
                      <GridItem xs={12}>
                        <TextField
                          style={{ marginTop: '25px' }}
                          disabled={userType === 'reviewer'}
                          required
                          label="Name"
                          variant="outlined"
                          fullWidth
                          value={departmentInfo.name}
                          onChange={(e) => updateDepartment({ name: e.target.value })}
                          helperText={errors.name.errorMessage}
                          error={errors.name.hasError}
                        />
                      </GridItem>
                      <GridItem xs={12}>
                        <Autocomplete
                          disablePortal
                          disabled={userType === 'reviewer'}
                          options={reviewers}
                          style={{ maxWidth: '400px', marginTop: '15px' }}
                          getOptionSelected={(selected) => departmentInfo.reviewerEmail === selected.email}
                          getOptionLabel={(option) =>
                            option.name}
                          value={departmentInfo.reviewerEmail ? {
                            name: departmentInfo.reviewerName,
                            email: departmentInfo.reviewerEmail
                          } : null}
                          renderInput={(params) => (
                            <TextField {...params} label="Reviewer" variant="outlined" />
                          )}
                          onChange={(e, v) => {
                            updateDepartment({
                              reviewerEmail: v.email,
                              reviewerName: v.name
                            })
                          }
                          }
                          autoSelect={true}
                          autoComplete={true}
                          autoHighlight={true}
                          disableClearable
                        />
                        <HelperText error={errors.reviewer} />
                      </GridItem>
                      <GridItem xs={12} style={{ marginTop: '10px' }}>
                        <span style={{ fontSize: '16px' }}>Viewers:</span>
                        <div
                          className={classes.formSection}
                          style={{
                            alignItems: "center",
                            border: "1px solid #ddd",
                            borderRadius: "3px",
                            background: "#fff",
                            padding: "10px",
                          }}>
                          {(userType === 'admin') && (
                            <GridContainer>
                              <GridItem xs={11}>
                                <Autocomplete
                                  fullWidth
                                  disablePortal
                                  options={viewers.filter(item => departmentInfo.viewAccess.indexOf(item.email) === -1)}
                                  getOptionLabel={(option) =>
                                    option.name}
                                  value={selectedViewer}
                                  renderInput={(params) => (
                                    <TextField {...params} label="Viewer" />
                                  )}
                                  onChange={(e, v) => { setSelectedViewer(v) }}
                                  autoSelect={true}
                                  autoComplete={true}
                                  autoHighlight={true}
                                  disableClearable
                                />
                              </GridItem>
                              <GridItem xs={1} style={{ display: 'flex', alignItems: 'end', justifyContent: 'right', height: '48px' }}>
                                <Button
                                  style={{ margin: '0px', padding: '7px', height: '32px' }}
                                  color='primary'
                                  title='add'
                                  onClick={(e) => {
                                    e.preventDefault()
                                    addViewAccess(selectedViewer.email)
                                    setSelectedViewer(null)
                                  }}
                                >
                                  <span style={{ margin: '0px 5px' }}>Add</span>
                                  {/* <Add style={{ marginRight: '0px' }} /> */}
                                </Button>
                              </GridItem>
                            </GridContainer>)}
                          {(departmentInfo.viewAccess.length === 0) && (
                            <div style={{ textAlign: 'center', width: '100%', marginTop: '10px' }}>
                              <span>No viewers for department.</span>
                            </div>
                          )}
                          {(departmentInfo.viewAccess.length > 0) && (
                            <>
                              {departmentInfo.viewAccess.map((item, index) => {
                                const user = viewers.find(u => u.email === item)
                                return (
                                  <div xs={12} key={index}>
                                    {user ?
                                      <div
                                        style={{
                                          display: 'flex',
                                          justifyContent: 'space-between',
                                          alignItems: 'end',
                                          borderBottom: '1px solid #ddd',
                                          padding: '1px 0px',
                                          marginTop: '10px'
                                        }}>
                                        <span style={{ fontSize: '14px', fontWeight: 'bold' }}>{user.name} ({user.email})</span>
                                        {(userType === 'admin') && (
                                          <Button
                                            style={{ margin: '0px', padding: '7px' }}
                                            color='danger'
                                            title='Remove'
                                            onClick={(e) => {
                                              e.preventDefault()
                                              removeViewAccess(index)
                                            }}
                                          >
                                            <CloseIcon style={{ marginRight: '0px' }} />
                                          </Button>)}
                                      </div>
                                      :
                                      <span>missing {item}</span>
                                    }
                                  </div>
                                )
                              })}
                            </>
                          )}
                          {/* </div> */}
                        </div>
                      </GridItem>

                      <GridItem xs={12}>
                        <Autocomplete
                          disablePortal
                          options={['fixed', 'custom', 'options']}
                          style={{ maxWidth: '400px', marginTop: '15px' }}
                          value={departmentInfo.payType}
                          renderInput={(params) => (
                            <TextField {...params} label="Pay Type" variant="outlined" />
                          )}
                          onChange={(e, v) => {
                            updateDepartmentPayOption(v)
                          }}
                          disabled={id === 'Create' ? false : true}
                          autoSelect={true}
                          autoComplete={true}
                          autoHighlight={true}
                          disableClearable
                        />
                        <HelperText error={errors.payType} />
                      </GridItem>

                      {departmentInfo.payType === 'options' && (
                        <GridItem xs={12} style={{ marginTop: '10px' }}>
                          <span style={{ fontSize: '16px' }}>Default Pay Options:</span>
                          <div
                            className={classes.formSection}
                            style={{
                              alignItems: "center",
                              border: errors.defaultPayOptions.hasError ? "1px solid red" : "1px solid #ddd",
                              borderRadius: "3px",
                              background: "#fff",
                              padding: "10px",
                              marginBottom: '0px'
                            }}>
                            {departmentInfo.defaultPayOptions.map((option, optIndex) => {
                              return (
                                <div key={optIndex} style={{ marginTop: optIndex === 0 ? '5px' : '15px', display: 'flex' }}>
                                  <TextField
                                    style={{ marginRight: '10px', flex: 2 }}
                                    required
                                    placeholder="Label"
                                    value={option.label}
                                    onChange={(e) => updateDefaultPayOptionLabel(e.target.value, optIndex)}
                                  />
                                  <TextField
                                    placeholder={`Amount`}
                                    value={option.pay}
                                    onChange={(e) => updateDefaultPayOptionPay(e.target.value, optIndex)}
                                    onWheel={(e) => e.target.blur()}
                                    type="number"
                                    inputProps={{
                                      step: 0.01,
                                    }}
                                    style={{ flex: 1, marginRight: '10px' }}
                                  />
                                  <Button
                                    style={{ margin: '0px', padding: '7px' }}
                                    color='danger'
                                    title='Remove'
                                    onClick={(e) => {
                                      e.preventDefault()
                                      removeDefaultPayOption(optIndex)
                                    }}
                                  >
                                    <CloseIcon style={{ marginRight: '0px' }} />
                                  </Button>
                                </div>
                              )
                            })}
                            <div style={{ textAlign: 'right' }}>
                              <Button
                                style={{ margin: '0px', padding: '7px', marginTop: '20px' }}
                                color='primary'
                                onClick={(e) => {
                                  e.preventDefault()
                                  addDefaultPayOption()
                                }}
                              >
                                Add Option
                              </Button>
                            </div>
                          </div>
                          <HelperText error={errors.defaultPayOptions} />
                        </GridItem>
                      )}

                      <GridItem xs={12}>
                        <Autocomplete
                          disablePortal
                          options={['N/A', 'Rate Multiplier']}
                          style={{ maxWidth: '400px', marginTop: '15px' }}
                          value={departmentInfo.customField ? departmentInfo.customField.type : 'N/A'}
                          renderInput={(params) => (
                            <TextField {...params} label="Custom Field Type" variant="outlined" />
                          )}
                          onChange={(e, v) => {
                            addCustomField(v)
                          }}
                          disabled={id === 'Create' ? false : true}
                          autoSelect={true}
                          autoComplete={true}
                          autoHighlight={true}
                          disableClearable
                        />
                      </GridItem>

                      {(departmentInfo.customField?.type === 'Rate Multiplier') && (
                        <GridItem xs={12} style={{ marginTop: '10px' }}>
                          <span style={{ fontSize: '16px' }}>Custom Field Options:</span>
                          <div
                            className={classes.formSection}
                            style={{
                              alignItems: "center",
                              border: errors.customField.hasError ? "1px solid red" : "1px solid #ddd",
                              borderRadius: "3px",
                              background: "#fff",
                              padding: "10px",
                              marginBottom: '0px'
                            }}>
                            {departmentInfo.customField.options.map((option, optIndex) => {
                              return (
                                <div key={optIndex} style={{ marginTop: optIndex === 0 ? '5px' : '15px', display: 'flex' }}>
                                  <TextField
                                    style={{ marginRight: '10px', flex: 2 }}
                                    required
                                    placeholder="Label"
                                    value={option.label}
                                    onChange={(e) => updateDepartmentCustomField(optIndex, { label: e.target.value })}
                                  />
                                  <TextField
                                    placeholder={`Amount`}
                                    value={option.amount}
                                    onChange={(e) => updateDepartmentCustomField(optIndex, { amount: e.target.value })}
                                    onWheel={(e) => e.target.blur()}
                                    type="number"
                                    inputProps={{
                                      step: 1,
                                    }}
                                    style={{ flex: 1, marginRight: '10px' }}
                                  />
                                  <Button
                                    style={{ margin: '0px', padding: '7px' }}
                                    color='danger'
                                    title='Remove'
                                    onClick={(e) => {
                                      e.preventDefault()
                                      removeDepartmentCustomField(optIndex)
                                    }}
                                  >
                                    <CloseIcon style={{ marginRight: '0px' }} />
                                  </Button>
                                </div>
                              )
                            })}
                            <div style={{ textAlign: 'right' }}>
                              <Button
                                style={{ margin: '0px', padding: '7px', marginTop: '20px' }}
                                color='primary'
                                onClick={(e) => {
                                  e.preventDefault()
                                  addDepartmentCustomField()
                                }}
                              >
                                Add Option
                              </Button>
                            </div>
                          </div>
                          <HelperText error={errors.customField} />
                        </GridItem>
                      )}
                    </GridContainer>
                  )}

                  {/* {(userType === 'reviewer') && (
                    <GridContainer>
                      <GridItem xs={12} style={{ marginTop: '10px' }}>
                        <span style={{ fontSize: '1.8rem', fontWeight: 'bold' }}>{department.name}</span>
                      </GridItem>
                      <GridItem xs={12} md={6} style={{ display: 'flex', marginTop: '5px', fontSize: '0.9rem' }}>
                        <span style={{ marginRight: '5px', color: '#3195d9', fontWeight: 'bold' }}>Reviewer:</span>
                        <span>{department.reviewerName} ({department.reviewerEmail})</span>
                      </GridItem>
                      <GridItem xs={12} md={6} style={{ display: 'flex', marginTop: '5px', fontSize: '0.9rem' }}>
                        <span style={{ marginRight: '5px', color: '#3195d9', fontWeight: 'bold' }}>Pay Type:</span>
                        <span>{department.payType}</span>
                      </GridItem>
                      {(department.payType === 'options') && (
                        <GridItem xs={12} md={6} style={{ display: 'flex', marginTop: '5px', fontSize: '0.9rem' }}>
                          <span style={{ marginRight: '5px', color: '#3195d9', fontWeight: 'bold' }}>Default Options:</span>
                          <div>
                            {department.defaultPayOptions.map((item, index) => {
                              return (
                                <div key={index}>
                                  <span>{`${item.label}: $${item.pay}`}</span>
                                </div>
                              )
                            })}
                          </div>
                        </GridItem>
                      )}
                    </GridContainer>
                  )} */}
                </CardBody>
              </Card>
              <div style={{ textAlign: 'right' }}>
                <Button
                  style={{ margin: '0px', padding: '10px 20px', marginTop: '-10px' }}
                  color='primary'
                  onClick={(e) => {
                    e.preventDefault()
                    submitForm()
                  }}
                >
                  {submittingForm ? <LoadingBars /> : 'Save'}
                </Button>
              </div>
            </>}
        </GridItem>
      </GridContainer>
    </React.Fragment>
  )
}

const AddEditContractorDepartment = (props) => {
  return (
    <React.Suspense fallback={<Loading color="blue" />}>
      <AddEditContractorDepartmentComp {...props} />
    </React.Suspense>
  )
}

const HelperText = ({ error }) => {
  return error.hasError ? (
    <span
      style={{
        margin: 0,
        fontSize: '0.75rem',
        marginTop: '3px',
        textAlign: 'left',
        fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
        fontWeight: 400,
        lineHeight: 1.66,
        color: '#f44336',
        marginLeft: '14px'
      }}
    >
      {error.errorMessage}
    </span>
  )
    :
    <></>
}

export default AddEditContractorDepartment