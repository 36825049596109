import { atom, selector, selectorFamily } from "recoil";
import axios from "axios";
import api from "../api.config";

export const lastHBABFormAtom = atom({
  key: 'lastHBABFormAtom',
  default: selector({
    key: 'lastHBABFormAtomSelector',
    get: async () => {
      return await axios
        .post(`${api.path}/staff_portal_api/v1/getLastHBABForm`,
        ).then((res) => {
          if (res.data !== null) {
            res.data.keyLeaders = JSON.parse(res.data.keyLeaders)
            res.data.leaders = JSON.parse(res.data.leaders)
            res.data.urgentTasks = JSON.parse(res.data.urgentTasks)
            res.data.tasks = JSON.parse(res.data.tasks)
            res.data.contacts = JSON.parse(res.data.contacts)
            res.data.resources = JSON.parse(res.data.resources)
            res.data.misc = JSON.parse(res.data.misc)
          }
          return res.data
        })
    }
  })
})

export const lastMyMinistryFormAtom = atom({
  key: 'lastMyMinistryFormAtom',
  default: selector({
    key: 'lastMyMinistryFormAtomSelector',
    get: async () => {
      return await axios
        .post(`${api.path}/staff_portal_api/v1/getLastMyMinistryForm`,
        ).then((res) => {
          if (res.data !== null) {
            res.data.coreValues = JSON.parse(res.data.coreValues)
            res.data.report = JSON.parse(res.data.report)
            res.data.orgMap = JSON.parse(res.data.orgMap)
            res.data.ministryArea = JSON.parse(res.data.ministryArea)
          }
          return res.data
        })
    }
  })
})

export const getMyTeamsHBABForms = atom({
  key: 'getMyTeamsHBABForms',
  default: selector({
    key: 'getMyTeamsHBABFormsSelector',
    get: async () => {
      return await axios
        .post(`${api.path}/staff_portal_api/v1/getMyTeamsHBABForms`,
        ).then((res) => {
          return res.data
        })
    }
  })
})

export const getWhoCompletedHBABForms = atom({
  key: 'getWhoCompletedHBABForms',
  default: selector({
    key: 'getWhoCompletedHBABFormsSelector',
    get: async () => {
      return await axios
        .post(`${api.path}/staff_portal_api/v1/getWhoCompletedHBABForms`,
        ).then((res) => {
          return res.data
        })
    }
  })
})

export const getHBABForm = selectorFamily({
  key: 'getHBABForm',
  get: (data) => async () => {
    return await axios
      .post(`${api.path}/staff_portal_api/v1/HBABFormRequest`,
        data
      ).then((res) => {
        if (res.data.form) {
          if (data.type === 'Role') {
            res.data.form.keyLeaders = JSON.parse(res.data.form.keyLeaders)
            res.data.form.leaders = JSON.parse(res.data.form.leaders)
            res.data.form.urgentTasks = JSON.parse(res.data.form.urgentTasks)
            res.data.form.tasks = JSON.parse(res.data.form.tasks)
            res.data.form.contacts = JSON.parse(res.data.form.contacts)
            res.data.form.resources = JSON.parse(res.data.form.resources)
            res.data.form.misc = JSON.parse(res.data.form.misc)
          } else if (data.type === 'Ministry') {
            res.data.form.coreValues = JSON.parse(res.data.form.coreValues)
            res.data.form.report = JSON.parse(res.data.form.report)
            res.data.form.orgMap = JSON.parse(res.data.form.orgMap)
            res.data.form.ministryArea = JSON.parse(res.data.form.ministryArea)
          }
        }

        return res.data
      })
  }
})