import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import OrgChart from "../../components/OrgChart/OrgChart";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import ReactTable from "components/ReactTable/ReactTable.js";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import { staffDirectoryAtom } from "state/directory";
import { useRecoilValue } from "recoil";
import Loading from "components/Loading/Loading";
import update from "immutability-helper";
import blankProfile from 'assets/img/blankProfile.png'

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
};

const useStyles = makeStyles(styles);

const StaffDirectoryComponent = (props) => {
  const classes = useStyles();

  const data = useRecoilValue(staffDirectoryAtom).map((item, index) => {

    let newList = { ...item };

    newList = update(newList, {
      $merge: {
        fullName: `${item.firstName} ${item.lastName}`,
      },
    });

    newList = update(newList, {
      $merge: {
        profilePhoto: (
          <img
            src={item.avatar ? item.avatar : blankProfile}
            className={classes.avatarImg}
            style={{ width: "42px", height: "42px", borderRadius: '30px' }}
          />),
      },
    });

    newList = update(newList, {
      $merge: {
        campus: (
          <React.Fragment>
            {item.org.campus}
            <br />
            <span
              style={{
                color: "#2c82c5",
                fontSize: "smaller",
              }}
            >
              {item.org.department} - {item.org.title}
            </span>
          </React.Fragment>
        ),
      },
    });

    //console.log("newList", newList);

    return newList;
  });

  const [buttonText, setButtonText] = useState("View Org Chart");
  const [view, setView] = useState("directory");

  const toggleHandler = (e) => {
    if (view === "directory") {
      setView("chart");
      setButtonText("View Directory");
    } else {
      setView("directory");
      setButtonText("View Org Chart");
    }
  };

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Button color="primary" onClick={toggleHandler}>
          {buttonText}
        </Button>
        <Card>
          <CardBody>

            {view === "chart" && <OrgChart />}

            {view === "directory" && (
              <ReactTable
                columns={[
                  {
                    Header: "",
                    accessor: "profilePhoto",
                    disableFilters: true,
                    disableSortBy: true,
                    style: {
                      maxWidth: '48px',
                      margin: '0px 5px',
                      display: 'flex',
                      alignItems: 'center'
                    }
                  },
                  {
                    Header: "Name",
                    accessor: "fullName",
                  },
                  {
                    Header: "Email",
                    accessor: "email",
                  },
                  {
                    Header: "Phone",
                    accessor: "phone",
                  },
                  {
                    Header: "Birthdate",
                    accessor: "birthdate",
                  },
                  {
                    Header: "Campus/Dept",
                    accessor: "campus",
                    disableFilters: true,
                    disableSortBy: true,
                  },
                ]}
                data={data}
                defaultPageSize={data.length}
                minWidthTable={'500px'}
              />
            )}
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

const StaffDirectory = (props) => {
  return (
    <React.Suspense fallback={<Loading color="blue" />}>
      <StaffDirectoryComponent {...props} />
    </React.Suspense>
  );
};

export default StaffDirectory;
