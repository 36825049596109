import React, { useEffect, useState } from 'react'
import CustomTabs from "components/CustomTabs/CustomTabs";
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import PastoralCareForm from './PastoralCareForm';
import axios from 'axios';
import PastoralCareList from './PastoralCareList';

const SECURITY_KEY = '3456b7vy7tc8urwemnb354y84t7vc9ur24yb87t24ycru9njqxdmcnv93btygn5782tmucr39idnv43u312'
/**
 * Used to create the pastoral care page, this componentent manage the tabs and any API Requests to
 * the POC API.
 */
const PastoralCare = (props) => {
  // determines what tab we should be one if a link is used.
  const tab = +(props.location.search?.split("=")[1] || 0);

  // used to swap between .dev and .work for development.
  var extension = window.location.origin.endsWith('.work') ? '.work' : '.dev'
  const endpoint = 'https://pocboard.sagebrush' + extension + '/poc_board_api'


  // list of upcomingPOC's {funeral and hospital visits}
  const [upcomingPOC, setUpcomingPOC] = useState({ hospitalVisits: [], funerals: [] })
  // list of All POC's {funeral and hospital visits}
  const [allPOC, setAllPOC] = useState({ hospitalVisits: [], funerals: [] })

  /**
   * used to update a POC Event in the database
   * @param {string} type the form type that was submitted
   * @param {object} item all the data contained inside the form
   * @returns 
   */
  const updateItem = async (type, item) => {
    // check if the POC type has changed, if so we need to delete the old and create a new one.
    if (item.originalFormType !== '' && type !== item.originalFormType) {
      deleteItem(item.id, item.originalFormType)
      submitForm(type, item)
    } else {
      if (type === 'HospitalVisit') {
        await axios({
          method: 'post',
          url: endpoint + '/updateHospitalVisit',
          data: {
            key: SECURITY_KEY,
            data: item
          }
        })
      } else {
        await axios({
          method: 'post',
          url: endpoint + '/updateFuneral',
          data: {
            key: SECURITY_KEY,
            data: item
          }
        })
      }
    }
    // update out list of upcoming and all POC's
    getALLPOCs()
    getUpcomingPOCs()
    return
  }

  /**
   * Called to delete a POC Event out of the database.
   * @param {number} id the POC Event ID number
   * @param {string} type the POC type
   * @returns 
   */
  const deleteItem = async (id, type) => {
    if (type === 'HospitalVisit') {
      await axios({
        method: 'post',
        url: endpoint + '/deleteHospitalVisit',
        data: {
          key: SECURITY_KEY,
          data: {
            id: id
          }
        }
      })
    } else {
      await axios({
        method: 'post',
        url: endpoint + '/deleteFuneral',
        data: {
          key: SECURITY_KEY,
          data: {
            id: id
          }
        }
      })
    }
    // update out list of upcoming and all POC's
    getALLPOCs()
    getUpcomingPOCs()
    return
  }

  /**
   * called to update the list of upcoming POC's from the database.
   * @returns 
   */
  const getUpcomingPOCs = () => {
    return axios({
      method: 'get',
      url: endpoint + '/getUpcomingHospitalVisitsAndFunerals'
    }).then((msg) => {
      setUpcomingPOC(msg.data)
    })
  }

  /**
   * called to update the list of all POC's from the database.
   * @returns 
   */
  const getALLPOCs = () => {
    return axios({
      method: 'get',
      url: endpoint + '/getAllHospitalVisitsAndFunerals'
    }).then((msg) => {
      setAllPOC(msg.data)
    })
  }

  /**
   * Called to submit a new POC Event to the API to be added to the database.
   * @param {string} type the POC type
   * @param {object} formData all the data from the form needed to create a new POC Event
   * @returns 
   */
  const submitForm = async (type, formData) => {
    if (type === 'HospitalVisit') {
      await axios({
        method: 'post',
        url: endpoint + '/addHospitalVisit',
        data: {
          key: SECURITY_KEY,
          data: formData
        }
      })
    } else {
      await axios({
        method: 'post',
        url: endpoint + '/addFuneral',
        data: {
          key: SECURITY_KEY,
          data: formData
        }
      })
    }
    // update out list of upcoming and all POC's
    getALLPOCs()
    getUpcomingPOCs()
    return
  }

  /**
   * the tabs that will appear on the page and what element will appear when selecting one.
   */
  const tabs = [
    {
      tabName: "Create New",
      tabContent: (
        <PastoralCareForm submitForm={submitForm} />
      ),
    },
    {
      tabName: "Active",
      tabContent: (
        <PastoralCareList POCList={upcomingPOC} deleteItem={deleteItem} updateItem={updateItem} />
      ),
    },
    {
      tabName: "All",
      tabContent: (
        <PastoralCareList POCList={allPOC} deleteItem={deleteItem} updateItem={updateItem} />
      ),
    },
  ];

  /**
   * grab our POC lists when the page loads.
   */
  useEffect(() => {
    getUpcomingPOCs()
    getALLPOCs()
  }, [])

  return (
    <React.Fragment>
      <GridContainer>
        <GridItem xs={12} md={10} lg={7}>
          <CustomTabs headerColor="info" tabs={tabs} value={tab} />
        </GridItem>
      </GridContainer>
    </React.Fragment>
  )
}

export default PastoralCare