import { atom, selector } from "recoil";
import axios from "axios";
import Info from "@material-ui/icons/Info";
import update from "immutability-helper";
import api from "../api.config";

export const newsfeedAtom = atom({
  key: "newsfeed",
  default: selector({
    key: "newsfeedSelector",
    get: async () => {
      return await axios
        .get(`${api.path}/staff_portal_api/v1/listnewsfeed`)
        .then((res) => {
          let newsfeedItems = [];

          res.data.map((item) => {
            let feed = JSON.parse(item.feedjson);

            newsfeedItems.push({
              inverted: true,
              badgeColor: "info",
              badgeIcon: Info,
              title: feed.title,
              titleColor: "info",
              body: feed.text,
              id: item.id,
              status: item.status,
              operator: item.operator,
              updatedTime: item.updated_time
            });
          });

          return newsfeedItems;
        });
    },
  }),
});
