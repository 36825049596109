import React, { useRef, useState } from "react";
import Loading from "components/Loading/Loading";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import { useRecoilValue } from "recoil";
import { getHBABForm } from "state/HBABFormRequest";

import Button from "components/CustomButtons/Button";
import { ArrowBack } from "@material-ui/icons";

import html2PDF from 'jspdf-html2canvas';
import html2canvas from "html2canvas";


const HBABFormPDFComp = (props) => {
  const currentId = +props.match.params.id
  const currentType = props.match.params.type
  const currentRole = props.location.search?.split('=')[1] || 'Me'
  const HBABRef = useRef(null)
  const downloadPdfDocument = (rootElementId) => {

    const input = document.getElementById(rootElementId);
    html2PDF(input, {
      margin: {
        top: 35,
        right: 35,
        bottom: 35,
        left: 35,
      },
      imageType: 'image/jpeg',
      output: `${currentForm.form.name.replaceAll(' ', '_')}_${currentType}_Form.pdf`
    });
  }

  const downloadJPGDocument = (rootElementId) => {
    const input = document.getElementById(rootElementId);

    html2canvas(input).then((canvas) => {
      var a = document.createElement('a');
      // toDataURL defaults to png, so we need to request a jpeg, then convert for file download.
      a.href = canvas.toDataURL("image/jpeg").replace("image/jpeg", "image/octet-stream");
      a.download = `${currentForm.form.name.replaceAll(' ', '_')}_${currentType}_Form.jpg`;
      a.click();
    })
  }

  let data = {
    id: currentId,
    type: currentType,
    role: currentRole
  }
  const currentForm = useRecoilValue(getHBABForm(data))

  const getNext = () => {
    let next = currentForm.formIDs.findIndex(item => item === currentId)
    if (next + 1 > currentForm.formIDs.length - 1) {
      return false
    }
    return `/portal/MyProfile/HBAB/${currentType}/${currentForm.formIDs[next + 1]}?type=${currentRole}`
  }

  const getPrev = () => {
    let prev = currentForm.formIDs.findIndex(item => item === currentId)
    if (prev - 1 < 0) {
      return false
    }
    return `/portal/MyProfile/HBAB/${currentType}/${currentForm.formIDs[prev - 1]}?type=${currentRole}`
  }
  const nextClickHandler = () => {
    let next = getNext();

    if (next) {
      location.href = next;
    }
  };

  const prevClickHandler = () => {
    let prev = getPrev();

    if (prev) {
      location.href = prev;
    }
  };

  const getReturnLocation = () => {
    if (currentRole === 'Admin') {
      return '/portal/MyProfile/HBABAdmin'
    }
    if (currentRole === 'Team') {
      return '/portal/MyProfile/Team'
    }
    if (currentRole === 'Me') {
      if (currentType === 'Role') {
        return '/portal/MyProfile/Roles'
      } else if (currentType === 'Ministry') {
        return '/portal/MyProfile/Ministry'
      }
    }
  }

  const [videoHeight, setVideoHeight] = useState('200px')

  const getVideoHeight = ({ target: vid }) => {
    const { offsetWidth } = vid

    let height = (offsetWidth * 9) / 16
    height = Math.round(height)

    setVideoHeight(`${height}px`)
  }

  return (
    <React.Fragment>
      {currentForm.form ?
        <>
          <Button
            href={getReturnLocation()}
            color="white"
          >
            <ArrowBack /> {`Back`}
          </Button>
          {currentForm.formIDs.length > 1 && (
            <>
              <Button
                onClick={prevClickHandler}
                disabled={getPrev() === false ? true : false}
                color="primary"
              >
                {`Previous`}
              </Button>
              <Button
                onClick={nextClickHandler}
                disabled={getNext() === false ? true : false}
                color="primary"
              >
                {`Next`}
              </Button>
            </>
          )}

          <Button
            onClick={() => downloadPdfDocument("divToDownload")}
            color="warning">
            Download PDF
          </Button>

          <Button
            onClick={() => downloadJPGDocument("divToDownload")}
            color="warning">
            Download JPEG
          </Button>

          <Card>
            <CardHeader color="primary" icon>
              <CardIcon color="primary">
                <h4 style={{ fontWeight: 'bold', paddingRight: '5px', paddingLeft: '5px' }}>{currentType}</h4>
              </CardIcon>
            </CardHeader>
            <CardBody>
              <div>
                <div id="divToDownload" ref={HBABRef}>
                  <div>
                    <p style={{ fontSize: '18px' }}><strong>Name:</strong> {currentForm.form.name}</p>
                  </div>
                  <div>
                    <p style={{ fontSize: '18px' }}><strong>Department:</strong> {currentForm.form.dept}</p>
                  </div>
                  <div>
                    <p style={{ fontSize: '18px' }}><strong>Title:</strong> {currentForm.form.title}</p>
                  </div>
                  <div>
                    <div style={{ fontSize: '18px' }}>
                      <p style={{ fontSize: '18px', paddingRight: '10px' }}><strong>Ministry:</strong> {currentForm.form.ministry}</p>

                    </div>
                  </div>
                  {currentType === 'Role' ?
                    <>
                      <div>
                        <div style={{ display: 'flex' }}>
                          <h4 style={{ paddingRight: '5px', fontWeight: 'bolder' }}>1.</h4>
                          <h4 style={{ fontWeight: 'bolder' }}>Who is your most key leader(s) in your ministry who can help to manage your
                            responsibilities?</h4>
                        </div>
                        <div style={{ marginLeft: '20px' }} dangerouslySetInnerHTML={{ __html: currentForm.form.keyLeaders.html }} />
                      </div>

                      <div>
                        <div style={{ display: 'flex' }}>
                          <h4 style={{ paddingRight: '5px', fontWeight: 'bolder' }}>2.</h4>
                          <h4 style={{ fontWeight: 'bolder' }}>Who are your mentors or staff persons that you directly oversee? (These are your
                            second-tier leaders)</h4>
                        </div>
                        <div style={{ marginLeft: '20px' }} dangerouslySetInnerHTML={{ __html: currentForm.form.leaders.html }} />
                      </div>

                      <div>
                        <div style={{ display: 'flex' }}>
                          <h4 style={{ paddingRight: '5px', fontWeight: 'bolder' }}>3.</h4>
                          <h4 style={{ fontWeight: 'bolder' }}>What are the key weekly responsibilities that MUST be done in order for our
                            ministry to survive? (Please list the person either on staff or on your volunteer
                            team who can cover the responsibility)</h4>
                        </div>
                        <div style={{ marginLeft: '20px' }} dangerouslySetInnerHTML={{ __html: currentForm.form.urgentTasks.html }} />
                      </div>

                      <div>
                        <div style={{ display: 'flex' }}>
                          <h4 style={{ paddingRight: '5px', fontWeight: 'bolder' }}>4.</h4>
                          <h4 style={{ fontWeight: 'bolder' }}>What are the key weekly responsibilities that need to be covered, but are LESS
                            urgent?</h4>
                        </div>
                        <div style={{ marginLeft: '20px' }} dangerouslySetInnerHTML={{ __html: currentForm.form.tasks.html }} />
                      </div>

                      <div>
                        <div style={{ display: 'flex' }}>
                          <h4 style={{ paddingRight: '5px', fontWeight: 'bolder' }}>5.</h4>
                          <h4 style={{ fontWeight: 'bolder' }}>Key Organizations that you might need to be in contact with in my absence? (We
                            want to know what institutions, counseling centers, youth shelters, community action
                            groups, or other key organization that you contact regularly)</h4>
                        </div>
                        <div style={{ marginLeft: '20px' }} dangerouslySetInnerHTML={{ __html: currentForm.form.contacts.html }} />
                      </div>

                      <div>
                        <div style={{ display: 'flex' }}>
                          <h4 style={{ paddingRight: '5px', fontWeight: 'bolder' }}>6.</h4>
                          <h4 style={{ fontWeight: 'bolder' }}>Means of communication…</h4>
                        </div>
                        <div style={{ marginLeft: '20px' }} dangerouslySetInnerHTML={{ __html: currentForm.form.resources.html }} />
                      </div>

                      <div>
                        <div style={{ display: 'flex' }}>
                          <h4 style={{ paddingRight: '5px', fontWeight: 'bolder' }}>7.</h4>
                          <h4 style={{ fontWeight: 'bolder' }}>Anything else that we need to know in your absence?</h4>
                        </div>
                        <div style={{ marginLeft: '20px' }} dangerouslySetInnerHTML={{ __html: currentForm.form.misc.html }} />
                      </div>
                    </>
                    :
                    <>
                      <div>
                        <div style={{ display: 'flex' }}>
                          <h4 style={{ paddingRight: '5px', fontWeight: 'bolder' }}>1.</h4>
                          <h4 style={{ fontWeight: 'bolder' }}>What are your ministrys 5 focus areas?</h4>
                        </div>
                        <div style={{ marginLeft: '20px' }} dangerouslySetInnerHTML={{ __html: currentForm.form.coreValues.html }} />
                      </div>

                      <div>
                        <div style={{ display: 'flex' }}>
                          <h4 style={{ paddingRight: '5px', fontWeight: 'bolder' }}>2.</h4>
                          <h4 style={{ fontWeight: 'bolder' }}>Exec/Elder Communcation and Responsibilities</h4>
                        </div>
                        <div style={{ marginLeft: '20px' }} dangerouslySetInnerHTML={{ __html: currentForm.form.report.html }} />
                      </div>

                      <div>
                        <div style={{ display: 'flex' }}>
                          <h4 style={{ paddingRight: '5px', fontWeight: 'bolder' }}>3.</h4>
                          <h4 style={{ fontWeight: 'bolder' }}>Organizational Map (RACI)</h4>
                        </div>
                        <div style={{ marginLeft: '20px' }} dangerouslySetInnerHTML={{ __html: currentForm.form.orgMap.html }} />
                      </div>

                      <div>
                        <div style={{ display: 'flex' }}>
                          <h4 style={{ paddingRight: '5px', fontWeight: 'bolder' }}>4.</h4>
                          <h4 style={{ fontWeight: 'bolder' }}>Ministry Area Key Processes/Responsibilities/Resources</h4>
                        </div>
                        <div style={{ marginLeft: '20px' }} dangerouslySetInnerHTML={{ __html: currentForm.form.ministryArea.html }} />
                      </div>
                    </>
                  }
                </div>
              </div>
            </CardBody>
          </Card>
        </>
        :
        <>
          <p>Congrats! You changed the url to see if you could access someone elses stuff.</p>
          <iframe
            style={{ width: '100%', height: videoHeight }}
            onLoad={getVideoHeight}
            src="https://www.youtube.com/embed/dQw4w9WgXcQ?autoplay=1&cc_load_policy=1&mute=1"
            allow="autoplay">

          </iframe>
        </>
      }

    </React.Fragment>
  )
}

const HBABFormPDF = (props) => {
  return (
    <React.Suspense fallback={<Loading color="blue" />}>
      <GridContainer>
        <GridItem xs={12} lg={7}>
          <HBABFormPDFComp {...props} />
        </GridItem>
      </GridContainer>
    </React.Suspense>
  )
}

export default HBABFormPDF