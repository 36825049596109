import React from "react";
import { useRecoilValue } from "recoil";
import { newsfeedAtom } from "state/newsfeed";
import Loading from "components/Loading/Loading";
import Timeline from "components/Timeline/Timeline";

const NewsfeedWidgetComponent = (props) => {
  const newsfeed = useRecoilValue(newsfeedAtom);

  return (
    <React.Fragment>
      <Timeline simple stories={newsfeed} />
    </React.Fragment>
  );
};

const NewsfeedWidget = (props) => {
  return (
    <React.Suspense fallback={<Loading color="blue" />}>
      <NewsfeedWidgetComponent />
    </React.Suspense>
  );
};

export default NewsfeedWidget;
