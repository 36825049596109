import React, { useState } from "react";
import ReactTable from "components/ReactTable/ReactTable.js";
import { useRecoilValue, useRecoilState } from "recoil";

import Loading from "components/Loading/Loading";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Radio from "@material-ui/core/Radio";

import Edit from "@material-ui/icons/Edit";
import Clear from '@material-ui/icons/Clear';

import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "components/CustomButtons/Button";

import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import {
  onlineFormsAtom,
  removeOnlineForm,
  createOnlineForm,
  editOnlineForm,
  getOnlineForms
} from "state/onlineForms";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { TextField } from "@material-ui/core";

import update from "immutability-helper";
import dashboardStyles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";

const useStyles = makeStyles(() => ({
  ...styles,
  ...dashboardStyles,
}));


const ManageOnlineFormsComp = (props) => {
  const classes = useStyles();

  const [onlineForms, setOnlineForms] = useRecoilState(onlineFormsAtom)

  const emptyOnlineForm = {
    link: '',
    title: '',
    desc: '',
    newTab: true
  }

  const emptyErrors = {
    title: false,
    link: false
  }

  const [errors, setErrors] = useState(emptyErrors)

  const [toggleLoading, setToggleLoading] = useState(false)

  const [currentOnlineForm, setCurrentOnlineForm] = useState(emptyOnlineForm)

  const formOptions = onlineForms.map(item => {
    return {
      ...item,
      newTabReadable: item.newTab ? 'Yes' : 'No',
      actions: (
        <React.Fragment>
          <Button
            justIcon
            color="primary"
            onClick={() => { setCurrentOnlineForm(item) }}
            title='Edit'
          >
            <Edit />
          </Button>
          <Button
            justIcon
            color="danger"
            onClick={async () => {
              if (confirm(`Delete Online Form: ${item.title}?`)) {
                setToggleLoading(true)
                await removeOnlineForm({ id: item._id })
                setOnlineForms(getOnlineForms())
                setToggleLoading(false)
              }
            }}
            title='Delete'
          >
            <Clear />
          </Button>
        </React.Fragment>
      )
    }
  })

  const validateForm = () => {
    let valid = true
    let newErrors = emptyErrors
    if (currentOnlineForm.title.trim() === '') {
      valid = false
      newErrors.title = true
    }

    if (currentOnlineForm.link.trim() === '') {
      valid = false
      newErrors.link = true
    }

    setErrors(newErrors)

    return valid
  }

  const submitHandler = async () => {
    if (validateForm()) {
      setToggleLoading(true)
      let form = {
        title: currentOnlineForm.title,
        link: currentOnlineForm.link,
        desc: currentOnlineForm.desc,
        newTab: currentOnlineForm.newTab
      }
      if (currentOnlineForm._id) {
        form.id = currentOnlineForm._id
        await editOnlineForm(form)
      } else {
        await createOnlineForm(form)
      }
      setCurrentOnlineForm(emptyOnlineForm)
      setErrors(emptyErrors)
      setOnlineForms(getOnlineForms())
      setToggleLoading(false)
    }
  }

  return (
    <React.Fragment>
      {toggleLoading ? <Loading color='blue' /> :
        <GridContainer
          className={classes.formSection}
        >
          <GridItem sm={6} xs={12} style={{ marginTop: '10px' }}>
            <TextField
              fullWidth
              label="Title"
              variant="outlined"
              type="text"
              value={currentOnlineForm.title}
              error={errors.title}
              helperText={`${currentOnlineForm.title.length}/50`}
              onChange={(e) => {
                let val = e.target.value.substring(0, 50)
                setCurrentOnlineForm(prevState => {
                  return update(prevState, {
                    title: { $set: val }
                  })
                })
              }}
              required
            />
          </GridItem>
          <GridItem sm={6} xs={12} style={{ marginTop: '10px' }}>
            <span style={{ marginRight: '15px', fontSize: '15px' }}>Open in new tab?</span>
            <FormControlLabel
              control={
                <Radio
                  checked={currentOnlineForm.newTab}
                  name="Yes"
                  value={true}
                  onChange={(e) => {
                    setCurrentOnlineForm(prevState => {
                      return update(prevState, {
                        newTab: { $set: true }
                      })
                    })
                  }}
                  classes={{
                    checked: classes.checked,
                    root: classes.checkRoot,
                  }}
                />
              }
              classes={{
                label: classes.label,
                root: classes.labelRoot,
              }}
              label="Yes"
            />
            <FormControlLabel
              control={
                <Radio
                  checked={!currentOnlineForm.newTab}
                  name="No"
                  value={false}
                  onChange={(e) => {
                    setCurrentOnlineForm(prevState => {
                      return update(prevState, {
                        newTab: { $set: false }
                      })
                    })
                  }}
                  classes={{
                    checked: classes.checked,
                    root: classes.checkRoot,
                  }}
                />
              }
              classes={{
                label: classes.label,
                root: classes.labelRoot,
              }}
              label="No"
            />
          </GridItem>
          <GridItem xs={12} style={{ marginTop: '10px' }}>
            <TextField
              fullWidth
              label="Link"
              variant="outlined"
              type="text"
              value={currentOnlineForm.link}
              error={errors.link}
              onChange={(e) => {
                setCurrentOnlineForm(prevState => {
                  return update(prevState, {
                    link: { $set: e.target.value }
                  })
                })
              }}
              required
            />
          </GridItem>
          <GridItem xs={12} style={{ marginTop: '10px' }}>
            <TextField
              fullWidth
              label="Description"
              variant="outlined"
              type="text"
              value={currentOnlineForm.desc}
              multiline
              rows={3}
              // error={errors.code.hasError}
              helperText={`${currentOnlineForm.desc.length}/150`}
              onChange={(e) => {
                let val = e.target.value.substring(0, 150)
                setCurrentOnlineForm(prevState => {
                  return update(prevState, {
                    desc: { $set: val }
                  })
                })
              }}
            />
          </GridItem>
          <GridItem xs={12} style={{ textAlign: 'right' }}>
            <Button
              color="primary"
              onClick={() => { submitHandler() }}
            >
              Submit
            </Button>
          </GridItem>
          <GridItem xs={12}>
            <ReactTable
              columns={[
                {
                  Header: "Title",
                  accessor: "title",
                  disableFilters: false,
                  disableSortBy: false,
                },
                {
                  Header: "Description",
                  accessor: "desc",
                  disableFilters: false,
                  disableSortBy: false,
                },
                {
                  Header: "Link",
                  accessor: 'link',
                  disableFilters: false,
                  disableSortBy: false,
                },
                {
                  Header: "Open in new Tab",
                  accessor: 'newTabReadable',
                  disableFilters: false,
                  disableSortBy: false,
                },
                {
                  Header: "Actions",
                  accessor: "actions",
                  disableFilters: false,
                  disableSortBy: false,
                },
              ]}
              data={formOptions}
            />
          </GridItem>
        </GridContainer>
      }
    </React.Fragment>
  )
}


const ManageOnlineForms = (props) => {
  return (
    <React.Suspense fallback={<Loading color='blue' />}>
      <ManageOnlineFormsComp {...props} />
    </React.Suspense>
  )
}

export default ManageOnlineForms