import React, { useState, useMemo } from "react";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

import "assets/css/quill.snow.css";
import ReactQuill from "react-quill-new";

import Button from "components/CustomButtons/Button";

import update from "immutability-helper";

// Card
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import CardText from "components/Card/CardText";
import Loading from "components/Loading/Loading";

// Atom stuff
import { useRecoilValue } from "recoil";
import { userAtom } from "state/auth";
import {
  selectableDepartmentsAtom,
  activeUsersAtom,
  newTicket,
  inADepartmentAtom,
  isDepartmentOwnerOrAdminAtom
} from "state/ticketSystem";
import { Add, AttachFile, Close } from "@material-ui/icons";

const CreateEditTicketComp = (props) => {

  const departments = useRecoilValue(selectableDepartmentsAtom(true))
  const activeUsers = useRecoilValue(activeUsersAtom)
  const agent = useRecoilValue(inADepartmentAtom)
  const ownerAdmin = useRecoilValue(isDepartmentOwnerOrAdminAtom)
  const user = useRecoilValue(userAtom)

  const myProfile = activeUsers.find(i => i.userID === user.googleID)

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "indent",
    "link",
    "color",
  ];

  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          [{ header: [2, 3, 4, false] }],
          ["bold", "italic", "underline", "blockquote"],
          [{ color: [] }],
          [
            { list: "ordered" },
            { indent: "-1" },
            { indent: "+1" },
          ],
          ["link"],
        ],
      },
      clipboard: {
        matchVisual: true,
      },
    })
  );

  const emptyProfile = {
    userID: null,
    name: null,
    type: 'User'
  }

  const emptyTicket = {
    department: departments.length > 0 ? departments[0] : null,
    user: myProfile ? myProfile : emptyProfile,
    collaborators: [],
    subject: '',
    html: '',
    text: '',
    location: '',
    needBy: '',
    files: []
  }

  const emptyError = {
    hasError: false,
    message: ''
  }

  const emptyErrors = {
    department: { ...emptyError },
    user: { ...emptyError },
    collaborators: [],
    subject: { ...emptyError },
    html: { ...emptyError },
    location: {
      hasError: false,
      message: '*Optional'
    },
    needBy: {
      hasError: false,
      message: '*Optional'
    },
    files: { ...emptyError }
  }

  const collaboratorTypes = [
    {
      value: 'User',
      label: 'Staff'
    },
    {
      value: 'Email',
      label: 'Non-Staff'
    },
  ]

  const [ticket, setTicket] = useState(emptyTicket)
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState(emptyErrors)

  const maxUploadSize = 200000000 // 200MB
  const totalUploadSize = ticket.files.map(i => i.size).reduce((a, b) => a + b, 0)

  const setDepartment = (department) => {
    setTicket((prev) => {
      return update(prev, {
        department: { $set: department }
      })
    })
  }

  const setUser = (user) => {
    setTicket((prev) => {
      return update(prev, {
        user: { $set: user }
      })
    })
  }

  const setSubject = (subject) => {
    setTicket((prev) => {
      return update(prev, {
        subject: { $set: subject }
      })
    })
  }

  const setHTML = (html, text) => {
    setTicket((prev) => {
      return update(prev, {
        html: { $set: html },
        text: { $set: text }
      })
    })
  }

  const setLocation = (value) => {
    setTicket((prev) => {
      return update(prev, {
        location: { $set: value }
      })
    })
  }

  const setNeedBy = (value) => {
    setTicket((prev) => {
      return update(prev, {
        needBy: { $set: value }
      })
    })
  }

  const addCC = () => {
    let entry = {
      id: null,
      name: null,
      type: 'User'
    }
    setTicket((prev) => {
      return update(prev, {
        collaborators: { $push: [entry] }
      })
    })
    setErrors((prev) => {
      return update(prev, {
        collaborators: { $push: [{ ...emptyError }] }
      })
    })
  }

  const updateCC = (value, index) => {
    setTicket((prev) => {
      return update(prev, {
        collaborators: { [index]: { $set: value } }
      })
    })
  }

  const removeCC = (index) => {
    setTicket((prev) => {
      return update(prev, {
        collaborators: { $splice: [[index, 1]] }
      })
    })
    setErrors((prev) => {
      return update(prev, {
        collaborators: { $splice: [[index, 1]] }
      })
    })
  }

  const addFiles = (files) => {
    let listFiles = []
    for (let i = 0; i < files.length; i++) {
      listFiles.push(files[i])
    }
    setTicket((prev) => {
      return update(prev, {
        files: { $push: listFiles }
      })
    })
  }

  const removeFile = (index) => {
    setTicket((prev) => {
      return update(prev, {
        files: { $splice: [[index, 1]] }
      })
    })
  }

  const filterOutExisting = (options, alreadySelected, type, currentVal = null) => {
    let newOptions = []
    alreadySelected = [...alreadySelected, ticket.user]
    if (type === 'User') {
      options.forEach(item => {
        if (!alreadySelected.find(i => i.userID === item.userID)) {
          newOptions.push(item)
        } else if (currentVal) {
          if (currentVal.userID === item.userID) {
            newOptions.push(item)
          }
        }
      })
    }
    return newOptions
  }

  const createTicket = async () => {
    setLoading(true)
    let formData = new FormData()
    if (agent || ownerAdmin) {
      formData.append('userID', ticket.user.userID)
    }
    formData.append('html', ticket.html)
    formData.append('text', ticket.text)
    formData.append('subject', ticket.subject)
    ticket.collaborators.forEach(i => {
      formData.append('collaborators', i.userID)
    })
    formData.append('departmentID', ticket.department.id)
    if (ticket.needBy !== '' && ticket.needBy) {
      formData.append('needBy', ticket.needBy)
    }
    if (ticket.location !== '' && ticket.location) {
      formData.append('location', ticket.location)
    }
    await newTicket(formData, ticket.files)
    setTicket(emptyTicket)
    setLoading(false)
  }

  const validEmail = (email) => {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  }

  const validateForm = () => {
    let error = false
    let newErrors = emptyErrors

    if (agent || ownerAdmin) {
      if (!ticket.user.userID) {
        newErrors.user.hasError = true
        newErrors.user.message = '*Required'
        error = true
      } else {
        if (ticket.user.type === 'Email') {
          if (ticket.user.userID.toLowerCase().includes('@sagebrush.')) {
            newErrors.user.hasError = true
            newErrors.user.message = '*Staff email'
            error = true
          }
          if (!validEmail(ticket.user.userID)) {
            newErrors.user.hasError = true
            newErrors.user.message = '*Invalid email format'
            error = true
          }
        }
      }
    }

    if (ticket.department === null) {
      newErrors.department.hasError = true
      newErrors.department.message = '*Required'
      error = true
    }

    ticket.collaborators.forEach((i, index) => {
      newErrors.collaborators.push({ ...emptyError })
      if (!i.userID) {
        newErrors.collaborators[index].hasError = true
        newErrors.collaborators[index].message = '*Required'
        error = true
      } else {
        if (i.type === 'Email') {
          if (i.userID.toLowerCase().includes('@sagebrush.')) {
            newErrors.collaborators[index].hasError = true
            newErrors.collaborators[index].message = '*Staff email'
            error = true
          }
          if (!validEmail(i.userID)) {
            newErrors.collaborators[index].hasError = true
            newErrors.collaborators[index].message = '*Invalid email format'
            error = true
          }
        }
      }
    })

    if (!ticket.subject) {
      newErrors.subject.hasError = true
      newErrors.subject.message = '*Required'
      error = true
    }

    if (!ticket.html) {
      newErrors.html.hasError = true
      newErrors.html.message = '*Required'
      error = true
    }

    if (totalUploadSize > maxUploadSize) {
      error = true
    }

    setErrors(newErrors)
    return error
  }

  return (
    <React.Fragment>
      <GridContainer>
        <GridItem xs={12} style={{ maxWidth: '1000px' }}>
          <Card>
            <CardHeader color="info" text>
              <CardText color="info" style={{ fontSize: '16px', fontWeight: '400', padding: '10px' }}>
                New Ticket
              </CardText>
            </CardHeader>
            {loading ? <Loading color='blue' /> :
              <CardBody style={{ padding: '20px 20px' }}>
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                  <div style={{ flex: 1 }}>
                    <Autocomplete
                      disablePortal
                      options={departments}
                      getOptionLabel={(option) =>
                        `${option.name}` || ""
                      }
                      style={{ minWidth: '300px', marginBottom: '10px' }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label='Department'
                          variant="outlined"
                          helperText={errors.department.message}
                          error={errors.department.hasError} />
                      )}
                      onChange={(e, v) =>
                        setDepartment(v)
                      }
                      autoSelect={true}
                      disableClearable
                      autoComplete={true}
                      autoHighlight={true}
                      value={ticket.department}
                      getOptionSelected={(selected) => selected.id === ticket.department.id}
                    />
                  </div>
                  <div style={{ width: '10px' }} />
                  <div style={{ flex: 1 }} />
                </div>
                {(agent || ownerAdmin) && (
                  <>
                    <span >Ticket For:</span>
                    <div
                      style={{
                        border: "1px solid rgba(0, 0, 0, 0.23)",
                        borderRadius: "3px",
                        background: "#fffff",
                        padding: "10px",
                        minWidth: '290px',
                        overflowX: 'auto',
                        marginBottom: '10px'
                      }}
                    >
                      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <div style={{ display: 'flex', padding: '10px 0px', margin: '-5px', width: '100%', flexWrap: 'wrap' }}>
                          <div style={{ minWidth: '225px', flex: 1, margin: '0px 5px' }}>
                            <Autocomplete
                              disablePortal
                              options={collaboratorTypes}
                              fullWidth
                              getOptionLabel={(option) =>
                                option.label}
                              value={collaboratorTypes.find(i => i.value === ticket.user.type)}
                              renderInput={(params) => (
                                <TextField {...params} label="Type" />
                              )}
                              ListboxProps={
                                {
                                  style: {
                                    maxHeight: '120px',
                                  }
                                }
                              }
                              onChange={(e, v) => {
                                if (v !== ticket.user.type) {
                                  let entry = {
                                    userID: null,
                                    name: null,
                                    type: v.value
                                  }
                                  setUser(entry)
                                }
                              }
                              }
                              autoSelect={true}
                              autoComplete={true}
                              autoHighlight={true}
                              disableClearable
                            />
                          </div>
                          <div style={{ minWidth: '225px', flex: 1, margin: '0px 5px' }}>
                            {ticket.user.type === 'User' ?
                              <Autocomplete
                                disablePortal
                                options={filterOutExisting(activeUsers, ticket.collaborators, ticket.user.type, ticket.user)}
                                fullWidth
                                getOptionSelected={(selected) => {
                                  return selected.userID === ticket.user.userID
                                }}
                                getOptionLabel={(option) => {
                                  return option.name
                                }}
                                value={ticket.user.userID ? ticket.user : null}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label={ticket.user.type}
                                    helperText={errors.user.message}
                                    error={errors.user.hasError}
                                  />
                                )}
                                onChange={(e, v) => {
                                  setUser(v)
                                }
                                }
                                autoSelect={true}
                                autoComplete={true}
                                autoHighlight={true}
                                disableClearable
                                ListboxProps={
                                  {
                                    style: {
                                      maxHeight: '120px',
                                    }
                                  }
                                }
                              />
                              :
                              <>
                                <TextField
                                  fullWidth
                                  label='Email'
                                  onChange={(e) => {
                                    let val = {
                                      userID: e.target.value,
                                      name: e.target.value,
                                      type: ticket.user.type
                                    }
                                    setUser(val)
                                  }}
                                  value={ticket.user.userID ? ticket.user.userID : ''}
                                  helperText={errors.user.message}
                                  error={errors.user.hasError}
                                />
                              </>
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
                <span >CC:</span>
                <div
                  style={{
                    border: "1px solid rgba(0, 0, 0, 0.23)",
                    borderRadius: "3px",
                    background: "#fffff",
                    padding: "10px",
                    minWidth: '290px',
                    overflowX: 'auto',
                  }}
                >
                  {ticket.collaborators.map((item, index) => {
                    return (
                      <div key={index} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <div style={{ display: 'flex', padding: '10px 0px', margin: '-5px', width: '100%', flexWrap: 'wrap' }}>
                          <div style={{ minWidth: '225px', flex: 1, margin: '0px 5px' }}>
                            <Autocomplete
                              disablePortal
                              options={collaboratorTypes}
                              fullWidth
                              getOptionLabel={(option) =>
                                option.label}
                              value={collaboratorTypes.find(i => i.value === item.type)}
                              renderInput={(params) => (
                                <TextField {...params} label="Type" />
                              )}
                              ListboxProps={
                                {
                                  style: {
                                    maxHeight: '120px',
                                  }
                                }
                              }
                              onChange={(e, v) => {
                                if (v !== item.type) {
                                  let entry = {
                                    userID: null,
                                    name: null,
                                    type: v.value
                                  }
                                  updateCC(entry, index)
                                }
                              }
                              }
                              autoSelect={true}
                              autoComplete={true}
                              autoHighlight={true}
                              disableClearable
                            />
                          </div>
                          <div style={{ minWidth: '225px', flex: 1, margin: '0px 5px' }}>
                            {item.type === 'User' ?
                              <Autocomplete
                                disablePortal
                                options={filterOutExisting(activeUsers, ticket.collaborators, item.type, item)}
                                fullWidth
                                getOptionSelected={(selected) => {
                                  return selected.userID === item.userID
                                }}
                                getOptionLabel={(option) => {
                                  return option.name
                                }}
                                value={item.userID ? item : null}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label={item.type}
                                    error={errors.collaborators[index].hasError}
                                    helperText={errors.collaborators[index].message}
                                  />
                                )}
                                onChange={(e, v) => {
                                  updateCC(v, index)
                                }
                                }
                                autoSelect={true}
                                autoComplete={true}
                                autoHighlight={true}
                                disableClearable
                                ListboxProps={
                                  {
                                    style: {
                                      maxHeight: '120px',
                                    }
                                  }
                                }
                              />
                              :
                              <>
                                <TextField
                                  fullWidth
                                  label='Email'
                                  error={errors.collaborators[index].hasError}
                                  helperText={errors.collaborators[index].message}
                                  onChange={(e) => {
                                    let val = {
                                      userID: e.target.value,
                                      name: e.target.value,
                                      type: item.type
                                    }
                                    updateCC(val, index)
                                  }}
                                  value={item.userID ? item.userID : ''}
                                />
                              </>
                            }
                          </div>
                        </div>
                        <Button
                          style={{ margin: '0px', padding: '7px', height: '32px', marginLeft: '10px' }}
                          color='danger'
                          title='Remove'
                          onClick={(e) => {
                            e.preventDefault()
                            removeCC(index)
                          }}
                        >
                          <Close style={{ margin: 0, padding: 0 }} />
                        </Button>
                      </div>
                    )
                  })}
                  <div style={{ width: '100%', textAlign: 'right' }}>
                    <Button
                      style={{ margin: '0px', padding: '7px', height: '32px' }}
                      color='primary'
                      title='Add'
                      onClick={(e) => {
                        e.preventDefault()
                        addCC()
                      }}
                    >
                      Add
                    </Button>
                  </div>
                </div>
                <span style={{ color: 'rgba(0, 0, 0, 0.7)', fontSize: '12px', marginLeft: '15px' }}>*Optional</span>

                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                  <TextField
                    style={{ flex: 1, minWidth: '300px', marginTop: '5px' }}
                    variant="outlined"
                    label='Location/Campus'
                    helperText={errors.location.message}
                    error={errors.location.hasError}
                    value={ticket.location}
                    onChange={(e) => {
                      setLocation(e.target.value)
                    }}
                  />
                  <div style={{ width: '10px' }}></div>
                  <TextField
                    style={{ flex: 1, minWidth: '300px', marginTop: '5px' }}
                    type="date"
                    variant="outlined"
                    label='Need By'
                    helperText={errors.needBy.message}
                    error={errors.needBy.hasError}
                    value={ticket.needBy}
                    onChange={(e) => {
                      setNeedBy(e.target.value)
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>
                <TextField
                  style={{ marginTop: '5px' }}
                  variant="outlined"
                  label='Subject'
                  fullWidth
                  value={ticket.subject}
                  helperText={errors.subject.message}
                  error={errors.subject.hasError}
                  onChange={(e) => {
                    setSubject(e.target.value)
                  }}
                />

                <ReactQuill
                  onChange={(content, delta, source, editor) => {
                    setHTML(editor.getHTML(), editor.getText())
                  }}
                  preserveWhitespace
                  value={ticket.html}
                  style={errors.html.hasError ? { marginTop: '20px', border: '1px solid red' } : { marginTop: '20px' }}
                  modules={modules}
                  formats={formats}
                />
                {(errors.html.message) && (<span style={{ marginBottom: '10px', color: errors.html.hasError ? 'red' : 'rgba(0, 0, 0, 0.7)', fontSize: '12px', marginLeft: '15px' }}>{errors.html.message}</span>)}

                <p style={{ margin: 0, padding: 0 }}>Attachments:</p>
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                  <input
                    //accept="image/*"
                    style={{ display: 'none' }}
                    id="upload-button"
                    multiple
                    type="file"
                    onChange={(e) => {
                      addFiles(e.target.files)
                    }}
                  />
                  <label htmlFor="upload-button">
                    <Button
                      style={{
                        height: '30px',
                        //backgroundColor: '#3695d7',
                        borderRadius: '5px',
                        margin: '5px 10px 0px 0px',
                        display: 'flex',
                        justifyContent: 'center',
                        padding: '0px',
                        overflow: 'hidden',
                        alignItems: 'center',
                      }}
                      justIcon
                      title='Upload'
                      color='primary'
                      component="span"
                      variant="contained"
                    >
                      <Add style={{ margin: '0px', padding: '0px' }} />
                    </Button>
                  </label>
                  {ticket.files.map((file, index) => {
                    return (
                      <div
                        style={{
                          display: 'flex',
                          margin: '5px 10px 0px 0px',
                          alignItems: 'center',
                          backgroundColor: '#eee',
                          borderRadius: '5px'
                        }}
                        key={index}
                      >
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'start',
                            padding: '0px 5px',
                            color: 'black',
                            overflow: 'hidden',
                            alignItems: 'center',
                          }}
                          title={file.name}
                        >
                          <AttachFile style={{ color: '#3695d7', margin: '0px', padding: '0px', fontSize: '18px' }} />
                          <span style={{ margin: '0px', padding: '0px', textTransform: 'none' }}>{file.name}</span>
                        </div>
                        <Button
                          style={{ padding: '5px', margin: '0px' }} color='primary'
                          onClick={(e) => {
                            e.preventDefault()
                            removeFile(index)
                          }}
                        >
                          <Close style={{ margin: '0px', padding: '0px', }} />
                        </Button>
                      </div>
                    )
                  })}
                </div>
                <p style={{ marginTop: '5px', color: totalUploadSize > maxUploadSize ? 'red' : 'black' }}>Upload Size: {Math.round((totalUploadSize / 1000000) * 100) / 100} / {maxUploadSize / 1000000}MB</p>

                <div style={{ textAlign: 'right' }}>
                  <Button color="primary"
                    onClick={(e) => {
                      e.preventDefault()
                      if (!validateForm()) {
                        createTicket()
                      }
                    }}
                  >
                    Send
                  </Button>
                </div>
              </CardBody>}
          </Card>
        </GridItem>
      </GridContainer>
    </React.Fragment >
  )
}

const CreateEditTicket = (props) => {
  return (
    <React.Suspense fallback={<Loading color="blue" />}>
      <CreateEditTicketComp {...props} />
    </React.Suspense>
  )
}

export default CreateEditTicket