import React from "react";
import {
  Document,
  Page,
  View,
  Text,
  StyleSheet,
} from "@react-pdf/renderer";

const styles = StyleSheet.create({
  page: {
    fontSize: 10,
    padding: '50px',
  },
  defaultFlex: {
    display: "flex",
    flexDirection: "row",
  },
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  box: {
    border: "1px solid #000",
    borderTop: "none",
    padding: 5,
  },
  lineItemsWrap: {
    display: "flex",
    flexDirection: "row",
    fontSize: 10,
  },
  lineItemsHeadings: {
    fontWeight: "bold",
    display: "flex",
    flexDirection: "row",
    borderBottom: "1px solid #000",
    fontSize: 12,
  },
  lineItemSingle: {
    padding: 5,
    borderBottom: "1px solid #000",
  },
  signature: {
    fontFamily: "Signature",
    fontSize: 30,
  },
  boxHeader: {
    backgroundColor: "#2C82C5",
    color: "#fff",
    padding: 5,
    fontSize: 12,
    textAlign: "center",
  },
  center: {
    textAlign: "center",
  },
  smallPadding: {
    padding: 5,
  },
  section: {
    marginTop: 10,
    marginBottom: 10,
  },
  mediumColumn: {
    flexBasis: "25%",
    borderLeft: "1px solid #000",
  },
  largeColumn: {
    flexBasis: "60%",
    borderLeft: "1px solid #000",
  },
  smallColumn: {
    flexBasis: "15%",
    borderRight: "1px solid #000",
    borderLeft: "1px solid #000",
  },
  fifth: {
    flexBasis: "20%",
    borderBottom: "1px solid #000",
    borderLeft: "1px solid #000",
    borderTop: "1px solid #000",
  },
  extraLargeColumn: {
    flexBasis: "85%",
    borderLeft: "1px solid #000",
  },
});

/**
 * Used to determine what color to use in the pdf based off enviornment.
 * @param {String} enviornment the enviornment name
 * @returns {String}
 * @func
 */
const getColor = (enviornment) => {
  if (enviornment === 'Wired') {
    return '#f20000'
  } else if (enviornment === 'Adventure Island') {
    return '#f77e21'
  } else {
    return '#2c82c5'
  }
}
/**
 * @typedef FormInfo
 * @type {object}
 * @prop {String} title Title of the document
 * @prop {String} enviornment what enviornment this is for (W, AI, SS)
 * @prop {Array<SupplyCalc>} totals the total number of each supply that is needed
 * @prop {Array<RoomCalculation>} rooms each room and the supplies needed for each room
 */

/**
 * Creates the PDF document containing all the calculations from the form.
 * @param {Object} props 
 * @prop {FormInfo} formInfo
 * @component 
 */
const PackagingPDF = ({ formInfo }) => {
  return (
    <React.Fragment>
      <Document>
        <Page style={styles.page}>
          <TotalsPage formInfo={formInfo} />
          {formInfo.rooms.map((room, index) => {
            return (<RoomPage
              key={index}
              room={room}
              title={formInfo.title}
              enviornment={formInfo.enviornment}
            />)
          })}
        </Page>
      </Document>
    </React.Fragment>
  )
}

export default PackagingPDF

/**
 * Used to generate a page containing all the supplies needed for a room.
 * @param {object} props 
 * @prop {RoomCalculation} room information about the room and supplies needed
 * @prop {String} title the title of the doccument
 * @prop {String} enviornment the enviornment the document is for
 * @component
 */
const RoomPage = ({ room, title, enviornment }) => {
  const color = getColor(enviornment)
  return (
    <>
      <View
        style={[styles.header, styles.section, { marginTop: 0 }]}
        break
      >
        <View
          style={[
            {
              textTransform: "uppercase",
              fontSize: 14,
            },
          ]}
        >
          <View
            style={[
              {
                fontSize: 30,
                paddingBottom: 1,
                marginBottom: 5,
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
              },
            ]}
          >
            <Text style={[{ borderBottom: `1px solid ${color}`, color: color }]}>
              {room.location}
            </Text>
          </View>
          <Text>{enviornment}</Text>
          <Text>{title}</Text>
          <View
            style={{ fontSize: 10, marginTop: '10px' }}>
            <Text>Kids: {room.kids}</Text>
            <Text>Services: {room.numberOfServices}</Text>
          </View>
        </View>
      </View>

      <View
        style={[
          {
            textTransform: "uppercase",
            fontSize: 18,
            marginTop: '15px',
          },
        ]}
      >
        <Text>Supplies:</Text>
      </View>
      <View
        style={{
          display: 'flex',
          alignItems: "center",
          flexDirection: "row",
          marginTop: '5px',
          border: `1px solid ${color}`
        }}>
        <View style={{ flex: 4, borderRight: `1px solid ${color}`, padding: '5px' }}>
          <Text>Description</Text>
        </View>
        <View style={{ flex: 2, borderRight: `1px solid ${color}`, padding: '5px' }}>
          <Text>Quantity</Text>
        </View>
        <View style={{ flex: 2, borderRight: `1px solid ${color}`, padding: '5px' }}>
          <Text>Location</Text>
        </View>
        <View style={{ flex: 1, padding: '5px' }}>
          <Text>Returned?</Text>
        </View>
      </View>

      {room.supplies.map((item, index) => {
        return (<View
          key={index}
          style={{
            display: 'flex',
            alignItems: "center",
            flexDirection: "row",
            borderBottom: `1px solid ${color}`,
            borderRight: `1px solid ${color}`,
            borderLeft: `1px solid ${color}`
          }}>
          <View style={{ flex: 4, borderRight: `1px solid ${color}`, padding: '5px', overflow: 'hidden' }}>
            <Text>{item.desc}</Text>
          </View>
          <View style={{ flex: 2, borderRight: `1px solid ${color}`, padding: '5px' }}>
            <Text>{item.quantity}</Text>
          </View>
          <View style={{ flex: 2, borderRight: `1px solid ${color}`, padding: '5px' }}>
            <Text>{item.status}</Text>
          </View>
          <View style={{ flex: 1, padding: '5px' }}>
            <Text>{item.returned}</Text>
          </View>
        </View>)
      })}
      <View
        style={{
          display: 'flex',
          alignItems: "center",
          flexDirection: "row",
          marginTop: '25px',
        }}>
        <View style={{ flex: 7, display: 'flex', flexDirection: "row" }}>
          <View style={{ flex: 1 }}><Text>Packed By:</Text></View>
          <View style={{ flex: 4, borderBottom: '1px solid black' }}></View>
        </View>
        <View style={{ flex: 3, display: 'flex', marginLeft: '10px', flexDirection: "row" }}>
          <View style={{ flex: 1 }}><Text>Date:</Text></View>
          <View style={{ flex: 4, borderBottom: '1px solid black' }}></View>
        </View>
      </View>

      <View
        style={{
          display: 'flex',
          alignItems: "center",
          flexDirection: "row",
          marginTop: '25px',
        }}>
        <View style={{ flex: 7, display: 'flex', flexDirection: "row" }}>
          <View style={{ flex: 1 }}><Text>Checked By:</Text></View>
          <View style={{ flex: 4, borderBottom: '1px solid black' }}></View>
        </View>
        <View style={{ flex: 3, display: 'flex', marginLeft: '10px', flexDirection: "row" }}>
          <View style={{ flex: 1 }}><Text>Date:</Text></View>
          <View style={{ flex: 4, borderBottom: '1px solid black' }}></View>
        </View>
      </View>

      <View
        style={{
          display: 'flex',
          alignItems: "center",
          flexDirection: "row",
          marginTop: '25px',
        }}>
        <View style={{ flex: 7, display: 'flex', flexDirection: "row" }}>
          <View style={{ flex: 1 }}><Text>Picked Up By:</Text></View>
          <View style={{ flex: 4, borderBottom: '1px solid black' }}></View>
        </View>
        <View style={{ flex: 3, display: 'flex', marginLeft: '10px', flexDirection: "row" }}>
          <View style={{ flex: 1 }}><Text>Date:</Text></View>
          <View style={{ flex: 4, borderBottom: '1px solid black' }}></View>
        </View>
      </View>
    </>
  )
}


/**
 * Creates the front page/totals page for the doc
 * @param {object} props
 * @prop {FormInfo} formInfo
 * @component 
 */
const TotalsPage = ({ formInfo }) => {
  const color = getColor(formInfo.enviornment)
  return (
    <>
      <View
        style={[styles.header, styles.section, { marginTop: 0 }]}
      >
        <View
          style={[
            {
              textTransform: "uppercase",
              fontSize: 14,
            },
          ]}
        >
          <View
            style={[
              {
                fontSize: 30,
                paddingBottom: 1,
                marginBottom: 5,
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
              },
            ]}
          >
            <Text style={[{ borderBottom: `1px solid ${color}`, color: color }]}>
              Packaging Numbers
            </Text>
          </View>
          <Text>{formInfo.enviornment}</Text>
          <Text>{formInfo.title}</Text>
        </View>
      </View>
      <View
        style={[
          {
            textTransform: "uppercase",
            fontSize: 18,
            marginTop: '15px',
          },
        ]}
      >
        <Text>Totals:</Text>
      </View>
      <View
        style={{
          display: 'flex',
          alignItems: "center",
          flexDirection: "row",
          marginTop: '5px',
          border: `2px solid ${color}`
        }}>
        <View style={{ flex: 4, borderRight: `1px solid ${color}`, padding: '5px' }}>
          <Text>Description</Text>
        </View>
        <View style={{ flex: 2, borderRight: `1px solid ${color}`, padding: '5px' }}>
          <Text>Quantity</Text>
        </View>
        <View style={{ flex: 2, borderRight: `1px solid ${color}`, padding: '5px' }}>
          <Text>Location</Text>
        </View>
        <View style={{ flex: 1, padding: '5px' }}>
          <Text>Returned?</Text>
        </View>
      </View>

      {formInfo.totals.map((item, index) => {
        return (<View
          key={index}
          style={{
            display: 'flex',
            alignItems: "center",
            flexDirection: "row",
            borderBottom: `1px solid ${color}`,
            borderRight: `1px solid ${color}`,
            borderLeft: `1px solid ${color}`
          }}>
          <View style={{ flex: 4, borderRight: `1px solid ${color}`, padding: '5px', overflow: 'hidden' }}>
            <Text>{item.desc}</Text>
          </View>
          <View style={{ flex: 2, borderRight: `1px solid ${color}`, padding: '5px' }}>
            <Text>{item.quantity}</Text>
          </View>
          <View style={{ flex: 2, borderRight: `1px solid ${color}`, padding: '5px' }}>
            <Text>{item.status}</Text>
          </View>
          <View style={{ flex: 1, padding: '5px' }}>
            <Text>{item.returned}</Text>
          </View>
        </View>)
      })}
    </>
  )
}