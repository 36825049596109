import React from "react";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CheckinOverview from "widgets/CheckinOverview";
import MonthlyVerse from "widgets/MonthlyVerse";
import CalendarWidget from "widgets/CalendarWidget";
import NewsfeedWidget from "widgets/NewsfeedWidget";

export default function Dahboard() {
  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12} lg={6}>
          <GridContainer>
            <GridItem xs={12} sm={6}>
              <CheckinOverview />
            </GridItem>
            <GridItem xs={12} sm={6}>
              <MonthlyVerse />
            </GridItem>
          </GridContainer>

          <GridContainer>
            <GridItem xs={12}>
              <NewsfeedWidget />
            </GridItem>
          </GridContainer>
        </GridItem>

        <GridItem xs={12} sm={12} md={12} lg={6}>
          <CalendarWidget />
        </GridItem>
      </GridContainer>
    </div>
  );
}
