/*eslint-disable*/
import React, { useEffect, useState } from "react";
import {
  useTable,
  useFilters,
  useAsyncDebounce,
  useSortBy,
  usePagination,
} from "react-table";
import classnames from "classnames";
// A great library for fuzzy filtering/sorting items
import { matchSorter } from "match-sorter";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputLabel from "@material-ui/core/InputLabel";
import Switch from "@material-ui/core/Switch";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
// core components
import CustomInput from "components/CustomInput/CustomInput.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { Checkbox } from "@material-ui/core";

import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";

const newStyles = {
  ...styles,
  formControlMargins: {
    margin: "3px 0 !important",
  },
  gridContainer: {
    justifyContent: "center",
  },
};

const useStyles = makeStyles(newStyles);

// Define a default UI for filtering
function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length;

  return (
    <CustomInput
      formControlProps={{
        fullWidth: true,
      }}
      inputProps={{
        value: filterValue || "",
        onChange: (e) => {
          setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
        },
        placeholder: `Search ${count} records...`,
      }}
    />
  );
}

function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val) => !val;

// Our table component
function Table({ columns, data, defaultPageSize = 50, minWidthTable, checkBox }) {
  const [numberOfRows, setNumberOfRows] = React.useState(250);
  const [pageSelect, handlePageSelect] = useState(0);

  const classes = useStyles();
  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
              .toLowerCase()
              .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );


  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state,
    visibleColumns,
    nextPage,
    pageOptions,
    pageCount,
    previousPage,
    canPreviousPage,
    canNextPage,
    setPageSize,
    gotoPage,
    state: { pageIndex, pageSize }
  } = useTable(
    {
      columns,
      data,
      defaultColumn, // Be sure to pass the defaultColumn option
      filterTypes,
      initialState: { pageSize: defaultPageSize, pageIndex: pageSelect },
    },
    useFilters, // useFilters!
    useSortBy,
    usePagination
  );

  // We don't want to render all of the rows for this example, so cap
  // it for this use case
  // const firstPageRows = rows.slice(0, 10);
  let pageSelectData = Array.apply(
    null,
    Array(pageOptions.length)
  ).map(function () { });
  let numberOfRowsData = [5, 10, 20, 25, 50, 100];
  return (
    <div style={{ overflow: 'auto' }}>
      <div className="ReactTable -striped -highlight" style={minWidthTable ? { minWidth: minWidthTable } : {}}>
        {pageCount > 1 && (
          <div className="pagination-bottom" style={{ display: 'flex', marginTop: '10px', justifyContent: 'center' }}>
            <button
              style={canPreviousPage ?
                { cursor: 'pointer', border: '1px solid #aaa', borderRadius: '2px', marginRight: '5px' }
                : { marginRight: '5px' }}
              onClick={() => {
                gotoPage(0)
                handlePageSelect(0)
              }}
              disabled={!canPreviousPage}>
              {"<<"}
            </button>
            <button
              style={canPreviousPage ?
                { cursor: 'pointer', border: '1px solid #aaa', borderRadius: '2px', marginRight: '5px' }
                : { marginRight: '5px' }}
              onClick={() => {
                previousPage()
                handlePageSelect(pageSelect - 1)
              }}
              disabled={!canPreviousPage}>
              {"<"}
            </button>
            <div style={{ display: 'flex' }}>
              <input
                type="text"
                style={{
                  marginRight: '5px',
                  width: '30px'

                }}
                value={pageSelect !== '' ? pageSelect + 1 : pageSelect}
                onKeyPress={(e) => {
                  if (!/[0-9]/.test(e.key)) {
                    e.preventDefault();
                  }
                }}
                onChange={(e) => {
                  let val = e.target.value
                  if (/^\d+$/.test(val) || val === '') {
                    if (val !== '') {
                      val = parseInt(val) - 1
                      if (val < 0) {
                        val = 0
                      }
                      if (val >= pageCount) {
                        val = pageCount - 1
                      }
                      gotoPage(val)
                      handlePageSelect(val)
                    } else {
                      handlePageSelect(val)
                    }
                  } else {
                    e.preventDefault()
                  }
                }}
              />
              <span style={{ marginRight: '5px' }}>of {pageCount}</span>
            </div>
            <button
              style={canNextPage ?
                { cursor: 'pointer', border: '1px solid #aaa', borderRadius: '2px', marginRight: '5px' }
                : { marginRight: '5px' }}
              onClick={() => {
                nextPage()
                handlePageSelect(pageSelect + 1)
              }}
              disabled={!canNextPage}>
              {">"}
            </button>
            <button
              style={canNextPage ?
                { cursor: 'pointer', border: '1px solid #aaa', borderRadius: '2px' } : {}}
              onClick={() => {
                gotoPage(pageCount - 1)
                handlePageSelect(pageCount - 1)
              }}
              disabled={!canNextPage}>
              {">>"}
            </button>
          </div>
        )}
        <table {...getTableProps()} className="rt-table" style={{ overflow: 'unset' }}>
          <thead className="rt-thead -header">
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()} className="rt-tr">
                {headerGroup.headers.map((column, key) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    style={column.headerStyle ? column.headerStyle : column.style}
                    className={classnames("rt-th rt-resizable-header", {
                      "-cursor-pointer": true,
                      "-sort-asc": column.isSorted && !column.isSortedDesc,
                      "-sort-desc": column.isSorted && column.isSortedDesc,
                    })}
                  >
                    <div
                      className={classnames("rt-resizable-header-content", {
                        "is-sortable": column.canSort,
                      })}
                    >
                      {(checkBox && column.checkBox) ? checkBox() : column.render("Header")}
                    </div>
                    {/* Render the columns filter UI */}
                    <div>
                      {headerGroup.headers.length - 1 === key
                        ? null
                        : column.canFilter
                          ? column.render("Filter")
                          : null}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()} className="rt-tbody">
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  className={classnames(
                    "rt-tr",
                    { " -odd": i % 2 === 0 },
                    { " -even": i % 2 === 1 }
                  )}
                >
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps({ style: cell.column.style })}

                        className="rt-td"
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
        {pageCount > 1 && (
          <div className="pagination-bottom" style={{ display: 'flex', marginTop: '10px', justifyContent: 'center' }}>
            <button
              style={canPreviousPage ?
                { cursor: 'pointer', border: '1px solid #aaa', borderRadius: '2px', marginRight: '5px' }
                : { marginRight: '5px' }}
              onClick={() => {
                gotoPage(0)
                handlePageSelect(0)
              }}
              disabled={!canPreviousPage}>
              {"<<"}
            </button>
            <button
              style={canPreviousPage ?
                { cursor: 'pointer', border: '1px solid #aaa', borderRadius: '2px', marginRight: '5px' }
                : { marginRight: '5px' }}
              onClick={() => {
                previousPage()
                handlePageSelect(pageSelect - 1)
              }}
              disabled={!canPreviousPage}>
              {"<"}
            </button>
            <div style={{ display: 'flex' }}>
              <input
                type="text"
                style={{
                  marginRight: '5px',
                  width: '30px'

                }}
                value={pageSelect !== '' ? pageSelect + 1 : pageSelect}
                onKeyPress={(e) => {
                  if (!/[0-9]/.test(e.key)) {
                    e.preventDefault();
                  }
                }}
                onChange={(e) => {
                  let val = e.target.value
                  if (/^\d+$/.test(val) || val === '') {
                    if (val !== '') {
                      val = parseInt(val) - 1
                      if (val < 0) {
                        val = 0
                      }
                      if (val >= pageCount) {
                        val = pageCount - 1
                      }
                      gotoPage(val)
                      handlePageSelect(val)
                    } else {
                      handlePageSelect(val)
                    }
                  } else {
                    e.preventDefault()
                  }
                }}
              />
              <span style={{ marginRight: '5px' }}>of {pageCount}</span>
            </div>
            <button
              style={canNextPage ?
                { cursor: 'pointer', border: '1px solid #aaa', borderRadius: '2px', marginRight: '5px' }
                : { marginRight: '5px' }}
              onClick={() => {
                nextPage()
                handlePageSelect(pageSelect + 1)
              }}
              disabled={!canNextPage}>
              {">"}
            </button>
            <button
              style={canNextPage ?
                { cursor: 'pointer', border: '1px solid #aaa', borderRadius: '2px' } : {}}
              onClick={() => {
                gotoPage(pageCount - 1)
                handlePageSelect(pageCount - 1)
              }}
              disabled={!canNextPage}>
              {">>"}
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

// Define a custom filter filter function!
function filterGreaterThan(rows, id, filterValue) {
  return rows.filter((row) => {
    const rowValue = row.values[id];
    return rowValue >= filterValue;
  });
}

// This is an autoRemove method on the filter function that
// when given the new filter value and returns true, the filter
// will be automatically removed. Normally this is just an undefined
// check, but here, we want to remove the filter if it's not a number
filterGreaterThan.autoRemove = (val) => typeof val !== "number";

export default Table;
