import React from 'react'
import ReactTable from "components/ReactTable/ReactTable.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import Button from "components/CustomButtons/Button.js";
import ReactTooltip from "react-tooltip";
import PersonIcon from '@material-ui/icons/Person';
import PeopleIcon from '@material-ui/icons/People';

const ViewHBABForms = ({ HBABRoles, viewType }) => {
  const myTeamTable = HBABRoles.map((item, index) => {
    return {
      ...item,
      actions: (
        <React.Fragment>
          {item.lastRoleFormID >= 0 && (
            <>
              <Button
                justIcon
                color="primary"
                // data-tip
                // data-for={`role-item-${index}`}
                title='Role Forms'
                onClick={() => {
                  location.href = `/portal/MyProfile/HBAB/Role/${item.lastRoleFormID}?type=${viewType}`
                }}
              >
                <PersonIcon />
              </Button>
              {/* <ReactTooltip
                delayHide={50}
                delayShow={500}
                effect="solid"
                id={`role-item-${index}`}
              >
                Role Forms
              </ReactTooltip> */}
            </>
          )}

          {item.lastMinistryFormID >= 0 && (
            <>
              <Button
                justIcon
                color="primary"
                // data-tip
                // data-for={`ministry-item-${index}`}
                title='Ministry Forms'
                onClick={() => {
                  location.href = `/portal/MyProfile/HBAB/Ministry/${item.lastMinistryFormID}?type=${viewType}`
                }}
              >
                <PeopleIcon />
              </Button>
              {/* <ReactTooltip
                delayHide={50}
                delayShow={500}
                effect="solid"
                id={`ministry-item-${index}`}
              >
                Ministry Forms
              </ReactTooltip> */}
            </>
          )}

          {item.lastMinistryFormID == -1 && item.lastRoleFormID == -1 && (
            <p>Not Created Yet.</p>
          )}
        </React.Fragment>
      )
    }
  })
  return (
    <React.Fragment>
      <GridItem xs={12}>
        {myTeamTable.length > 0 ?
          <ReactTable
            columns={[
              {
                Header: "Name",
                accessor: "name",
                disableFilters: false,
                disableSortBy: false,
              },
              {
                Header: "Email",
                accessor: "email",
                disableFilters: false,
                disableSortBy: false,
              },
              {
                Header: "Department",
                accessor: "dept",
                disableFilters: false,
                disableSortBy: false,
              },
              {
                Header: "Job Title",
                accessor: "title",
                disableFilters: false,
                disableSortBy: false,
              },
              {
                Header: "Forms",
                accessor: "actions",
                disableFilters: true,
                disableSortBy: true,
              },
            ]}
            data={myTeamTable}
          />
          :
          <p>No Team Members Found.</p>
        }
      </GridItem>
    </React.Fragment>
  )
}

export default ViewHBABForms