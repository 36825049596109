import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import CustomInput from "components/CustomInput/CustomInput.js";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Button from "components/CustomButtons/Button";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import formStyles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import update from "immutability-helper";
import { HuePicker } from "react-color";
import {
  calendarListAtom,
  calendarSettingsFamily,
  saveCalendarSettingsSelector,
} from "state/settings";
import { userAtom } from "state/auth";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { useHistory } from "react-router-dom";
import Loading from "components/Loading/Loading";

const useStyles = makeStyles(styles);
const useFormStyles = makeStyles(formStyles);

const specialStyles = makeStyles({
  calendarGroup: {
    border: "1px solid #ddd",
    marginBottom: "15px",
    borderRadius: "3px",
    background: "#fafafa",
    padding: "0",
    paddingRight: "50px",
    paddingBottom: "30px",
  },
});

const CalendarSettingsFormComponent = ({ calendarIndex, removeTabHandler }) => {
  const classes = useStyles();
  const formClasses = useFormStyles();
  const specialClasses = specialStyles();
  const history = useHistory();
  const user = useRecoilValue(userAtom);
  const calendarList = useRecoilValue(calendarListAtom);

  const [calendarSettings, setCalendarSettings] = useRecoilState(
    calendarSettingsFamily(calendarIndex)
  );

  const saveCalendarSettings = useSetRecoilState(
    saveCalendarSettingsSelector(calendarIndex)
  );

  const addCalendarHandler = (e) => {
    setCalendarSettings((prevState) => {
      return update(prevState, {
        calendars: {
          $push: [
            {
              index: prevState.calendars.length,
              id: user.email,
              eventColor: "#ff0000",
            },
          ],
        },
      });
    });
  };

  const submitHandler = () => {
    if (calendarSettings.title && calendarSettings.title.length > 0) {
      saveCalendarSettings({
        calendarIndex: 0,
        newCalendars: calendarSettings,
      });
      history.push("/portal/dashboard");
    } else {
      alert("You must enter a name for the tab.");
    }
  };

  const titleChangeHandler = (e) => {
    setCalendarSettings((prevState) => {
      return update(prevState, {
        $merge: { title: e.target.value },
      });
    });
  };

  const removeCalendarGroupHandler = (index) => {
    if (calendarSettings.calendars.length > 1) {
      setCalendarSettings((prevState) => {
        return update(prevState, {
          calendars: { $splice: [[index, 1]] },
        });
      });
    } else {
      alert("You cannot remove all calendars from a tab.");
    }
  };

  const calendarSelectHandler = (e, index) => {
    setCalendarSettings((prevState) => {
      return update(prevState, {
        calendars: { [index]: { $merge: { id: e.target.value } } },
      });
    });
  };

  const eventColorChangeHandler = (color, index) => {
    setCalendarSettings((prevState) => {
      return update(prevState, {
        calendars: { [index]: { $merge: { eventColor: color.hex } } },
      });
    });
  };

  return (
    <React.Fragment>
      <form>
        <GridContainer>
          <GridItem xs={12} sm={1}>
            <FormLabel className={formClasses.labelHorizontal}>
              Tab Name
            </FormLabel>
          </GridItem>
          <GridItem xs={12} sm={11}>
            <CustomInput
              id="help-text"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                type: "text",
                value: calendarSettings.title,
                onChange: (e) => titleChangeHandler(e),
                maxLength: "25",
              }}
            />
          </GridItem>
        </GridContainer>
        {calendarSettings &&
          calendarSettings.calendars.map((calendar, index) => {
            return (
              <React.Fragment key={index}>
                <div className={specialClasses.calendarGroup}>
                  <GridContainer
                    style={{ justifyContent: "flex-end", marginLeft: "30px" }}
                  >
                    <GridItem>
                      <Button
                        size="sm"
                        color="danger"
                        onClick={() => removeCalendarGroupHandler(index)}
                      >
                        REMOVE
                      </Button>
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={1}>
                      <InputLabel className={formClasses.labelHorizontal}>
                        Calendar
                      </InputLabel>
                    </GridItem>
                    <GridItem xs={12} sm={11}>
                      <FormControl
                        fullWidth
                        className={formClasses.selectFormControl}
                      >
                        {calendarList && (
                          <Select
                            value={calendar.id}
                            onChange={(e) => {
                              calendarSelectHandler(e, index);
                            }}
                            MenuProps={{ className: formClasses.selectMenu }}
                            classes={{ select: formClasses.select }}
                            inputProps={{
                              name: "calendarSelect",
                            }}
                            style={{ marginTop: "15px" }}
                          >
                            <MenuItem
                              disabled
                              classes={{
                                root: formClasses.selectMenuItem,
                              }}
                              value="none"
                            >
                              Choose Calendar
                            </MenuItem>

                            {calendarList.map((item) => {
                              return (
                                <MenuItem
                                  key={item.id}
                                  classes={{
                                    root: formClasses.selectMenuItem,
                                    selected:
                                      formClasses.selectMenuItemSelected,
                                  }}
                                  value={item.id}
                                >
                                  {item.summary}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        )}
                      </FormControl>
                    </GridItem>
                  </GridContainer>

                  <GridContainer style={{ alignItems: "flex-end" }}>
                    <GridItem xs={12} sm={1}>
                      <FormLabel className={formClasses.labelHorizontal}>
                        Event Color
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={11} style={{ marginBottom: "4px" }}>
                      <div
                        style={{
                          display: "inline-block",
                          verticalAlign: "bottom",
                        }}
                      >
                        <HuePicker
                          color={calendar.eventColor}
                          onChange={(color) =>
                            eventColorChangeHandler(color, index)
                          }
                        />
                      </div>

                      <span
                        style={{
                          backgroundColor: calendar.eventColor,
                          display: "inline-block",
                          padding: "3px",
                          borderRadius: "3px",
                          fontSize: "smaller",
                          color: "#fff",
                          marginLeft: "10px",
                        }}
                      >
                        Example Event
                      </span>
                    </GridItem>
                  </GridContainer>
                </div>
              </React.Fragment>
            );
          })}
        <GridContainer>
          <GridItem xs={12}>
            <Button onClick={addCalendarHandler}>Add Another Calendar</Button>
          </GridItem>
        </GridContainer>
      </form>
      <GridContainer
        style={{
          marginTop: "25px",
          paddingTop: "15px",
          borderTop: "1px solid #eee",
        }}
      >
        <GridItem xs={12} style={{ textAlign: "left" }}>
          <Button color="info" onClick={submitHandler}>
            Save Settings
          </Button>
          <Button color="danger" onClick={removeTabHandler}>
            Remove Tab
          </Button>
        </GridItem>
      </GridContainer>
    </React.Fragment>
  );
};

const CalendarSettingsForm = (props) => {
  return (
    <React.Suspense fallback={<Loading color="blue" />}>
      <CalendarSettingsFormComponent {...props} />
    </React.Suspense>
  );
};

export default CalendarSettingsForm;
