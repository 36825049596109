import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem';
import Button from "components/CustomButtons/Button";
import { makeStyles } from "@material-ui/core/styles";
import dashboardStyles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import React, { useState } from 'react'
import Edit from "@material-ui/icons/Edit";
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Close from '@material-ui/icons/Close';
import Visibility from "@material-ui/icons/Visibility";

import ReactTable from "components/ReactTable/ReactTable.js";
import ReactTooltip from "react-tooltip";
import ReactDOM from "react-dom";
import PastoralCareForm from './PastoralCareForm';

const useStyles = makeStyles(() => ({
  ...styles,
  ...dashboardStyles,
  formSection: {
    marginBottom: "2rem",
  },
}));

/**
 * used to create a viewable list of our POC entries, adding functionality to view, edit, and delete
 * a POC entry.
 * @param {object} props
 * @prop {object<Array<POCEntrie>>} POCList contains of list of funeral and hospital visits
 * @prop {boolean} editable whether the items in the POCList need to be editable
 * @prop {function} deleteItem call deleteItem(<POCEntry id>, <POCEntry type>) to delete a POC Entry
 * @prop {function} updateItem called updateItem(<POCEntry type>,<POCEntry FormData>) to update an existing POC Entry
 * @returns
 */
const PastoralCareList = ({ POCList, deleteItem, updateItem }) => {
  const classes = useStyles();
  const [formType, setFormType] = useState('HospitalVisit')
  const [togglePopup, setTogglePopup] = useState(false)
  const [eventToEdit, setEventToEdit] = useState('')
  const [editable, setEditable] = useState(false)

  /**
   * called to close out edit popup and then call our function to update a POC Entry.
   * @param {string} type the POC Entry form type
   * @param {object} item form data of a POC Entry
   */
  const updateEntry = (type, item) => {
    setTogglePopup(false)
    setEventToEdit('')
    updateItem(type, item)
  }

  /**
   * Add the proper actions (view, edit, delete) to each funeral entry.
   */
  const funerals = POCList.funerals.map((item, index) => {
    var now = new Date()
    now.setHours(now.getHours() - 6)
    now = now.toISOString()
    now = now.substring(0, now.length - 8)
    var newItem = {
      ...item,
      actions: (
        <React.Fragment>
          {editable || now < item.stopShowing ? <>
            <Button
              justIcon
              color="primary"
              onClick={(e) => {
                setEventToEdit(item)
                setTogglePopup(true)
                setEditable(true)
              }}
              title='Edit'
            // data-tip
            // data-for={`edit-item-${index}`}
            >
              <Edit />
            </Button>
            {/* <ReactTooltip
              delayHide={50}
              delayShow={500}
              effect="solid"
              id={`edit-item-${index}`}
            >
              Edit
            </ReactTooltip> */}
          </> : <></>}

          <Button
            justIcon
            color="primary"
            onClick={(e) => {
              setEventToEdit(item)
              setTogglePopup(true)
              setEditable(false)
            }}
            title='View'
          // data-tip
          // data-for={`view-item-${index}`}
          >
            <Visibility />
          </Button>
          {/* <ReactTooltip
            delayHide={50}
            delayShow={500}
            effect="solid"
            id={`view-item-${index}`}
          >
            View
          </ReactTooltip> */}

          <Button
            justIcon
            color="danger"
            onClick={() => deleteItem(item.id, 'Funeral')}
            // data-tip
            // data-for={`delete-item-${index}`}
            title='Delete'
          >
            <DeleteForeverIcon />
          </Button>
          {/* <ReactTooltip
            delayHide={50}
            delayShow={500}
            effect="solid"
            id={`delete-item-${index}`}
          >
            delete
          </ReactTooltip> */}

        </React.Fragment>
      )
    }
    return newItem
  })

  /**
   * Add the proper actions (view, edit, delete) to each hospital visit entry.
   */
  const hospitalVisits = POCList.hospitalVisits.map((item, index) => {
    var now = new Date()
    now.setHours(now.getHours() - 6)
    now = now.toISOString()
    now = now.substring(0, now.length - 8)
    var newItem = {
      ...item,
      actions: (
        <React.Fragment>
          {editable || now < item.stopShowing ? <>
            <Button
              justIcon
              color="primary"
              onClick={(e) => {
                setEventToEdit(item)
                setTogglePopup(true)
                setEditable(true)
              }}
              title='Edit'
            // data-tip
            // data-for={`edit-item-${index}`}
            >
              <Edit />
            </Button>
            {/* <ReactTooltip
              delayHide={50}
              delayShow={500}
              effect="solid"
              id={`edit-item-${index}`}
            >
              Edit
            </ReactTooltip> */}
          </> : <></>}
          <Button
            justIcon
            color="primary"
            onClick={(e) => {
              setEventToEdit(item)
              setTogglePopup(true)
              setEditable(false)
            }}
            title='View'
          // data-tip
          // data-for={`view-item-${index}`}
          >
            <Visibility />
          </Button>
          {/* <ReactTooltip
            delayHide={50}
            delayShow={500}
            effect="solid"
            id={`view-item-${index}`}
          >
            View
          </ReactTooltip> */}

          <Button
            justIcon
            color="danger"
            onClick={() => deleteItem(item.id, 'HospitalVisit')}
            title='Delete'
          // data-tip
          // data-for={`delete-item-${index}`}
          >
            <DeleteForeverIcon />
          </Button>
          {/* <ReactTooltip
            delayHide={50}
            delayShow={500}
            effect="solid"
            id={`delete-item-${index}`}
          >
            delete
          </ReactTooltip> */}

        </React.Fragment>
      )
    }
    return newItem
  })
  return (
    <React.Fragment>
      <GridContainer>
        <GridItem xs={12}>
          {togglePopup ?
            <EditPOCEntryPopup entry={eventToEdit} toggle={setTogglePopup} submit={updateEntry} formType={formType} viewOnly={!editable} />
            : <></>}
          <div style={{ display: 'flex' }}>
            <Button
              color={formType === 'HospitalVisit' ? 'facebook' : 'info'}
              onClick={(e) => setFormType('HospitalVisit')}
              tabIndex="-100"
            >
              Hospital Visit
            </Button>
            <Button
              color={formType === 'Funeral' ? 'facebook' : 'info'}
              onClick={(e) => setFormType('Funeral')}
              tabIndex="-100"
            >
              Funeral
            </Button>
          </div>
        </GridItem>
        <GridItem xs={12}>
          <React.Fragment>
            <ReactTable
              columns={[
                {
                  Header: "When",
                  accessor: "startTime",
                  disableFilters: false,
                  disableSortBy: false,
                },
                {
                  Header: "Name",
                  accessor: "name",
                  disableFilters: false,
                  disableSortBy: false,
                },
                {
                  Header: "Location",
                  accessor: "location",
                  disableFilters: false,
                  disableSortBy: false,
                },
                {
                  Header: "Pastor",
                  accessor: "pastor",
                  disableFilters: false,
                  disableSortBy: false,
                },
                {
                  Header: "Actions",
                  accessor: "actions",
                  disableFilters: false,
                  disableSortBy: false,
                },
              ]}
              data={formType === 'HospitalVisit' ? hospitalVisits : funerals}
            />
          </React.Fragment>
        </GridItem>
      </GridContainer>
    </React.Fragment>
  )
}

export default PastoralCareList

/**
 * create the popup for editing/viewing a POC Entry
 * @param {object} props
 * @prop {object} entry the POC entry we are editing
 * @prop {function} toggle function used to set whether or not the pop up is visible
 * @prop {function} submit function that will be called when submitting changes to a POC entry
 * @prop {string} formType the POC entry type
 * @prop {boolean} viewOnly whether or not the popup is for viewing or editing.
 * @returns
 */
const EditPOCEntryPopup = ({ entry, toggle, submit, formType, viewOnly }) => {
  const newEntry = {
    ...entry,
    formType: formType
  }
  const styles = {
    wrap: {
      background: "#000",
      position: "absolute",
      top: "0",
      left: "0",
      right: "0",
      bottom: "0",
      zIndex: 99998,
      opacity: ".7",
    },
    modal: {
      position: "absolute",
      top: "2vh",
      left: "calc(50% - 400px)",
      width: "800px",
      margin: "0 auto",
      boxShadow: "0px 0px 20px 5px #333",
      padding: "0px 30px",
      paddingTop: "20px",
      background: "#fff",
      zIndex: 99999,
      borderRadius: "10px",
      maxHeight: "96vh",
      overflowY: "auto",
    },
  };

  return (
    <React.Fragment>
      {ReactDOM.createPortal(
        <div style={styles.wrap} onClick={(e) => toggle(false)}></div>,
        document.getElementById("file-list-modal-overlay")
      )}
      {ReactDOM.createPortal(
        <GridContainer style={styles.modal}>
          <GridContainer style={{ alignItems: "center" }}>
            <GridItem
              xs={12}
              style={{
                textAlign: "right",
              }}
            >
              <Button
                justIcon
                round
                color="danger"
                size="sm"
                onClick={(e) => toggle(false)}
              >
                <Close />
              </Button>
            </GridItem>
          </GridContainer>
          <GridContainer
            style={{
              marginTop: "25px",
              marginBottom: "25px",
              alignItems: "center",
            }}
          >
            <PastoralCareForm defaults={newEntry} submitForm={submit} viewOnly={viewOnly} />
          </GridContainer>
        </GridContainer>,
        document.getElementById("file-list-modal")
      )}
    </React.Fragment>
  )
}