import React, { useState, useEffect } from "react";
import {
  Document,
  Page,
  View,
  Text,
  StyleSheet,
  Font,
  Image,
} from "@react-pdf/renderer";
import Loading from "components/Loading/Loading";
import SBLogo from "assets/img/sb-logo-no-text.png";
import api from "api.config";

Font.register({
  family: "Signature",
  src: "/fonts/signature.ttf",
  fontStyle: "normal",
  fontWeight: "normal",
});

const styles = StyleSheet.create({
  page: {
    fontSize: 10,
    padding: 15,
  },
  defaultFlex: {
    display: "flex",
    flexDirection: "row",
  },
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  box: {
    border: "1px solid #000",
    borderTop: "none",
    padding: 5,
  },
  lineItemsWrap: {
    display: "flex",
    flexDirection: "row",
    fontSize: 10,
  },
  lineItemsHeadings: {
    fontWeight: "bold",
    display: "flex",
    flexDirection: "row",
    borderBottom: "1px solid #000",
    fontSize: 12,
  },
  lineItemSingle: {
    padding: 5,
    borderBottom: "1px solid #000",
  },
  signature: {
    fontFamily: "Signature",
    fontSize: 30,
  },
  boxHeader: {
    backgroundColor: "#2C82C5",
    color: "#fff",
    padding: 5,
    fontSize: 12,
    textAlign: "center",
  },
  center: {
    textAlign: "center",
  },
  smallPadding: {
    padding: 5,
  },
  section: {
    marginTop: 10,
    marginBottom: 10,
  },
  mediumColumn: {
    flexBasis: "25%",
    borderLeft: "1px solid #000",
  },
  largeColumn: {
    flexBasis: "60%",
    borderLeft: "1px solid #000",
  },
  smallColumn: {
    flexBasis: "15%",
    borderRight: "1px solid #000",
    borderLeft: "1px solid #000",
  },
  fifth: {
    flexBasis: "20%",
    borderBottom: "1px solid #000",
    borderLeft: "1px solid #000",
    borderTop: "1px solid #000",
  },
  extraLargeColumn: {
    flexBasis: "85%",
    borderLeft: "1px solid #000",
  },
});

// Helper to trigger an update in a functional component
function useForceUpdate() {
  const [value, setValue] = useState(0);
  return () => setValue((value) => value + 1);
}

const CurrentPDFComponent = (props) => {
  let fontsLoaded = false;
  let forceUpdate = null;

  const [fontLoaded, setFontLoaded] = useState(false);

  useEffect(() => {
    // Force loading and wait for loading to finish
    (() => {
      Promise.all([Font.load({ fontFamily: "Signature" })]).then(() => {
        setFontLoaded(true);
      });
    })();
  }, []);

  const currentId = props.id ? props.id : +props.match.params.id;
  const currentPo = props.currentPo;

  const files = JSON.parse(currentPo.attachedFiles);

  const lineItems =
    currentPo && currentPo.lineItems ? JSON.parse(currentPo.lineItems) : [];

  const calculateDescriptionSplits = (desc) => {
    let lines = 0
    let splits = desc.split('\n')
    splits.forEach(newLine => {
      let lineSplits = newLine.match(/.{1,72}/g)
      if (!lineSplits) {
        lines += 1
      } else {
        lines += lineSplits.length
      }
    });
    return lines
  }
  const calculateLineitemSpace = () => {
    let lineItemsSize = 0
    const descriptions = JSON.parse(currentPo.lineItems).map(item => { return item.description })
    lineItemsSize += descriptions.length * 32 //25
    let lines = 0
    descriptions.forEach(desc => { lines += calculateDescriptionSplits(desc) })
    lines -= descriptions.length
    lineItemsSize += lines * 15.5 //15
    return lineItemsSize
  }

  const lineItemSize = calculateLineitemSpace()
  const emptyLineItem = 32
  const maxSize = 380

  //console.log("line items", lineItems);

  if (lineItemSize < maxSize) {
    let i = 0;
    let numberOfLines = Math.floor((maxSize - lineItemSize) / emptyLineItem)
    while (i < numberOfLines) {
      lineItems.push({ acctCode: "", description: "", amount: "" });
      i++;
    }
  }

  lineItems.push({
    acctCode: "",
    description: "Total",
    amount: currentPo.total,
  });

  if (!fontLoaded && !props.bulkDownload) {
    return <div></div>;
  }

  return (
    <React.Fragment>
      <Document>
        <Page style={styles.page}>
          <View style={[styles.header, styles.section, { marginTop: 0 }]}>
            <View
              style={[
                {
                  textTransform: "uppercase",
                  fontSize: 14,
                },
              ]}
            >
              <View
                style={[
                  {
                    fontSize: 20,
                    paddingBottom: 1,
                    marginBottom: 5,
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "row",
                  },
                ]}
              >
                <Image
                  src={SBLogo}
                  style={[{ width: "32px", height: "32px", marginRight: 10 }]}
                />
                <Text style={[{ borderBottom: "1px solid #000" }]}>
                  Sagebrush.church
                </Text>
              </View>
              <Text>6440 Coors Blvd NW</Text>
              <Text>Albuquerque, NM 87120</Text>
            </View>

            <View>
              <Text
                style={[
                  styles.boxHeader,
                  {
                    fontSize: 20,
                    padding: 10,
                    borderTop: "1px solid #000",
                    borderLeft: "1px solid #000",
                    borderRight: "1px solid #000",
                  },
                ]}
              >
                Purchase Order
              </Text>
              <Text
                style={[{ fontSize: 16 }, styles.box, { textAlign: "center" }]}
              >
                {currentId}
              </Text>
            </View>
          </View>

          <View style={[styles.defaultFlex, styles.section]}>
            <View style={{ flexBasis: "50%", flexGrow: 0, paddingRight: 25 }}>
              <Text
                style={[
                  styles.boxHeader,
                  {
                    borderTop: "1px solid #000",
                    borderRight: "1px solid #000",
                    borderLeft: "1px solid #000",
                  },
                ]}
              >
                Vendor
              </Text>
              <Text style={[styles.box]}>{currentPo.vendor}</Text>
            </View>

            <View style={{ flexBasis: "50%", flexGrow: 0, paddingLeft: 25 }}>
              <Text
                style={[
                  styles.boxHeader,
                  {
                    borderTop: "1px solid #000",
                    borderRight: "1px solid #000",
                    borderLeft: "1px solid #000",
                  },
                ]}
              >
                Payee
              </Text>
              <Text style={[styles.box,]}>{currentPo.address || "N/A"}</Text>
            </View>
          </View>

          <View style={[styles.section]}>
            <Text
              style={[
                styles.boxHeader,
                {
                  borderTop: "1px solid #000",
                  borderRight: "1px solid #000",
                  borderLeft: "1px solid #000",
                },
              ]}
            >
              Purchase Reason
            </Text>
            <Text style={[styles.box]}>{currentPo.purchaseReason}</Text>
          </View>

          <View style={[styles.defaultFlex, styles.section]}>
            <View style={[styles.fifth]}>
              <Text style={[styles.boxHeader, { borderLeft: "none" }]}>
                Date Ordered
              </Text>
              <Text style={[styles.smallPadding]}>
                {currentPo.dateOrdered || "N/A"}
              </Text>
            </View>

            <View style={[styles.fifth]}>
              <Text style={[styles.boxHeader, { borderLeft: "none" }]}>
                Payment By
              </Text>
              <Text style={[styles.smallPadding]}>
                {currentPo.paymentBy === "creditCard" ? "Credit Card" : "Check"}
              </Text>
            </View>

            <View style={[styles.fifth]}>
              <Text style={[styles.boxHeader, { borderLeft: "none" }]}>
                Recurring
              </Text>
              <Text
                style={[styles.smallPadding, { textTransform: "capitalize" }]}
              >
                {currentPo.recurring}
              </Text>
            </View>

            <View style={[styles.fifth]}>
              <Text style={[styles.boxHeader, { borderLeft: "none" }]}>
                Type
              </Text>
              <Text
                style={[styles.smallPadding, { textTransform: "capitalize" }]}
              >
                {currentPo.paymentType}
              </Text>
            </View>

            <View style={[styles.fifth, { borderRight: "1px solid #000" }]}>
              <Text style={[styles.boxHeader, { borderLeft: "none" }]}>
                Check To Be
              </Text>
              <Text style={[styles.smallPadding]}>
                {currentPo.checkToBe !== null
                  ? currentPo.checkToBe === "mailed"
                    ? "Mailed"
                    : "Given To Submitter"
                  : "N/A"}
              </Text>
            </View>
          </View>

          <View
            style={[
              styles.lineItemsHeadings,
              styles.section,
              {
                marginBottom: "0",
                borderTop: "1px solid #000",
              },
            ]}
          >
            <Text style={[styles.boxHeader, styles.mediumColumn]}>
              Account Code
            </Text>
            <Text
              style={[
                styles.boxHeader,
                styles.largeColumn,
                { textAlign: "center" },
              ]}
            >
              Description
            </Text>
            <Text style={[styles.boxHeader, styles.smallColumn]}>Amount</Text>
          </View>

          {lineItems.map((item, index) => {
            //console.log("lineItems.length, index", lineItems.length, index);
            if (index < lineItems.length - 1) {
              return (
                <View style={[styles.lineItemsWrap]} key={index}>
                  <Text style={[styles.mediumColumn, styles.lineItemSingle]}>
                    {item?.fundCode?.code && `${item.fundCode.code}-`}
                    {item?.glCode?.code && `${item.glCode.code}-`}
                    {item?.deptCode?.code && `${item.deptCode.code}`}
                    {item?.eventCode?.code && `-${item.eventCode.code}`}
                  </Text>
                  <Text style={[styles.largeColumn, styles.lineItemSingle]}>
                    {item.description}
                  </Text>
                  <Text style={[styles.smallColumn, styles.lineItemSingle]}>
                    {item.amount && (
                      <React.Fragment>
                        ${parseFloat(item.amount).toFixed(2)}
                      </React.Fragment>
                    )}

                    {!item.amount && <React.Fragment>$</React.Fragment>}
                  </Text>
                </View>
              );
            } else {
              return (
                <View
                  style={[
                    styles.lineItemsWrap,
                    styles.section,
                    { marginTop: 0 },
                  ]}
                  key={index}
                >
                  <Text
                    style={[
                      styles.mediumColumn,
                      styles.lineItemSingle,
                      {
                        border: "none",
                      },
                    ]}
                  >
                    {item.accountCode}
                  </Text>
                  <Text
                    style={[
                      styles.largeColumn,
                      styles.lineItemSingle,
                      {
                        textAlign: "right",
                        fontWeight: "bold",
                        border: "none",
                      },
                    ]}
                  >
                    {item.description}
                  </Text>
                  <Text
                    style={[
                      styles.smallColumn,
                      styles.lineItemSingle,
                      styles.boxHeader,
                      { borderTop: "none" },
                    ]}
                  >
                    {typeof item.amount === "string"
                      ? item.amount
                      : `$${parseFloat(item.amount).toFixed(2)}`}
                  </Text>
                </View>
              );
            }
          })}

          <View
            style={[
              styles.defaultFlex,
              styles.section,
              {
                borderBottom: "1px solid #000",
                borderTop: "1px solid #000",
              },
            ]}
          >
            {/* <View style={[styles.mediumColumn]}>
              <Text style={[styles.boxHeader, { borderLeft: "none" }]}>
                Department
              </Text>
              <Text style={[styles.smallPadding]}>{currentPo.dept}</Text>
            </View> */}

            <View style={[styles.extraLargeColumn]}>
              <Text style={[styles.boxHeader, { borderLeft: "none" }]}>
                Submitted By
              </Text>
              <Text style={[styles.smallPadding]}>
                {currentPo.submittedByName}
              </Text>
            </View>

            <View style={[styles.smallColumn]}>
              <Text style={[styles.boxHeader, { borderLeft: "none" }]}>
                Date
              </Text>
              <Text style={[styles.smallPadding]}>
                {currentPo.submittedDate}
              </Text>
            </View>
          </View>

          {(currentPo.status === "approved" || currentPo.archived) && (
            <View style={[styles.defaultFlex, styles.section]}>
              <View style={[styles.defaultFlex, { marginRight: 15 }]}>
                <View style={[{ flexBasis: "38%" }]}>
                  <Text
                    style={[
                      styles.boxHeader,
                      { borderTop: "1px solid #2C82C5" },
                    ]}
                  >
                    First Approver
                  </Text>
                  <Text
                    style={[
                      styles.signature,
                      {
                        textAlign: "center",
                        fontSize: '22px'
                      },
                    ]}
                  >
                    {currentPo.signedBy}
                  </Text>
                </View>

                <View style={[{ flexBasis: "12%" }]}>
                  <Text
                    style={[
                      styles.boxHeader,
                      {
                        borderRight: "1px solid #000",
                        borderLeft: "1px solid #000",
                        borderTop: "1px solid #000",
                      },
                    ]}
                  >
                    Date
                  </Text>
                  <Text style={[styles.box]}>{currentPo.approvedDate}</Text>
                </View>
              </View>

              <View style={[styles.defaultFlex, { marginLeft: 15 }]}>
                <View style={[{ flexBasis: "38%" }]}>
                  <Text
                    style={[
                      styles.boxHeader,
                      { borderTop: "1px solid #2C82C5" },
                    ]}
                  >
                    Second Approver
                  </Text>
                  <Text
                    style={
                      currentPo.signedBy2
                        ? [
                          styles.signature,
                          //styles.smallPadding,
                          {
                            textAlign: "center",
                            fontSize: '22px'
                          },
                        ]
                        : [styles.smallPadding]
                    }
                  >
                    {currentPo.signedBy2 || "N/A"}
                  </Text>
                </View>

                <View style={[{ flexBasis: "12%" }]}>
                  <Text
                    style={[
                      styles.boxHeader,
                      {
                        borderRight: "1px solid #000",
                        borderLeft: "1px solid #000",
                        borderTop: "1px solid #000",
                      },
                    ]}
                  >
                    Date
                  </Text>
                  <Text style={[styles.box]}>
                    {currentPo.approvedDate2 || "N/A"}
                  </Text>
                </View>
              </View>
            </View>
          )}
        </Page>
        {files !== null &&
          Array.isArray(files) &&
          files.map((item, index) => {
            return (
              <Page key={index}>
                <View
                  style={{
                    width: "100%",
                    height: "100%",
                    margin: "0 auto",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Image
                    src={`${api.path}/digitalpo_api/${item}`}
                    style={[
                      {
                        width: "80%",
                        height: "auto",
                      },
                    ]}
                  />
                </View>
              </Page>
            );
          })}
      </Document>
    </React.Fragment>
  );
};

const CurrentPDF = (props) => {
  return (
    <React.Suspense fallback={<Loading color="blue" />}>
      <CurrentPDFComponent {...props} />
    </React.Suspense>
  );
};

export default CurrentPDF;
