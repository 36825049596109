import React, { useState } from "react";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import ReactTable from "components/ReactTable/ReactTable.js";
import Loading from "components/Loading/Loading";
import Checkbox from '@material-ui/core/Checkbox';
import update from "immutability-helper";

import {
  PeopleOutline,
  FiberNew,
  Flag,
  MergeType,
  DoubleArrow,
  AssignmentInd,
  AssignmentLate,
  Visibility,
  ChatBubble,
  Sync,
  LocalOffer,
  Category
} from "@material-ui/icons";
import { encryptData } from "assets/jss/EncriptDecrypt/EncryptDecrypt";
import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import TicketChangePopup from "components/TicketSystem/TicketChangePopup";

const ListTicketsComp = (props) => {
  const { list, buttons, redirect, cols, updateList, searchTicket, departments, deptOptions, viewType, updateNumDays, numDays, ticketStates, CustomButton } = props
  const [selected, setSelected] = useState([])

  const redirectSearch = encryptData({ redirect: redirect })

  const [search, setSearch] = useState('')

  const [loading, setLoading] = useState(false)

  const [optionType, setOptionType] = useState('')

  const numDaysOptions = [
    {
      label: 'Week',
      value: 7
    },
    {
      label: 'Month',
      value: 31
    },
    {
      label: '3 Months',
      value: 92
    },
    {
      label: '6 Months',
      value: 183
    },
    {
      label: '1 Year',
      value: 365
    },
    {
      label: 'All',
      value: 0
    },
  ]

  const selectedNumDays = numDaysOptions.find(i => i.value === parseInt(numDays))

  const handleNumDays = (days) => {
    setLoading(true)
    setSelected([])
    updateNumDays(days)
    setLoading(false)
  }

  const addSelected = (item) => {
    setSelected(prevState => {
      return update(prevState, { $push: [item.id] })
    })
  }

  const removeSelected = (item) => {
    let i = selected.indexOf(item.id)
    setSelected(prevState => {
      return update(prevState, { $splice: [[i, 1]] })
    })
  }

  const updateTicketList = async () => {
    if (buttons.numDays) {
      await updateList(numDays)
    } else (
      await updateList()
    )
    return
  }

  const handlePopupSubmissionUpdate = async () => {
    if (optionType === 'Status') {
      location.reload()
    } else {
      await updateTicketList()
      setSelected([])
      setLoading(false)
    }
  }

  const priorityColors = {
    high: 'rgb(253 154 154)',
    medium: 'rgb(255 195 140)',
    stale: 'rgb(203 203 203)',
    normal: 'rgb(120 185 100)',
  }

  const formattedList = list.map((item) => {
    let entry = {
      ...item,
      notifications: (
        <div style={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
          {(item.newMessage) && (<FiberNew style={{ color: '#3695d7' }} />)}
          {(item.tags.length > 0 && buttons.tags) && (
            <div title={item.tags.join('\n')} style={{ cursor: 'pointer', color: '#3695d7', display: 'flex', alignItems: 'center' }}>
              <LocalOffer style={{ fontSize: '16px' }} />
            </div>
          )}
          {item.numMessages}
          <ChatBubble style={{ color: '#cccccc', fontSize: '16px', marginLeft: '2px' }} />
        </div>
      ),
      assignedToNamesComp: (
        <>
          {buttons.assignTo && (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', cursor: 'pointer' }} title={item.assignedToNames.map(i => i.name).join('\n')}>
              <span >{item.assignedToNames[0].name}</span>
              {(item.assignedToNames.length > 1) && (<PeopleOutline style={{ marginLeft: '5px' }} />)}
            </div>)
          }
        </>
      ),
      subjectLink: (
        <a
          href={`/portal/ticketSystem/view/${viewType}/${item.id}?${redirectSearch}`}
          style={{ color: '#3695d7' }}
        >
          <span>{item.subject.length > 100 ? `${item.subject.substring(0, 97)}...` : item.subject}</span>
        </a>
      ),
      priorityIcon: (
        <div style={{ display: 'flex', justifyContent: 'center', height: '100%', padding: '2px 0px' }}>
          <div style={{ backgroundColor: item.priority ? priorityColors[item.priority] : priorityColors.normal, width: '8px', height: '100%' }} />
        </div>
      ),
      checkBox: (
        <React.Fragment>
          {(buttons.checkBox) && (
            <Checkbox
              style={{ padding: '5px' }}
              onChange={(e) => {
                if (e.target.checked) {
                  addSelected(item)
                } else {
                  removeSelected(item)
                }
              }}
              color="primary"
              checked={selected.find(i => i === item.id) ? true : false}
            />
          )
          }
        </React.Fragment >
      ),
      view: (
        <React.Fragment>
          {(buttons.view) && (
            <Button
              justIcon
              color="primary"
              title='View'
              onClick={(e) => {
                e.preventDefault()
                location.href = `/portal/ticketSystem/view/${viewType}/${item.id}?${redirectSearch}`
              }
              }
            >
              <Visibility />
            </Button>
          )
          }
        </React.Fragment >
      )
    }

    return entry
  })

  const HeaderCheckbox = () => {
    return (
      <Checkbox
        style={{ padding: '0px 5px' }}
        onChange={(e) => {
          if (e.target.checked) {
            setSelected(searchedList.map(i => i.id))
          } else {
            setSelected([])
          }
        }}
        indeterminate={(selected.length > 0 && selected.length < searchedList.length)}
        color="primary"
        checked={selected.length >= searchedList.length && selected.length > 0}
      />
    )
  }

  const searchedList = searchTicket(formattedList, search)
  return (
    <GridContainer>
      {(loading) ? <GridItem xs={12}><Loading color="blue" /></GridItem> :
        <GridItem xs={12}>
          <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', alignItems: 'center' }}>
            <div>
              {(buttons.refresh) && (
                <Button
                  justIcon
                  // color='primary'
                  title='Refresh'
                  style={{ marginRight: '3px', backgroundColor: 'white', outline: '1px solid #eee' }}
                  onClick={async (e) => {
                    e.preventDefault()
                    setLoading(true)
                    await updateTicketList()
                    setLoading(false)
                  }}
                >
                  <Sync
                    style={{ height: '25px', width: '25px', color: '#3695d7' }}
                  // style={{ fontSize: '35px' }}
                  />
                </Button>
              )}
              {(buttons.customButton) && (<>{CustomButton}</>)}
            </div>

            <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'end', alignItems: 'center' }}>
              {(buttons.checkBox) && (
                <>
                  <TicketChangePopup
                    type={optionType}
                    setType={setOptionType}
                    tickets={list.filter(i => selected.indexOf(i.id) !== -1)}
                    updateData={handlePopupSubmissionUpdate}
                    setLoading={setLoading}
                    selectableDepartments={departments}
                    departmentSelectableOptions={deptOptions}
                    ticketStates={ticketStates}
                  />
                  <span style={{ margin: '3px' }}>{selected.length} Selected</span>
                  {(buttons.status) && (
                    <Button
                      justIcon
                      color='primary'
                      title='Status'
                      style={{ margin: '3px' }}
                      disabled={selected.length == 0}
                      onClick={(e) => {
                        e.preventDefault()
                        setOptionType('Status')
                      }}
                    >
                      <Flag style={{ height: '25px', width: '25px' }} />
                    </Button>)}
                  {/* {(buttons.tags) && (
                    <Button
                      justIcon
                      color='primary'
                      title='Tags'
                      style={{ margin: '3px' }}
                      disabled={selected.length == 0}
                      onClick={(e) => {
                        e.preventDefault()
                        setOptionType('Tags')
                      }}
                    >
                      <LocalOffer style={{ height: '25px', width: '25px' }} />
                    </Button>)} */}
                  {(buttons.state) && (
                    <Button
                      justIcon
                      color='primary'
                      title='State'
                      style={{ margin: '3px' }}
                      disabled={selected.length == 0}
                      onClick={(e) => {
                        e.preventDefault()
                        setOptionType('State')
                      }}
                    >
                      <Category style={{ height: '25px', width: '25px' }} />
                    </Button>)}
                  {(buttons.assign) && (
                    <Button
                      justIcon
                      color='primary'
                      title='Assign'
                      style={{ margin: '3px' }}
                      disabled={selected.length == 0}
                      onClick={(e) => {
                        e.preventDefault()
                        setOptionType('Assign')
                      }}
                    >
                      <AssignmentInd style={{ height: '25px', width: '25px' }} />
                    </Button>)}
                  {(buttons.priority) && (
                    <Button
                      justIcon
                      color='primary'
                      title='Priority'
                      style={{ margin: '3px' }}
                      disabled={selected.length == 0}
                      onClick={(e) => {
                        e.preventDefault()
                        setOptionType('Priority')
                      }}
                    >
                      <AssignmentLate style={{ height: '25px', width: '25px' }} />
                    </Button>)}
                  {(buttons.merge) && (
                    <Button
                      justIcon
                      color='primary'
                      title='Merge'
                      style={{ margin: '3px' }}
                      disabled={selected.length < 2}
                      onClick={(e) => {
                        e.preventDefault()
                        setOptionType('Merge')
                      }}
                    >
                      <MergeType style={{ height: '25px', width: '25px' }} />
                    </Button>)}
                  {(buttons.transfer) && (
                    <Button
                      justIcon
                      color='primary'
                      title='Transfer'
                      style={{ margin: '3px' }}
                      disabled={selected.length == 0}
                      onClick={(e) => {
                        e.preventDefault()
                        setOptionType('Transfer')
                      }}
                    >
                      <DoubleArrow style={{ height: '25px', width: '25px' }} />
                    </Button>)}
                </>
              )}
              {(buttons.numDays) && (
                <div style={{ width: '150px', margin: '3px' }}>
                  <Autocomplete
                    fullWidth
                    disablePortal
                    options={numDaysOptions}
                    getOptionLabel={(option) =>
                      `${option.label}` || ""
                    }
                    renderInput={(params) => (
                      <TextField {...params} variant="outlined" label='Closed in the last' />
                    )}
                    onChange={(e, v) => {
                      handleNumDays(v.value)
                    }}
                    autoSelect={true}
                    autoComplete={true}
                    autoHighlight={true}
                    disableClearable={true}
                    value={selectedNumDays}
                  />
                </div>
              )}
              <TextField
                variant="outlined"
                placeholder='Search...'
                style={{ margin: '3px', width: '275px' }}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
          </div>
          {formattedList.length > 0 && (
            <ReactTable
              columns={cols}
              data={searchedList}
              minWidthTable={'600px'}
              defaultPageSize={50}
              checkBox={HeaderCheckbox}
            />
          )}
          {formattedList.length === 0 && (
            <p style={{ textAlign: "center" }}>No Tickets.</p>
          )}
        </GridItem>
      }
    </GridContainer>
  )
}

const ListTickets = (props) => {
  return (
    <React.Suspense fallback={<Loading color="blue" />}>
      <ListTicketsComp {...props} />
    </React.Suspense>
  )
}

export default ListTickets