import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";
import axios from "axios";
import {
  groupsAtom,
  loggedInAtom,
  idTokenAtom,
  userAtom,
  jwtAtom,
} from "../../state/auth";
import { publishedPagesAtom } from "../../state/trainingModule";
import { useRecoilState } from "recoil";
import Loading from "components/Loading/Loading";
import useReauth from "../../context/Reauth";
import api from "../../api.config";
import { encryptData } from "assets/jss/EncriptDecrypt/EncryptDecrypt";

import Cookies from "js-cookie";

import { GoogleLogin, useGoogleOneTapLogin } from '@react-oauth/google';
import { sortPages } from "../../state/trainingModule";
import { Button } from "@material-ui/core";

const useStyles = makeStyles(styles);

export default function LoginPage() {
  const [isLoggedIn, setIsLoggedIn] = useRecoilState(loggedInAtom);
  const [idToken, setIdToken] = useRecoilState(idTokenAtom);
  const [user, setUser] = useRecoilState(userAtom);
  const [groups, setGroups] = useRecoilState(groupsAtom);
  const [jwt, setJwt] = useRecoilState(jwtAtom);
  const [attemptingLogin, setAttemptingLogin] = useState(false);
  const refreshToken = useReauth();
  const [publishedPages, setPublishedPages] = useRecoilState(publishedPagesAtom)

  const classes = useStyles();

  const checkForRedirect = () => {
    let redirectUrl = document.referrer;

    if ((redirectUrl.indexOf("sagebrush") !== -1 || redirectUrl.indexOf("localhost") !== -1)
      && !redirectUrl.includes('dashboard')) {
      return redirectUrl;
    }

    return false;
  };

  const checkIfLoggedIn = async () => {
    await axios.post(`${api.path}/staff_portal_api/v1/getUserData`)
      .then(async (res) => {
        if (res.data) {
          res.data.jwt =
            setUser(res.data.profile);
          setJwt(Cookies.get(`StaffPortalJWT`));
          setGroups(res.data.groups);

          // Set session storage
          sessionStorage.setItem("userData", encryptData(res.data.profile));
          sessionStorage.setItem("loggedIn", true);
          sessionStorage.setItem("groups", encryptData(res.data.groups));
          let sorted = await res.data.publishedPages.sort(sortPages)
          setPublishedPages(sorted)
          sessionStorage.setItem('publishedPagesDB', encryptData(sorted))
          return
        }
      });
    // await axios
    //   .get(`${api.path}/staff_portal_api/v1/getPublishedPages`)
    //   .then(async (res) => {
    //     let sorted = await res.data.sort(sortPages)
    //     setPublishedPages(sorted)
    //     sessionStorage.setItem('publishedPagesDB', encryptData(sorted))
    //   });

    if (jwt) {
      setIsLoggedIn(true);
    }


    let redirect = checkForRedirect();
    if (redirect) {
      window.location = redirect;
    }
  }

  useEffect(() => {
    if (Cookies.get(`StaffPortalJWT`) && !sessionStorage.getItem('loggedIn')) {
      checkIfLoggedIn()
    }
  }, []);

  const loginHandler = async (jwt) => {

    // Set the tokenId here
    setIdToken(jwt.tokenId);
    setAttemptingLogin(true);

    await axios
      .post(`${api.path}/staff_portal_api/v1/gauth`, {
        idtoken: jwt.credential,
      })
      .then(async (res) => {
        setUser(res.data.profile);
        setJwt(res.data.jwt);
        setGroups(res.data.groups);

        Cookies.set("StaffPortalJWT", res.data.jwt)
        sessionStorage.setItem("StaffPortalJWT", res.data.jwt)

        // Set session storage
        sessionStorage.setItem("userData", encryptData(res.data.profile));
        sessionStorage.setItem("loggedIn", true);
        sessionStorage.setItem("groups", encryptData(res.data.groups));
        let sorted = await res.data.publishedPages.sort(sortPages)
        setPublishedPages(sorted)
        sessionStorage.setItem('publishedPagesDB', encryptData(sorted))

        return
      });

    // await axios
    //   .get(`${api.path}/staff_portal_api/v1/getPublishedPages`)
    //   .then(async (res) => {
    //     let sorted = await res.data.sort(sortPages)
    //     setPublishedPages(sorted)
    //     sessionStorage.setItem('publishedPagesDB', encryptData(sorted))
    //   });

    if (jwt) {
      setIsLoggedIn(true);
    }

    let redirect = checkForRedirect();
    if (redirect) {
      window.location = redirect;
    }
  };
  return (
    <div className={classes.container}>
      <GridContainer justifyContent='center'>
        <GridItem xs={12} sm={6} md={4}>
          {attemptingLogin && <Loading color="blue-gray" />}

          {!attemptingLogin && (
            <form>
              <Card login>
                <CardBody style={{ textAlign: "center" }}>
                  <h2>Staff Portal</h2>
                  <p style={{ marginBottom: "1rem" }}>
                    Please login with your Sagebrush Google account below.
                  </p>
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <GoogleLogin
                      shape='rectangular'
                      useOneTap={Cookies.get(`StaffPortalJWT`) ? false : true}
                      auto_select
                      onSuccess={loginHandler}
                      onError={() => {
                        console.log('Login Failed');
                      }}
                    />
                  </div>
                </CardBody>
              </Card>
            </form>
          )}
        </GridItem>
      </GridContainer>
    </div>
  );
}
