import React, { useState } from 'react'
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Button from "components/CustomButtons/Button";
import { TextField } from '@material-ui/core';

import { makeStyles } from "@material-ui/core/styles";
import dashboardStyles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

const useStyles = makeStyles(() => ({
  ...styles,
  ...dashboardStyles,
  formSection: {
    marginBottom: "2rem",
  },
}));

/**
 * POC form for creating,editing and viewing POC Entries.
 * @param {object} props 
 * @prop {function} submitForm function to submit the form once it's completed
 * @prop {object} defaults default form values (optional)
 * @prop {boolean} viewOnly whether or not the form is a view only layout.
 * @returns 
 */
const PastoralCareForm = ({ submitForm, defaults, viewOnly }) => {
  const CHAR_LIMIT = 150
  const classes = useStyles();
  const [formType, setFormType] = useState(defaults ? defaults.formType : 'HospitalVisit')
  const [name, setName] = useState(defaults ? defaults.name : '')
  const [startTime, setStartTime] = useState(defaults ? defaults.dt : "2022-01-01T10:30")
  const [location, setLocation] = useState(defaults ? defaults.location : '')
  const [pastor, setPastor] = useState(defaults ? defaults.pastor : '')
  const [comment, setComment] = useState(defaults ? defaults.comment ? defaults.comment : '' : '')
  const [updates, setUpdates] = useState(defaults ? defaults.updates ? defaults.updates : '' : '')
  const [notes, setNotes] = useState(defaults ? defaults.notes ? defaults.notes : '' : '')
  const [startShowing, setStartShowing] = useState(defaults ? defaults.startShowing : "2022-01-01T10:30")
  const [stopShowing, setStopShowing] = useState(defaults ? defaults.stopShowing : "2022-01-01T10:30")
  const eventID = defaults ? defaults.id : ''
  const VIEW_ONLY = viewOnly ? viewOnly : false
  const originalFormType = defaults ? defaults.formType : ''

  const [submitted, setSubmitted] = useState(false)

  /**
   * reset the form to the default values.
   */
  const setDefaults = () => {
    setName('')
    setStartTime("2022-01-01T10:30")
    setLocation('')
    setPastor('')
    setComment('')
    setUpdates('')
    setNotes('')
    setStartShowing("2022-01-01T10:30")
    setStopShowing("2022-01-01T10:30")
    setSubmitted(false)
  }

  /**
   * check if a entry is invalid (empty string)
   * @param {string} value 
   * @returns 
   */
  const invalidEntry = (value) => {
    if (submitted) {
      if (value === '') {
        return true
      }
      return false
    }
    // not submitted yet
    return false
  }

  /**
   * check if a date is invalid
   * @param {string} value 
   * @returns 
   */
  const invalidDate = (value) => {
    if (submitted) {
      if (!isNaN(Date.parse(value))) {
        return false
      }
      return true
    }
    // not submitted yet
    return false
  }

  /**
   * verify the form is filled out before submitting
   */
  const verifyForm = async () => {
    setSubmitted(true)
    if (name !== '' && location !== '' && pastor !== '' && !isNaN(Date.parse(startShowing)) &&
      !isNaN(Date.parse(stopShowing)) && !isNaN(Date.parse(startTime))) {
      // valid form
      var formData
      if (formType === 'HospitalVisit') {
        formData = {
          id: eventID,
          dt: startTime,
          name: name,
          location: location,
          pastor: pastor,
          updates: updates,
          comment: comment,
          startShowing: startShowing,
          stopShowing: stopShowing,
          originalFormType: originalFormType
        }
      } else {
        formData = {
          id: eventID,
          dt: startTime,
          name: name,
          location: location,
          pastor: pastor,
          notes: notes,
          startShowing: startShowing,
          stopShowing: stopShowing,
          originalFormType: originalFormType
        }
      }
      await submitForm(formType, formData)
      setDefaults()
    }
  }

  /**
   * set the helper text based on if there value is invalid.
   * @param {string} value 
   * @returns 
   */
  const helperText = (value) => {
    if (invalidEntry(value)) {
      return 'This Field Cannot be Empty.'
    }
    return ''
  }

  return (
    <React.Fragment>
      <GridContainer>
        <GridItem xs={12} style={{ marginBottom: '15px' }}>
          <div style={{ display: 'flex' }}>
            <label style={{
              color: 'black', fontSize: '22px', marginRight: '20px',
              display: 'flex', alignItems: 'center', minWidth: '101px'
            }}>
              Type:
            </label>
            {!VIEW_ONLY || formType === 'HospitalVisit' ?
              <Button
                color={formType === 'HospitalVisit' ? 'facebook' : 'info'}
                onClick={(e) => setFormType('HospitalVisit')}
                tabIndex="-100"
              >
                Hospital Visit
              </Button>
              : <></>}
            {!VIEW_ONLY || formType === 'Funeral' ?
              <Button
                color={formType === 'Funeral' ? 'facebook' : 'info'}
                onClick={(e) => setFormType('Funeral')}
                tabIndex="-100"
              >
                Funeral
              </Button>
              : <></>}
          </div>
        </GridItem>
        <GridItem xs={12} md={12} style={{ marginBottom: '15px' }}>
          <div style={{ display: 'flex' }}>
            <label style={{
              color: 'black', fontSize: '22px', marginRight: '20px',
              display: 'flex', alignItems: 'center', minWidth: '101px'
            }}>
              Start:
            </label>
            <TextField
              id="datetime-local"
              label="Date/Time"
              type="datetime-local"
              value={startShowing}
              onChange={(e) => { if (!VIEW_ONLY) { setStartShowing(e.target.value) } }}
              className={classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
              error={invalidDate(startShowing)}
              helperText={'When event will appear on calendar.'}
            />
          </div>
        </GridItem>
        <GridItem xs={12} md={12} style={{ marginBottom: '15px' }}>
          <div style={{ display: 'flex' }}>
            <label style={{
              color: 'black', fontSize: '22px', marginRight: '20px',
              display: 'flex', alignItems: 'center', minWidth: '101px'
            }}>
              Stop:
            </label>
            <TextField
              id="datetime-local"
              label="Date/Time"
              type="datetime-local"
              value={stopShowing}
              onChange={(e) => { if (!VIEW_ONLY) { setStopShowing(e.target.value) } }}
              className={classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
              error={invalidDate(stopShowing)}
              helperText={'When event will be removed from the calendar.'}
            />
          </div>
        </GridItem>
        <GridItem xs={12} style={{ marginBottom: '15px' }}>
          <div style={{ display: 'flex' }}>
            <label style={{
              color: 'black', fontSize: '22px', marginRight: '20px',
              display: 'flex', alignItems: 'center', minWidth: '101px'
            }}>
              Who:
            </label>
            <TextField
              label='Name'
              variant='outlined'
              value={name}
              onChange={(e) => { if (!VIEW_ONLY) { setName(e.target.value) } }}
              style={{ backgroundColor: "#fff", width: '100%' }}
              error={invalidEntry(name)}
              helperText={helperText(name)}
            />
          </div>
        </GridItem>

        <GridItem xs={12}>
          <GridContainer>
            <GridItem xs={12} md={12} style={{ marginBottom: '15px' }}>
              <div style={{ display: 'flex' }}>
                <label style={{
                  color: 'black', fontSize: '22px', marginRight: '20px',
                  display: 'flex', alignItems: 'center', minWidth: '101px'
                }}>
                  When:
                </label>
                <TextField
                  id="datetime-local"
                  label="Date/Time"
                  type="datetime-local"
                  value={startTime}
                  onChange={(e) => { if (!VIEW_ONLY) { setStartTime(e.target.value) } }}
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={invalidDate(startTime)}
                />
              </div>
            </GridItem>
            <GridItem xs={12} md={12} style={{ marginBottom: '15px' }}>
              <div style={{ display: 'flex' }}>
                <label style={{
                  color: 'black', fontSize: '22px', marginRight: '20px',
                  display: 'flex', alignItems: 'center', minWidth: '101px'
                }}>
                  Where:
                </label>
                <TextField
                  label='location'
                  variant='outlined'
                  value={location}
                  onChange={(e) => { if (!VIEW_ONLY) { setLocation(e.target.value) } }}
                  style={{ backgroundColor: "#fff", width: '100%' }}
                  error={invalidEntry(location)}
                  helperText={helperText(location)}
                />
              </div>
            </GridItem>
          </GridContainer>
        </GridItem>
        <GridItem xs={12} style={{ marginBottom: '15px' }}>
          <div style={{ display: 'flex' }}>
            <label style={{
              color: 'black', fontSize: '22px', marginRight: '20px',
              display: 'flex', alignItems: 'center', minWidth: '101px'
            }}>
              Pastor:
            </label>
            <TextField
              label='Name'
              variant='outlined'
              value={pastor}
              onChange={(e) => { if (!VIEW_ONLY) { setPastor(e.target.value) } }}
              style={{ backgroundColor: "#fff", width: '100%' }}
              error={invalidEntry(pastor)}
              helperText={helperText(pastor)}
            />
          </div>
        </GridItem>

        {formType === 'HospitalVisit' ? <>
          <GridItem xs={12} style={{ marginBottom: '5px' }}>
            <div style={{ display: 'flex' }}>
              <label style={{
                color: 'black', fontSize: '22px', marginRight: '20px',
                display: 'flex', alignItems: 'center', minWidth: '101px'
              }}>
                Updates:
              </label>
              <TextField
                id="outlined-multiline-static"
                label="Updates"
                multiline
                rows={2}
                value={updates}
                onChange={(e) => {
                  {
                    if (!VIEW_ONLY) {
                      if (e.target.value.length <= CHAR_LIMIT) {
                        setUpdates(e.target.value)
                      } else {
                        e.preventDefault()
                      }
                    }
                  }
                }}
                variant="outlined"
                style={{ backgroundColor: "#fff", width: '100%' }}
                helperText={`Max ${CHAR_LIMIT} characters`}
              />
            </div>
          </GridItem>
          <GridItem xs={12} style={{ marginBottom: '5px' }}>
            <div style={{ display: 'flex' }}>
              <label style={{
                color: 'black', fontSize: '22px', marginRight: '20px',
                display: 'flex', alignItems: 'center', minWidth: '101px'
              }}>
                Comment:
              </label>
              <TextField
                id="outlined-multiline-static"
                label='Comment'
                multiline
                rows={2}
                value={comment}
                onChange={(e) => {
                  {
                    if (!VIEW_ONLY) {
                      if (e.target.value.length <= CHAR_LIMIT) {
                        setComment(e.target.value)
                      } else {
                        e.preventDefault()
                      }
                    }
                  }
                }}
                variant="outlined"
                style={{ backgroundColor: "#fff", width: '100%' }}
                helperText={`Max ${CHAR_LIMIT} characters`}
              />
            </div>
          </GridItem>
        </> : <>
          <GridItem xs={12} style={{ marginBottom: '5px' }}>
            <div style={{ display: 'flex' }}>
              <label style={{
                color: 'black', fontSize: '22px', marginRight: '20px',
                display: 'flex', alignItems: 'center', minWidth: '101px'
              }}>
                Notes:
              </label>
              <TextField
                id="outlined-multiline-static"
                label="Notes"
                multiline
                rows={2}
                value={notes}
                onChange={(e) => {
                  {
                    if (!VIEW_ONLY) {
                      if (e.target.value.length <= CHAR_LIMIT) {
                        setNotes(e.target.value)
                      } else {
                        e.preventDefault()
                      }
                    }
                  }
                }}
                variant="outlined"
                style={{ backgroundColor: "#fff", width: '100%' }}
                helperText={`Max ${CHAR_LIMIT} characters`}
              />
            </div>
          </GridItem>
        </>}
        {!VIEW_ONLY ?
          <GridItem xs={12}>
            <div
              style={{ display: 'flex', justifyContent: 'right' }}
            >
              <Button
                color="primary"
                onClick={verifyForm}
                tabIndex="-100"
              >
                Submit
              </Button>
            </div>
          </GridItem>
          : <></>}
      </GridContainer>

    </React.Fragment>
  )
}

export default PastoralCareForm